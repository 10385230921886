import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Header from "./header.js";
import Footer from "./footer.js";
import DefaultHome from "./defaulthome.js";
import Home from "./home.js";
import Repairing from "./repairing.js";
import Buysell from "./buysell.js";
import PullRequests from "./pullrequests.js";
import MapLoad from "./get_map.js";

import "./App.css";
import RecyclingPickup from "./recyclingPickup.js";
import HowITWork from "./how_it_work.js";
import BlogDetails from "./blog_details.js";
import TermsOfUse from "./terms_of_use.js";
import PrivacyPolicy from "./privacyPolicy.js";
import Faq from "./faq.js";
import Blog from "./blog.js";
import CoppellCity from "./coppell_city.js";
import PlanoCity from "./plano_city.js";
import Blogs from "./blogs.jsx";
import AssetsManagement from "./assets_management.js";
import DallasPawnShop from "./dallas_pawn_shop.js";
import SellItEquipment from "./sellItEquipment.js";


function App(props) {
  return (
    <React.Fragment>
      <Router>
        <Header />
        <Switch>
          <Route exact path="/" component={DefaultHome} />
          <Route exact path="/repairing" component={Home} />
          <Route exact path="/how-it-works" component={HowITWork} />
          {/* <Route exact  path="/recycling" component={Repairing} /> */}
          <Route exact path="/recycling" component={RecyclingPickup} />
          <Route exact path="/sell-it-equipment" component={SellItEquipment} />
          <Route exact path="/sell" component={Buysell} />
          <Route exact path="/pullRequests" component={PullRequests} />
          <Route exact path="/getMap" component={MapLoad} />
          <Route exact path="/TermsOfUse" component={TermsOfUse} />
          <Route exact path="/PrivacyPolicy" component={PrivacyPolicy} />
          <Route exact path="/our-blogs" component={Blogs} />
          
          <Route
            exact
            path="/assets-management-and-disposal"
            component={AssetsManagement}
          />
          <Route exact path="/pawn-shop-in-dallas" component={DallasPawnShop} />

          <Route exact path="/faq" component={Faq} />
          <Route exact path="/blog" component={Blog} />
          <Route exact path="/tx/coppell" component={CoppellCity} />
          <Route exact path="/tx/plano" component={PlanoCity} />
          {/* Blog Details url must be at the end */}
          <Route exact path="/:id" component={BlogDetails} />
          {/* Blog Details url must be at the end */}
        </Switch>
        <Footer />
      </Router>
    </React.Fragment>
  );
}

export default App;
