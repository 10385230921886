import React from "react";
import { posturl } from "./configuration.js";
import $ from "jquery";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//import ReactDOM from 'react-dom';
var that = "";
$(document).ready(function () {
  $(".QuickLinks a").on("click", function (event) {
    if (this.hash !== "") {
      event.preventDefault();

      var hash = this.hash;
      var pathname = window.location.pathname;
      if (pathname == "/") {
        $("html, body").animate(
          {
            scrollTop: $(hash).offset().top,
          },
          800,
          function () {
            window.location.hash = hash;
          }
        );
      } else if (hash == "#ContactUs") {
        if (pathname == "/our-blogs") {
          window.location.replace("/" + hash);
          return;
        }
        $("html, body").animate(
          {
            scrollTop: $(hash).offset().top,
          },
          800,
          function () {
            window.location.hash = hash;
          }
        );
      } else {
        window.location.replace("/" + hash);
      }
    }
  });
});
class Footer extends React.Component {
  constructor(props) {
    // var getUrl = window.location;
    // var finalurl = getUrl.protocol + "//" + getUrl.hostname;
    var finalurl = posturl;
    super(props);
    that = this;
    this.state = {
      baseUrl: finalurl,
      map_string: "",
      userName: "",
      userEmail: "",
      userMessage: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.subscribeForm = this.subscribeForm.bind(this);
  }
  //   notify = () => toast("Your Request Is Submited !");

  handleChange(e) {
    const name = e.target.name;
    this.setState({ [name]: e.target.value });
  }
  subscribeForm = (e) => {
    e.preventDefault();
    $("div#loader-container").removeClass("hidden");
    var userName = this.state.userName;
    var userEmail = this.state.userEmail;
    var userMessage = this.state.userMessage;
    if (!userName) {
      toast("Name Requred !");
      $("div#loader-container").addClass("hidden");
      return false;
    }
    if (!userEmail) {
      toast("Email Id Required !");
      $("div#loader-container").addClass("hidden");
      return false;
    }
    if (!userMessage) {
      toast("Message Required !");
      $("div#loader-container").addClass("hidden");
      return false;
    }
    let insertUrl =
      this.state.baseUrl +
      "/laptopzone/reactcontroller/Lz_website/c_lz_subscribe/lz_customer_subscriber";

    new Promise(function (resolve, reject) {
      $.ajax({
        url: insertUrl,
        dataType: "json",
        type: "POST",
        data: {
          userName: userName,
          userEmail: userEmail,
          userMessage: userMessage,
        },
      }).then(
        function (data) {
          resolve(data);
        },
        function (err) {
          reject(err);
        }
      );
    })
      .then((result) => {
        if (result.send) {
          $("div#loader-container").addClass("hidden");
          this.setState({
            userName: "",
            userEmail: "",
            userMessage: "",
          });
          toast("We will get back to you asap.");
          $("#subscribeEmail")[0].reset();
        } else {
          toast("Something went wrong");
          return false;
        }
      })
      .catch((err) => {
        $("div#loader-container").addClass("hidden");
        //$.LoadingOverlay("hide");
        console.log(err);
      });

    return false;
  };
  componentDidMount() {
    var pathname = window.location.pathname;
    console.log(pathname);
    if (pathname == "/tx/plano") {
      this.setState({
        map_string:
          "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d214004.532760014!2d-96.8767103341545!3d33.0611837034058!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x864c21da13c59513%3A0x62aa036489cd602b!2sPlano%2C%20TX%2C%20USA!5e0!3m2!1sen!2s!4v1580209878491!5m2!1sen!2s",
      });
      $(".dallas").removeClass("active");
      $(".plano").addClass("active");
      $(".coppell").removeClass("active");
    } else if (pathname == "/tx/coppell") {
      this.setState({
        map_string:
          "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d107126.87841419458!2d-97.05271565518923!3d32.95853445833818!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x864c29392775ee31%3A0xce442bcc373769fd!2sCoppell%2C%20TX%2C%20USA!5e0!3m2!1sen!2s!4v1580210084602!5m2!1sen!2s",
      });
      $(".dallas").removeClass("active");
      $(".plano").removeClass("active");
      $(".coppell").addClass("active");
    } else {
      this.setState({
        map_string:
          "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3350.134371626243!2d-96.88898108443756!3d32.89461538528623!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x864c278e23c47297%3A0x23f1d4509dbc6449!2sLaptopZone%20%7C%20Buy-Sell%20Used%20Iphone%20in%20Dallas%20TX!5e0!3m2!1sen!2s!4v1580209453071!5m2!1sen!2s",
      });
      $(".dallas").addClass("active");
      $(".plano").removeClass("active");
      $(".coppell").removeClass("active");
    }
  }
  render() {
    console.log(window.location.pathname);
    return (
      <React.Fragment>
        <div id="loader-container" className="loader-container hidden">
          <svg
            className="spinner"
            viewBox="0 0 66 66"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              className="path"
              fill="none"
              strokeLinecap="round"
              cx="33"
              cy="33"
              r="30"
            ></circle>
          </svg>

          <div className="icon">
            <img src="assets/image/main_logo.svg" />
          </div>
        </div>
        <ToastContainer
          position="top-right"
          autoClose={2500}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />
        {window.location.pathname != "/our-blogs" ? (
          <React.Fragment>
            <hr />
            <footer className="footerSection" id="ContactUs">
              <div className="container">
                <div className="row">
                  <div className="col-md-12 col-sm-12">
                    <div className="footerHead">
                      <h2>
                        Contact <span>us</span>
                      </h2>
                      <p>
                        Feel free to contact us! We are available
                        <br /> 24/7 for your assistance.
                      </p>
                    </div>
                  </div>

                  <div className="col-md-6 col-sm-12">
                    <h5 className="formHeading">Send us a message</h5>
                    <div className="ContactForm">
                      <form
                        id="subscribeEmail"
                        onSubmit={this.subscribeForm}
                        method="post"
                      >
                        <input
                          type="name"
                          id="userName"
                          name="userName"
                          onChange={this.handleChange}
                          placeholder="YOUR NAME"
                        />
                        <input
                          type="email"
                          id="userEmail"
                          name="userEmail"
                          onChange={this.handleChange}
                          placeholder="YOUR EMAIL"
                        />
                        <textarea
                          id="userMessage"
                          name="userMessage"
                          onChange={this.handleChange}
                          placeholder="MESSAGE"
                        ></textarea>
                        <button type="submit">Send</button>
                      </form>
                    </div>
                  </div>

                  <div className="col-md-6 col-sm-12">
                    <div className="OfficeAddress">
                      <h1>US Office</h1>
                      <p>
                      Suite 180, 2720 Royal Lane, Dallas, TX, 75229
                        <br />
                        United States
                      </p>
                    </div>

                    <div className="footerPhone">
                      <div className="PhoneLeft">
                        <h3>Phone number</h3>
                        <i className="icon-phone_number"></i>
                        <h2>(214) 205-0950</h2>
                      </div>

                      <div className="PhoneLeft">
                        <h3>Email Address</h3>
                        <i className="fa fa-envelope-o" aria-hidden="true"></i>
                        <h2>info@laptopzone.us</h2>
                      </div>
                    </div>

                    <div className="footerSocialMedia">
                      <h3>Social Support</h3>

                      <div className="socialMediaLeft">
                        <a
                          href="https://twitter.com/LaptopZoneUS"
                          target="_blank"
                        >
                          <i className="fa fa-twitter"></i>
                          /LaptopZoneUS
                        </a>
                      </div>

                      <div className="socialMediaLeft">
                        <a
                          href="https://www.facebook.com/LaptopZonedallas/"
                          target="_blank"
                        >
                          <i className="fa fa-facebook"></i>
                          /LaptopZonedallas
                        </a>
                      </div>

                      <div className="socialMediaLeft">
                        <a
                          href="https://www.instagram.com/laptopzone.us/"
                          target="_blank"
                        >
                          <i className="fa fa-instagram"></i>
                          /laptopzone.us
                        </a>
                      </div>

                      {/*<div className="socialMediaLeft">
		          <a>
		           <i className="fa fa-linkedin"></i>
		           LaptopZone
		          </a>
		         </div>*/}
                    </div>
                  </div>
                </div>
              </div>
            </footer>
          </React.Fragment>
        ) : null}

        <section className="BottomSection">
          <div className="container">
            <div className="row">
              <div className="col-md-4 col-sm-12 width-deincrease">
                <div className="BottomAdd-Image">
                  <img
                    src="/assets/image/ft-logo.png"
                    width="211"
                    height="42"
                    alt=""
                  />
                  <div className="addborder"></div>
                  <p>
                    The Best Local Place to Sell/Buy,
                    <br /> Repair and Recycle Used Electronics
                    <br /> with the Fastest, Safest, Easiest and
                    <br /> Professional Services.
                  </p>

                  <div className="Bottom-SocialMedia">
                    <h1>Social Media</h1>
                    <ul>
                      <li>
                        <a
                          href="https://www.facebook.com/LaptopZonedallas/"
                          target="_blank"
                        >
                          <i className="fa fa-facebook"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://twitter.com/LaptopZoneUS"
                          target="_blank"
                        >
                          <i className="fa fa-twitter"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.instagram.com/laptopzone.us/"
                          target="_blank"
                        >
                          <i className="fa fa-instagram"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-md-2 col-sm-12">
                <div className="QuickLinks">
                  <h2>Quick Links</h2>
                  <ul>
                    <li>
                      <a href="#AboutUs">About Us</a>
                    </li>
                    <li>
                      <a href="/how-it-works">How it works</a>
                    </li>
                    <li>
                      <a href="/">Our Services</a>
                    </li>
                    <li>
                      <a href="#AboutUs">What we do</a>
                    </li>
                    <li>
                      <a href="#ContactUs">Contact us</a>
                    </li>
                    <li>
                      <a href="/assets-management-and-disposal">
                        Assets Management
                      </a>
                    </li>
                    <li>
                      <a href="/pawn-shop-in-dallas">Pawn Shop</a>
                    </li>
                    <li>
                      <a href="/faq">Faq</a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-md-3 col-sm-12">
                <div className="QuickLinks">
                  <h2>Our Services</h2>
                  <ul>
                    <li>
                      <a href="/repairing">Repairing</a>
                    </li>
                    <li>
                      <a href="/recycling">Recycling</a>
                    </li>
                    <li>
                      <a href="/sell">Buy & Trade</a>
                    </li>
                    <li>
                      <a href="/sell-it-equipment">Sell IT Equipment</a>
                    </li>
                  </ul>
                </div>

                <div className="QuickPhone">
                  <ul>
                    <li>
                      <a href="tel:2142050950">
                        <i className="fa fa-phone"></i> (214) 205-0950
                      </a>
                    </li>
                    <li>
                      <a href="mailto:info@laptopzone.us">
                        <i className="fa fa-envelope"></i> info@laptopzone.us
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-md-3 col-sm-12">
                <div className="QuickLinks">
                  <h2>Our Services</h2>
                </div>

                <div className="CopellMenu">
                  <ul>
                    <li className="active dallas">
                      <a href="#" target="_blank">
                        Dallas
                      </a>
                    </li>
                    <li className="coppell">
                      <a href="/tx/coppell" target="_blank">
                        Coppell
                      </a>
                    </li>
                    <li className="plano">
                      <a href="/tx/plano" target="_blank">
                        Plano
                      </a>
                    </li>
                  </ul>
                </div>

                <div className="clearfix"></div>

                <div className="Copell-Image">
                  <iframe
                    src={this.state.map_string}
                    width="264"
                    height="166"
                    frameborder="0"
                    allowfullscreen=""
                  ></iframe>

                  {/* <img
                    src="./assets/image/map-address.png"
                    width="264"
                    height="166"
                    alt=""
                  /> */}
                </div>
              </div>
            </div>
          </div>

          <div className="CopyRight-Sec">
            <div className="container">
              <div className="row">
                <div className="col-md-4 col-sm-12">
                  <div className="FlagUsa">
                    <img
                      src="/assets/image/flad-usa.png"
                      width="27"
                      height="17"
                      alt=""
                    />
                    <p>United States</p>
                    <h3>© Copyright 2010–2020</h3>
                  </div>
                </div>

                <div className="col-md-8 col-sm-12">
                  <div className="CopyRight-Menu">
                    <ul>
                      <li>
                        <a href="#">Our process</a>
                      </li>
                      <li>
                        <a href="#">Terms of use</a>
                      </li>
                      <li>
                        <a href="#">Company Details</a>
                      </li>
                      <li>
                        <a href="#">Privacy Policy</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
export default Footer;
