import React, { Component } from "react";
import $ from "jquery";

class Faq extends React.Component {

  render() {
    return (
      <React.Fragment>
        
    <div className="FaqSearch-bg">
    <div className="container">
     <div className="row">	
   	 
   	  <div className="col-md-10 offset-md-1 col-sm-12">
   	   <div className="FaqSearch-barcontent">
   	   	<h1>How can we <span>help</span> you?</h1>
   	   	<input type="text" id="#" name="#" placeholder="Discribe your issue" />
   	   	<button type="submit" value=""><i className="fa fa-search"></i></button>
   	   </div>
   	  </div>
   	 	
     </div>
    </div>
   </div>
      
   <div className="container">
   	<div className="row">
   		
   	 <div className="col-md-12 col-sm-12">
   	  <div className="Frequently-asked-Content">
   	   <h2>Frequently asked<br /> questions</h2>	
   	  </div>
   	 </div>
   	 
   	 <div className="col-md-12 col-sm-12">
   	  <div className="AccordionTab">
   	  	        
        <div id="accordion" className="accordion">
         <div className="card mb-0">
           
            <div className="card-header collapsed" data-toggle="collapse" href="#collapseOne">
             <a className="card-title">How much do you charge for your services?</a>
            </div>
            
            <div id="collapseOne" className="card-body collapse" data-parent="#accordion" >
             <p>We charge $3.20 per listing and .10 cents each additional same item.</p>
            </div>
            
            <div className="card-header collapsed" data-toggle="collapse" data-  parent="#accordion" href="#collapseTwo">
              <a className="card-title">Define cost?</a>
            </div>
            
            <div id="collapseTwo" className="card-body collapse" data-parent="#accordion" >
             <p>We are on of the most competitive service provider in U.S. Please contact us from Pricing.</p>
            </div>
            
            <div className="card-header collapsed" data-toggle="collapse" data-  parent="#accordion" href="#collapseThree">
              <a className="card-title">Can we sell single products using your services?</a>
            </div>
            
            <div id="collapseThree" className="card-body collapse" data-parent="#accordion" >
             <p>Yes, we deal with people selling single products separately. Products don’t necessarily have to be in bulks.</p>
            </div>
            
            <div className="card-header collapsed" data-toggle="collapse" data-  parent="#accordion" href="#collapseFour">
              <a className="card-title">Can the sellers take pictures themselves?</a>
            </div>
            
            <div id="collapseFour" className="card-body collapse" data-parent="#accordion" >
             <p>Yes, we have an app through which you can take pictures and send us for further listing processes</p>
            </div>
            
            <div className="card-header collapsed" data-toggle="collapse" data-  parent="#accordion" href="#collapseFive">
              <a className="card-title">Who receives the money?</a>
            </div>
            
            <div id="collapseFive" className="card-body collapse" data-parent="#accordion" >
             <p>Seller will get the money through PayPal. If you are an international client and wish to Sell on eBay U.S please contact us.</p>
            </div>
            
            <div className="card-header collapsed" data-toggle="collapse" data-  parent="#accordion" href="#collapseSix">
              <a className="card-title">Where will you keep our products?</a>
            </div>
            
            <div id="collapseSix" className="card-body collapse" data-parent="#accordion" >
             <p>If ListJeannie is doing A-Z service from Inventory to Order Fulfillment then Inventor need to be in ListJeannie Fulfillment Located Dallas, TX. If Client decide to do fulfill Order at their own then ListJeannie can do Listing Alone.</p>
            </div>
            
         </div>
        </div>
   		
   	  </div>
     </div>
   	 
   	 </div>
	</div>
        
          
      </React.Fragment>
    );
  }
}
export default Faq;
