import React, { Component } from "react";
import $ from "jquery";
//import add from "./configfile.js";
import "react-phone-number-input/style.css";
import PhoneInput, {
  isValidPhoneNumber,
  parsePhoneNumber,
  formatPhoneNumber
} from "react-phone-number-input";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Map from "./map.js";
import ShipingMap from "./shiping_map.js";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from "./getSrc.js";

import { posturl } from "./configuration.js";
import DropOffMap from "./drop_off_map.js";
var that = "";

const google = window.google;
var rp_arr;
class PullRequests extends React.Component {
  constructor(props) {
    // var rp_arr = window.location.pathname;
    rp_arr = window.location.pathname.split("/");
    if (rp_arr[2]) {
      var rep_id = rp_arr[2];
    } else {
      var rep_id = "";
    }
    // var finalurl = getUrl.protocol + "//" + getUrl.hostname;
    var finalurl = posturl;

    super(props);
    that = this;
    this.state = {
      baseUrl: finalurl,

      fields: { address: "", city: "", area: "", state: "", zipcode: "" },
      getRepair: [],
      getShipmentCharges: [],
      ship_id: "",
      rate_id: "",
      label_remote_url: "",
      searcInput: rep_id,
      ship_height: "",
      ship_lenght: "",
      ship_weight: "",
      ship_width: "",
      showData: "hide",
      showOptions: "hide",

      dropData: "hide",
      picData: "hide",
      shipData: "hide",
      origin: "2720 Royal Ln, Dallas, TX 75229, USA",
      destination: "2720 Royal Ln, Dallas, TX 75229, USA",
      getRadioVale: ""
    };
    this.handleInput = this.handleInput.bind(this);
    this.handleInput1 = this.handleInput1.bind(this);
    this.getRepairRequest = this.getRepairRequest.bind(this);
    this.getShippingCharges = this.getShippingCharges.bind(this);
    this.getLabel = this.getLabel.bind(this);
    this.showOptions = this.showOptions.bind(this);
    this.showSelecOption = this.showSelecOption.bind(this);

    this.handleSelect = this.handleSelect.bind(this);
    this.handleSelect1 = this.handleSelect1.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChange1 = this.handleChange1.bind(this);
    this.renderGeocodeFailure = this.renderGeocodeFailure.bind(this);
    this.renderGeocodeSuccess = this.renderGeocodeSuccess.bind(this);
    this.saveInfo = this.saveInfo.bind(this);
  }
  handleSelect1(address1) {
    this.setState({
      address1,
      loading: true,
      showShipMap: "hide"
    });
    if (address1.button == 0) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(position => {
          console.log("1");
          console.log(position.coords);

          this.setState({
            currentLatLng1: {
              lat: position.coords.latitude,
              lng: position.coords.longitude
            },
            address1: "Your pin location",
            showShipMap: "show",
            loading: false
          });
        });
      }
    } else {
      geocodeByAddress(address1)
        .then(results => getLatLng(results[0]))
        .then(({ lat, lng }) => {
          console.log("Success Yay", { lat, lng });
          this.setState({
            geocodeResults1: this.renderGeocodeSuccess(lat, lng),
            currentLatLng1: {
              lat: lat,
              lng: lng
            },
            showShipMap: "show",
            loading: false
          });
        })
        .catch(error => {
          console.log("Oh no!", error);
          this.setState({
            geocodeResults1: this.renderGeocodeFailure(error),
            loading: false
          });
        });
    }
  }
  handleSelect(address) {
    this.setState({
      address,
      loading: true,
      showMap: "hide"
    });
    if (address.button == 0) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(position => {
          console.log("1");
          console.log(position.coords);

          this.setState({
            currentLatLng: {
              lat: position.coords.latitude,
              lng: position.coords.longitude
            },
            address: "Your pin location",
            showMap: "show",
            loading: false
          });
        });
      }
    } else {
      geocodeByAddress(address)
        .then(results => getLatLng(results[0]))
        .then(({ lat, lng }) => {
          console.log("Success Yay", { lat, lng });
          this.setState({
            geocodeResults: this.renderGeocodeSuccess(lat, lng),
            currentLatLng: {
              lat: lat,
              lng: lng
            },
            showMap: "show",
            loading: false
          });
        })
        .catch(error => {
          console.log("Oh no!", error);
          this.setState({
            geocodeResults: this.renderGeocodeFailure(error),
            loading: false
          });
        });
    }
    /* NOTE: Using callback (Deprecated version) */
    // geocodeByAddress(address,  (err, { lat, lng }) => {
    //   if (err) {
    //     console.log('Oh no!', err)
    //     this.setState({
    //       geocodeResults: this.renderGeocodeFailure(err),
    //       loading: false
    //     })
    //   }
    //   console.log(`Yay! got latitude and longitude for ${address}`, { lat, lng })
    //   this.setState({
    //     geocodeResults: this.renderGeocodeSuccess(lat, lng),
    //     loading: false
    //   })
    // })
  }
  handleChange(address) {
    //alert(address);
    this.setState({
      address,
      geocodeResults: null
    });
  }
  handleChange1(address1) {
    // alert(address1);
    this.setState({
      address1,
      geocodeResults1: null
    });
    if (this.state.fields.address) {
      this.setState({
        showShipMap: "hide",
        dropData: "hide"
      });
      $(".getCharge").removeClass("hide");
    }
  }

  renderGeocodeFailure(err) {
    return (
      <div className="alert alert-danger" role="alert">
        <strong>Error!</strong> {err}
      </div>
    );
  }

  renderGeocodeSuccess(lat, lng) {
    return (
      <div className="alert alert-success" role="alert">
        <strong>Success!</strong> Geocoder found latitude and longitude:{" "}
        <strong>
          {lat}, {lng}
        </strong>
      </div>
    );
  }

  notify = () => toast("Please Enter Your Repair No ");
  errorMesg = () => toast("Sorry ! No Record Found");

  handleInput(e) {
    const name = e.target.name;

    this.setState({ [name]: e.target.value });
    $(".labelPrint").addClass("hide");
    $(".getShip").addClass("hide");
    $(".getCharge").removeClass("hide");
    $(".getLabel").addClass("hide");
    this.setState({
      getShipmentCharges: []
    });
  }
  handleInput1(e) {
    const name = e.target.name;
    this.setState({ [name]: e.target.value });
  }
  showOptions() {
    this.setState({ showOptions: "show" });
  }

  showSelecOption(option) {
    if (option == "drop") {
      this.setState({
        dropData: "show",
        picData: "hide",
        shipData: "hide",
        getRadioVale: "1"
      });
    } else if (option == "pic") {
      this.setState({
        dropData: "hide",
        picData: "show",
        shipData: "hide",
        getRadioVale: "2"
      });
    } else if (option == "ship") {
      this.setState({
        dropData: "hide",
        picData: "hide",
        shipData: "show",
        getRadioVale: "3"
      });
    }

    console.log(option);
  }

  getRepairRequest() {
    $("div#loader-container").removeClass("hidden");
    this.setState({
      showData: "hide",
      showOptions: "hide",
      dropData: "hide",
      picData: "hide",
      shipData: "hide",
      address: "",
      address1: "",
      showMap: "hide",
      showShipMap: "hide",
      ship_height: "",
      ship_lenght: "",
      ship_weight: "",
      ship_width: "",
      ship_id: "",
      rate_id: ""
    });

    var searcInput = this.state.searcInput;

    if (!this.state.searcInput) {
      $("div#loader-container").addClass("hidden");
      this.notify();

      return false;
    }

    let insertUrl =
      this.state.baseUrl + "/laptopzone/reactcontroller/c_react/get_repairs";
    var ljw_getobject = []; //'adi;l asada  yahoo';

    new Promise(function(resolve, reject) {
      $.ajax({
        url: insertUrl,
        dataType: "json",
        type: "POST",
        data: { searcInput: searcInput }
      }).then(
        function(data) {
          resolve(data);
        },
        function(err) {
          reject(err);
        }
      );
    })
      .then(result => {
        if (result.exist == true) {
          $("div#loader-container").addClass("hidden");

          this.setState({ showData: "show", getRepair: result.get_data });
        } else if (result.exist == false) {
          $("div#loader-container").addClass("hidden");
          this.errorMesg();
          this.setState({ showData: "hide", getRepair: result.get_data });
        }
      })
      .catch(err => {
        //$.LoadingOverlay("hide");
        console.log(err);
      });
    return false;
  }
  getShippingCharges() {
    $("div#loader-container").removeClass("hidden");
    var ship_height = this.state.ship_height;
    var ship_lenght = this.state.ship_lenght;
    var ship_weight = this.state.ship_weight;
    var ship_width = this.state.ship_width;
    // var ship_address = this.state.address1;
    // var address_id = 1;
    var street1 = this.state.fields.address;
    var city = this.state.fields.city;
    var state = this.state.fields.state;
    var zip = this.state.fields.zipcode;
    // var area = this.state.fields.area;
    if (!street1) {
      $("div#loader-container").addClass("hidden");
      toast("Street address required !");
      return false;
    }
    if (!city) {
      $("div#loader-container").addClass("hidden");
      toast("City required !");
      return false;
    }
    if (!state) {
      $("div#loader-container").addClass("hidden");
      toast("State required !");
      return false;
    }
    if (!zip) {
      $("div#loader-container").addClass("hidden");
      toast("Zipcode required !");
      return false;
    }
    if (!street1) {
      $("div#loader-container").addClass("hidden");
      toast("Street address required !");
      return false;
    }
    console.log(this.state.fields.address);

    // if(! this.state.address1){
    // 	$("div#loader-container").addClass("hidden");
    // 	toast("Shpping address required !");

    // 	return false;
    // }

    if (!this.state.ship_lenght) {
      $("div#loader-container").addClass("hidden");
      toast("Lenght required !");

      return false;
    }
    if (!this.state.ship_width) {
      $("div#loader-container").addClass("hidden");
      toast("Width required !");

      return false;
    }
    if (!this.state.ship_height) {
      $("div#loader-container").addClass("hidden");
      toast("Height required !");

      return false;
    }
    if (!this.state.ship_weight) {
      $("div#loader-container").addClass("hidden");
      toast("Weight required !");

      return false;
    }

    let insertUrl =
      "http://71.78.236.22/laptop-zone-stage/public/api/dummy/shipment-rates";
    new Promise(function(resolve, reject) {
      $.ajax({
        url: insertUrl,
        dataType: "json",
        type: "POST",
        data: {
          weight: ship_weight,
          length: ship_lenght,
          width: ship_width,
          height: ship_height,
          city: city,
          zip: zip,
          street: street1,
          street: street1,
          state: state,
          phone: "+1539888550"
        },
        headers: {
          Authorization:
            "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6ImQ3NmJhYTkzZDBlMjZmY2UxNjA0ODE0MmM1YmJhNGZhOTc3NTAxOGQ2MDNjZDM3OTU1Njg4NjkxZjlkOWQ5YzZlZTlhN2JjZTc3OTMyOTU0In0.eyJhdWQiOiIxIiwianRpIjoiZDc2YmFhOTNkMGUyNmZjZTE2MDQ4MTQyYzViYmE0ZmE5Nzc1MDE4ZDYwM2NkMzc5NTU2ODg2OTFmOWQ5ZDljNmVlOWE3YmNlNzc5MzI5NTQiLCJpYXQiOjE1Njg3MjAwMzQsIm5iZiI6MTU2ODcyMDAzNCwiZXhwIjoxNjAwMzQyNDM0LCJzdWIiOiIxMiIsInNjb3BlcyI6W119.uwyP1k9wfw7ATlF2IByHDtZqeo8wF2EB13SMv1sz-_Z4QUibovIOnZuhUx5PVPVjR2YpfiANrw015bJhnDHkheFcz7iM4ik26ox4J8bPNCk3QmkesF3mJNYKaZ57uK-NgltEYkN0LiH25xm2vAUAv-_lOSmORerdqBvt7JHWjGgGS6SKDzC17WS6-voDA-oTx_LmqCnlv4qo32tonMf3Wkhqzjm-nUsTonDgE-Aa-U4D4GqFuZudq4LewXItyPYC-AlPfYvlnKorCL6bawTffHNTlkwAX1arjj47z8f8M9_OpF4ixQeFpdHJWnUm_qAm0E3vFb6DSJicC1ndSBpZHqNPKFL14VzHMT1vYQZEPdUan0buelBB2wf7ylq9b77qfAVsFkDP8C5xL8u-6XFOoZj8m57zYqScYTr69nWp0N4IpT_Ihcm2kuhy3HGNlgYCqGDkCfTP8kfCGAshlWqo59usL7y9rsr1YFyV2dwTkJJM1RTssUKQSjw1Na_vxgh99bmytJ4ZRQ6RjYWJ9iWHMUPMSHtMbY3olSKSTm01unz__en3WHlUKXqqfowGS05zXoaviqwwfgRYjZ3_ROm8P-8m3LEMICPNeJl4sidlTPccoJFi3IQfWTvAs0u6HMQxMf4WCsY1g-MXwmi3mZUAHCqy_Kz7BPoERH-OI_5OhxM"
        }
      }).then(
        function(data) {
          resolve(data);
        },
        function(err) {
          reject(err);
        }
      );
    })
      .then(result => {
        console.log(result);
        if (result) {
          $("div#loader-container").addClass("hidden");
          this.setState({
            showData: "show",
            getShipmentCharges: result.rates,
            ship_id: result.id
          });
          $(".getShip").removeClass("hide");
          $(".getLabel").removeClass("hide");
          $(".getCharge").addClass("hide");
        }
      })
      .catch(err => {
        $("div#loader-container").addClass("hidden");
        //$.LoadingOverlay("hide");
        console.log(err);
      });
    return false;
  }
  getLabel() {
    this.saveInfo();
    $("div#loader-container").removeClass("hidden");
    var shipment_id = this.state.ship_id;
    var rate_id = this.state.rate_id;
    var searcInput = this.state.searcInput;

    if (!this.state.ship_id) {
      $("div#loader-container").addClass("hidden");
      toast("Shpping id required !");

      return false;
    }
    if (!this.state.rate_id) {
      $("div#loader-container").addClass("hidden");
      toast("Select Rate  !");

      return false;
    }

    let insertUrl =
      "http://71.78.236.22/laptop-zone-stage/public/api/dummy/register";
    new Promise(function(resolve, reject) {
      $.ajax({
        url: insertUrl,
        dataType: "json",
        type: "POST",
        data: {
          shipment_id: shipment_id,
          rate_id: rate_id,
          request_id: searcInput,
          request_type: "REPAIRING"
        },
        headers: {
          Authorization:
            "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6ImQ3NmJhYTkzZDBlMjZmY2UxNjA0ODE0MmM1YmJhNGZhOTc3NTAxOGQ2MDNjZDM3OTU1Njg4NjkxZjlkOWQ5YzZlZTlhN2JjZTc3OTMyOTU0In0.eyJhdWQiOiIxIiwianRpIjoiZDc2YmFhOTNkMGUyNmZjZTE2MDQ4MTQyYzViYmE0ZmE5Nzc1MDE4ZDYwM2NkMzc5NTU2ODg2OTFmOWQ5ZDljNmVlOWE3YmNlNzc5MzI5NTQiLCJpYXQiOjE1Njg3MjAwMzQsIm5iZiI6MTU2ODcyMDAzNCwiZXhwIjoxNjAwMzQyNDM0LCJzdWIiOiIxMiIsInNjb3BlcyI6W119.uwyP1k9wfw7ATlF2IByHDtZqeo8wF2EB13SMv1sz-_Z4QUibovIOnZuhUx5PVPVjR2YpfiANrw015bJhnDHkheFcz7iM4ik26ox4J8bPNCk3QmkesF3mJNYKaZ57uK-NgltEYkN0LiH25xm2vAUAv-_lOSmORerdqBvt7JHWjGgGS6SKDzC17WS6-voDA-oTx_LmqCnlv4qo32tonMf3Wkhqzjm-nUsTonDgE-Aa-U4D4GqFuZudq4LewXItyPYC-AlPfYvlnKorCL6bawTffHNTlkwAX1arjj47z8f8M9_OpF4ixQeFpdHJWnUm_qAm0E3vFb6DSJicC1ndSBpZHqNPKFL14VzHMT1vYQZEPdUan0buelBB2wf7ylq9b77qfAVsFkDP8C5xL8u-6XFOoZj8m57zYqScYTr69nWp0N4IpT_Ihcm2kuhy3HGNlgYCqGDkCfTP8kfCGAshlWqo59usL7y9rsr1YFyV2dwTkJJM1RTssUKQSjw1Na_vxgh99bmytJ4ZRQ6RjYWJ9iWHMUPMSHtMbY3olSKSTm01unz__en3WHlUKXqqfowGS05zXoaviqwwfgRYjZ3_ROm8P-8m3LEMICPNeJl4sidlTPccoJFi3IQfWTvAs0u6HMQxMf4WCsY1g-MXwmi3mZUAHCqy_Kz7BPoERH-OI_5OhxM"
        }
      }).then(
        function(data) {
          resolve(data);
        },
        function(err) {
          reject(err);
        }
      );
    })
      .then(result => {
        console.log(result);
        if (result) {
          $("div#loader-container").addClass("hidden");
          this.setState({
            label_remote_url: result.label_remote_url
          });
          // this.setState({
          //   showOptions: "hide"
          // });
          $(".labelPrint").removeClass("hide");
          $(".getLabel").addClass("hide");
        }
      })
      .catch(err => {
        //$.LoadingOverlay("hide");

        $("div#loader-container").addClass("hidden");
        toast(err.responseJSON.message);
        // console.log(err);
      });
    return false;
  }
  saveInfo() {
    $("div#loader-container").removeClass("hidden");
    var requestId = this.state.searcInput;
    var getRadioVale = this.state.getRadioVale;

    if (!requestId) {
      $("div#loader-container").addClass("hidden");
      this.notify();

      return false;
    }
    let insertUrl =
      this.state.baseUrl +
      "/laptopzone/reactcontroller/Lz_website/c_lz_recycle/save_pull_request";
    new Promise(function(resolve, reject) {
      $.ajax({
        url: insertUrl,
        dataType: "json",
        type: "POST",
        data: {
          getRadioVale: getRadioVale,
          requestId: requestId
        }
      }).then(
        function(data) {
          resolve(data);
        },
        function(err) {
          reject(err);
        }
      );
    })
      .then(result => {
        console.log(result);
        if (result.save) {
          $("div#loader-container").addClass("hidden");
          if (getRadioVale != 3) {
            toast(result.message);
            this.setState({
              showOptions: "hide"
            });
            $(".dropConti").addClass("hide");
          }
        } else {
          $("div#loader-container").addClass("hidden");
          toast(result.message);
        }
      })
      .catch(err => {
        //$.LoadingOverlay("hide");
        $("div#loader-container").addClass("hidden");
        console.log(err);
      });
    return false;
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.fields !== this.state.fields && this.state.fields !== null) {
      console.log(this.state.fields);
    }
  }
  render() {
    console.log(rp_arr);
    const cssClasses = {
      root: "form-group",
      input: "Demo__search-input",
      autocompleteContainer: "Demo__autocomplete-container"
    };

    const AutocompleteItem = ({ formattedSuggestion }) => (
      <div className="Demo__suggestion-item">
        <i className="fa fa-map-marker Demo__suggestion-icon" />
        <strong>{formattedSuggestion.mainText}</strong>{" "}
        <small className="text-muted">
          {formattedSuggestion.secondaryText}
        </small>
      </div>
    );

    const inputProps = {
      type: "text",
      value: this.state.address,
      onChange: this.handleChange,
      // onBlur: () => {
      //   console.log("Blur event!");
      // },
      // onFocus: () => {
      //   console.log("Focused!");
      // },
      placeholder: "Search Places",
      name: "Demo__input",
      id: "my-input-id",
      className: "my_custome"
    };
    const inputProps1 = {
      type: "text",
      value: this.state.address1,
      onChange: this.handleChange1,
      onBlur: () => {
        console.log("Blur event!");
      },
      onFocus: () => {
        console.log("Focused!");
      },
      placeholder: "Search Places",
      name: "Demo__input",
      id: "my-input-id",
      className: "my_custome"
    };

    //console.log(this.state.getRepair);
    return (
      <React.Fragment>
        <div id="loader-container" className="loader-container hidden">
          <svg
            className="spinner"
            viewBox="0 0 66 66"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              className="path"
              fill="none"
              strokeLinecap="round"
              cx="33"
              cy="33"
              r="30"
            ></circle>
          </svg>

          <div className="icon">
            <img src="assets/image/main_logo.svg" />
          </div>
        </div>

        <section className="BannerSliding">
          <div className="container">
            <div className="row">
              {/*<div className="col-md-12 col-sm-12">
			        <div className="morphText">
			         <h1>Desktop PC and Laptops</h1>
			         <span>Repairing</span>
			        </div>
			       </div>*/}

              <div className="col-md-2 col-sm-12"></div>

              <div className="col-md-8 col-sm-12">
                <div className="HeaderSearch">
                  <input
                    type="text"
                    id="searcInput"
                    name="searcInput"
                    onChange={this.handleInput}
                    value={this.state.searcInput}
                    placeholder="Type Here Your Repair No To Search Your Device "
                  ></input>
                  <button
                    name="getRepair"
                    id="getRepair"
                    onClick={() => {
                      this.getRepairRequest();
                    }}
                  >
                    <i className="icon-search_icon"></i>
                  </button>
                </div>
              </div>

              <div className="col-md-2 col-sm-12"></div>
            </div>
          </div>
        </section>

        {this.state.showData == "show" ? (
          <section className="SectionAllBrands" style={{ marginTop: "20px" }}>
            <div className="container">
              <div className="row">
                <div className="col-md-12 col-sm-12 showmenu">
                  <table className="table table-striped table-responsive">
                    <thead>
                      <tr>
                        <th scope="col" className="PullRequest-Table">
                          Repair #
                        </th>
                        <th scope="col" className="PullRequest-Table">
                          Name
                        </th>
                        <th scope="col" className="PullRequest-Table">
                          Product
                        </th>
                        <th scope="col" className="PullRequest-Table">
                          Brand
                        </th>
                        <th scope="col" className="PullRequest-Table">
                          Series
                        </th>
                        <th scope="col" className="PullRequest-Table">
                          Model
                        </th>

                        <th scope="col" className="PullRequest-Table">
                          Status
                        </th>
                        <th scope="col" className="PullRequest-Table">
                          Repair Cost
                        </th>
                        <th scope="col" className="PullRequest-Table"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.getRepair.map((tit, index) => (
                        <tr key={index}>
                          <th className="PullBtm-Table">{tit.REPAIR_ID}</th>
                          <th className="PullBtm-Table">{tit.F_NAME}</th>
                          <th className="PullBtm-Table">{tit.PRODUCT_NAME}</th>
                          <th className="PullBtm-Table">{tit.BRAND_NAME}</th>
                          <th className="PullBtm-Table">{tit.SERIES_NAME}</th>
                          <th className="PullBtm-Table">{tit.MODEL_NAME}</th>

                          <th className="PullBtm-Table ">{tit.STATUS}</th>
                          <th className="PullBtm-Table text-center">
                            $ {tit.OFFER}
                          </th>
                          {tit.STATUS == "Approved" ? (
                            <th className="PullBtm-Table">
                              <button
                                className="aproveButton"
                                onClick={() => {
                                  this.showOptions();
                                }}
                                type="button"
                              >
                                Proceed
                              </button>
                            </th>
                          ) : null}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </section>
        ) : null}

        {this.state.showOptions == "show" ? (
          <section className="selectOptions" style={{ margin: "20px" }}>
            <div className="container">
              <div className="row">
                {/*<div className="col-md-12 col-sm-12">
			       	   <div className="BrandHeading">

			       	    <h1>Select Brand</h1>
			       	    <p>Step 2/6</p>
			       	   </div>
			       	  </div>*/}

                <div className="col-md-12 col-sm-12">
                  <div className="row">
                    <div className="col-md-4 col-sm-12">
                      <div className="SeriesSelected">
                        <label
                          className="checkbox"
                          onClick={() => {
                            this.showSelecOption("drop");
                          }}
                        >
                          <input
                            id="check1"
                            type="radio"
                            name="brand_name"
                            onClick={() => {
                              this.showSelecOption("drop");
                            }}
                          ></input>
                          <span></span>
                          <span className="FontSize">DropOff</span>
                        </label>
                      </div>
                    </div>

                    <div className="col-md-4 col-sm-12">
                      <div className="SeriesSelected">
                        <label className="checkbox">
                          <input
                            id="check1"
                            type="radio"
                            name="brand_name"
                            onClick={() => {
                              this.showSelecOption("pic");
                            }}
                          ></input>
                          <span></span>
                          <span className="FontSize">Pickup</span>
                        </label>
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-12">
                      <div className="SeriesSelected">
                        <label className="checkbox">
                          <input
                            id="check1"
                            type="radio"
                            name="brand_name"
                            onClick={() => {
                              this.showSelecOption("ship");
                            }}
                          ></input>
                          <span></span>
                          <span className="FontSize">Shipment</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : null}

        <section className="OptionsData" style={{ margin: "20px" }}>
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-sm-12">
                <div className="row">
                  {this.state.shipData == "show" ? (
                    <React.Fragment>
                      <div className="col-md-12 col-sm-12 HeaderSearch">
                        <PlacesAutocomplete
                          onSelect={this.handleSelect1}
                          autocompleteItem={AutocompleteItem}
                          onEnterKeyDown={this.handleSelect1}
                          input={({ ...props }) =>
                            React.createElement("input", props)
                          }
                          inputProps={inputProps1}
                        ></PlacesAutocomplete>
                        <button
                          type="button"
                          onClick={this.handleSelect1}
                          style={{
                            borderRadius: "0 0 0 0",
                            position: "absolute"
                          }}
                        >
                          <i class="fa fa-location-arrow"></i>
                        </button>

                        {this.state.loading ? (
                          <div>
                            <i className="fa fa-spinner fa-pulse fa-3x fa-fw Demo__spinner" />
                          </div>
                        ) : null}
                        {/* {!this.state.loading && this.state.geocodeResults1 ?
								<div className='geocoding-results'>{this.state.geocodeResults1}</div> :
							  null} */}
                      </div>
                      <div className="col-md-12 col-sm-12">
                        {this.state.showShipMap == "show" ? (
                          <div style={{ margin: "0px" }}>
                            <ShipingMap
                              google={this.props.google}
                              repId={this.state.searcInput}
                              radioId={this.state.getRadioVale}
                              center={{
                                lat: this.state.currentLatLng1.lat,
                                lng: this.state.currentLatLng1.lng
                              }}
                              that={that}
                              height="300px"
                              zoom={15}
                            />
                          </div>
                        ) : null}
                      </div>
                      {this.state.showShipMap == "show" ? (
                        <React.Fragment>
                          <div className="col-md-3 col-sm-12">
                            <div className="">
                              <div className="form-group">
                                <label>Lenght</label>
                                <input
                                  type="number"
                                  name="ship_lenght"
                                  onChange={this.handleInput}
                                  className="form-control"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3 col-sm-12">
                            <div className="">
                              <div className="form-group">
                                <label>Width</label>
                                <input
                                  type="number"
                                  name="ship_width"
                                  onChange={this.handleInput}
                                  className="form-control"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3 col-sm-12">
                            <div className="">
                              <div className="form-group">
                                <label>Height</label>
                                <input
                                  type="number"
                                  name="ship_height"
                                  onChange={this.handleInput}
                                  className="form-control"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3 col-sm-12">
                            <div className="">
                              <div className="form-group">
                                <label>Weight (Ounce)</label>
                                <input
                                  type="number"
                                  name="ship_weight"
                                  onChange={this.handleInput}
                                  className="form-control"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-12 hide getShip">
                            <div className="">
                              <div className="form-group">
                                <label>Select Shipment </label>
                                <select
                                  name="rate_id"
                                  onChange={this.handleInput1}
                                  className="form-control"
                                >
                                  <option value="">Select </option>
                                  {this.state.getShipmentCharges.map(
                                    (tit, index) =>
                                      tit.service == "Priority" ||
                                      tit.service == "First" ? (
                                        <option key={index} value={tit.id}>
                                          {tit.carrier +
                                            " " +
                                            tit.rate +
                                            " " +
                                            tit.service}
                                        </option>
                                      ) : null
                                  )}
                                </select>
                              </div>
                            </div>
                          </div>
                        </React.Fragment>
                      ) : null}
                      {this.state.showShipMap == "show" ? (
                        <div className="labelPrint col-md-6 col-sm-12 hide">
                          <div className="">
                            <div className="form-group">
                              <img
                                style={{ width: "25%" }}
                                src={this.state.label_remote_url}
                              />
                            </div>
                            <a
                              target="_blank"
                              className="aproveButton"
                              href={this.state.label_remote_url}
                            >
                              View
                            </a>
                          </div>
                        </div>
                      ) : null}
                      <div className="col-md-12 col-sm-12">
                        {this.state.showShipMap == "show" ? (
                          <button
                            className="aproveButton getCharge"
                            onClick={() => {
                              this.getShippingCharges();
                            }}
                            type="button"
                          >
                            Get Shipments
                          </button>
                        ) : null}
                        {this.state.showShipMap == "show" ? (
                          <button
                            className="aproveButton getLabel hide"
                            onClick={() => {
                              this.getLabel();
                            }}
                            type="button"
                          >
                            Get Label
                          </button>
                        ) : null}
                      </div>
                    </React.Fragment>
                  ) : null}

                  {this.state.dropData == "show" ? (
                    <React.Fragment>
                      <div className="col-md-12 col-sm-12">
                        <div className="ConDropschedule">
                          <h1>Schedule Drop off</h1>
                          <p>
                            You can drop the package yourself to our store at
                            following office hours{" "}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-12">
                        <div className="DropListing">
                          <ul>
                            <li>Mon-Thus:</li>
                            <li>9AM - 7PM</li>
                            <li>Friday:</li>
                            <li>9AM - 6PM</li>
                            <li>Saturday:</li>
                            <li>9AM - 4PM</li>
                          </ul>
                        </div>
                        <div class="dropoffAddress">
                          <h1>US Office</h1>
                          <p>2720 Royal Lane Ste #180 Dallas, TX 75229</p>
                          <p>United States</p>
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-12">
                        <div style={{ marginTop: "30px" }}>
                          <DropOffMap />
                        </div>
                      </div>
                      <div className="col-md-12 col-sm-12">
                        <button
                          className="aproveButton dropConti"
                          onClick={() => {
                            this.saveInfo();
                          }}
                          type="button"
                        >
                          Continue
                        </button>
                      </div>
                    </React.Fragment>
                  ) : null}

                  {this.state.picData == "show" ? (
                    <React.Fragment>
                      <div className="col-md-12 col-sm-12 HeaderSearch">
                        <PlacesAutocomplete
                          onSelect={this.handleSelect}
                          autocompleteItem={AutocompleteItem}
                          onEnterKeyDown={this.handleSelect}
                          input={({ ...props }) =>
                            React.createElement("input", props)
                          }
                          inputProps={inputProps}
                        ></PlacesAutocomplete>
                        <button
                          type="button"
                          onClick={this.handleSelect}
                          style={{
                            borderRadius: "0 0 0 0",
                            position: "absolute"
                          }}
                        >
                          <i class="fa fa-location-arrow"></i>
                        </button>

                        {this.state.loading ? (
                          <div>
                            <i className="fa fa-spinner fa-pulse fa-3x fa-fw Demo__spinner" />
                          </div>
                        ) : null}
                        {/* {!this.state.loading && this.state.geocodeResults ?
						            <div className='geocoding-results'>{this.state.geocodeResults}</div> :
						          null} */}
                      </div>

                      <div className="col-md-12 col-sm-12">
                        {this.state.showMap == "show" ? (
                          <div style={{ margin: "0px" }}>
                            <Map
                              google={this.props.google}
                              repId={this.state.searcInput}
                              radioId={this.state.getRadioVale}
                              center={{
                                lat: this.state.currentLatLng.lat,
                                lng: this.state.currentLatLng.lng
                              }}
                              that={that}
                              height="400px"
                              zoom={15}
                            />
                          </div>
                        ) : null}
                      </div>
                    </React.Fragment>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </section>

        <ToastContainer
          position="top-right"
          autoClose={2500}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />
      </React.Fragment>
    );
  }
}
export default PullRequests;
