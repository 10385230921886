import React, { Component } from "react";
import $ from "jquery";

class DallasPawnShop extends React.Component {

  render() {
    return (
    <React.Fragment>
     <section class="BannerSection">
     <div class="container">
      <div class="row">
      
       <div class="col-md-5 col-sm-12 d-xl-none d-block d-sm-none">
       	<div class="AssetManagement-BannerImage">
         <img src="/assets/image/asset-management-image.jpg" width="576" height="625" alt=""/>
        </div>
       </div>
      	
       <div class="col-md-7 col-sm-12">
        <div class="AssetManagement-Banner">
         <h2>Welcome to <span>LaptopZone</span><br /> the Reputed Pawn Shop
		 <br /> in Dallas!</h2>
         
         <p>Fast Loans and Cash, What's Not to Like? Get incredible<br /> merchandise at deeply discounted costs each day! We buy,<br /> sell, and loan on almost anything of significant worth!<br /> LaptopZone is the largest pawn shop in Dallas, TX.</p>
         
         <div class="PawnShop-Btn">
          <a href="#">Read More</a>
         </div>
         
        </div>
       </div>
       
       <div class="col-md-5 col-sm-12 d-none d-sm-block">
       	<div class="AssetManagement-BannerImage">
         <img src="/assets/image/asset-management-image.jpg" width="576" height="625" alt=""/>
        </div>
       </div>
      	
      </div>
     </div>
    </section>
    
    
    
    <div class="AssetDispotion-background">
     <div class="container">
      <div class="row">
      	
       <div class="col-lg-6 col-md-4 col-sm-12">
       	<div class="AssetDispotion-Head">
         <div class="DispotionImage">
          <img src="/assets/image/asset-thumable-02.jpg" width="482" height="483" alt=""/>
         </div>
       	</div>
       </div>
       
       <div class="col-lg-6 col-md-8 col-sm-12">
       	<div class="AssetDispotion-Head">
       	 <div class="DispotionContent">
       	  <h3>What are the Top Benefits <br /><span>Pawn Shop</span> in Dallas?</h3>
       	  
       	  <div class="PawnShop-Listing">
       	   <ul>
       	    <li>Quick cash</li>
       	    <li>No credit checks</li>
       	    <li>Quick and easy service</li>
       	    <li>Protected, on-site storage</li>
       	   </ul>
       	  </div>
       	  
       	 </div>
       	</div>
       </div>
      	
      </div>
     </div>
    </div>
    
    <section class="ITcritical-Seaction">
     <div class="container">
      <div class="row">
      
        <div class="col-lg-6 col-md-4 col-sm-12 d-none d-xl-none d-block d-sm-none">
      	 <div class="ITcriticalImage pt-0">
   	      {/* <!--<img src="/assets/image/it-critical-1.png" width="496" height="450" alt=""/>--> */}
   	      
   	      <img src="/assets/image/asset-thumable-02.jpg" width="482" height="483" alt=""/>
   	      
   	     </div>
        </div>
      	
      	<div class="col-lg-6 col-md-8 col-sm-12">
      	 <div class="Disposible-SCHead">
         <h1>Get Fast and Easy Cash<br /> from LaptopZone</h1>
         <p>We are frequently in search of new approaches to<br /> serve you better. Our well-trained customer care<br /> team works hard to assist you with getting fast cash<br /> for your products in a fast and hassle-free way.</p>
        </div>
      	</div>
      	
      	<div class="col-lg-6 col-md-4 col-sm-12 d-none d-sm-block">
      	 <div class="ITcriticalImage pt-0">
   	      {/* <!--<img src="/assets/image/it-critical-1.png" width="496" height="450" alt=""/>--> */}
   	      
   	      <img src="/assets/image/asset-thumable-02.jpg" width="482" height="483" alt=""/>
   	      
   	     </div>
      	</div>
      	
      </div>
     </div>
    </section>
    
    <section class="Sec-GetItem">
     <div class="container">
      <div class="row">
       
       <div class="col-lg-6 col-md-4 col-sm-12">
        <div class="Disposible-SCImage">
         <img src="/assets/image/asset-thumable-02.jpg" width="502" height="549" alt=""/>
        </div>
       </div>
       
       <div class="col-lg-6 col-md-8 col-sm-12">
        <div class="Disposible-SCHead">
         <h1>Get an Item You Want at<br /> Costs You will Love</h1>
         <p>Are you in search of saving up to 50% on the brands you love? Yes, save big by buying used, brand-name stock. What about a Samsung HDTV or Apple devices like MacBook or iPhones? Searching for Makita power apparatuses? Prepared to rock out on a Fender guitar? We carry the brand names you love at costs that can't be beaten. Our stock is continually changing, so visit us often.</p>
        </div>
       </div>
       
      </div>
     </div>
    </section>
    
    
    <section class="SectionRecovery">
     <div class="container">
      <div class="row">
      
       <div class="col-lg-6 col-md-4 col-sm-12 d-none d-xl-none d-block d-sm-none">
      	<div class="Recovery-HeadImage">
      	 <img src="/assets/image/disposable-firm-image.png" width="502" height="549" alt=""/>
      	</div>
       </div>
      	
      	<div class="col-lg-6 col-md-8 col-sm-12">
      	 
      	 <div class="Recovery-Head">
      	  <h1>We Pay Big Bucks for<br /> Different Products</h1>
      	  
      	  <p>We are pleased with the entirety of our stores and our store’s efficient teams. Regardless of whether you need a loan or looking for a great deal on name-brand products, we welcome you to come into any of our three pawn shops in Dallas, Coppell and Plano. You'll be happy you did!</p>
      	  
      	  <div class="PawnShop-Listing">
       	   <ul>
       	    <li>Outdated computers and smartphones</li>
       	    <li>Tablets and HD TVs</li>
       	    <li>Power tools and electronics</li>
       	    <li>Stereos and gaming videos</li>
       	    <li>Jewelry, musical instruments, different tools</li>
       	    <li>Gaming consoles and game systems</li>
       	   </ul>
       	  </div>
       	  
       	  <p class="pt-3">
       	  	We make It simple for you, so surprise us! We take almost everything, save big by buying pre-owned and get quick $$$ without any hassles.
       	  </p>
      	  
      	 </div>
      	 
      	</div>
      	
      	<div class="col-lg-6 col-md-4 col-sm-12 d-none d-sm-block">
      	 <div class="Recovery-HeadImage">
      	  <img src="/assets/image/disposable-firm-image.png" width="502" height="549" alt=""/>
      	 </div>
      	</div>
      	
      </div>
     </div>
    </section>
    
    <section class="Section-StandardServices">
     <div class="container">
      <div class="row">
      	
      	<div class="col-md-6 col-sm-12">
      	 
      	 <div class="Recovery-HeadImage1">
      	  <img src="/assets/image/we-protect-image.png" width="529" height="279" alt=""/>
      	 </div>
      	 
      	 <div class="GrossSales-two">
      	  <h2>High Tech</h2>
      	  <p>We sell and buy almost any sort of PC – MacBook and Dell/HP laptops – and computer accessories. We rapidly assess the brand of PC, the age, the model number and the condition of your computer. We at that point give you the most ideal cost. We pay up to 35% more than different shops and our computer pros love to assist our clients out in any way we can! You can bring your devices such as,</p>
      	  
      	  <div class="PawnShop-Listing">
       	   <ul>
       	    <li>Dell/HP Laptops and MacBook</li>
       	    <li>Tablets and Android/iOS phones </li>
       	    <li>Smartwatches and more</li>
       	   </ul>
       	  </div>
      	  
      	 </div>
      	 
      	 <div class="GrossSales-two">
      	  <h2>Power Tools</h2>
      	  <p>Regardless of whether you are a mortgage holder, own an independent business or are a contractor, an incredible spot to get name brand devices is at a pawn shop in Dallas! LaptopZone has more apparatuses that numerous hardware stores. We have a wide selection of power tools like drills, cutting apparatuses, sanders, generators and air compressors. Furthermore, that is only a tip of something larger, in addition to the fact that we have a HUGE selection of these apparatuses, however, we have them such a great amount of less expensive than you can go anyplace else! Sell now,</p>
      	  
      	  <div class="PawnShop-Listing">
       	   <ul>
       	    <li>Drills and saws</li>
       	    <li>Routers and grinders</li>
       	    <li>Compressors, paint sprayers and more</li>
       	   </ul>
       	  </div>
      	  
      	 </div>
      	 
      	 <div class="GrossSales-two">
      	  <h2>Gold and Diamond Jewelry</h2>
      	  <p>Jewelry is a significant piece of any woman's life. Should you need to sell your old gems or get some second hand, in perfect condition, pieces, don't stop for a second to talk with us. We want you to leave our store happy. So, you can come up with different stuff such as,</p>
      	  
      	  <div class="PawnShop-Listing">
       	   <ul>
       	    <li>Rings and watches</li>
       	    <li>Bracelets and earrings</li>
       	    <li>Pendants, necklaces and many unique pieces</li>
       	   </ul>
       	  </div>
      	  
      	 </div>
      	 
      	</div>
      	
      	<div class="col-md-6 col-sm-12">
      	
      	 <div class="Recovery-Head-one">
      	  <h1>We Offer a Wide Range of <br />Merchandise Including:</h1>
      	 </div>
      	 
      	 <div class="GrossSales-two">
      	  <h2>Electronics</h2>
      	  <p>We buy and sell a wide range of gadgets. A pawn shop Dallas is an extraordinary spot to sell your large screen TVs, DVD and Blu-ray players, stereo equipment, digital cameras and camcorders, PCs, iPods and tablets, versatile cell phones, and the sky is the limit from there. Pawn shop isn’t a secret that the better brand name gadgets can be VERY, VERY costly when you buy them new from the big box stores or online! In any case, if you are in search of getting more for your cash and save some of your hard-earned money, at that point come and look at our selection of tvs and other electronics! Our stock is continually changing don't as well, delay! You can sell or buy,</p>
      	  
      	  <div class="PawnShop-Listing">
       	   <ul>
       	    <li>TVs, Blu-ray</li>
       	    <li>Game systems and car audio</li>
       	    <li>DVD players and stereos</li>
       	    <li>DVDs, CDs, office equipment and more</li>
       	   </ul>
       	  </div>
      	  
      	 </div>
      	 
      	 <div class="GrossSales-two">
      	  <h2>Video Gaming</h2>
      	  <p>Here at LaptopZone, gamers are always welcome! We are your local video game headquarters and are glad to offer a huge selection of a wide range of video gaming systems and computer games. Is it accurate to say that you are searching for the newest systems like the Xbox One, or are your outdated and searching for vintage Atari? It doesn't make a difference what you are in the mood for, we are consistently on the cutting edge of computer games and systems.</p>
      	  
      	  <div class="PawnShop-Listing">
       	   <ul>
       	    <li>Xbox One and Xbox 360</li>
       	    <li>Sony Playstation, Playstation 3 (PS3), Playstation 4 (PS4),                Playstation 2 (PS2)</li>
       	    <li>Super NES (SNES), Nintendo (NES), Nintendo 64 and GameCube</li>
       	    <li>Nintendo DS, Nintendo 3DS, Playstation PSP, Playstation PSP Go, Playstation Vita, Wii U</li>
       	   </ul>
       	  </div>
      	  
      	 </div>
      	 
      	 <div class="GrossSales-two">
      	  <h2>Musical Instruments</h2>
      	  <p>Instruments don't come modest. We have a collection of instruments that you can browse whether you are a beginner just getting another instrument or an established musician searching for something explicit. On the other hand, in the event that you need to make fast $$$, get your instruments to be evaluated by our specialists and you can leave the store with money in your hand within 10 to 15 minutes. We expect to give you the most ideal cost for what you acquire. We need to ensure we make you a repeat client. Come and talk with us today!</p>
      	  
      	  <div class="PawnShop-Listing">
       	   <ul>
       	    <li>Guitars and drums</li>
       	    <li>Keyboards and saxophones</li>
       	    <li>Violins, amplifiers, mixers and more</li>
       	   </ul>
       	  </div>
      	  
      	 </div>
      	 
      	</div>
      	
      	
      </div>
     </div>
    </section>
    
    <section class="Sec-WeProtect">
     <div class="container">
      <div class="row">
      
        <div class="col-md-6 col-sm-12 d-xl-none d-block d-sm-none">
      	 
      	 <div class="Recovery-HeadImage1">
   	      <img src="/assets/image/we-protect-image.png" width="529" height="279" alt=""/>
   	     </div>
      	 
      	</div>
      
        <div class="col-md-6 col-sm-12">
      	
      	 <div class="Recovery-Head-one">
      	  <h1>Instant Cash at the Spot</h1>
      	  <p>Eventually, everybody has experienced a cash crunch. Pawn loans are instant cash loans so you get the money you need on the spot!</p>
      	 </div>
      	 
      	</div>
      	
      	<div class="col-md-6 col-sm-12 d-none d-sm-block">
      	 
      	 <div class="Recovery-HeadImage1">
   	      <img src="/assets/image/we-protect-image.png" width="529" height="279" alt=""/>
   	     </div>
      	 
      	</div>      	
      	
      </div>
     </div>
    </section>
    
    <section class="Sec-YourMaximize">
     <div class="container">
      <div class="row">
      
        <div class="col-lg-6 col-md-4 col-sm-12">
      	 <div class="Recovery-HeadImage2">
   	      <img src="/assets/image/we-protect-image.png" width="505" height="444" alt=""/>
   	     </div>
      	</div>  
      
        <div class="col-lg-6 col-md-8 col-sm-12">
      	 <div class="Recovery-Head-one">
      	  <h1>We Offer Online Deals</h1>
      	  <p>At LaptopZone, we buy and sell a wide range of items! Furthermore, while our product is continually changing, our commitment to great value never shows signs of change!</p>
      	 </div>
      	</div>
      	
      </div>
     </div>
    </section>
    
    <section class="EquipmentSection">
     <div class="container">
      <div class="row">
      
        <div class="col-md-6 col-sm-12 d-xl-none d-block d-sm-none">
      	 
      	 <div class="Recovery-HeadImage2">
   	      <img src="/assets/image/we-protect-image.png" width="529" height="279" alt=""/>
   	     </div>
      	 
      	 <div class="GrossSales-two Brocade-mrtop">
      	  <h2>Plano</h2>
      	  <p>Plano is the second prime location of LaptopZone where you can sell and buy electronic devices without any hassle. </p>
      	 </div>
      	 
      	</div>
      
        <div class="col-md-6 col-sm-12">
      	
      	 <div class="Recovery-Head-one">
      	  <h1>Our Prime Locations</h1>
      	  <p>We are proud to have given over 300,000 loans and are excited to approach the future and grow our business and our clientele.<br /><br />Pawn shop Dallas is all about supporting local businesses. We love being a part of the Dallas business network. It is difficult to be a small business in America nowadays with the consistently changing guidelines and changes that are happening in our government. We at LaptopZone are pleased to have been able to maintain a private business for more than 24 years in the same area. We are offering our valued services in different areas of Texas. We ease people with the fastest, safest, and easiest services. Take a look below to find out our prime location here, </p>
      	 </div>
      	 
      	 <div class="GrossSales-two">
      	  <h2>Dallas</h2>
      	  <p>LaptopZone’s headquarter is located in Dallas, TX. We have a team of expert people who entertain your all queries at the spot. As well as, to facilitate the entire community of Dallas; we are working in LaptopZone’s Pawn Shop - North Dallas, LaptopZone’s Pawn Shop - South Dallas and LaptopZone’s Pawn Shop – Garland.</p>
      	 </div>
      	 
      	 <div class="GrossSales-two">
      	  <h2>Coppell</h2>
      	  <p>Coppell is the third important location of the LaptopZone store. Come to us along with your used electronics or other products to get fast cash at the spot.<br /><br />On the other hand, we ease the people in Mesquite and East Grand Avenue in Dallas. So, why wait? Just visit our store today or ping us now to get an instant quote! </p>
      	 </div>

      	</div>
      	
      	<div class="col-md-6 col-sm-12 d-none d-sm-block">
      	 
      	 <div class="Recovery-HeadImage2">
   	      <img src="/assets/image/we-protect-image.png" width="529" height="279" alt=""/>
   	     </div>
      	 
      	 <div class="GrossSales-two">
      	  <h2>Plano</h2>
      	  <p>Plano is the second prime location of LaptopZone where you can sell and buy electronic devices without any hassle. </p>
      	 </div>
      	 
      
      	</div>      	
      	
      </div>
     </div>
    </section>
    
   
    </React.Fragment>
    );
  }
}
export default DallasPawnShop;
