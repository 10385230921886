import React, { Component } from "react";

import $ from "jquery";

//import './circlecss/css/jCirclize.css';

//import './circlecss/dist/jquery.jCirclize.js';

//import ReactDOM from 'react-dom';

class Repairing extends React.Component {
  componentDidMount() {
    // this.$el = $(this.el);
    // this.$el.circlize({
    //   stroke: 15,
    //   percentage: 45,
    //   usePercentage: true,
    //   background: "#1abc9c",
    //   gradientColors: ["#ffa500", "#ff4500", "#ffa500"]
    // });
  }
  componentWillUnmount() {
    //this.$el.circlize('destroy');
  }
  render() {
    return (
      <React.Fragment>
        {/*<div ref={el => this.el = el}>
      </div>*/}

        <section className="BannerSliding">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-sm-12">
                <div className="morphText">
                  <h1>Repair, Sell and Buy</h1>
                  <span id="js-rotating-3">
                    Laptop, Iphone, Home Techs, Phones
                  </span>
                </div>
              </div>

              <div className="col-md-12 col-sm-12">
                <form action="#" method="#">
                  <div className="LandingSearch">
                    <input
                      type="text"
                      id=""
                      name=""
                      placeholder="TYPE HERE TO SEARCH YOUR adil"
                    ></input>
                    <div className="clearfix"></div>
                    <button>Search</button>
                  </div>
                </form>
              </div>

              <div className="col-md-12 col-sm-12">
                <div className="SelectHeading">
                  <h1>Or Select your Product</h1>
                </div>

                <div className="SelectProd">
                  <a href="#">
                    <img
                      src="assets/image/prod-1.png"
                      width="217"
                      height="202"
                      alt=""
                    />
                  </a>
                  <h2 className="ProdHeading">Camcoder</h2>
                </div>

                <div className="SelectProd">
                  <a href="#">
                    <img
                      src="assets/image/prod-2.png"
                      width="193"
                      height="203"
                      alt=""
                    />
                  </a>
                  <h2 className="ProdHeading">Watches</h2>
                </div>

                <div className="SelectProd">
                  <a href="#">
                    <img
                      src="assets/image/prod-3.png"
                      width="221"
                      height="203"
                      alt=""
                    />
                  </a>
                  <h2 className="ProdHeading">Touch Screens</h2>
                </div>

                <div className="SelectProd">
                  <a href="#">
                    <img
                      src="assets/image/prod-4.png"
                      width="171"
                      height="202"
                      alt=""
                    />
                  </a>
                  <h2 className="ProdHeading">Phones & iphones</h2>
                </div>

                <div className="SelectProd">
                  <a href="#">
                    <img
                      src="assets/image/prod-5.png"
                      width="206"
                      height="203"
                      alt=""
                    />
                  </a>
                  <h2 className="ProdHeading">Laptops</h2>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="AppDownload">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-sm-12">
                <div className="AppHeading">
                  <h1>Download our app</h1>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                  </p>
                </div>

                <div className="AppButton">
                  <a href="#" target="_blank">
                    <img
                      src="assets/image/playstore-icon.png"
                      width="35"
                      height="38"
                      alt=""
                    />{" "}
                    Play Store
                  </a>

                  <a href="#" target="_blank">
                    <img
                      src="assets/image/apple.png"
                      width="35"
                      height="42"
                      alt=""
                    />{" "}
                    App Store
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="RecentSection">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-sm-12">
                <div className="RecentHead">
                  <h2>Recently Traded</h2>
                  <p>
                    put some text here put some text here put some text here put
                    some text here put some text here put some text here
                  </p>
                  <a href="#">See All</a>
                </div>

                <div className="IconSlider">
                  <h4>Select your Product</h4>
                  <ul>
                    <li>
                      <a href="#" id="#slide_1">
                        <i className="icon-tablet_icon"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#" id="#slide_2">
                        <i className="icon-tab-icon"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#" id="#slide_3">
                        <i className="icon-pc-icon"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#" id="#slide_4">
                        <i className="icon-watch-icon"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-md-6 col-sm-12">
                <div className="ProdSlider">
                  <img
                    src="assets/image/prod-1.png"
                    width="217"
                    height="202"
                    alt=""
                  />
                </div>

                <div className="ProdSlider">
                  <img
                    src="assets/image/prod-1.png"
                    width="217"
                    height="202"
                    alt=""
                  />
                </div>

                <div className="ProdSlider">
                  <img
                    src="assets/image/prod-1.png"
                    width="217"
                    height="202"
                    alt=""
                  />
                </div>

                <div className="ProdSlider">
                  <img
                    src="assets/image/prod-1.png"
                    width="217"
                    height="202"
                    alt=""
                  />
                </div>

                <div className="ProdSlider">
                  <img
                    src="assets/image/prod-1.png"
                    width="217"
                    height="202"
                    alt=""
                  />
                </div>

                <div className="ProdSlider">
                  <img
                    src="assets/image/prod-1.png"
                    width="217"
                    height="202"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="MobileRepairing">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-sm-12">
                <div className="MobileHead">
                  <h5>
                    Mobile <span>Repairing</span>
                  </h5>
                  <h5> Service</h5>
                  <p>
                    Your question may already be answered in our FAQs. If not,
                    send us a message:
                  </p>
                  <a href="#">Learn More</a>
                </div>
              </div>

              <div className="col-md-6 col-sm-12">
                <div className="MobileIcon">
                  <i className="icon-repairing-icon"></i>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
export default Repairing;
