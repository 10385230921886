import React, { Component } from "react";
import $ from "jquery";

class Blog extends React.Component {

  render() {
    return (
    <React.Fragment>

  <section className="BlogDetail-Section">
   	<div className="container">
   	 <div className="row">
   	 
   	  <div className="col-md-12 col-sm-12">
   	   <div className="BlogHeading">
   	   	<h4>Blog & News</h4>
   	   	<p>Lorem ipsum dolor sit amet consece tetur adipiscing elit,<br/> sed do eiusmod tempor incididunt ut labore et dolore<br/> magna aliqua labore et dolore.</p>
   	   </div>
   	  </div>
   	
   	  
   	  <div className="col-md-12 col-sm-12">
   	   <div className="row">
   	   	   	   	
   	   	<div className="col-md-4 col-sm-12">
   	   	 <div className="BlogProduct">
   	   	  <img src="/assets/image/blog-product.png" width="1112" height="349" alt=""/>
   	   	  <h2>Laptopzone Exibition</h2>
   	   	  <h3>16, July 2019</h3>
   	   	  <p>Lorem ipsum dolor sit amet consece tetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
   	   	  <a href="#">Read more</a>
   	   	 </div>
   	   	</div>
   	   	
   	   	<div className="col-md-4 col-sm-12">
   	   	 <div className="BlogProduct">
   	   	  <img src="/assets/image/blog-product.png" width="1112" height="349" alt=""/>
   	   	  <h2>Laptopzone Exibition</h2>
   	   	  <h3>16, July 2019</h3>
   	   	  <p>Lorem ipsum dolor sit amet consece tetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
   	   	  <a href="#">Read more</a>
   	   	 </div>
   	   	</div>
   	   	
   	   	<div className="col-md-4 col-sm-12">
   	   	 <div className="BlogProduct">
   	   	  <img src="/assets/image/blog-product.png" width="1112" height="349" alt=""/>
   	   	  <h2>Laptopzone Exibition</h2>
   	   	  <h3>16, July 2019</h3>
   	   	  <p>Lorem ipsum dolor sit amet consece tetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
   	   	  <a href="#">Read more</a>
   	   	 </div>
   	   	</div>
   	   	
   	   	<div className="col-md-4 col-sm-12">
   	   	 <div className="BlogProduct">
   	   	  <img src="/assets/image/blog-product.png" width="1112" height="349" alt=""/>
   	   	  <h2>Laptopzone Exibition</h2>
   	   	  <h3>16, July 2019</h3>
   	   	  <p>Lorem ipsum dolor sit amet consece tetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
   	   	  <a href="#">Read more</a>
   	   	 </div>
   	   	</div>
   	   	
   	   	<div className="col-md-4 col-sm-12">
   	   	 <div className="BlogProduct">
   	   	  <img src="/assets/image/blog-product.png" width="1112" height="349" alt=""/>
   	   	  <h2>Laptopzone Exibition</h2>
   	   	  <h3>16, July 2019</h3>
   	   	  <p>Lorem ipsum dolor sit amet consece tetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
   	   	  <a href="#">Read more</a>
   	   	 </div>
   	   	</div>
   	   	
   	   	<div className="col-md-4 col-sm-12">
   	   	 <div className="BlogProduct">
   	   	  <img src="/assets/image/blog-product.png" width="1112" height="349" alt=""/>
   	   	  <h2>Laptopzone Exibition</h2>
   	   	  <h3>16, July 2019</h3>
   	   	  <p>Lorem ipsum dolor sit amet consece tetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
   	   	  <a href="#">Read more</a>
   	   	 </div>
   	   	</div>
   	   	
   	   	<div className="col-md-12 col-sm-12">
   	   	 <div className="BlogPagination">
   	   	  <ul>
   	   	   <li><a href="#">1</a></li>
   	   	   <li><a href="#">2</a></li>
   	   	   <li className="active"><a href="#">3</a></li>
   	   	   <li><a href="#">4</a></li>
   	   	  </ul>
   	   	 </div>
   	   	</div>
   	   	
   	   </div>
   	  </div>

   	 </div>
   	</div>
   </section>
        
     </React.Fragment>
    );
  }
}
export default Blog;
