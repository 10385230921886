import React from "react";
import { posturl } from "./configuration.js";
import $ from "jquery";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
var HtmlToReactParser = require("html-to-react").Parser;
// import ReactDOM from 'react-dom';
var htmlToReactParser = new HtmlToReactParser();
var that = "";

class BlogDetails extends React.Component {
  constructor(props) {
    var finalurl = posturl;
    super(props);
    this.state = {
      FinalUrl: posturl,
      newsDetail: [],
      tags: []
    };
    that = this;
  }

  componentDidMount() {
    const art_id = this.props.match.params.id;
    const insertUrl =
      this.state.FinalUrl +
      "/laptopzone/reactcontroller/c_haziqreact/get_news_events_detail";

    new Promise((resolve, reject) => {
      $.ajax({
        url: insertUrl,
        dataType: "json",
        type: "GET",
        data: { art_id: art_id }
      }).then(
        data => {
          resolve(data);
        },
        err => {
          reject(err);
        }
      );
    })
      .then(response => {
        this.setState({
          ...this.state,
          newsDetail: response.data
        });
        console.log(response);
      })
      .catch(err => {
        console.log(err.message);
      });
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.newsDetail !== this.state.newsDetail &&
      this.state.newsDetail !== ""
    ) {
      let tags = [];

      this.state.newsDetail.map(item => {
        tags =
          item.TAGS !== null && item.TAGS !== ""
            ? item.TAGS.split(",")
            : [item.TAGS];
      });
      this.setState({
        ...this.state,
        tags: tags
      });
    }
  }
  render() {
    console.log(this.state.tags);
    console.log(this.props.match.params.id);
    return (
      <React.Fragment>
        {this.state.newsDetail.length > 0 ? (
          <section class="BlogDetail-Section">
            <div class="container">
              <div class="row">
                {/* <div class='col-md-12 col-sm-12'>
                <div class='BlogBreadCrumb'>
                  <ul>
                    <li>
                      <a href='#'>Blog</a>
                    </li>
                    <li>
                      <a href='#'>Sell mobile</a>
                    </li>
                    <li>
                      <a href='#'>Blog 3</a>
                    </li>
                  </ul>
                </div>
              </div> */}
                {this.state.newsDetail.map((item, index) => (
                  <div class="col-sm-12 col-md-12">
                    <div class="BlogDetail-Image">
                      <img
                        src={item.MASTER_IMAGE_PATH + item.BANNER_IMG}
                        width="1112"
                        height="349"
                        alt=""
                      />
                    </div>

                    <div class="BlogDetail-Content">
                      <div class="row">
                        <div class="col-md-9 col-sm-12">
                          <h1>{item.TILTE}</h1>
                          <div class="Bt-List">
                            <ul>
                              <li>
                                <h2>{item.CREATED_DATE}</h2>
                              </li>
                              {/* <li>
                              <h2>Themepalace</h2>
                            </li> */}
                            </ul>
                          </div>
                        </div>

                        <div class="col-md-3 col-sm-12">
                          <div class="BlogSocial">
                            <ul>
                              <li>
                                <a
                                  href="https://www.instagram.com/laptopzone.us/"
                                  target="_blank"
                                >
                                  <i class="fa fa-instagram" />
                                </a>
                              </li>
                              <li>
                                <a
                                  href="https://twitter.com/LaptopZoneUS"
                                  target="_blank"
                                >
                                  <i class="fa fa-twitter" />
                                </a>
                              </li>
                              <li>
                                <a
                                  href="https://www.facebook.com/LaptopZonedallas/"
                                  target="_blank"
                                >
                                  <i class="fa fa-facebook-f" />
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>

                        <div class="col-md-12 col-sm-12">
                          {htmlToReactParser.parse(item.LONG_DESC)}
                          {/* <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book. It has
                        survived not only five centuries, but also the leap into
                        electronic typesetting, remaining essentially unchanged
                        maining essentially unchanged.
                      </p>

                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book. It has
                        survived not only five centuries, but also the leap into
                        electronic typesetting, remaining essentially unchanged
                        maining essentially unchanged. Lorem Ipsum is simply
                        dummy text of the printing and typesetting industry.
                        Lorem Ipsum has been the industry's standard dummy text
                        ever since the 1500s, when an unknown printer took a
                        galley of type and scrambled it to make a type specimen
                        book. It has survived not only five centuries, but also
                        the leap into electronic typesetting, remaining
                        essentially unchanged maining essentially unchanged.
                      </p>

                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book. It has
                        survived not only five centuries, but also the leap into
                        electronic typesetting, remaining essentially unchanged
                        maining essentially unchanged.
                      </p>

                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book. It has
                        survived not only five centuries, but also the leap into
                        electronic typesetting, remaining essentially unchanged
                        maining essentially unchanged. Lorem Ipsum is simply
                        dummy text of the printing and typesetting industry.
                        Lorem Ipsum has been the industry's standard dummy text
                        ever since the 1500s, when an unknown printer took a
                        galley of type and scrambled it to make a type specimen
                        book. It has survived not only five centuries, but also
                        the leap into electronic typesetting, remaining
                        essentially unchanged maining essentially unchanged.
                      </p>

                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book. It has
                        survived not only five centuries, but also the leap into
                        electronic typesetting, remaining essentially unchanged
                        maining essentially unchanged.
                      </p>

                      <div class='BlogDetail-List'>
                        <ul>
                          <li>
                            There are times when we assume that since something.
                          </li>
                          <li>
                            Remembers it, and we don’t really need to
                            communicate.
                          </li>
                          <li>
                            In a PM’s work, there is no such thing as
                            over-communication.
                          </li>
                          <li>
                            Make sure that all information is clear and
                            delivered.
                          </li>
                          <li>
                            Summarizing every discussion in writing and making
                            sure.
                          </li>
                          <li>
                            Steps are clear to everyone. It creates trust
                            between partners and prevents some.
                          </li>
                          <li>Unfortunate assumptions and expectations.</li>
                        </ul>
					  </div> */}
                          {/* {item.LONG_DESC} */}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
                {/* <div class='col-md-12 col-sm-12'>
                <div class='row'>
                  <div class='col-md-12 col-sm-12'>
                    <div class='RelatedHead'>
                      <h1>Related Content</h1>
                    </div>
                  </div>

                  <div class='col-md-4 col-sm-12'>
                    <div class='BlogProduct'>
                      <img
                        src='/assets/image/blog-product.png'
                        width='1112'
                        height='349'
                        alt=''
                      />
                      <h2>Laptopzone Exibition</h2>
                      <h3>16, July 2019</h3>
                      <p>
                        Lorem ipsum dolor sit amet consece tetur adipiscing
                        elit, sed do eiusmod tempor incididunt ut labore et
                        dolore magna aliqua.
                      </p>
                      <a href='#'>Read more</a>
                    </div>
                  </div>

                  <div class='col-md-4 col-sm-12'>
                    <div class='BlogProduct'>
                      <img
                        src='/assets/image/blog-product.png'
                        width='1112'
                        height='349'
                        alt=''
                      />
                      <h2>Laptopzone Exibition</h2>
                      <h3>16, July 2019</h3>
                      <p>
                        Lorem ipsum dolor sit amet consece tetur adipiscing
                        elit, sed do eiusmod tempor incididunt ut labore et
                        dolore magna aliqua.
                      </p>
                      <a href='#'>Read more</a>
                    </div>
                  </div>

                  <div class='col-md-4 col-sm-12'>
                    <div class='BlogProduct'>
                      <img
                        src='/assets/image/blog-product.png'
                        width='1112'
                        height='349'
                        alt=''
                      />
                      <h2>Laptopzone Exibition</h2>
                      <h3>16, July 2019</h3>
                      <p>
                        Lorem ipsum dolor sit amet consece tetur adipiscing
                        elit, sed do eiusmod tempor incididunt ut labore et
                        dolore magna aliqua.
                      </p>
                      <a href='#'>Read more</a>
                    </div>
                  </div>
                </div>
              </div> */}

                <div class="col-md-12 col-sm-12">
                  <div class="row">
                    <div class="col-md-12 col-sm-12">
                      <div class="RelatedHead">
                        <h1>Related Tags</h1>
                      </div>
                    </div>

                    <div class="col-md-12 col-sm-12">
                      <div class="RelatedTags-List">
                        <ul>
                          {this.state.tags.map(item =>
                            item !== "" && item !== null ? (
                              <li>
                                <a href="#">{item}</a>
                              </li>
                            ) : null
                          )}

                          {/* <li>
                          <a href='#'>ajustable</a>
                        </li>
                        <li>
                          <a href='#'>chrome</a>
                        </li>
                        <li>
                          <a href='#'>customized</a>
                        </li>
                        <li>
                          <a href='#'>metal</a>
                        </li>

                        <li>
                          <a href='#'>ajust</a>
                        </li>
                        <li>
                          <a href='#'>ajustable</a>
                        </li>
                        <li>
                          <a href='#'>chrome</a>
                        </li>
                        <li>
                          <a href='#'>customized</a>
                        </li>
                        <li>
                          <a href='#'>metal</a>
                        </li>

                        <li>
                          <a href='#'>ajust</a>
                        </li>
                        <li>
                          <a href='#'>ajustable</a>
                        </li>
                        <li>
                          <a href='#'>chrome</a>
                        </li>
                        <li>
                          <a href='#'>customized</a>
                        </li>
                        <li>
                          <a href='#'>metal</a>
                        </li>

                        <li>
                          <a href='#'>ajust</a>
                        </li>
                        <li>
                          <a href='#'>ajustable</a>
                        </li>
                        <li>
                          <a href='#'>chrome</a>
                        </li>
                        <li>
                          <a href='#'>customized</a>
                        </li>
                        <li>
                          <a href='#'>metal</a>
                        </li>

                        <li>
                          <a href='#'>ajust</a>
                        </li>
                        <li>
                          <a href='#'>ajustable</a>
                        </li>
                        <li>
                          <a href='#'>chrome</a>
                        </li>
                        <li>
                          <a href='#'>customized</a>
                        </li>
                        <li>
                          <a href='#'>metal</a>
                        </li>

                        <li>
                          <a href='#'>ajust</a>
                        </li>
                        <li>
                          <a href='#'>ajustable</a>
                        </li>
                        <li>
                          <a href='#'>chrome</a>
                        </li>
                        <li>
                          <a href='#'>customized</a>
                        </li>
                        <li>
                          <a href='#'>metal</a>
                        </li>

                        <li>
                          <a href='#'>ajust</a>
                        </li>
                        <li>
                          <a href='#'>ajustable</a>
                        </li>
                        <li>
                          <a href='#'>chrome</a>
                        </li>
                        <li>
                          <a href='#'>customized</a>
                        </li>
                        <li>
                          <a href='#'>metal</a>
                        </li>

                        <li>
                          <a href='#'>ajust</a>
                        </li>
                        <li>
                          <a href='#'>ajustable</a>
                        </li>
                        <li>
                          <a href='#'>chrome</a>
                        </li>
                        <li>
                          <a href='#'>customized</a>
                        </li>
                        <li>
                          <a href='#'>metal</a>
                        </li>

                        <li>
                          <a href='#'>ajust</a>
                        </li>
                        <li>
                          <a href='#'>ajustable</a>
                        </li>
                        <li>
                          <a href='#'>chrome</a>
                        </li>
                        <li>
                          <a href='#'>customized</a>
                        </li>
                        <li>
                          <a href='#'>metal</a>
                        </li>
                        <li>
                          <a href='#'>customized</a>
                        </li>
                        <li>
                          <a href='#'>metal</a>
                        </li> */}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-12 col-sm-12">
                  <div class="CommentSection">
                    <div class="row">
                      {/* <div class='col-md-12 col-sm-12'>
                      <div class='CommentHeading'>
                        <h1>COMMENTS:</h1>
                      </div>
                    </div> */}

                      {/* <div class='col-md-12 col-sm-12'>
                      <div class='CommentContainer'>
                        <div class='row'>
                          <div class='col-md-2 col-sm-12'>
                            <div class='CommentLeft'>
                              <img
                                src='/assets/image/comment-pic.png'
                                width='120'
                                height='120'
                                alt=''
                              />
                            </div>
                          </div>

                          <div class='col-md-10 col-sm-12'>
                            <div class='CommentRight'>
                              <h1>
                                Johny Anderson <span>16, July 2019</span>
                              </h1>
                              <p>
                                Lorem Ipsum is simply dummy text of the printing
                                and typesetting industry. Lorem Ipsum has been
                                the industry's standard dummy text ever since
                                the 1500s, when an unknown printer took a galley
                                of type and scrambled it to make a type specimen
                                book.{' '}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class='CommentContainer'>
                        <div class='row'>
                          <div class='col-md-2 col-sm-12'>
                            <div class='CommentLeft'>
                              <img
                                src='/assets/image/comment-pic.png'
                                width='120'
                                height='120'
                                alt=''
                              />
                            </div>
                          </div>

                          <div class='col-md-10 col-sm-12'>
                            <div class='CommentRight'>
                              <h1>
                                Johny Anderson <span>16, July 2019</span>
                              </h1>
                              <p>
                                Lorem Ipsum is simply dummy text of the printing
                                and typesetting industry. Lorem Ipsum has been
                                the industry's standard dummy text ever since
                                the 1500s, when an unknown printer took a galley
                                of type and scrambled it to make a type specimen
                                book.{' '}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class='clearfix' />

                      <div class='CommentContainer'>
                        <div class='row'>
                          <div class='col-md-2 col-sm-12'>
                            <div class='CommentLeft'>
                              <img
                                src='/assets/image/comment-pic.png'
                                width='120'
                                height='120'
                                alt=''
                              />
                            </div>
                          </div>

                          <div class='col-md-10 col-sm-12'>
                            <div class='CommentRight'>
                              <h1>
                                Johny Anderson <span>16, July 2019</span>
                              </h1>
                              <p>
                                Lorem Ipsum is simply dummy text of the printing
                                and typesetting industry. Lorem Ipsum has been
                                the industry's standard dummy text ever since
                                the 1500s, when an unknown printer took a galley
                                of type and scrambled it to make a type specimen
                                book.{' '}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}

                      <div class="col-md-12 col-sm-12">
                        <form action="#">
                          <div class="row">
                            <div class="col-md-12 col-sm-12">
                              <div class="CommentHeading">
                                <h1>Post a Comment:</h1>
                              </div>
                            </div>

                            <div class="col-md-12 col-sm-12">
                              <div class="CommentForm">
                                <textarea
                                  name=""
                                  id=""
                                  placeholder="Your Comment"
                                />
                              </div>
                            </div>

                            <div class="col-md-4 col-sm-12">
                              <div class="CommentForm">
                                <input
                                  type="name"
                                  name=""
                                  id=""
                                  value=""
                                  placeholder="Your Name"
                                />
                              </div>
                            </div>

                            <div class="col-md-4 col-sm-12">
                              <div class="CommentForm">
                                <input
                                  type="email"
                                  name=""
                                  id=""
                                  value=""
                                  placeholder="Your Email"
                                />
                              </div>
                            </div>

                            <div class="col-md-4 col-sm-12">
                              <div class="CommentForm">
                                <input
                                  type="text"
                                  name=""
                                  id=""
                                  value=""
                                  placeholder="Website"
                                />
                              </div>
                            </div>

                            <div class="col-md-4 col-sm-12">
                              <div class="CommentForm">
                                <button type="submit" value="">
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : null}
      </React.Fragment>
    );
  }
}
export default BlogDetails;
