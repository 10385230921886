import React from "react";
import { posturl } from "./configuration.js";
import PageTitles from "./pageTitles";
import $ from "jquery";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//import ReactDOM from 'react-dom';
var that = "";
class HowITWork extends React.Component {
  constructor(props) {
    var finalurl = posturl;
    super(props);
    that = this;
    this.state = {
      baseUrl: finalurl
    };
  }

  render() {
    const title = "Electronics Recycling in Dallas | LaptopZone";
    const pageLink = "http://laptopzone.us/recycling";
    const metaDescription =
      "LaptopZone, the perfect place for repairing electronic gadgets in Dallas, TX. We deal with your e-waste and electronics recycling needs, just to save the surroundings.We recycle your iPhone, MacBook, Dell Laptop etc.";

    return (
      <React.Fragment>
        <section className="SectionHowWorks">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <div className="WorksHeading">
                      <h1>How does it work</h1>
                      <p>
                        Our dealings are very easy; you bring us your old or new
                        gadgets we will buy it from you. We will have the
                        gadgets, you will get the money, a perfect win win
                        situation for both. Visit us to make easy money.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-12 col-sm-12">
                <div className="row">
                  <div className="col-md-6 col-sm-6">
                    <div className="HowItLeft">
                      {/* <!--<img src="image/2.png" width="262" height="200" alt=""/>--> */}
                    </div>
                  </div>

                  <div className="col-md-6 col-sm-6">
                    <div className="HowItRight">
                      <h3>step 1</h3>
                      {/* <!--<h4>Lorem ipsum doller site amet site ametore</h4>--> */}
                      <p>
                        Make an appointment to visit our shop. You can also
                        contact us if you have an extra or useless stock like
                        laptops, mobiles, tablets, etc.{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-12 col-sm-12">
                <div className="row">
                  <div className="col-md-6 col-sm-6">
                    <div className="HowItRight">
                      <h3>step 2</h3>
                      {/* <!--<h4>Lorem ipsum doller site amet site ametore</h4>--> */}
                      <p>
                        After getting an appointment you can visit us. On the
                        other hand, we will pick up your useless stock from your
                        place for convenience.
                      </p>
                    </div>
                  </div>

                  <div className="col-md-6 col-sm-6">
                    <div className="HowItLeft">
                      {/* <!--<img src="image/2.png" width="262" height="200" alt=""/>--> */}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-12 col-sm-12">
                <div className="row">
                  <div className="col-md-6 col-sm-6">
                    <div className="HowItLeft">
                      {/* <!--<img src="image/2.png" width="262" height="200" alt=""/>--> */}
                    </div>
                  </div>

                  <div className="col-md-6 col-sm-6">
                    <div className="HowItRight">
                      <h3>step 3</h3>

                      <p>
                        Now you can sell your used stuff like laptops, iPhones,
                        iPads, tablets, testing and networking equipment &
                        computer
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-12 col-sm-12">
                <div className="row">
                  <div className="col-md-6 col-sm-6">
                    <div className="HowItRight">
                      <h3>step 4</h3>

                      <p>Make profit!</p>
                    </div>
                  </div>

                  <div className="col-md-6 col-sm-6">
                    <div className="HowItLeft"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
export default HowITWork;
