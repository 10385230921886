import React from "react";
import { posturl } from "./configuration.js";
import $ from "jquery";
var that = "";

class CoppellCity extends React.Component {
  constructor(props) {
    var finalurl = posturl;
    super(props);
    that = this;
    this.state = {
      baseUrl: finalurl
    };
  }
  changeTab =(para) =>{
    if(para == 'one'){
        $('#one').addClass('show active');
        $('#two').removeClass('show active');
        $('#three').removeClass('show active');

        $('#one-tab').addClass('active');
        $('#two-tab').removeClass('active');
        $('#three-tab').removeClass('active');
    }else if(para == 'two'){
      $('#one').removeClass('show active');
      $('#two').addClass('show active');
      $('#three').removeClass('show active');

      $('#one-tab').removeClass('active');
        $('#two-tab').addClass('active');
        $('#three-tab').removeClass('active');
    }else{
      $('#one').removeClass('show active');
        $('#two').removeClass('show active');
        $('#three').addClass('show active');

        $('#one-tab').removeClass('active');
        $('#two-tab').removeClass('active');
        $('#three-tab').addClass('active');
    }

}
  render() {
    return <React.Fragment>
        <section className="SectionMacBook">
    <img src="/assets/image/mac-banner.png" alt="" />
    
    <div className="Macbook-InnerContent">
     <div className="container">
      <div className="row">
      	
       <div className="col-md-6 col-sm-12">
       
       	<div className="UsedMacbook">
       	 <h1>We Buy Used Electronics</h1>
       	 <br />
       	 <p>Buy, sell and trade unwanted gadgets locally, securely, and quickly!</p>
       	</div>
       	
       	<div className="MackbookForms">
       	 <form action="#" method="#">
       	  <input type="text" name="" id="" value="" placeholder="eg. Macbook Pro 450" />
       	  <button type="submit" value="">Get A Quote</button>
       	 </form>
       	</div>
       	
       </div>
       
       <div className="col-md-6 col-sm-12">
       	<div className="MacbookImage">
       	 <img src="/assets/image/mackbook-image.png" alt=""/>
       	</div>
       </div>
      	
      </div>
     </div>
    </div> 
       
   </section>
       
   <section className="SectionSell-Gadget">
   	<div className="container">
   	 <div className="row">
   	 	
   	  <div className="col-md-12 col-sm-12">
   	   <h2>Welcome to Our Top Services </h2>
   	  </div>
   	  
   	  <div className="col-md-4 col-sm-12">
   	   <div className="GetQuote-Container">
   	   	
   	   	<div className="GetIcon-Box">
   	   	 <i className="icon-send-quote-icon"></i>
   	   	</div>
   	   	
   	   	<div className="GetContent-Btm">
   	   	 <h3>Repairing</h3>
   	   	 <p>Repairing connects us to our old things.<br /> Bring your broken gadgets and we repair it on the spot.</p>
               <a href="/repairing">Get a Quote</a>
   	   	</div>
   	   	
   	   </div>
   	  </div>
   	  
   	  <div className="col-md-4 col-sm-12">
   	   <div className="GetQuote-Container">
   	   	
   	   	<div className="GetIcon-Box">
   	   	 <i className="icon-send-quote-icon"></i>
   	   	</div>
   	   	
   	   	<div className="GetContent-Btm">
   	   	 <h3>Recycling</h3>
   	   	 <p>Don't throw into the trash, just<br /> bring to us and we recycle your used MacBook or other electronics.</p>
               <a href="/recycling">Get a Quote</a>
   	   	</div>
   	   	
   	   </div>
   	  </div>
   	  
   	  <div className="col-md-4 col-sm-12">
   	   <div className="GetQuote-Container">
   	   	
   	   	<div className="GetIcon-Box">
   	   	 <i className="icon-send-quote-icon"></i>
   	   	</div>
   	   	
   	   	<div className="GetContent-Btm">
   	   	 <h3>Buy / Sell</h3>
   	   	 <p>Sell used electronics in Coppell for cash. <br />Bring your unwanted electronics today and get paid.</p>
               <a href="/sell">Get a Quote</a>
   	   	</div>
   	   	
   	   </div>
   	  </div>
   	  
   	  {/* <div className="col-md-12 col-sm-12">
   	   <div className="GetQuote-Button">
   	   	<a href="#">Get a Quote</a>
   	   </div>
   	  </div> */}
   	 	
   	 </div>
   	</div>
   </section>
   
   <section className="Section-AboutLaptop">
   	<div className="container">
   	 <div className="row">
   	 
   	  <div className="col-md-12 col-sm-12">
   	   <div className="SellHeading">
   	   	<h4>We are Giving New Life to Used Gadgets</h4>
   	   </div>
   	  </div>
   	 	
   	  <div className="col-md-12 col-sm-12">
   	   <div className="tab" role="tabpanel">
        
        <div className="tab-card">
        
         <div className="card-header tab-card-header">
          <ul className="nav nav-tabs card-header-tabs" id="myTab" role="tablist">
           
            <li className="nav-item">
             <a className="nav-link active" id="one-tab" data-toggle="tab" onClick={() => this.changeTab('one')} role="tab" aria-controls="One" aria-selected="true">Where can I sell my used electronics?</a>
            </li>
            
            <li className="nav-item">
             <a className="nav-link" id="two-tab" data-toggle="tab" onClick={() => this.changeTab('two')} role="tab" aria-controls="Two" aria-selected="false">Is repairing save money and time? </a>
            </li>
            
            <li className="nav-item">
             <a className="nav-link" id="three-tab" data-toggle="tab" onClick={() => this.changeTab('three')} role="tab" aria-controls="Three" aria-selected="false">Where can I recycle my old electronics?</a>
            </li>
            
          </ul>
         </div>

         <div className="tab-content" id="myTabContent">
         
          <div className="tab-pane fade show active" id="one" role="tabpanel" aria-labelledby="one-tab">
           <div className="row">
           	
           	<div className="col-md-8 col-sm-12">
           	 <div className="TabAll-Content">
           	  
           	  <p>If you're ready to sell your used electronics in Coppell like cell phones, MacBook, tablets, iPods, iPads, networking accessories or gaming consoles for some extra cash then sell them today and get paid at the spot. We are paying you the top dollars for your used or broken electronics.</p>
           	  
           	  <p>We make it easy for our esteemed customers to sell your iPhone in Coppell or other electronics with instant price quotes. So, bring your used or old electronic devices and we guarantee you the best prices. </p>
           	  
           	 </div>
           	</div>
           	
           	<div className="col-md-4 col-sm-12">
           	 <div className="TabAbout-Image">
           	  <img src="/assets/image/mackbook-image.png" alt="" />
           	 </div>
           	</div>
           	
           </div>
          </div>
          
          <div className="tab-pane fade" id="two" role="tabpanel" aria-labelledby="two-tab">
           <div className="row">
           	
           	<div className="col-md-8 col-sm-12">
           	 <div className="TabAll-Content">
           	  
           	  <p>Yes, there is no doubt, repairing saves your hard-earned money and valuable time. On the other hand, it connects people with their favorite old things. It is high time we request the right to open, fix with, and repair used electronics in Coppell we own. Come and get our top repairing services in the bustling city, Coppell.</p>
           	  
           	  <p>We offer outstanding services for repair your used dell/hp laptops in Coppell at affordable rates. As well as, we treat you like a family and give you the friendly-environment. Visit us today and get benefit from repairing services.</p>
           	  
           	 </div>
           	</div>
           	
           	<div className="col-md-4 col-sm-12">
           	 <div className="TabAbout-Image">
           	  <img src="/assets/image/mackbook-image.png" alt="" />
           	 </div>
           	</div>
           	
           </div>
          </div>
          
          <div className="tab-pane fade" id="three" role="tabpanel" 
           aria-labelledby="three-tab">
           <div className="row">
           	
           	<div className="col-md-8 col-sm-12">
           	 <div className="TabAll-Content">
           	  
           	  <p>A world is facing the biggest threat of e-waste. The effects of electronic waste are hazardous for all living beings. This is the major reason; you don’t throw your old electronic gadget into the trash. It is our prime duty to save the environment. Therefore, we offer recycling services for the valued people of Coppell.</p>
           	  
           	  <p>Recycle your old iPhones, MacBook, laptops, tablets and other electronics. We can give you a pick-up service as well, yes our experts will pick-up your unwanted electronics from your doorstep.</p>
           	  
           	 </div>
           	</div>
           	
           	<div className="col-md-4 col-sm-12">
           	 <div className="TabAbout-Image">
           	  <img src="/assets/image/mackbook-image.png" alt="" />
           	 </div>
           	</div>
           	
           </div>
          </div>

         </div>
        
        </div>
        
       </div>
   	  </div>	
   	 	
   	 </div>
   	</div>
   </section>
       
     
     <section className="RecylingAbout" id="AboutUs">
    <div className="container">
     <div className="row">
      	
      <div className="col-md-12 col-sm-12">
       <div className="RecylingHeading">
      	<h2>Who We <span>Are</span></h2>
        <p>We Buy, Repair & Recycle All Apple Products</p>
       </div>
      </div>
      	
      <div className="col-md-6 col-sm-6">
      	 
       <div className="Recylingbt-Content">
      	<h3>About Us</h3>
      	<p>We started our journey 15 years ago in the industry of electronics selling/buying, repairing and recycling. We are running our business in Coppell, TX as a local electronics store. Our lists of selling/buying, repairing and recycling services and clients have grown gradually ever since.<br /><br />At present, we sell, buy, repair and recycle most of the famous electronic devices, and serve people and local businesses of Coppell over the internet. We provide a safe, hassle-free and fun way for clients to sell their used or unwanted electronic devices. Electronics repairing and recycling are both our top services as well. </p>
       </div>
      	 
       <h4 className="UseAllHeading">What we do</h4>
      	 
       <div className="RecyleContainer">
      	<div className="row">
      	  
      	 <div className="col-md-3 col-sm-12">
      	  <div className="RecylePC_Left">
      	   <i className="icon-tablet_icon"></i>
      	  </div>
      	 </div>
      	  	
      	 <div className="col-md-9 col-sm-12">
      	  <div className="RecylePC_Right">
      	   <h4>Recycling</h4>
      	   <p>We are experts in electronics recycling. Come and visit us for computer recycling in Coppell. Recycling is the best solution to save from the e-waste threat.</p>
      	  </div>
      	 </div>
      	  	
        </div>
       </div>
      	 
       <div className="RecyleContainer">
      	<div className="row">
      	  
      	 <div className="col-md-3 col-sm-12">
      	  <div className="RecylePC_Left">
      	   <i className="icon-tablet_icon"></i>
      	  </div>
      	 </div>
      	  	
      	 <div className="col-md-9 col-sm-12">
      	  <div className="RecylePC_Right">
      	   <h4>Repairing</h4>
      	   <p>Get the electronics repairing services at economical prices. We offer fast, reliable and safe repairing services, so come and repair your broken MacBook in Coppell.</p>
      	  </div>
      	 </div>
      	  	
        </div>
       </div>
      	 
       <div className="RecyleContainer">
      	<div className="row">
      	  
      	 <div className="col-md-3 col-sm-12">
      	  <div className="RecylePC_Left">
      	   <i className="icon-tablet_icon"></i>
      	  </div>
      	 </div>
      	  	
      	 <div className="col-md-9 col-sm-12">
      	  <div className="RecylePC_Right">
      	   <h4>Buy/Sell</h4>
      	   <p>We pay you the top $$$ if you sell your used electronics such as iPhones, laptops, MacBook etc. Get our top-notch services of selling and buying today.</p>
      	  </div>
      	 </div>
      	  	
        </div>
       </div>
      	       	 
      </div>
      	
      <div className="col-md-6 col-sm-6">
      	 
       <h4 className="UseAllHeading">Why choose us</h4>
      	 
       <div className="RecyleContainer">
      	<div className="row">
      	  
      	 <div className="col-md-3 col-sm-12">
      	  <div className="RecylePC_Left">
      	   <i className="icon-tablet_icon"></i>
      	  </div>
      	 </div>
      	  	
      	 <div className="col-md-9 col-sm-12">
      	  <div className="RecylePC_Right">
      	   <h4>Fast Cash at the Spot</h4>
      	   <p>Get cash on the spot and enjoy the highest prices to fulfill your necessary needs. Sell your used electronics products like iPhones, laptops, MacBook etc and we pay you top dollars in return.</p>
      	  </div>
      	 </div>
      	  	
        </div>
       </div>
      	 
       <div className="RecyleContainer">
      	<div className="row">
      	  
      	 <div className="col-md-3 col-sm-12">
      	  <div className="RecylePC_Left">
      	   <i className="icon-tablet_icon"></i>
      	  </div>
      	 </div>
      	  	
      	 <div className="col-md-9 col-sm-12">
      	  <div className="RecylePC_Right">
      	   <h4>Zero Risk</h4>
      	   <p>We are professional and working in the sell-buy electronics field for the last 15 years. There is no specific obligation as well as we provide you a friendly environment. Just sell and make money.</p>
      	  </div>
      	 </div>
      	  	
        </div>
       </div>
      	 
       <div className="RecyleContainer">
      	<div className="row">
      	  
      	 <div className="col-md-3 col-sm-12">
      	  <div className="RecylePC_Left">
      	   <i className="icon-tablet_icon"></i>
      	  </div>
      	 </div>
      	  	
      	 <div className="col-md-9 col-sm-12">
      	  <div className="RecylePC_Right">
      	   <h4>Affordable Repairing Rates</h4>
      	   <p>If you can’t afford to buy a new device, the best option is to repair your old or broken electronics in Coppell’s best-repairing store. We offer affordable repairing services, come and repair today.</p>
      	  </div>
      	 </div>
      	  	
        </div>
       </div>
      	 
       <div className="RecyleContainer">
      	<div className="row">
      	  
      	 <div className="col-md-3 col-sm-12">
      	  <div className="RecylePC_Left">
      	   <i className="icon-tablet_icon"></i>
      	  </div>
      	 </div>
      	  	
      	 <div className="col-md-9 col-sm-12">
      	  <div className="RecylePC_Right">
      	   <h4>We Save Environment</h4>
      	   <p>By our electronics recycling service in Coppell, we save the environment. Don’t trash your useless electronics, just recycle and make this world a greener and better place. So, recycle used MacBook and other electronics.</p>
      	  </div>
      	 </div>
      	  	
        </div>
       </div>
      	 
      </div>
      	
     </div>
    </div>
   </section>
      
   {/* <div className="ShopMr-Btm"></div> */}

   
    </React.Fragment>;
  }
}
export default CoppellCity;
