import React from "react";
import "./App.css";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import $ from "jquery";

// $(".nav a").click(function() {
$(".nav a").removeClass("active");
$(this).addClass("active");
// });
$(document).ready(function() {
  $("header a").on("click", function(event) {
    if (this.hash !== "") {
      event.preventDefault();

      var hash = this.hash;
      var pathname = window.location.pathname;
      if (pathname == "/") {
        $("html, body").animate(
          {
            scrollTop: $(hash).offset().top
          },
          800,
          function() {
            window.location.hash = hash;
          }
        );
      } else if (hash == "#ContactUs") {
        if (pathname == "/our-blogs") {
          window.location.replace("/" + hash);
          return;
        }
        $("html, body").animate(
          {
            scrollTop: $(hash).offset().top
          },
          800,
          function() {
            window.location.hash = hash;
          }
        );
      } else {
        window.location.replace("/" + hash);
      }
    }
  });
  $(".NavMobile .fa-bars").click(function() {
    $("ul").slideToggle();
    $("ul ul").css("display", "none");
  });

  $("ul li").click(function() {
    $("ul ul").slideUp();
    $(this)
      .find("ul")
      .slideToggle();
  });

  $(window).resize(function() {
    if ($(window).width() > 1199) {
      $("ul").removeAttr("style");
    }
  });
});
class Header extends React.Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    var pathname = window.location.pathname;
    $('.nav a[href="' + pathname + '"]').addClass("active");
    // $('.nav2 a[href="' + pathname + '"]').addClass("active");
  }
  render() {
    return (
      <React.Fragment>
        <header>
          <div className="HeaderTop navigation-section">
            <div className="container">
              <div className="row">
                <div className="col-md-4 col-sm-12">
                  <div className="MainLogo">
                    <a href="/">
                      <img
                        src="/assets/image/Laptop Zone.png"
                        width="240"
                        height="41"
                        title=""
                        alt=""
                      />
                    </a>
                  </div>
                </div>

                <div className="col-md-5 col-sm-12">
                  <div className="HeaderSearch">
                    <form action="#" method="#">
                      <input
                        type="text"
                        id=""
                        name=""
                        placeholder="Search..."
                      ></input>
                      <button>
                        <i className="icon-search_icon"></i>
                      </button>
                    </form>
                  </div>
                </div>

                <div className="col-md-3 col-sm-12">
                  <div className="HeaderRight">
                    <i className="icon-header_phone"></i>
                    <h1>&nbsp; (214) 205-0950</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="HeaderNav navigation-section float-panel"
            data-top="0"
            data-scroll="500"
          >
            <div className="container">
              <div className="row">
                <div className="col-md-6 col-sm-12">
                  <nav className="nav">
                    <a className="nav-link" href="/">
                      Home
                    </a>
                    <a className="nav-link" href="#AboutUs">
                      About Us
                    </a>
                    <a className="nav-link" href="/how-it-works">
                      How it works
                    </a>
                    <a className="nav-link" href="/our-blogs">
                      Our Blogs
                    </a>

                    
                    <a className="nav-link" href="#ContactUs">
                      Contact us
                    </a>
                  </nav>
                </div>

                <div className="col-md-4 col-sm-12 NavBorder">
                  <nav className="nav">
                  <a className="nav-link NavMenu" href="/sell-it-equipment">
                    Sell IT Equipment 
                    </a>
                    <a className="nav-link NavMenu" href="/recycling">
                      Recycling
                    </a>
                    <a className="nav-link NavMenu" href="/repairing">
                      Repairing
                    </a>

                    <a className="nav-link NavMenu" href="/sell">
                      Sell
                    </a>
                    
                  </nav>
                </div>

                <div className="col-md-2 col-sm-12">
                  <div className="NavSign nav">
                    {/* <a href="/getMap">Sign In</a> */}
                    {/* <a href="#">Sign Up</a> */}
                    <a href="/pullRequests">Pull Request</a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="nav_mobile">
            <nav className="NavMobile">
              <div>
                <a href="/">
                  <img
                    src="/assets/image/Laptop Zone.png"
                    width="240"
                    height="41"
                    alt=""
                  />
                </a>
                <i className="fa fa-bars"></i>
              </div>

              <ul>
                {/* <div className="MenuLogin">
                  <a href="#">Sign In</a>
                  <a href="#">Sign Up</a>
                </div> */}

                <li>
                  <a href="/">Home</a>
                </li>
                <li>
                  <a href="#AboutUs">About</a>
                </li>
                <li>
                  <a href="/how-it-works">How it Works</a>
                </li>
                <li>
                  <a href="/our-blogs">Our Blogs</a>
                </li>
                <li>
                  <a href="#ContactUs">Contact</a>
                </li>

                <div className="MenuDefferntBtn">
                  <a href="/recycling">Recycling</a>
                  <a href="/repairing">Repairing</a>
                  <a href="/sell">Sell</a>
                </div>
              </ul>
            </nav>

            <div className="HeaderSearch">
              <form action="#" method="#">
                <input
                  type="text"
                  id=""
                  name=""
                  placeholder="Search..."
                ></input>
                <button>
                  <i className="icon-search_icon"></i>
                </button>
              </form>
            </div>
          </div>
        </header>
      </React.Fragment>
    );
  }
}

export default Header;
