import React from "react";
import PageTitles from "./pageTitles";
import $ from "jquery";
import "./insideAssets/owl-carousel/owl.carousel.min.js";
//  import './insideAssets/owl-carousel/owl.carousel.min.css';
import { posturl } from "./configuration.js";

class DefaultHome extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      FinalUrl: posturl,
      newsEvents: []
    };
  }

  componentDidMount() {
    this.$el = $(this.el);
    this.$el.owlCarousel({
      items: 4,
      itemsDesktop: [1000, 4],
      itemsDesktopSmall: [990, 4],
      itemsTablet: [768, 3],
      pagination: false,
      navigation: false,
      navigationText: ["", ""],
      slideSpeed: 5000,
      transitionStyle: "backSlide",
      autoPlay: true
    });

    const insertUrl =
      this.state.FinalUrl +
      "/laptopzone/reactcontroller/c_haziqreact/get_news_events";

    new Promise((resolve, reject) => {
      $.ajax({
        url: insertUrl,
        dataType: "json",
        type: "GET"
      }).then(
        data => {
          resolve(data);
        },
        err => {
          reject(err);
        }
      );
    })
      .then(response => {
        // console.log(response)
        this.setState({
          ...this.state,
          newsEvents: response.data
        });
      })
      .catch(err => {
        console.log(err.message);
      });
  }
  render() {
    const title = "Buy-Sell Used Electronic Gadgets in Dallas, TX | LaptopZone";
    const pageLink = "http://laptopzone.us";
    const metaDescription =
      "Come to LaptopZone, the best seller, buyer, recycler and repairer near you in Dallas, TX.Visit us now to avail Professional, Reliable and Fastest Services to make old/broken electronic gadgets new again!";
    return (
      <React.Fragment>
        <PageTitles
          title={title}
          metaDescription={metaDescription}
          pageLink={pageLink}
        />
        <section className="SellAndUsed-Container">
          <div className="container">
            <div className="row">
              <div className="col-md-2 col-sm-12" />

              <div className="col-md-8 col-sm-12">
                <div className="SellAndUsed-Content">
                  <h1>We Make It Easy For You</h1>
                  <p>Buy, Sell, Trade, Recycle and Repair! We Do It All Here</p>
                </div>

                <div className="SellAndUsed-Form">
                  <input
                    type="text"
                    id=""
                    name=""
                    value=""
                    placeholder="eg. Macbook Pro 450"
                  />
                  <button type="submit" value="">
                    Get a Quote
                  </button>
                </div>
              </div>

              <div className="col-md-2 col-sm-12" />
            </div>
          </div>
        </section>

        <section className="SectionSell-Gadget">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-sm-12">
                <h2>Select our Service</h2>
              </div>

              <div className="col-md-4 col-sm-12">
                <div className="GetQuote-Container">
                  <div className="GetIcon-Box">
                    <i className="icon-icon-repairing" />
                  </div>

                  <div className="GetContent-Btm">
                    <h3>Repairing</h3>
                    <p>
                      Repairing Your Old/Broken Gadgets
                      <br /> Making It New Again. A professional, reliable &
                      fastest repairing services!
                    </p>
                    <a href="/repairing">Get a Quote</a>
                  </div>
                </div>
              </div>

              <div className="col-md-4 col-sm-12">
                <div className="GetQuote-Container">
                  <div className="GetIcon-Box">
                    <i className="icon-icon-recycling" />
                  </div>

                  <div className="GetContent-Btm">
                    <h3>Recycling</h3>
                    <p>
                      Ending the culture of use and <br />
                      throw-away! Reuse, reprocess and clean data along with
                      your gadgets.
                    </p>
                    <a href="/recycling">Get a Quote</a>
                  </div>
                </div>
              </div>

              <div className="col-md-4 col-sm-12">
                <div className="GetQuote-Container">
                  <div className="GetIcon-Box">
                    <i className="icon-send-quote-icon" />
                  </div>

                  <div className="GetContent-Btm">
                    <h3>Buy / Sell</h3>
                    <p>
                      Thrive exchange and get cash on the <br />
                      spot with the highest standard of customer service!
                    </p>
                    <a href="/sell">Get a Quote</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="RecylingAbout" id="AboutUs">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-sm-12">
                <div className="RecylingHeading">
                  <h2>
                    Who We <span>Are</span>
                  </h2>
                  <p>
                    Sell, Repair or Recycle Your Old/Broken Gadgets Through
                    <br /> our Professional, Reliable and Fastest Services.
                  </p>
                </div>
              </div>

              <div className="col-md-6 col-sm-6">
                <div className="Recylingbt-Content">
                  <h3>About Us</h3>
                  <p>
                    Helping you get rid of your old, broken and used electronic
                    gadgets. LaptopZone is a dedicated Dallas recycling,
                    repairing and buy/sell center based enterprise motivated to
                    help you with your E-waste.
                    <br />
                    <br />
                    Make money out of those old machines as we buy everything
                    from broken laptop, phones, tabs, etc. Our business model is
                    based on recycling and repairing the old/broken to make it
                    new or to sell the parts of the machine. <br />
                    Join hands today and bring you old and useless gadgets to us
                    so we can make them useful again.
                  </p>
                </div>

                <h4 className="UseAllHeading">What we do</h4>

                <div className="RecyleContainer">
                  <div className="row">
                    <div className="col-md-3 col-sm-12">
                      <div className="RecylePC_Left">
                        <i className="icon-tablet_icon" />
                      </div>
                    </div>

                    <div className="col-md-9 col-sm-12">
                      <div className="RecylePC_Right">
                        <h4>Recycling</h4>
                        <p>
                          We will be reusing, reprocessing and cleaning your
                          data along with your gadgets because we have
                          warehouses for computer recycling, laptop recycling
                          and other electronics recycling. Join hands for a
                          greener environment.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="RecyleContainer">
                  <div className="row">
                    <div className="col-md-3 col-sm-12">
                      <div className="RecylePC_Left">
                        <i className="icon-tablet_icon" />
                      </div>
                    </div>

                    <div className="col-md-9 col-sm-12">
                      <div className="RecylePC_Right">
                        <h4>Repairing</h4>
                        <p>
                          By computer repairing and laptop repairing, we not
                          just help save the globe, but we keep our society’s
                          money local, get instant cash and have fun. Best dell
                          laptop repairing center in Dallas!
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="RecyleContainer">
                  <div className="row">
                    <div className="col-md-3 col-sm-12">
                      <div className="RecylePC_Left">
                        <i className="icon-tablet_icon" />
                      </div>
                    </div>

                    <div className="col-md-9 col-sm-12">
                      <div className="RecylePC_Right">
                        <h4>Buy/Sell</h4>
                        <p>
                          We buy iPhone and Samsung cellphones which you have
                          stocked up and are unsure about. We deal B2B as well
                          if you've extra gadgets stocked up contact us. Sell
                          MacBook & Dell Laptops now!
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-sm-6">
                <h4 className="UseAllHeading">Why choose us</h4>

                <div className="RecyleContainer">
                  <div className="row">
                    <div className="col-md-3 col-sm-12">
                      <div className="RecylePC_Left">
                        <i className="icon-tablet_icon" />
                      </div>
                    </div>

                    <div className="col-md-9 col-sm-12">
                      <div className="RecylePC_Right">
                        <h4>We Save Your Money</h4>
                        <p>
                          We know the value of the hard work you do to earn some
                          dollars, therefore, we save you money through buy,
                          recycling and MacBook repair services. Abandon the
                          throwaway economy!
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="RecyleContainer">
                  <div className="row">
                    <div className="col-md-3 col-sm-12">
                      <div className="RecylePC_Left">
                        <i className="icon-tablet_icon" />
                      </div>
                    </div>

                    <div className="col-md-9 col-sm-12">
                      <div className="RecylePC_Right">
                        <h4>We Recycle/Buy Every Electronic Gadget</h4>
                        <p>
                          We have the best recycle/buy center in Dallas so come
                          to us with any brand and condition, we start recycling
                          and buying your old, broken and used electronic
                          gadgets.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="RecyleContainer">
                  <div className="row">
                    <div className="col-md-3 col-sm-12">
                      <div className="RecylePC_Left">
                        <i className="icon-tablet_icon" />
                      </div>
                    </div>

                    <div className="col-md-9 col-sm-12">
                      <div className="RecylePC_Right">
                        <h4>Fastest, Safest and Easiest Services</h4>
                        <p>
                          We ease people with the best iPhone repairing, Samsung
                          mobile/laptop repairing, computer recycling and
                          sell/buy iPhone services. Get the fastest, safest and
                          easiest recycling, repairing and buy/sell services
                          now!
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="RecyleContainer">
                  <div className="row">
                    <div className="col-md-3 col-sm-12">
                      <div className="RecylePC_Left">
                        <i className="icon-tablet_icon" />
                      </div>
                    </div>

                    <div className="col-md-9 col-sm-12">
                      <div className="RecylePC_Right">
                        <h4>Repairing at Economical Rates</h4>
                        <p>
                          Come to us for mobile repairing, iPhone repairing,
                          dell laptop repairing etc and we’ll fix your gadget at
                          very economical rates which are less than a
                          marketplace.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="RecylingClients">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-sm-12">
                <div className="ClientsHead">
                  <h2>
                    Our <span>Products</span>
                  </h2>
                  <p>
                    Our global product portfolio enables
                    <br /> clinicians to be more practical and productive.
                  </p>
                </div>
              </div>

              <div className="col-md-12 col-sm-12">
                <div
                  id="recyling_clients"
                  ref={el => (this.el = el)}
                  className="owl-carousel"
                >
                  <div className="ClientsImage">
                    <img
                      src="/assets/image/logo-01.png"
                      width="300"
                      height="157"
                      alt=""
                    />
                  </div>

                  <div className="ClientsImage">
                    <img
                      src="/assets/image/logo-02.png"
                      width="300"
                      height="157"
                      alt=""
                    />
                  </div>

                  <div className="ClientsImage">
                    <img
                      src="/assets/image/logo-3.png"
                      width="300"
                      height="157"
                      alt=""
                    />
                  </div>

                  <div className="ClientsImage">
                    <img
                      src="/assets/image/logo-04.png"
                      width="300"
                      height="157"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="AppDownload">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-sm-12">
                <div className="AppHeading">
                  <h1>Download our app</h1>
                  <p>
                    You will take professional pictures of your old/broken
                    <br /> electronic gadgets with our Proprietary LaptopZone
                    App
                    <br /> at your home or business location and send us.
                  </p>
                </div>

                <div className="AppButton">
                  <a href="#" target="_blank">
                    <img
                      src="/assets/image/playstore-icon.png"
                      width="35"
                      height="38"
                      alt=""
                    />{" "}
                    Play Store
                  </a>

                  <a href="#" target="_blank">
                    <img
                      src="/assets/image/apple.png"
                      width="35"
                      height="42"
                      alt=""
                    />{" "}
                    App Store
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="RecylingNews" id="NewEvents">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-sm-12">
                <div className="NewsHead">
                  <h2>
                    News and <span>Events</span>
                  </h2>
                  <p>Learn more about our latest updates with just a click!</p>
                </div>
              </div>
              {this.state.newsEvents.map((item, index) => (
                <div className="col-md-6 col-sm-12">
                  <div className="NewsContent">
                    <h5>{item.TILTE}</h5>
                    <h6>{item.CREATED_DATE}</h6>
                    <p>{item.SHORT_DESC}</p>
                    <a href={"/" + item.SLUG}>Read More</a>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
export default DefaultHome;
