import React from "react";
import { posturl } from "./configuration.js";
import PageTitles from "./pageTitles";
import $ from "jquery";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//import ReactDOM from 'react-dom';
var that = "";
class Blogs extends React.Component {
  constructor(props) {
    var finalurl = posturl;
    super(props);
    that = this;
    this.state = {
      baseUrl: finalurl,
      newsEvents: [],
      hasMore: true,
      start: 0,
      limit: 6
    };
  }
  componentDidMount() {
    this.loadBlogs();
  }
  loadBlogs() {
    var limit = this.state.limit;
    const insertUrl =
      this.state.baseUrl +
      "/laptopzone/reactcontroller/c_haziqreact/get_news_event_blogs";

    new Promise((resolve, reject) => {
      $.ajax({
        url: insertUrl,
        dataType: "json",
        type: "POST",
        data: { limit: limit }
      }).then(
        data => {
          resolve(data);
        },
        err => {
          reject(err);
        }
      );
    })
      .then(response => {
        if (response.data.length < this.state.limit) {
          this.setState({ hasMore: false });
        }

        // console.log(response)
        this.setState({
          ...this.state,
          newsEvents: response.data
        });

        if (limit > 6) {
          $("html,body").animate(
            {
              scrollTop: $("#id" + (limit - 5)).offset().bottom
            },
            2500
          );
        }
      })
      .catch(err => {
        console.log(err.message);
      });

    // setTimeout(() => {
    //   $(".div")
    //     .slice(0, 6)
    //     .show();
    //   $("#loadMore").on("click", function(e) {
    //     e.preventDefault();
    //     $(".div:hidden")
    //       .slice(0, 6)
    //       .slideDown();
    //     if ($(".div:hidden").length == 0) {
    //       $("#load").fadeOut("slow");
    //     }
    //     $("html,body").animate(
    //       {
    //         scrollTop: $(this).offset().top
    //       },
    //       1500
    //     );
    //   });
    // }, 1000);
  }
  fetchMoreData = () => {
    this.setState({
      limit: this.state.limit + 6
    });

    setTimeout(() => {
      this.loadBlogs();
    }, 500);
  };
  render() {
    console.log(this.state.newsEvents);
    // const title = "Electronics Recycling in Dallas | LaptopZone";
    // const pageLink = "http://laptopzone.us/recycling";
    // const metaDescription =
    //   "LaptopZone, the perfect place for repairing electronic gadgets in Dallas, TX. We deal with your e-waste and electronics recycling needs, just to save the surroundings.We recycle your iPhone, MacBook, Dell Laptop etc.";

    return (
      <React.Fragment>
        <section className="Section-MainBlog" style={{ marginBottom: "100px" }}>
          <div className="container">
            <div className="row">
              {this.state.newsEvents.map((item, index) => (
                <div
                  className="col-md-4 col-sm-6 col-12"
                  id={"id-" + index}
                  key={index}
                >
                  <div className="BlogThumbnail">
                    <img
                      src={item.MASTER_IMAGE_PATH + item.BANNER_IMG}
                      width="1170"
                      height="690"
                      alt=""
                    />

                    {/* <ul>
                        {item.TAG_LIST.map((list, index1) =>
                          index1 <= 2 ? (
                            <li>
                              <a href="#">{"#" + list}</a>
                            </li>
                          ) : null
                        )}
                      </ul> */}

                    <h4>{item.TILTE}</h4>
                    <p>{item.SHORT_DESC}</p>
                    <a
                      style={{ textDecoration: "none" }}
                      href={"/" + item.SLUG}
                    >
                      Read More
                    </a>
                    <div className="BadgeText hide">
                      <h1>{item.DATE_FINAL[0]}</h1>
                      <span>{item.DATE_FINAL[1]}</span>
                    </div>
                  </div>
                </div>
              ))}

              <div className="col-md-12 col-sm-12 col-12">
                {this.state.hasMore ? (
                  <a
                    style={{ cursor: "pointer" }}
                    id="loadMore"
                    onClick={this.fetchMoreData}
                  >
                    Load More
                  </a>
                ) : null}
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
export default Blogs;
