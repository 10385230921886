import React from "react";
import Helment from "react-helmet";
const PageTitles = props => {
  return (
    <div>
      <Helment>
        <title>{props.title} </title>
        <link rel="canonical" href={props.pageLink} />
        <meta name="description" content={props.metaDescription} />
        <meta name="google-site-verification" content="hlUkMpeNqntlfU5W-tktz3IF5MvA7Tk3WK2q-hGpJDQ" />
        
<script async src="https://www.googletagmanager.com/gtag/js?id=UA-208057289-1"></script>
<script>
  {`window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'UA-208057289-1');
  `}
</script>

<script async src="https://www.googletagmanager.com/gtag/js?id=AW-16453686637">
</script>
<script>
  {`window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'AW-16453686637');
  `}
</script>
<script type='application/ld+json'> 
{`
  "@context": "http://www.schema.org",
  "@type": "Organization",
  "name": "LaptopZone",
  "url": "https://laptopzone.us/",
  "logo": "https://laptopzone.us/assets/image/Laptop%20Zone.png",
  "image": "https://laptopzone.us/assets/image/Laptop%20Zone.png",
  "description": "Helping you get rid of your old, broken and used electronic gadgets. LaptopZone is a dedicated Dallas recycling, repairing and buy/sell center based enterprise motivated to help you with your E-waste.\n\nMake money out of those old machines as we buy everything from broken laptop, phones, tabs, etc. Our business model is based on recycling and repairing the old/broken to make it new or to sell the parts of the machine.\nJoin hands today and bring you old and useless gadgets to us so we can make them useful again.",
  "address": {
    "@type": "PostalAddress",
    "streetAddress": "Suite 180, 2720 Royal Lane",
    "addressLocality": "Dallas",
    "addressRegion": "TX",
    "postalCode": "75229 ",
    "addressCountry": "United States"
  },
  "openingHours": "Mo, Tu, We, Th, Fr, Sa, Su -",
  "contactPoint": {
    "@type": "ContactPoint",
    "telephone": "(214) 205-0950"
  }
  `
}
 </script>
      </Helment>
    </div>
  );
};

export default PageTitles;
