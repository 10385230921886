// var getUrl = window.location;
//    var finalurl = getUrl.protocol + '//' + getUrl.hostname;
// var getUrl = window.location;
//    var liv_ip= '71.78.236.20';//live ip
//    var finalurl = getUrl.protocol + '//' + liv_ip;

var getUrl = window.location;
var liv_ip = "laptopzone.us"; //live ip
var finalurl = getUrl.protocol + "//" + liv_ip; // getUrl.hostname;
// var finalurl = "https:" + "//" + liv_ip; // getUrl.hostname;
// var finalurl = getUrl.protocol + "//192.168.100.61";

export const posturl = finalurl;
