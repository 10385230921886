import React, { Component } from "react";
import $ from "jquery";
//import add from "./configfile.js";
import "react-phone-number-input/style.css";
import PhoneInput, {
  isValidPhoneNumber,
  parsePhoneNumber,
  formatPhoneNumber
} from "react-phone-number-input";
import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
import { posturl } from "./configuration.js";
import BuySellMap from "./buySellMap.js";
import ShipingMap from "./shiping_map.js";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from "./getSrc.js";
import DropOffMap from "./drop_off_map.js";

var that = "";
$(document).ready(function() {
  var finalurl = posturl;
  $(".showTop").click(function() {
    var clickDiv = $(this).attr("target");
    if (clickDiv == "top1") {
      if (
        that.state.selectTop == true ||
        that.state.offerTop == true ||
        that.state.paymentTop == true ||
        that.state.shippingTop == true
      ) {
        $(".targetDiv").hide();
        // $(".AllButtons .AllSteps").removeClass("active");
        $(".AllButtons .target_1").addClass("active");
        $(".AllButtons .target_div_1").addClass("active");
        $(".targetDiv_1").show();
        $("#selectionDiv").removeClass("hide");
        $("#getOfferDiv").addClass("hide");
        $("#paymentDiv").addClass("hide");
        $(".shippingDiv").addClass("hide");
      }
    } else if (clickDiv == "top2") {
      if (
        that.state.offerTop == true ||
        that.state.paymentTop == true ||
        that.state.shippingTop == true
      ) {
        $(".AllButtons .target_div_2").addClass("active");
        $("#selectionDiv").addClass("hide");
        $("#getOfferDiv").removeClass("hide");
        $("#paymentDiv").addClass("hide");
        $(".shippingDiv").addClass("hide");
      }
    } else if (clickDiv == "top3") {
      if (that.state.paymentTop == true || that.state.shippingTop == true) {
        $(".AllButtons .target_div_3").addClass("active");
        $("#selectionDiv").addClass("hide");
        $("#getOfferDiv").addClass("hide");
        $("#paymentDiv").removeClass("hide");
        $(".shippingDiv").addClass("hide");
      }
    } else if (clickDiv == "top4") {
      if (
        that.state.selectTop == true &&
        that.state.offerTop == true &&
        that.state.paymentTop == true &&
        that.state.shippingTop == true
      ) {
        $(".AllButtons .target_div_4").addClass("active");
        $("#selectionDiv").addClass("hide");
        $("#getOfferDiv").addClass("hide");
        $("#paymentDiv").addClass("hide");
        $(".shippingDiv").removeClass("hide");
      }
    }
  });
  $(".showSellSingle1").click(function() {
    $(".targetDiv").hide();
    var clickDiv = $(this).attr("target");
    if (clickDiv == 1) {
      $(".targetDiv").hide();
      $(".AllButtons .AllSteps").removeClass("active");
      $(".AllButtons .target_1").addClass("active");
      $(".AllButtons .target_div_1").addClass("active");
      $(".targetDiv_1").show();
    } else if (clickDiv == 2) {
      var get_product_name = $("#product_name").val();
      if (
        get_product_name == null ||
        get_product_name == "" ||
        get_product_name == "undefined"
      ) {
        $(".targetDiv").hide();
        $(".AllButtons .AllSteps").removeClass("active");
        $(".AllButtons .target_1").addClass("active");
        $(".AllButtons .target_div_1").addClass("active");
        $(".targetDiv_1").show();
      } else {
        $(".targetDiv").hide();
        $(".AllButtons .AllSteps").removeClass("active");
        $(".AllButtons .target_1").addClass("active");
        $(".AllButtons .target_2").addClass("active");
        $(".AllButtons .target_div_1").addClass("active");
        $(".targetDiv_2").show();

        let insertUrl =
          finalurl + "/laptopzone/reactcontroller/c_react/ljw_Brands";
        var ljw_getobject = []; //'adi;l asada  yahoo';
        new Promise(function(resolve, reject) {
          $.ajax({
            url: insertUrl,
            dataType: "json",
            type: "POST",
            data: { get_product_name: get_product_name }
          }).then(
            function(data) {
              resolve(data);
            },
            function(err) {
              reject(err);
            }
          );
        })
          .then(result => {
            if (result.exist) {
              var length = result.ljw_getobject.length;
              for (var i = 0; i < length; i++) {
                ljw_getobject.push({
                  brand_name: result.ljw_getobject[i].BRAND_NAME,
                  brand_url: result.ljw_getobject[i].BRAND_URL,
                  brand_dt_id: result.ljw_getobject[i].BRAND_DT_ID
                });
              }
              $(".targetDiv_2").show();
              that.setState({
                Brands: ljw_getobject
              });
            } else {
            }
          })
          .catch(err => {
            console.log(err);
          });
      }
    } else if (clickDiv == 3) {
      let insertUrl =
        finalurl + "/laptopzone/reactcontroller/c_react/ljw_Series";
      var get_brand_name = $("#brand_name").val();
      var get_product_name = $("#product_name").val();

      if (
        get_product_name == null ||
        get_product_name == "" ||
        get_product_name == "undefined"
      ) {
        $(".targetDiv").hide();
        $(".AllButtons .AllSteps").removeClass("active");
        $(".AllButtons .target_1").addClass("active");
        $(".AllButtons .target_div_1").addClass("active");
        $(".targetDiv_1").show();
      } else if (
        get_brand_name == null ||
        get_brand_name == "" ||
        get_brand_name == "undefined"
      ) {
        $(".targetDiv").hide();
        $(".AllButtons .AllSteps").removeClass("active");
        $(".AllButtons .target_1").addClass("active");
        $(".AllButtons .target_2").addClass("active");
        $(".AllButtons .target_div_1").addClass("active");
        $(".targetDiv_2").show();
      } else {
        $(".targetDiv").hide();
        $(".AllButtons .AllSteps").removeClass("active");
        $(".AllButtons .target_1").addClass("active");
        $(".AllButtons .target_2").addClass("active");
        $(".AllButtons .target_3").addClass("active");
        $(".AllButtons .target_div_1").addClass("active");
        $(".targetDiv_3").show();
        var ljw_Series = []; //'adi;l asada  yahoo';
        new Promise(function(resolve, reject) {
          $.ajax({
            url: insertUrl,
            dataType: "json",
            type: "POST",
            data: { get_brand_name: get_brand_name }
          }).then(
            function(data) {
              resolve(data);
            },
            function(err) {
              reject(err);
            }
          );
        })
          .then(result => {
            if (result.exist) {
              var length = result.ljw_Series.length;

              for (var i = 0; i < length; i++) {
                ljw_Series.push({
                  series_name: result.ljw_Series[i].SERIES_NAME,
                  series_dt_id: result.ljw_Series[i].SERIES_DT_ID
                });
              }
              $(".targetDiv_3").show();
              that.setState({
                SeriesName: ljw_Series
              });
            } else {
            }
          })
          .catch(err => {
            console.log(err);
          });
      }
    } else if (clickDiv == 4) {
      let insertUrl =
        finalurl + "/laptopzone/reactcontroller/c_react/ljw_Model";
      var get_product_name = $("#product_name").val();
      var get_brand_name = $("#brand_name").val();
      var get_series_name = $("#series_name").val();
      if (
        get_product_name == null ||
        get_product_name == "" ||
        get_product_name == "undefined"
      ) {
        $(".targetDiv").hide();
        $(".AllButtons .AllSteps").removeClass("active");
        $(".AllButtons .target_1").addClass("active");
        $(".AllButtons .target_div_1").addClass("active");
        $(".targetDiv_1").show();
      } else if (
        get_brand_name == null ||
        get_brand_name == "" ||
        get_brand_name == "undefined"
      ) {
        $(".targetDiv").hide();
        $(".AllButtons .AllSteps").removeClass("active");
        $(".AllButtons .target_1").addClass("active");
        $(".AllButtons .target_2").addClass("active");
        $(".AllButtons .target_div_1").addClass("active");
        $(".targetDiv_2").show();
      } else if (
        get_series_name == null ||
        get_series_name == "" ||
        get_series_name == "undefined"
      ) {
        $(".targetDiv").hide();
        $(".AllButtons .AllSteps").removeClass("active");
        $(".AllButtons .target_1").addClass("active");
        $(".AllButtons .target_2").addClass("active");
        $(".AllButtons .target_3").addClass("active");
        $(".AllButtons .target_div_1").addClass("active");
        $(".targetDiv_3").show();
      } else {
        $(".targetDiv").hide();
        $(".AllButtons .AllSteps").removeClass("active");
        $(".AllButtons .target_1").addClass("active");
        $(".AllButtons .target_2").addClass("active");
        $(".AllButtons .target_3").addClass("active");
        $(".AllButtons .target_4").addClass("active");
        $(".AllButtons .target_div_1").addClass("active");
        $(".targetDiv_4").show();

        var ljw_Model = []; //'adi;l asada  yahoo';

        new Promise(function(resolve, reject) {
          $.ajax({
            url: insertUrl,
            dataType: "json",
            type: "POST",
            data: { get_series_name: get_series_name }
          }).then(
            function(data) {
              resolve(data);
            },
            function(err) {
              reject(err);
            }
          );
        })
          .then(result => {
            if (result.exist) {
              var length = result.ljw_Model.length;
              for (var i = 0; i < length; i++) {
                ljw_Model.push({
                  description: result.ljw_Model[i].DESCRIPTION,
                  model_dt_id: result.ljw_Model[i].MODEL_DT_ID,
                  model_url :  result.ljw_Model[i].IMAGE_URL
                });
              }
              $(".targetDiv_4").show();
              that.setState({
                ModelName: ljw_Model
              });
            } else {
            }
          })
          .catch(err => {
            console.log(err);
          });
      }
    } else if (clickDiv == 5) {
      let insertUrl =
        finalurl + "/laptopzone/reactcontroller/c_react/ljw_Carrier";
      var get_product_name = $("#product_name").val();
      var get_brand_name = $("#brand_name").val();
      var get_series_name = $("#series_name").val();
      var get_model_names = $("#model_names").val();
      if (
        get_product_name == null ||
        get_product_name == "" ||
        get_product_name == "undefined"
      ) {
        $(".targetDiv").hide();
        $(".AllButtons .AllSteps").removeClass("active");
        $(".AllButtons .target_1").addClass("active");
        $(".AllButtons .target_div_1").addClass("active");
        $(".targetDiv_1").show();
      } else if (
        get_brand_name == null ||
        get_brand_name == "" ||
        get_brand_name == "undefined"
      ) {
        $(".targetDiv").hide();
        $(".AllButtons .AllSteps").removeClass("active");
        $(".AllButtons .target_1").addClass("active");
        $(".AllButtons .target_2").addClass("active");
        $(".AllButtons .target_div_1").addClass("active");
        $(".targetDiv_2").show();
      } else if (
        get_series_name == null ||
        get_series_name == "" ||
        get_series_name == "undefined"
      ) {
        $(".targetDiv").hide();
        $(".AllButtons .AllSteps").removeClass("active");
        $(".AllButtons .target_1").addClass("active");
        $(".AllButtons .target_2").addClass("active");
        $(".AllButtons .target_3").addClass("active");
        $(".AllButtons .target_div_1").addClass("active");
        $(".targetDiv_3").show();
      } else if (
        get_model_names == null ||
        get_model_names == "" ||
        get_model_names == "undefined"
      ) {
        $(".targetDiv").hide();
        $(".AllButtons .AllSteps").removeClass("active");
        $(".AllButtons .target_1").addClass("active");
        $(".AllButtons .target_2").addClass("active");
        $(".AllButtons .target_3").addClass("active");
        $(".AllButtons .target_4").addClass("active");
        $(".AllButtons .target_div_1").addClass("active");
        $(".targetDiv_4").show();
      } else {
        $(".targetDiv").hide();
        $(".AllButtons .AllSteps").removeClass("active");
        $(".AllButtons .target_1").addClass("active");
        $(".AllButtons .target_2").addClass("active");
        $(".AllButtons .target_3").addClass("active");
        $(".AllButtons .target_4").addClass("active");
        $(".AllButtons .target_5").addClass("active");
        $(".AllButtons .target_div_1").addClass("active");
        $(".targetDiv_5").show();
        var ljw_Carrier = []; //'adi;l asada  yahoo';
        new Promise(function(resolve, reject) {
          $.ajax({
            url: insertUrl,
            dataType: "json",
            type: "POST",
            data: { model_dt_id: get_model_names }
          }).then(
            function(data) {
              resolve(data);
            },
            function(err) {
              reject(err);
            }
          );
        })
          .then(result => {
            if (result.exist) {
              var length = result.ljw_Carrier.length;
              for (var i = 0; i < length; i++) {
                ljw_Carrier.push({
                  CARRIER_NAME: result.ljw_Carrier[i].CARRIER_NAME,
                  CARRIER_ID: result.ljw_Carrier[i].LZW_CARRIER_DT_ID,
                  IMAGE_URL : result.ljw_Carrier[i].IMAGE_URL
                });
              }
              $(".targetDiv_5").show();
              that.setState({
                carrierName: ljw_Carrier
              });
            } else {
            }
          })
          .catch(err => {
            console.log(err);
          });
      }
    } else if (clickDiv == 6) {
      $(".targetDiv").hide();
      $(".AllButtons .AllSteps").removeClass("active");
      $(".AllButtons .target_6").addClass("active");
      $(".AllButtons .target_div_1").addClass("active");
      let insertUrl =
        finalurl + "/laptopzone/reactcontroller/c_react/ljw_Storage";
      var get_product_name = $("#product_name").val();
      var get_brand_name = $("#brand_name").val();
      var get_series_name = $("#series_name").val();
      var get_model_names = $("#model_names").val();
      var get_storage_names = $("#carrier_name").val();
      console.log(get_storage_names);
      if (
        get_product_name == null ||
        get_product_name == "" ||
        get_product_name == "undefined"
      ) {
        $(".targetDiv").hide();
        $(".AllButtons .AllSteps").removeClass("active");
        $(".AllButtons .target_1").addClass("active");
        $(".AllButtons .target_div_1").addClass("active");
        $(".targetDiv_1").show();
      } else if (
        get_brand_name == null ||
        get_brand_name == "" ||
        get_brand_name == "undefined"
      ) {
        $(".targetDiv").hide();
        $(".AllButtons .AllSteps").removeClass("active");
        $(".AllButtons .target_1").addClass("active");
        $(".AllButtons .target_2").addClass("active");
        $(".AllButtons .target_div_1").addClass("active");
        $(".targetDiv_2").show();
      } else if (
        get_series_name == null ||
        get_series_name == "" ||
        get_series_name == "undefined"
      ) {
        $(".targetDiv").hide();
        $(".AllButtons .AllSteps").removeClass("active");
        $(".AllButtons .target_1").addClass("active");
        $(".AllButtons .target_2").addClass("active");
        $(".AllButtons .target_3").addClass("active");
        $(".AllButtons .target_div_1").addClass("active");
        $(".targetDiv_3").show();
        alert("select Series First");
        return false;
      } else if (
        get_model_names == null ||
        get_model_names == "" ||
        get_model_names == "undefined"
      ) {
        $(".targetDiv").hide();
        $(".AllButtons .AllSteps").removeClass("active");
        $(".AllButtons .target_1").addClass("active");
        $(".AllButtons .target_2").addClass("active");
        $(".AllButtons .target_3").addClass("active");
        $(".AllButtons .target_4").addClass("active");
        $(".AllButtons .target_div_1").addClass("active");
        $(".targetDiv_4").show();
        alert("select Model First");
        return false;
      } else if (
        get_storage_names == null ||
        get_storage_names == "" ||
        get_storage_names == "undefined"
      ) {
        $(".targetDiv").hide();
        $(".AllButtons .AllSteps").removeClass("active");
        $(".AllButtons .target_1").addClass("active");
        $(".AllButtons .target_2").addClass("active");
        $(".AllButtons .target_3").addClass("active");
        $(".AllButtons .target_4").addClass("active");
        $(".AllButtons .target_5").addClass("active");
        $(".AllButtons .target_div_1").addClass("active");
        $(".targetDiv_5").show();
        alert("select Carrier first");
        return false;
      } else {
        $(".targetDiv").hide();
        $(".AllButtons .AllSteps").removeClass("active");
        $(".AllButtons .target_1").addClass("active");
        $(".AllButtons .target_2").addClass("active");
        $(".AllButtons .target_3").addClass("active");
        $(".AllButtons .target_4").addClass("active");
        $(".AllButtons .target_5").addClass("active");
        $(".AllButtons .target_6").addClass("active");
        $(".AllButtons .target_div_1").addClass("active");
        $(".targetDiv_6").show();

        var ljw_Storage = []; //'adi;l asada  yahoo';

        new Promise(function(resolve, reject) {
          $.ajax({
            url: insertUrl,
            dataType: "json",
            type: "POST",
            data: { carrier_id: get_storage_names }
          }).then(
            function(data) {
              resolve(data);
            },
            function(err) {
              reject(err);
            }
          );
        })
          .then(result => {
            if (result.exist) {
              var length = result.ljw_Storage.length;

              for (var i = 0; i < length; i++) {
                console.log(i);
                ljw_Storage.push({
                  storage_id: result.ljw_Storage[i].LZW_STORAGE_DT_ID,
                  storage_name: result.ljw_Storage[i].STORAGE_DESC,
                  sale_price: result.ljw_Storage[i].SALE_PRICE
                });
              }
              that.setState({
                storageName: ljw_Storage
              });
            } else {
            }
          })
          .catch(err => {
            console.log(err);
          });
      }
    }
  });
});
$(document).ready(function() {
  $(".TabHide").slideToggle("slow");
  $(".showmenu").css("display", "none");
  $(".AllButtons .target_1").addClass("active");
  $(".targetDiv_1").show();
  $(".BrandImage img").click(function() {
    $(".BrandImage img").removeClass("active");
    $(this).addClass("active");
  });
});

$(document).ready(function() {
  $(".SeriesSelected label").click(function() {
    $(".SeriesSelected label").removeClass("active");
    $(this).addClass("active");
  });
});

$(document).ready(function() {
  $(".SelectModal").click(function() {
    $(".SelectModal").removeClass("active");
    $(this).addClass("active");
  });
});

$(document).ready(function() {
  $(".DesktopPc").click(function() {
    $(".DesktopPc").removeClass("active");
    $(this).addClass("active");
  });
});
function changeouterproduct(outerproductName) {
  $("#outerproduct").text(outerproductName);
}
class Home extends React.Component {
  constructor(props) {
    var finalurl = posturl;
    super(props);
    that = this;
    this.state = {
      baseUrl: finalurl,
      Brands: [],
      getShipmentCharges: [],
      ship_id: "",
      rate_id: "",
      buy_id: "",
      SeriesName: [],
      ModelName: [],
      carrierName: [],
      storageName: [],
      questions: [],
      answers: [],
      questions_arr: [],
      questions_ids: [],
      product_info_brand: "",
      product_info_model: "",
      product_info_carrier: "",
      product_info_storage: "",
      product_info: "",
      product_name: "",
      brand_name: "",
      series_name: "",
      model_name: "",
      carrier_name: "",
      paypalEmail: "",
      re_paypalEmail: "",
      payable_to: "",
      sale_price: 0,
      sale_price_temp: 0,
      checkEmail: "",
      checkaddress1: "",
      checkaddress2: "",
      number_of_questions: "",
      cityName: "",
      stateName: "",
      zipCode: "",
      countryName: "United States",
      selectProduction: "show",
      selectValues: "show",
      enterComents: "",
      selectTop: false,
      offerTop: false,
      paymentTop: false,
      shippingTop: false,
      fieldsPick: {
        pick_address: "",
        pick_city: "",
        pick_area: "",
        pick_state: "",
        pick_zipcode: ""
      },
      fields: { address: "", city: "", area: "", state: "", zipcode: "" },
      ship_height: "",
      ship_lenght: "",
      ship_weight: "",
      ship_width: "",
      showData: "hide",
      showOptions: "hide",

      dropData: "hide",
      picData: "hide",
      shipData: "hide",
      origin: "2720 Royal Ln, Dallas, TX 75229, USA",
      destination: "2720 Royal Ln, Dallas, TX 75229, USA",
      getRadioVale: ""
    };
    this.saveBuySell = this.saveBuySell.bind(this);
    this.saveBuySell1 = this.saveBuySell1.bind(this);
    this.nextPayment = this.nextPayment.bind(this);
    this.continuePayment = this.continuePayment.bind(this);
    this.showSelecOption = this.showSelecOption.bind(this);
    this.paymentForm = this.paymentForm.bind(this);
    this.otherBrand = this.otherBrand.bind(this);
    this.otherBrandNext = this.otherBrandNext.bind(this);
    this.handleInput = this.handleInput.bind(this);
    this.getAnOffer = this.getAnOffer.bind(this);
    this.showmenu = this.showmenu.bind(this);
    this.getShippingCharges = this.getShippingCharges.bind(this);
    this.getLabel = this.getLabel.bind(this);
    this.otherSeriesNext = this.otherSeriesNext.bind(this);
    this.otherModalNext = this.otherModalNext.bind(this);
    this.otherCarrierNext = this.otherCarrierNext.bind(this);
    this.otherStorageNext = this.otherStorageNext.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChange1 = this.handleChange1.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleSelect1 = this.handleSelect1.bind(this);
    this.handleInput1 = this.handleInput1.bind(this);
  }
  handleSelect1(address1) {
    this.setState({
      address1,
      loading: true,
      showShipMap: "hide"
    });
    if (address1.button == 0) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(position => {
          console.log("1");
          console.log(position.coords);

          this.setState({
            currentLatLng1: {
              lat: position.coords.latitude,
              lng: position.coords.longitude
            },
            address1: "Your pin location",
            showShipMap: "show",
            loading: false
          });
        });
      }
    } else {
      geocodeByAddress(address1)
        .then(results => getLatLng(results[0]))
        .then(({ lat, lng }) => {
          console.log("Success Yay", { lat, lng });
          this.setState({
            geocodeResults1: this.renderGeocodeSuccess(lat, lng),
            currentLatLng1: {
              lat: lat,
              lng: lng
            },
            showShipMap: "show",
            loading: false
          });
        })
        .catch(error => {
          console.log("Oh no!", error);
          this.setState({
            geocodeResults1: this.renderGeocodeFailure(error),
            loading: false
          });
        });
    }
  }
  handleSelect(address) {
    this.setState({
      address,
      loading: true,
      showMap: "hide"
    });
    if (address.button == 0) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(position => {
          console.log("1");
          console.log(position.coords);

          this.setState({
            currentLatLng: {
              lat: position.coords.latitude,
              lng: position.coords.longitude
            },
            address: "Your pin location",
            showMap: "show",
            loading: false
          });
        });
      }
    } else {
      geocodeByAddress(address)
        .then(results => getLatLng(results[0]))
        .then(({ lat, lng }) => {
          console.log("Success Yay", { lat, lng });
          this.setState({
            geocodeResults: this.renderGeocodeSuccess(lat, lng),
            currentLatLng: {
              lat: lat,
              lng: lng
            },
            showMap: "show",
            loading: false
          });
        })
        .catch(error => {
          console.log("Oh no!", error);
          this.setState({
            geocodeResults: this.renderGeocodeFailure(error),
            loading: false
          });
        });
    }
    /* NOTE: Using callback (Deprecated version) */
    // geocodeByAddress(address,  (err, { lat, lng }) => {
    //   if (err) {
    //     console.log('Oh no!', err)
    //     this.setState({
    //       geocodeResults: this.renderGeocodeFailure(err),
    //       loading: false
    //     })
    //   }
    //   console.log(`Yay! got latitude and longitude for ${address}`, { lat, lng })
    //   this.setState({
    //     geocodeResults: this.renderGeocodeSuccess(lat, lng),
    //     loading: false
    //   })
    // })
  }
  handleChange(address) {
    //alert(address);
    this.setState({
      address,
      geocodeResults: null
    });
  }
  handleChange1(address1) {
    // alert(address1);
    this.setState({
      address1,
      geocodeResults1: null
    });
    if (this.state.fields.address) {
      this.setState({
        showShipMap: "hide",
        dropData: "hide"
      });
      $(".getCharge").removeClass("hide");
    }
  }

  renderGeocodeFailure(err) {
    return (
      <div className="alert alert-danger" role="alert">
        <strong>Error!</strong> {err}
      </div>
    );
  }

  renderGeocodeSuccess(lat, lng) {
    return (
      <div className="alert alert-success" role="alert">
        <strong>Success!</strong> Geocoder found latitude and longitude:{" "}
        <strong>
          {lat}, {lng}
        </strong>
      </div>
    );
  }
  handleChangeInput = event => {
    const name = event.target.name;
    const { value, maxLength } = event.target;
    const brndInput = value.slice(0, maxLength);
    this.setState({
      [name]: event.target.value
    });
  };
  handleInput1(e) {
    const name = e.target.name;
    this.setState({ [name]: e.target.value });
  }
  handleInput(e) {
    const name = e.target.name;
    this.setState({ [name]: e.target.value });
    $(".labelPrint").addClass("hide");
    $(".getShip").addClass("hide");
    $(".getCharge").removeClass("hide");
    $(".getLabel").addClass("hide");
    this.setState({
      getShipmentCharges: []
    });
  }
  changeproduct(productName, product_id) {
    this.setState({
      product_info : productName,
      product_info_brand : "",
      product_info_carrier : "",
      product_info_model : "",
      product_info_storage : "",
    })
    
    $("#div2 .tickRemove").addClass("without-after-element");
    $("#div3 .tickRemove").addClass("without-after-element");
    $("#div4 .tickRemove").addClass("without-after-element");
    $("#div5 .tickRemove").addClass("without-after-element");
    $("#div6 .tickRemove").addClass("without-after-element");

    this.setState({
      product_name: product_id,
      brand_name: "",
      series_name: "",
      model_name: "",
      carrier_name: "",
      storage_name: "",
      modalInput: "",
      showbrndInput: "hide",
      showModelInput: "hide",
      showCarrierInput: "hide",
      showStorageInput: "hide",
      selectTop: false,
      offerTop: false,
      paymentTop: false,
      shippingTop: false
    });
    if(productName == 'Laptop'){
      $('.target_4').addClass('AllStepsBorder');
      $('.target_5').addClass('hide');
      $('.target_6').addClass('hide');
      
    }else{
      $('.target_4').removeClass('AllStepsBorder');
      $('.target_5').removeClass('hide');
      $('.target_6').removeClass('hide');
    }
    
    var get_product_name = product_id;
    if (
      get_product_name == null ||
      get_product_name == "" ||
      get_product_name == "undefined"
    ) {
      $(".targetDiv").hide();
      $(".AllButtons .AllSteps").removeClass("active");
      $(".AllButtons .target_1").addClass("active");
      $(".AllButtons .target_div_1").addClass("active");
    } else {
      $(".targetDiv").hide();
      $(".AllButtons .AllSteps").removeClass("active");
      $(".AllButtons .target_1").addClass("active");
      $(".AllButtons .target_div_1").addClass("active");
      let insertUrl =
        this.state.baseUrl + "/laptopzone/reactcontroller/c_react/ljw_Brands";
      var ljw_getobject = []; //'adi;l asada  yahoo';
      new Promise(function(resolve, reject) {
        $.ajax({
          url: insertUrl,
          dataType: "json",
          type: "POST",
          data: { get_product_name: get_product_name }
        }).then(
          function(data) {
            resolve(data);
          },
          function(err) {
            reject(err);
          }
        );
      })
        .then(result => {
          if (result.exist) {
            var length = result.ljw_getobject.length;

            for (var i = 0; i < length; i++) {
              ljw_getobject.push({
                brand_name: result.ljw_getobject[i].BRAND_NAME,
                brand_url: result.ljw_getobject[i].BRAND_URL,
                brand_dt_id: result.ljw_getobject[i].BRAND_DT_ID
              });
            }
            $("#product").html("");
            $("#selection").html("");
            $("#offers").html("");
            $("#payment").html("");
            $("#shipping").html("");
            $("#brand").html("....");
            $("#series").html("....");
            $("#SelectModelName").html("....");
            $("#carrierDiv").html("....");
            $("#storageDiv").html("....");
            $("#product").append(
              productName +
                '<i class="fa fa-check-circle" aria-hidden="true" "></i>'
            );
            if (length > 0) {
              $(".AllButtons .target_2").addClass("active");
              $(".targetDiv_2").show();
              that.setState({
                Brands: ljw_getobject
              });
            } else {
              toast("No record found !");
              $(".targetDiv_1").show();
            }
          } else {
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
    return false;
  }
  changeBrand(brandName, brand_dt_id) {
    
    this.setState({
      brand_name : brandName,
      series_name: "",
      model_name: "",
      carrier_name: "",
      storage_name: "",
      modalInput: "",
      showbrndInput: "hide",
      showModelInput: "hide",
      showCarrierInput: "hide",
      showStorageInput: "hide",
      selectTop: false,
      offerTop: false,
      paymentTop: false,
      shippingTop: false
    })
    $("#div2 .tickRemove").removeClass("without-after-element");
    $("#div3 .tickRemove").addClass("without-after-element");
    $("#div4 .tickRemove").addClass("without-after-element");
    $("#div5 .tickRemove").addClass("without-after-element");
    $("#div6 .tickRemove").addClass("without-after-element");

    this.setState({
      brand_name: brand_dt_id,
      showbrndInput: "hide",
      showSeriesInput: "hide",
      showModelInput: "hide",
      showCarrierInput: "hide",
      showStorageInput: "hide",
      selectTop: false,
      offerTop: false,
      paymentTop: false,
      shippingTop: false
    });
    let insertUrl =
      this.state.baseUrl + "/laptopzone/reactcontroller/c_react/ljw_Series";
    var get_brand_name = brand_dt_id; // this.state.brand_name;//$('#brand_name').val();
    var get_product_name = $("#product_name").val();
    if (
      get_product_name == null ||
      get_product_name == "" ||
      get_product_name == "undefined"
    ) {
      $(".targetDiv").hide();
      $(".AllButtons .AllSteps").removeClass("active");
      $(".AllButtons .target_1").addClass("active");
      $(".AllButtons .target_div_1").addClass("active");
    } else if (
      brand_dt_id == null ||
      brand_dt_id == "" ||
      brand_dt_id == "undefined"
    ) {
      $(".targetDiv").hide();
      $(".AllButtons .AllSteps").removeClass("active");
      $(".AllButtons .target_2").addClass("active");
      $(".AllButtons .target_div_1").addClass("active");
    } else {
      $(".targetDiv").hide();
      $(".AllButtons .AllSteps").removeClass("active");
      $(".AllButtons .target_1").addClass("active");
      $(".AllButtons .target_2").addClass("active");
      $(".AllButtons .target_3").addClass("active");
      $(".AllButtons .target_div_1").addClass("active");
      var ljw_Series = []; //'adi;l asada  yahoo';
      new Promise(function(resolve, reject) {
        $.ajax({
          url: insertUrl,
          dataType: "json",
          type: "POST",
          data: { get_brand_name: get_brand_name }
        }).then(
          function(data) {
            resolve(data);
          },
          function(err) {
            reject(err);
          }
        );
      })
        .then(result => {
          if (result.exist) {
            var length = result.ljw_Series.length;

            for (var i = 0; i < length; i++) {
              ljw_Series.push({
                series_name: result.ljw_Series[i].SERIES_NAME,
                series_dt_id: result.ljw_Series[i].SERIES_DT_ID
              });
            }
            $("#brand").html("");
            $("#selection").html("");
            $("#offers").html("");
            $("#payment").html("");
            $("#shipping").html("");
            $("#series").html("....");
            $("#SelectModelName").html("....");
            $("#carrierDiv").html("....");
            $("#storageDiv").html("....");
            $("#brand").append(
              brandName +
                '<i class="fa fa-check-circle" aria-hidden="true" "></i>'
            );
            $(".targetDiv_3").show();
            that.setState({
              SeriesName: ljw_Series
            });
          } else {
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
    return false;
  }
  otherBrand() {
    this.setState({
      showbrndInput: "show"
    });
    $("#div2 .tickRemove").removeClass("without-after-element");
  }
  otherBrandNext() {
    var brndInput = this.state.brndInput;
    $("#div3 .tickRemove").addClass("without-after-element");
    if (brndInput == null || brndInput == "" || brndInput == "undefined") {
      alert("Enter Brand Name");
      return false;
    }

    // $("#brand").text(brndInput);
    $("#brand").html("");
    $("#series").html("....");
    $("#SelectModelName").html("....");
    $("#carrierDiv").html("....");
    $("#storageDiv").html("....");
    $("#brand").append(
      brndInput + '<i class="fa fa-check-circle" aria-hidden="true" "></i>'
    );
    var brand_dt_id = brndInput;
    this.setState({
      brand_name: brand_dt_id
    });
    let insertUrl =
      this.state.baseUrl + "/laptopzone/reactcontroller/c_react/ljw_Series";
    var get_brand_name = brand_dt_id; // this.state.brand_name;//$('#brand_name').val();
    var get_product_name = $("#product_name").val();

    if (
      get_product_name == null ||
      get_product_name == "" ||
      get_product_name == "undefined"
    ) {
      $(".targetDiv").hide();
      $(".AllButtons .AllSteps").removeClass("active");
      $(".AllButtons .target_1").addClass("active");
      $(".AllButtons .target_div_1").addClass("active");
      // alert('select Product First');
      // return false;
    } else if (
      brand_dt_id == null ||
      brand_dt_id == "" ||
      brand_dt_id == "undefined"
    ) {
      $(".targetDiv").hide();
      $(".AllButtons .AllSteps").removeClass("active");
      $(".AllButtons .target_2").addClass("active");
      $(".AllButtons .target_div_1").addClass("active");
    } else {
      $(".targetDiv").hide();
      $(".AllButtons .AllSteps").removeClass("active");
      $(".AllButtons .target_1").addClass("active");
      $(".AllButtons .target_2").addClass("active");
      $(".AllButtons .target_3").addClass("active");
      $(".AllButtons .target_div_1").addClass("active");

      this.setState({
        series_name: "",
        model_name: "",
        seriesInput: "",
        modalInput: "",
        carrier_name: "",
        showSeriesInput: "hide",
        showModelInput: "hide",
        showCarrierInput: "hide",
        showStorageInput: "hide"
      });

      $("#series").text(".....");
      $("#SelectModelName").text(".....");
      $("#carrierDiv").text(".....");
      $("#storageDiv").text(".....");
      $(".SeriesSelected label").removeClass("active");

      var ljw_Series = []; //'adi;l asada  yahoo';

      new Promise(function(resolve, reject) {
        $.ajax({
          url: insertUrl,
          dataType: "json",
          type: "POST",
          data: { get_brand_name: get_brand_name }
        }).then(
          function(data) {
            resolve(data);
          },
          function(err) {
            reject(err);
          }
        );
      })
        .then(result => {
          if (result.exist) {
            var length = result.ljw_Series.length;

            for (var i = 0; i < length; i++) {
              //console.log(i);
              ljw_Series.push({
                series_name: result.ljw_Series[i].SERIES_NAME,
                series_dt_id: result.ljw_Series[i].SERIES_DT_ID
              });
            }

            $(".targetDiv_3").show();

            that.setState({
              SeriesName: ljw_Series
            });
          } else {
          }
        })
        .catch(err => {
          console.log(err);
        });
    }

    return false;
  }
  changeseries(seriesName, series_dt_id) {
    
    this.setState({
      series_name: series_dt_id,
      showSeriesInput: "hide",
      model_name: "",
      modalInput: "",
      carrier_name: "",
      storage_name: "",
      showbrndInput: "hide",
      showModelInput: "hide",
      showCarrierInput: "hide",
      showStorageInput: "hide",
      selectTop: false,
      offerTop: false,
      paymentTop: false,
      shippingTop: false
    });
    $("#div3 .tickRemove").removeClass("without-after-element");
    $("#div4 .tickRemove").addClass("without-after-element");
    $("#div5 .tickRemove").addClass("without-after-element");
    $("#div6 .tickRemove").addClass("without-after-element");
    let insertUrl =
      this.state.baseUrl + "/laptopzone/reactcontroller/c_react/ljw_Model";
    var get_product_name = $("#product_name").val();
    var get_brand_name = $("#brand_name").val();
    var get_series_name = series_dt_id; //$('#series_name').val();
    if (
      get_product_name == null ||
      get_product_name == "" ||
      get_product_name == "undefined"
    ) {
      $(".targetDiv").hide();
      $(".AllButtons .AllSteps").removeClass("active");
      $(".AllButtons .target_1").addClass("active");
      $(".AllButtons .target_div_1").addClass("active");
    } else if (
      get_brand_name == null ||
      get_brand_name == "" ||
      get_brand_name == "undefined"
    ) {
      $(".targetDiv").hide();
      $(".AllButtons .AllSteps").removeClass("active");
      $(".AllButtons .target_2").addClass("active");
      $(".AllButtons .target_div_1").addClass("active");
    } else if (
      get_series_name == null ||
      get_series_name == "" ||
      get_series_name == "undefined"
    ) {
      $(".targetDiv").hide();
      $(".AllButtons .AllSteps").removeClass("active");
      $(".AllButtons .target_3").addClass("active");
      $(".AllButtons .target_div_1").addClass("active");
    } else {
      $(".targetDiv").hide();
      $(".AllButtons .AllSteps").removeClass("active");
      $(".AllButtons .target_1").addClass("active");
      $(".AllButtons .target_2").addClass("active");
      $(".AllButtons .target_3").addClass("active");
      $(".AllButtons .target_4").addClass("active");
      $(".AllButtons .target_div_1").addClass("active");

      var ljw_Model = []; //'adi;l asada  yahoo';
      new Promise(function(resolve, reject) {
        $.ajax({
          url: insertUrl,
          dataType: "json",
          type: "POST",
          data: { get_series_name: get_series_name }
        }).then(
          function(data) {
            resolve(data);
          },
          function(err) {
            reject(err);
          }
        );
      })
        .then(result => {
          if (result.exist) {
            var length = result.ljw_Model.length;
            for (var i = 0; i < length; i++) {
              //console.log(i);
              ljw_Model.push({
                description: result.ljw_Model[i].DESCRIPTION,
                model_dt_id: result.ljw_Model[i].MODEL_DT_ID,
                model_url :  result.ljw_Model[i].IMAGE_URL
              });
            }
            $("#series").html("");
            $("#selection").html("");
            $("#offers").html("");
            $("#payment").html("");
            $("#shipping").html("");
            $("#SelectModelName").html("....");
            $("#carrierDiv").html("....");
            $("#storageDiv").html("....");
            $("#series").append(
              seriesName +
                '<i class="fa fa-check-circle" aria-hidden="true" "></i>'
            );
            $(".targetDiv_4").show();
            that.setState({
              ModelName: ljw_Model
            });
          } else {
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
    return false;
  }
  otherSeries() {
    this.setState({
      showSeriesInput: "show"
    });
    $("#div3 .tickRemove").removeClass("without-after-element");
  }
  otherSeriesNext() {
    var seriesInput = this.state.seriesInput;
    $("#div4 .tickRemove").addClass("without-after-element");
    if (
      seriesInput == null ||
      seriesInput == "" ||
      seriesInput == "undefined"
    ) {
      alert("Enter Series Name");
      return false;
    }
    $("#series").text(seriesInput);
    $("#series").html("");
    $("#SelectModelName").html("....");
    $("#carrierDiv").html("....");
    $("#staorageDiv").html("....");
    $("#series").append(
      seriesInput +
        '<i class="fa fa-check-circle" aria-hidden="true" "></i>'
    );
    var series_dt_id = seriesInput;
    this.setState({
      series_name: series_dt_id
    });
    let insertUrl =
      this.state.baseUrl + "/laptopzone/reactcontroller/c_react/ljw_Model";
    var get_product_name = $("#product_name").val();
    var get_brand_name = $("#brand_name").val();
    var get_series_name = series_dt_id; //$('#series_name').val();

    if (
      get_product_name == null ||
      get_product_name == "" ||
      get_product_name == "undefined"
    ) {
      $(".targetDiv").hide();
      $(".AllButtons .AllSteps").removeClass("active");
      $(".AllButtons .target_1").addClass("active");
      $(".AllButtons .target_div_1").addClass("active");
    } else if (
      get_brand_name == null ||
      get_brand_name == "" ||
      get_brand_name == "undefined"
    ) {
      $(".targetDiv").hide();
      $(".AllButtons .AllSteps").removeClass("active");
      $(".AllButtons .target_2").addClass("active");
      $(".AllButtons .target_div_1").addClass("active");
    } else if (
      get_series_name == null ||
      get_series_name == "" ||
      get_series_name == "undefined"
    ) {
      $(".targetDiv").hide();
      $(".AllButtons .AllSteps").removeClass("active");
      $(".AllButtons .target_3").addClass("active");
      $(".AllButtons .target_div_1").addClass("active");
    } else {
      $(".targetDiv").hide();
      $(".AllButtons .AllSteps").removeClass("active");
      $(".AllButtons .target_1").addClass("active");
      $(".AllButtons .target_2").addClass("active");
      $(".AllButtons .target_3").addClass("active");
      $(".AllButtons .target_4").addClass("active");
      $(".AllButtons .target_div_1").addClass("active");

      this.setState({
        model_name: "",
        modalInput: "",
        carrierInput: "",
        storageInput: "",
        storage_name: "",
        showModelInput: "hide",
        showcarrierInput: "hide",
        showStorageInput: "hide"
      });
      $("#SelectModelName").text(".....");
      $("#carrierDiv").text(".....");
      var ljw_Model = []; //'adi;l asada  yahoo';
      new Promise(function(resolve, reject) {
        $.ajax({
          url: insertUrl,
          dataType: "json",
          type: "POST",
          data: { get_series_name: get_series_name }
        }).then(
          function(data) {
            resolve(data);
          },
          function(err) {
            reject(err);
          }
        );
      })
        .then(result => {
          if (result.exist) {
            var length = result.ljw_Model.length;
            for (var i = 0; i < length; i++) {
              ljw_Model.push({
                description: result.ljw_Model[i].DESCRIPTION,
                model_dt_id: result.ljw_Model[i].MODEL_DT_ID,
                model_url :  result.ljw_Model[i].IMAGE_URL
                
              });
            }

            $(".targetDiv_4").show();
            that.setState({
              ModelName: ljw_Model
            });
          } else {
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
    return false;
  }
  changeSelectModel(SelectModelName, model_dt_id) {
    
    this.setState({
      product_info_model : SelectModelName
    })
    $("#div4 .tickRemove").removeClass("without-after-element");
    $("#div5 .tickRemove").addClass("without-after-element");
    $("#div6 .tickRemove").addClass("without-after-element");
    this.setState({
      model_name: model_dt_id,
      showModelInput: "hide",
      selectTop: false,
      offerTop: false,
      paymentTop: false,
      shippingTop: false
    });
    //console.log('3');
    let insertUrl =
      this.state.baseUrl + "/laptopzone/reactcontroller/c_react/ljw_Carrier";
    var get_product_name = $("#product_name").val();
    var get_brand_name = $("#brand_name").val();
    var get_series_name = $("#series_name").val();
    var get_model_names = model_dt_id; //$('#model_names').val();
    $("#selection").html("");
    $("#offers").html("");
    $("#payment").html("");
    $("#shipping").html("");
    $("#SelectModelName").html("");

    $("#SelectModelName").append(SelectModelName + '<i class="fa fa-check-circle" aria-hidden="true" "></i>');
    $("#carrierDiv").html("....");
    $("#storageDiv").html("....");
    if(this.state.product_info == 'Laptop'){
      this.getAnOffer();
    }else{
    if (
      get_product_name == null ||
      get_product_name == "" ||
      get_product_name == "undefined"
    ) {
      $(".targetDiv").hide();
      $(".AllButtons .AllSteps").removeClass("active");
      $(".AllButtons .target_1").addClass("active");
      $(".AllButtons .target_div_1").addClass("active");
      // alert('select Product First');
      // return false;
    } else if (
      get_brand_name == null ||
      get_brand_name == "" ||
      get_brand_name == "undefined"
    ) {
      $(".targetDiv").hide();
      $(".AllButtons .AllSteps").removeClass("active");
      $(".AllButtons .target_2").addClass("active");
      $(".AllButtons .target_div_1").addClass("active");
      // alert('select Brand First');
      // return false;
    } else if (
      get_series_name == null ||
      get_series_name == "" ||
      get_series_name == "undefined"
    ) {
      $(".targetDiv").hide();
      $(".AllButtons .AllSteps").removeClass("active");
      $(".AllButtons .target_3").addClass("active");
      $(".AllButtons .target_div_1").addClass("active");
      alert("select Series First");
      return false;
    } else if (
      get_model_names == null ||
      get_model_names == "" ||
      get_model_names == "undefined"
    ) {
      $(".targetDiv").hide();
      $(".AllButtons .AllSteps").removeClass("active");
      $(".AllButtons .target_4").addClass("active");
      $(".AllButtons .target_div_1").addClass("active");
      alert("select Model First");
      return false;
    } else {
      $(".targetDiv").hide();
      $(".AllButtons .AllSteps").removeClass("active");
      $(".AllButtons .target_1").addClass("active");
      $(".AllButtons .target_2").addClass("active");
      $(".AllButtons .target_3").addClass("active");
      $(".AllButtons .target_4").addClass("active");
      $(".AllButtons .target_5").addClass("active");
      $(".AllButtons .target_div_1").addClass("active");

      var ljw_Carrier = []; //'adi;l asada  yahoo';

      new Promise(function(resolve, reject) {
        $.ajax({
          url: insertUrl,
          dataType: "json",
          type: "POST",
          data: { model_dt_id: model_dt_id }
        }).then(
          function(data) {
            resolve(data);
          },
          function(err) {
            reject(err);
          }
        );
      })
        .then(result => {
          if (result.exist) {
            var length = result.ljw_Carrier.length;

            for (var i = 0; i < length; i++) {
              console.log(i);
              ljw_Carrier.push({
                CARRIER_NAME: result.ljw_Carrier[i].CARRIER_NAME,
                CARRIER_ID: result.ljw_Carrier[i].LZW_CARRIER_DT_ID,
                IMAGE_URL : result.ljw_Carrier[i].IMAGE_URL
                // brand_url: result.ljw_Carrier[i].BRAND_URL,
                // brand_dt_id: result.ljw_Carrier[i].BRAND_DT_ID,
              });
            }
            
            $(".targetDiv_5").show();
            //$('#div'+$(this).attr('target')).show();
            that.setState({
              carrierName: ljw_Carrier
            });
          } else {
          }
        })
        .catch(err => {
          //$.LoadingOverlay("hide");
          console.log(err);
        });
    }
    return false;
    }

    // $('#model_name').attr('value', model_dt_id);
    //$("#model_name").val(model_dt_id);
  }
  otherModals() {
    this.setState({
      showModelInput: "show"
    });
    $("#div4 .tickRemove").removeClass("without-after-element");
  }
  otherModalNext() {
    var modalInput = this.state.modalInput;

    if (modalInput == null || modalInput == "" || modalInput == "undefined") {
      alert("Enter Modal Name");
      return false;
    }

    // $("#SelectModelName").text(modalInput);
    $("#SelectModelName").html("");
    $("#SelectModelName").append(
      modalInput + '<i class="fa fa-check-circle" aria-hidden="true" "></i>'
    );

    var model_dt_id = modalInput;
    this.setState({
      model_name: model_dt_id
    });
    //console.log('3');
    let insertUrl =
      this.state.baseUrl + "/laptopzone/reactcontroller/c_react/ljw_Carrier";
    var get_product_name = $("#product_name").val();
    var get_brand_name = $("#brand_name").val();
    var get_series_name = $("#series_name").val();
    var get_model_names = model_dt_id; //$('#model_names').val();

    //console.log(get_brand_name);
    if (
      get_product_name == null ||
      get_product_name == "" ||
      get_product_name == "undefined"
    ) {
      $(".targetDiv").hide();
      $(".AllButtons .AllSteps").removeClass("active");
      $(".AllButtons .target_1").addClass("active");
      $(".AllButtons .target_div_1").addClass("active");
      // alert('select Product First');
      // return false;
    } else if (
      get_brand_name == null ||
      get_brand_name == "" ||
      get_brand_name == "undefined"
    ) {
      $(".targetDiv").hide();
      $(".AllButtons .AllSteps").removeClass("active");
      $(".AllButtons .target_2").addClass("active");
      $(".AllButtons .target_div_1").addClass("active");
      // alert('select Brand First');
      // return false;
    } else if (
      get_series_name == null ||
      get_series_name == "" ||
      get_series_name == "undefined"
    ) {
      $(".targetDiv").hide();
      $(".AllButtons .AllSteps").removeClass("active");
      $(".AllButtons .target_3").addClass("active");
      $(".AllButtons .target_div_1").addClass("active");
      alert("select Series First");
      return false;
    } else if (
      get_model_names == null ||
      get_model_names == "" ||
      get_model_names == "undefined"
    ) {
      $(".targetDiv").hide();
      $(".AllButtons .AllSteps").removeClass("active");
      $(".AllButtons .target_4").addClass("active");
      $(".AllButtons .target_div_1").addClass("active");
      alert("select Model First");
      return false;
    } else {
      $(".targetDiv").hide();
      $(".AllButtons .AllSteps").removeClass("active");
      $(".AllButtons .target_1").addClass("active");
      $(".AllButtons .target_2").addClass("active");
      $(".AllButtons .target_3").addClass("active");
      $(".AllButtons .target_4").addClass("active");
      $(".AllButtons .target_6").addClass("active");
      $(".AllButtons .target_div_1").addClass("active");

      this.setState({
        carrier_name: ""
      });

      $("#carrierDiv").text(".....");

      var ljw_Carrier = []; //'adi;l asada  yahoo';

      new Promise(function(resolve, reject) {
        $.ajax({
          url: insertUrl,
          dataType: "json",
          type: "POST",
          data: { model_dt_id: model_dt_id }
        }).then(
          function(data) {
            resolve(data);
          },
          function(err) {
            reject(err);
          }
        );
      })
        .then(result => {
          if (result.exist) {
            var length = result.ljw_Carrier.length;

            for (var i = 0; i < length; i++) {
              ljw_Carrier.push({
                CARRIER_NAME: result.ljw_Carrier[i].CARRIER_NAME,
                CARRIER_ID: result.ljw_Carrier[i].LZW_CARRIER_DT_ID,
                IMAGE_URL : result.ljw_Carrier[i].IMAGE_URL
                // brand_url: result.ljw_Carrier[i].BRAND_URL,
                // brand_dt_id: result.ljw_Carrier[i].BRAND_DT_ID,
              });
            }

            $(".targetDiv_5").show();
            that.setState({
              carrierName: ljw_Carrier
            });
          } else {
          }
        })
        .catch(err => {
          //$.LoadingOverlay("hide");
          console.log(err);
        });
    }
    return false;
  }
  changeSelectCarrier(carrierName, carrier_id) {
    
    var temp = "(" + carrierName + ")" 
    this.setState({
      product_info_carrier : temp
    })
    $("#div5 .tickRemove").removeClass("without-after-element");
    $("#div6 .tickRemove").addClass("without-after-element");
    this.setState({
      carrier_name: carrier_id,
      showCarrierInput: "hide",
      storage_name: "",
      showCarrierInput: "hide",
      showStorageInput: "hide",
      selectTop: false,
      offerTop: false,
      paymentTop: false,
      shippingTop: false
    });
    let insertUrl =
      this.state.baseUrl + "/laptopzone/reactcontroller/c_react/ljw_Storage";
    var get_product_name = $("#product_name").val();
    var get_brand_name = $("#brand_name").val();
    var get_series_name = $("#series_name").val();
    var get_model_names = $("#model_names").val();
    var get_carrier_names = carrier_id;

    if (
      get_product_name == null ||
      get_product_name == "" ||
      get_product_name == "undefined"
    ) {
      $(".targetDiv").hide();
      $(".AllButtons .AllSteps").removeClass("active");
      $(".AllButtons .target_1").addClass("active");
      $(".AllButtons .target_div_1").addClass("active");
    } else if (
      get_brand_name == null ||
      get_brand_name == "" ||
      get_brand_name == "undefined"
    ) {
      $(".targetDiv").hide();
      $(".AllButtons .AllSteps").removeClass("active");
      $(".AllButtons .target_2").addClass("active");
      $(".AllButtons .target_div_1").addClass("active");
    } else if (
      get_series_name == null ||
      get_series_name == "" ||
      get_series_name == "undefined"
    ) {
      $(".targetDiv").hide();
      $(".AllButtons .AllSteps").removeClass("active");
      $(".AllButtons .target_3").addClass("active");
      $(".AllButtons .target_div_1").addClass("active");
      alert("select Series First");
      return false;
    } else if (
      get_model_names == null ||
      get_model_names == "" ||
      get_model_names == "undefined"
    ) {
      $(".targetDiv").hide();
      $(".AllButtons .AllSteps").removeClass("active");
      $(".AllButtons .target_4").addClass("active");
      $(".AllButtons .target_div_1").addClass("active");
      alert("select Model First");
      return false;
    } else {
      $(".targetDiv").hide();
      $(".AllButtons .AllSteps").removeClass("active");
      $(".AllButtons .target_1").addClass("active");
      $(".AllButtons .target_2").addClass("active");
      $(".AllButtons .target_3").addClass("active");
      $(".AllButtons .target_4").addClass("active");
      $(".AllButtons .target_5").addClass("active");
      $(".AllButtons .target_6").addClass("active");
      $(".AllButtons .target_div_1").addClass("active");
      $("#selection").html("");
      $("#offers").html("");
      $("#payment").html("");
      $("#shipping").html("");
      var ljw_Storage = []; //'adi;l asada  yahoo';

      new Promise(function(resolve, reject) {
        $.ajax({
          url: insertUrl,
          dataType: "json",
          type: "POST",
          data: { carrier_id: carrier_id }
        }).then(
          function(data) {
            resolve(data);
          },
          function(err) {
            reject(err);
          }
        );
      })
        .then(result => {
          if (result.exist) {
            var length = result.ljw_Storage.length;

            for (var i = 0; i < length; i++) {
              console.log(i);
              ljw_Storage.push({
                storage_id: result.ljw_Storage[i].LZW_STORAGE_DT_ID,
                storage_name: result.ljw_Storage[i].STORAGE_DESC,
                sale_price: result.ljw_Storage[i].SALE_PRICE
              });
            }
            $("#carrierDiv").html("");
            $("#storageDiv").html("....");
            $("#carrierDiv").append(
              carrierName +
                '<i class="fa fa-check-circle" aria-hidden="true" "></i>'
            );
            $(".targetDiv_6").show();

            that.setState({
              storageName: ljw_Storage
            });
          } else {
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
    return false;
  }
  otherCarrier() {
    this.setState({
      showCarrierInput: "show"
    });
    $("#div5 .tickRemove").removeClass("without-after-element");
  }
  otherCarrierNext() {
    var carrierInput = this.state.carrierInput;
    if (
      carrierInput == null ||
      carrierInput == "" ||
      carrierInput == "undefined"
    ) {
      alert("Enter Carrier Name");
      return false;
    }
    $("#carrierDiv").html("");
    $("#storageDiv").html("....");
    $("#carrierDiv").append(
      carrierInput +
        '<i class="fa fa-check-circle" aria-hidden="true" "></i>'
    );
    var carrier_id = carrierInput;
    this.setState({
      carrier_name: carrier_id
    });
    let insertUrl =
      this.state.baseUrl + "/laptopzone/reactcontroller/c_react/ljw_Storage";
    var get_product_name = $("#product_name").val();
    var get_brand_name = $("#brand_name").val();
    var get_series_name = $("#series_name").val();
    var get_model_names = $("#model_names").val();
    if (
      get_product_name == null ||
      get_product_name == "" ||
      get_product_name == "undefined"
    ) {
      $(".targetDiv").hide();
      $(".AllButtons .AllSteps").removeClass("active");
      $(".AllButtons .target_1").addClass("active");
      $(".AllButtons .target_div_1").addClass("active");
    } else if (
      get_brand_name == null ||
      get_brand_name == "" ||
      get_brand_name == "undefined"
    ) {
      $(".targetDiv").hide();
      $(".AllButtons .AllSteps").removeClass("active");
      $(".AllButtons .target_2").addClass("active");
      $(".AllButtons .target_div_1").addClass("active");
    } else if (
      get_series_name == null ||
      get_series_name == "" ||
      get_series_name == "undefined"
    ) {
      $(".targetDiv").hide();
      $(".AllButtons .AllSteps").removeClass("active");
      $(".AllButtons .target_3").addClass("active");
      $(".AllButtons .target_div_1").addClass("active");
      alert("select Series First");
      return false;
    } else if (
      get_model_names == null ||
      get_model_names == "" ||
      get_model_names == "undefined"
    ) {
      $(".targetDiv").hide();
      $(".AllButtons .AllSteps").removeClass("active");
      $(".AllButtons .target_4").addClass("active");
      $(".AllButtons .target_div_1").addClass("active");
      alert("select Model First");
      return false;
    } else {
      $(".targetDiv").hide();
      $(".AllButtons .AllSteps").removeClass("active");
      $(".AllButtons .target_1").addClass("active");
      $(".AllButtons .target_2").addClass("active");
      $(".AllButtons .target_3").addClass("active");
      $(".AllButtons .target_4").addClass("active");
      $(".AllButtons .target_5").addClass("active");
      $(".AllButtons .target_6").addClass("active");
      $(".AllButtons .target_div_1").addClass("active");

      var ljw_Storage = []; //'adi;l asada  yahoo';
      new Promise(function(resolve, reject) {
        $.ajax({
          url: insertUrl,
          dataType: "json",
          type: "POST",
          data: { carrier_id: carrier_id }
        }).then(
          function(data) {
            resolve(data);
          },
          function(err) {
            reject(err);
          }
        );
      })
        .then(result => {
          if (result.exist) {
            var length = result.ljw_Storage.length;

            for (var i = 0; i < length; i++) {
              ljw_Storage.push({
                storage_id: result.ljw_Storage[i].LZW_STORAGE_DT_ID,
                storage_name: result.ljw_Storage[i].STORAGE_DESC,
                sale_price: result.ljw_Storage[i].SALE_PRICE
              });
            }
            $(".targetDiv_6").show();
            that.setState({
              storageName: ljw_Storage
            });
          } else {
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
    return false;
  }
  changeSelectStorage(storageName, storage_id, sale_price) {
   
    this.setState({
      product_info_storage : storageName
    })
    $("#div6 .tickRemove").removeClass("without-after-element");
    this.setState({
      storage_name: storage_id,
      sale_price: sale_price,
      sale_price_temp: sale_price,
      showStorageInput: "hide",
      selectTop: false,
      offerTop: false,
      paymentTop: false,
      shippingTop: false
    });
    $("#selection").html("");
    $("#offers").html("");
    $("#payment").html("");
    $("#shipping").html("");
    $("#storageDiv").html("");
    $("#storageDiv").append(
      storageName +
        '<i class="fa fa-check-circle" aria-hidden="true" "></i>'
    );
    $(".targetDiv_6").show();
    $("#getOfferShow").removeClass("hide");

    return false;
  }
  otherStorage() {
    this.setState({
      showStorageInput: "show"
    });
    $("#div6 .tickRemove").removeClass("without-after-element");
  }
  otherStorageNext() {
    var storageInput = this.state.storageInput;

    if (
      storageInput == null ||
      storageInput == "" ||
      storageInput == "undefined"
    ) {
      alert("Enter Storage Name");
      return false;
    }

    // $("#SelectModelName").text(storageInput);
    $("#storageDiv").html("");
    $("#storageDiv").append(
      storageInput +
        '<i class="fa fa-check-circle" aria-hidden="true" "></i>'
    );

    var storage_id = storageInput;
    this.setState({
      storage_name: storage_id
    });
    $("#getOfferHide").addClass("hide");
    $("#getOfferShow").removeClass("hide");
    return false;
  }
  componentDidMount() {}
  notify = () => toast("Your Request Is Submited !");

  getAnOffer() {
    
    $('.uncheckRadio').prop('checked', false);
    $('.checkedRadio').prop('checked', true);
    var object_id = this.state.product_name;
    $("div#loader-container").removeClass("hidden");
    var answersvalue = []; //'adi;l asada  yahoo';
    let insertUrl =
      this.state.baseUrl + "/laptopzone/reactcontroller/c_react/ljw_getAnOffer";

    new Promise(function(resolve, reject) {
      $.ajax({
        url: insertUrl,
        dataType: "json",
        type: "POST",
        data: { object_id: object_id }
      }).then(
        function(data) {
          resolve(data);
        },
        function(err) {
          reject(err);
        }
      );
    })
      .then(result => {
        if (result) {
          // console.log(result);

          $("div#loader-container").addClass("hidden");
          var length = result.lzw_questions_ans.length;

          for (var i = 0; i < length; i++) {
            if (result.lzw_questions_ans[i].DEFAULT_VAL == 1) {
              answersvalue.push({
                ANSWERS_ID: result.lzw_questions_ans[i].ANSWERS_ID,
                DEFAULT_VAL: result.lzw_questions_ans[i].DEFAULT_VAL,
                EFFECTIVE_VALUE: result.lzw_questions_ans[i].EFFECTIVE_VALUE,
                QUESTIONS_MT_ID: result.lzw_questions_ans[i].QUESTIONS_MT_ID,
                VALUE_DESC: result.lzw_questions_ans[i].VALUE_DESC
              });
            } else {
              answersvalue.push({
                ANSWERS_ID: result.lzw_questions_ans[i].ANSWERS_ID,
                DEFAULT_VAL: result.lzw_questions_ans[i].DEFAULT_VAL,
                EFFECTIVE_VALUE: result.lzw_questions_ans[i].EFFECTIVE_VALUE,
                QUESTIONS_MT_ID: result.lzw_questions_ans[i].QUESTIONS_MT_ID,
                VALUE_DESC: result.lzw_questions_ans[i].VALUE_DESC
              });
            }
          }
          this.setState({
            questions: result.lzw_questions_mt,
            answers: answersvalue,
            number_of_questions: result.lzw_questions_mt.length,
            selectTop: true,
            offerTop: true,
            paymentTop: false,
            shippingTop: false
          });
          if(this.state.sale_price <= 0 || this.state.sale_price == 'null'){
            $('#invalidPrice').addClass('hide');
            $('#nextPayment').addClass('hide');
            $('#invalidOffer').removeClass('hide');
            $('#invalidOffer').html("");
            $('#invalidOffer').append("Unfortunately, we can't offer you any money for this item");
          }else{
            $('#invalidPrice').removeClass('hide');
            $('#nextPayment').removeClass('hide');
            $('#invalidOffer').addClass('hide');
            $('#invalidOffer').html("");
          }
          $("#selectionDiv").addClass("hide");
          $("#getOfferDiv").removeClass("hide");
          $("#selection").append(
            '<i class="fa fa-check-circle" aria-hidden="true" "></i>'
          );
         $(".AllButtons .target_div_2").addClass("active");
          return false;
        } else {
          $("div#loader-container").removeClass("hidden");
          alert("Error");
          return false;
        }
      })
      .catch(err => {
        console.log(err);
      });

    return false;
  }

  selectValues = e => {
    this.setState({
      selectValues: "show"
    });
  };

  showmenu(productName, product_id) {
    $(".TabHide").slideToggle("slow");
    $(".showmenu").css("display", "none");
    $("#product").text(productName);
    this.setState({
      product_name: product_id
    });

    var get_product_name = product_id; //$('#product_name').val();

    if (
      get_product_name == null ||
      get_product_name == "" ||
      get_product_name == "undefined"
    ) {
      $(".targetDiv").hide();
      $(".AllButtons .AllSteps").removeClass("active");
      $(".AllButtons .target_1").addClass("active");
      // alert('select Product First');
      // return false;
    } else {
      $(".targetDiv").hide();
      $(".AllButtons .AllSteps").removeClass("active");
      $(".AllButtons .target_1").addClass("active");
      $(".AllButtons .target_2").addClass("active");

      let insertUrl =
        this.state.baseUrl + "/laptopzone/reactcontroller/c_react/ljw_Brands";
      var ljw_getobject = []; //'adi;l asada  yahoo';

      new Promise(function(resolve, reject) {
        $.ajax({
          url: insertUrl,
          dataType: "json",
          type: "POST",
          data: { get_product_name: get_product_name }
        }).then(
          function(data) {
            resolve(data);
          },
          function(err) {
            reject(err);
          }
        );
      })
        .then(result => {
          if (result.exist) {
            var length = result.ljw_getobject.length;

            for (var i = 0; i < length; i++) {
              //console.log(i);
              ljw_getobject.push({
                brand_name: result.ljw_getobject[i].BRAND_NAME,
                brand_url: result.ljw_getobject[i].BRAND_URL,
                brand_dt_id: result.ljw_getobject[i].BRAND_DT_ID
              });
            }

            $(".targetDiv_2").show();
            //$('#div'+$(this).attr('target')).show();
            that.setState({
              Brands: ljw_getobject
            });
          } else {
          }
        })
        .catch(err => {
          //$.LoadingOverlay("hide");
          console.log(err);
        });
    }

    // console.log(brandName);
    // console.log(brand_dt_id);

    return false;
  }
  nextPayment() {
    var answer_ids = "";
    $.each($("#getOfferDiv input[type=radio]"), function() {
      if ($(this).prop("checked")) {
        if (answer_ids) {
          answer_ids += ",";
        }
        answer_ids += $(this).val();
      }
    });
    var questions_lenght = this.state.number_of_questions;
    if (!answer_ids) {
      toast("Check all answers!");
      return false;
    } else {
      var temp = answer_ids.split(",");
      if (questions_lenght != temp.length) {
        toast("Check all answers!");
        return false;
      } else {
        // console.log(answer_ids);
        // return false;
        $("#getOfferDiv").addClass("hide");
        $("#paymentDiv").removeClass("hide");
        $(".AllButtons .target_div_3").addClass("active");
        $("#offers").append(
          '<i class="fa fa-check-circle" aria-hidden="true" "></i>'
        );
      }
    }
    this.setState({
      answer_ids: answer_ids,
      selectTop: true,
      offerTop: true,
      paymentTop: true,
      shippingTop: false
    });
  }
  saveBuySell() {
    // console.log(this.state);
    // return false;
    var product_name = this.state.product_name;
    var brand_name = this.state.brand_name;
    var series_name = this.state.series_name;
    var model_name = this.state.model_name;
    var carrier_name = this.state.carrier_name;
    var storage_name = this.state.storage_name;
    var answer_ids = this.state.answer_ids;
    var gevin_offer = this.state.sale_price;
    var available_offer = this.state.sale_price_temp;
    var paypalEmail = this.state.paypalEmail;
    var select_option = this.state.getRadioVale;
    var paymentType = this.state.paymentType;

    var payable_to = this.state.payable_to;
    var checkEmail = this.state.checkEmail;
    var address1 = this.state.checkaddress1;
    var address2 = this.state.checkaddress2;
    var cityName = this.state.cityName;
    var stateName = this.state.stateName;
    var zipCode = this.state.zipCode;
    var countryName = this.state.countryName;

    var pick_address = this.state.fieldsPick.pick_address;
    var pick_area = this.state.fieldsPick.pick_area;
    var pick_city = this.state.fieldsPick.pick_city;
    var pick_state = this.state.fieldsPick.pick_state;
    var pick_zipcode = this.state.fieldsPick.pick_zipcode;

    var ship_address = this.state.fields.address;
    var ship_area = this.state.fields.area;
    var ship_city = this.state.fields.city;
    var ship_state = this.state.fields.state;
    var ship_zipcode = this.state.fields.zipcode;

    if (select_option == 2) {
      if (!$.trim(this.state.fieldsPick.pick_address)) {
        toast("Pick up address required");
        return false;
      }
      if (!$.trim(this.state.fieldsPick.pick_area)) {
        toast("Pick up area required");
        return false;
      }
      if (!$.trim(this.state.fieldsPick.pick_city)) {
        toast("Pick up city required");
        return false;
      }
      if (!$.trim(this.state.fieldsPick.pick_state)) {
        toast("Pick up state required");
        return false;
      }
      if (!$.trim(this.state.fieldsPick.pick_zipcode)) {
        toast("Pick up zipcode required");
        return false;
      }
    }
    if (select_option == 3) {
      if (!$.trim(this.state.fields.address)) {
        toast("Pick up address required");
        return false;
      }
      if (!$.trim(this.state.fields.area)) {
        toast("Pick up area required");
        return false;
      }
      if (!$.trim(this.state.fields.city)) {
        toast("Pick up city required");
        return false;
      }
      if (!$.trim(this.state.fields.state)) {
        toast("Pick up state required");
        return false;
      }
      if (!$.trim(this.state.fields.zipcode)) {
        toast("Pick up zipcode required");
        return false;
      }
    }
    $("div#loader-container").removeClass("hidden");
    var formData = new FormData();
    formData.append("product_name", product_name);
    formData.append("brand_name", brand_name);
    formData.append("series_name", series_name);
    formData.append("model_name", model_name);
    formData.append("carrier_name", carrier_name);
    formData.append("storage_name", storage_name);
    formData.append("answer_ids", answer_ids);
    formData.append("gevin_offer", gevin_offer);
    formData.append("available_offer", available_offer);
    formData.append("select_option", select_option);
    formData.append("payment_mode", paymentType);
    // paypal
    formData.append("paypalEmail", paypalEmail);
    // check info
    formData.append("payable_to", payable_to);
    formData.append("email_address", checkEmail);
    formData.append("address1", address1);
    formData.append("address2", address2);
    formData.append("cityName", cityName);
    formData.append("stateName", stateName);
    formData.append("zipCode", zipCode);
    formData.append("countryName", countryName);
    // pick up info
    formData.append("pick_address", pick_address);
    formData.append("pick_area", pick_area);
    formData.append("pick_city", pick_city);
    formData.append("pick_state", pick_state);
    formData.append("pick_zipcode", pick_zipcode);
    let insertUrl =
      this.state.baseUrl +
      "/laptopzone/reactcontroller/c_react/ljw_saveBuySell";
    new Promise(function(resolve, reject) {
      $.ajax({
        url: insertUrl,
        dataType: "json",
        type: "POST",
        cache: false,
        contentType: false,
        processData: false,
        data: formData
      }).then(
        function(data) {
          resolve(data);
        },
        function(err) {
          reject(err);
        }
      );
    })
      .then(result => {
        if (result) {
          $("div#loader-container").addClass("hidden");
          this.setState({
            buy_id: result.data
          });
          if (select_option == 3) {
            $(".getShip").removeClass("hide");
            $(".getLabel").removeClass("hide");
            $(".getCharge").addClass("hide");
            // $("div#loader-container").removeClass("hidden");
          } else {
            this.setState({
              getBackButtn: "show",
            });
            $("#selectionDiv").addClass("hide");
            $("#getOfferDiv").addClass("hide");
            $(".shippingDiv").addClass("hide");
            $("#selectionDiv").addClass("hide");
            // $("div#loader-container").removeClass("hidden");
            $("#showSell").text("Your Sell No is " + result.data);
            $("#showSellText").text("We will get back to you ASAP.");
            this.notify();
          }
        } else {
          $("div#loader-container").removeClass("hidden");
          toast('Server Issue');
          return false;
        }
      })
      .catch(err => {
        console.log(err);
      });

    return false;
  }
  saveBuySell1() {
    $("div#loader-container").removeClass("hidden");
    $(".getShip").addClass("hide");
    $(".getLabel").addClass("hide");
    $(".getCharge").addClass("hide");
    this.setState({
      getBackButtn: "show",
    });
    $("#selectionDiv").addClass("hide");
    $("#getOfferDiv").addClass("hide");
    $(".shippingDiv").addClass("hide");
    $("#selectionDiv").addClass("hide");
    $("div#loader-container").addClass("hidden");
    this.notify();
    $("#showSell").text("Your Sell No is " + this.state.buy_id);
    $("#showSellText").text("We will get back to you ASAP.");
  }
  anotherReq() {
    this.setState({
      product_name: "",
      brand_name: "",
      series_name: "",
      model_name: "",
      carrier_name: "",
      storage_name: "",
      getBackButtn: "hide",
      answer_ids: "",
      sale_price: "",
      sale_price_temp: "",
      paypalEmail: "",
      getRadioVale: "",
      paymentType: "",
      payable_to: "",
      checkaddress1: "",
      checkaddress2: "",
      cityName: "",
      stateName: "",
      zipCode: "",
      countryName: "",
      fieldsPick: {
        pick_address: "",
        pick_area: "",
        pick_city: "",
        pick_state: "",
        pick_zipcode: ""
      },
      fields: {
        address: "",
        area: "",
        city: "",
        state: "",
        zipcode: ""
      }
    });
    $("#selection").html("");
    $("#offers").html("");
    $("#payment").html("");
    $("#shipping").html("");
    $("#product").html("....");
    $("#brand").html("....");
    $("#series").html("....");
    $("#SelectModelName").html("....");
    $("#carrierDiv").html("....");
    $("#storageDiv").html("....");
    $(".targetDiv").hide();
    $(".AllButtons .AllSteps").removeClass("active");
    $(".AllButtons .target_1").addClass("active");
    $(".AllButtons .target_div_1").addClass("active");
    $(".AllButtons .target_div_2").removeClass("active");
    $(".AllButtons .target_div_3").removeClass("active");
    $(".AllButtons .target_div_4").removeClass("active");
    $(".targetDiv_1").show();
    $("#checkHide").addClass("hide");
    $("#paypalHide").addClass("hide");
    $("input[name=paypalEmail").val("");
    $("input[name=re_paypalEmail").val("");
    $("input[name=payable_to").val("");
    $("input[name=checkaddress1").val("");
    $("input[name=checkaddress2").val("");
    $("input[name=cityName").val("");
    $("input[name=stateName").val("");
    $("input[name=zipCode").val("");
    $("#getOfferDiv").addClass("hide");
    $(".shippingDiv").addClass("hide");
    $("#selectionDiv").removeClass("hide");
    $("#paymentDiv").addClass("hide");
    return false;
  }

  continuePayment(type) {
    this.setState({
      paymentType: type,
      selectTop: true,
      offerTop: true,
      paymentTop: true,
      shippingTop: true
    });
    if (type == "paypal") {
      if (!$.trim(this.state.paypalEmail)) {
        toast("Email Required");
        return false;
      } else {
        if (
          !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
            this.state.paypalEmail
          )
        ) {
          $("#validEmailSpan")
            .css("display", "inline")
            .fadeOut(2000);
          return false;
        }
      }
      if (!$.trim(this.state.re_paypalEmail)) {
        toast("Re-enter Email");
        return false;
      } else {
        if (this.state.re_paypalEmail != this.state.paypalEmail) {
          $("#confirmEmailSpan")
            .css("display", "inline")
            .fadeOut(2000);
          return false;
        }
      }
    } else {
      if (!$.trim(this.state.payable_to)) {
        toast("Name required!");
        return false;
      }
      if (!$.trim(this.state.checkaddress1)) {
        toast("Address line 1 required!");
        return false;
      }
      if (!$.trim(this.state.cityName)) {
        toast("City required!");
        return false;
      }
      if (!$.trim(this.state.stateName)) {
        toast("State required!");
        return false;
      }
      if (!$.trim(this.state.zipCode)) {
        toast("Zip Code required!");
        return false;
      }
      var regexp = /^[0-9]{5}(?:-[0-9]{4})?$/;
      if (regexp.test(this.state.zipCode)) {
      } else {
        toast("Invalid zip code!");
        return false;
      }
    }
    $("#paymentDiv").addClass("hide");
    $(".shippingDiv").removeClass("hide");
    $("#payment").append(
      '<i class="fa fa-check-circle" aria-hidden="true" "></i>'
    );
    $(".AllButtons .target_div_4").addClass("active");
    return false;
  }
  updateAnswersCheck = (eff_value, question_id, default_val, total_ans) => {
    this.setState({
      paymentTop : false,
      shippingTop : false,
      paypalEmail : "",
      re_paypalEmail: "",
      payable_to : "",
      checkEmail : '',
      checkaddress1 : '',
      checkaddress2 : '',
      cityName : "",
      stateName : "",
      zipCode : "",
    });
    $("#payment").html("");
    $("#shipping").html("");
    $("#checkHide").addClass("hide");
    $("#paypalHide").addClass("hide");
    $("input[name=paypalEmail").val("");
    $("input[name=re_paypalEmail").val("");
    $("input[name=payable_to").val("");
    $("input[name=checkaddress1").val("");
    $("input[name=checkaddress2").val("");
    $("input[name=cityName").val("");
    $("input[name=stateName").val("");
    $("input[name=zipCode").val("");
    $(".shippingDiv").addClass("hide");
    $(".AllButtons .target_div_3").removeClass("active");
    $(".AllButtons .target_div_4").removeClass("active");
    
    var sale_price = this.state.sale_price_temp;
    var sale_price1 = ""
    // console.log(this.state.questions_arr);
    if (this.state.questions_ids.find(element => element == question_id)) {
      const questions_arr = this.state.questions_arr.map(item => {
        // console.log(item.last_value);
        // console.log(eff_value);
        if (item.question_id == question_id) {
          if (default_val == 1) {
            if (total_ans <= 2) {
            sale_price1 = parseFloat(sale_price) + parseFloat(item.last_value);
            that.setState({
              sale_price_temp: sale_price1
                
            });
            return {
              ...item,
              last_value: eff_value
            };
          }else{
            if (default_val == 1) {
              sale_price1 = parseFloat(sale_price) + parseFloat(item.last_value)
              that.setState({
                sale_price_temp: sale_price1
                  
              });
            return {
              ...item,
              last_value: 0
            };
          }else{
            sale_price1 = parseFloat(sale_price) + parseFloat(item.last_value);
            sale_price1 = sale_price1 - eff_value
            that.setState({
              sale_price_temp: sale_price1
            });
            return {
              ...item,
              last_value: eff_value
            };
          }
          }
          } else {
            if (total_ans <= 2) {
              sale_price1 = sale_price - eff_value
              that.setState({
                sale_price_temp: sale_price1
              });
              return {
                ...item,
                last_value: eff_value
              };
            } else {
              
              if (default_val == 1) {
                sale_price1 = sale_price - eff_value
                that.setState({
                  sale_price_temp: sale_price1
                });
              return {
                ...item,
                last_value: 0
              };
            }else{
              sale_price1 = parseFloat(sale_price) + parseFloat(item.last_value);
              sale_price1 =  sale_price1 - eff_value
              that.setState({
                sale_price_temp: sale_price1
              });
              return {
                ...item,
                last_value: eff_value
              };
            }
            }
          }
        } else {
          return {
            ...item,
            question_id: item.question_id,
            last_value: item.last_value
          };
        }
      });
      this.setState({
        questions_arr
      });
    } else { 
      var data = '';
      sale_price1 = sale_price - eff_value
      that.setState({
        sale_price_temp: sale_price1
      });
      if (total_ans <= 2) {
         data = {
          question_id: question_id,
          last_value: eff_value
        };
      } else {
        if (default_val == 1) {
         data = {
          question_id: question_id,
          last_value: 0
        };
      }else{
        data = {
          question_id: question_id,
          last_value: eff_value
        };
      }
      }
      this.setState({
        questions_arr: [...this.state.questions_arr, data],
        questions_ids: [...this.state.questions_ids, question_id]
      });
    }
    
    
    if(sale_price1 <= 0){
      $('#invalidPrice').addClass('hide');
      $('#nextPayment').addClass('hide');
      $('#invalidOffer').removeClass('hide');
      $('#invalidOffer').html("");
      $('#invalidOffer').append("Unfortunately, we can't offer you any money for this item");
    }else{
      $('#invalidPrice').removeClass('hide');
      $('#nextPayment').removeClass('hide');
      $('#invalidOffer').addClass('hide');
      $('#invalidOffer').html("");
    }

  };

  paymentForm = type => {
    if (type == "paypal") {
      $("#paypalHide").slideDown("slow");
      $("#paypalHide").removeClass("hide");
      $("#checkHide").addClass("hide");
    } else {
      $("#checkHide").removeClass("hide");
      $("#paypalHide").addClass("hide");
    }
  };
  showSelecOption(option) {
    if (option == "drop") {
      this.setState({
        dropData: "show",
        picData: "hide",
        shipData: "hide",
        getRadioVale: "1"
      });
    } else if (option == "pic") {
      this.setState({
        dropData: "hide",
        picData: "show",
        shipData: "hide",
        getRadioVale: "2"
      });
    } else if (option == "ship") {
      this.setState({
        dropData: "hide",
        picData: "hide",
        shipData: "show",
        getRadioVale: "3"
      });
    }

    console.log(option);
  }
  getShippingCharges() {
    $("div#loader-container").removeClass("hidden");
    var ship_height = this.state.ship_height;
    var ship_lenght = this.state.ship_lenght;
    var ship_weight = this.state.ship_weight;
    var ship_width = this.state.ship_width;
    // var ship_address = this.state.address1;
    // var address_id = 1;
    var street1 = this.state.fields.address;
    var city = this.state.fields.city;
    var state = this.state.fields.state;
    var zip = this.state.fields.zipcode;
    // var area = this.state.fields.area;
    if (!street1) {
      $("div#loader-container").addClass("hidden");
      toast("Street address required !");
      return false;
    }
    if (!city) {
      $("div#loader-container").addClass("hidden");
      toast("City required !");
      return false;
    }
    if (!state) {
      $("div#loader-container").addClass("hidden");
      toast("State required !");
      return false;
    }
    if (!zip) {
      $("div#loader-container").addClass("hidden");
      toast("Zipcode required !");
      return false;
    }
    if (!street1) {
      $("div#loader-container").addClass("hidden");
      toast("Street address required !");
      return false;
    }
    console.log(this.state.fields.address);

    // if(! this.state.address1){
    // 	$("div#loader-container").addClass("hidden");
    // 	toast("Shpping address required !");

    // 	return false;
    // }

    if (!this.state.ship_lenght) {
      $("div#loader-container").addClass("hidden");
      toast("Lenght required !");

      return false;
    }
    if (!this.state.ship_width) {
      $("div#loader-container").addClass("hidden");
      toast("Width required !");

      return false;
    }
    if (!this.state.ship_height) {
      $("div#loader-container").addClass("hidden");
      toast("Height required !");

      return false;
    }
    if (!this.state.ship_weight) {
      $("div#loader-container").addClass("hidden");
      toast("Weight required !");

      return false;
    }

    let insertUrl =
      "http://71.78.236.22/laptop-zone-stage/public/api/dummy/shipment-rates";
    // let insertUrl =
    //   "http://192.168.100.28/sts-laravel/public/api/dummy/shipment-rates";
    new Promise(function(resolve, reject) {
      $.ajax({
        url: insertUrl,
        dataType: "json",
        type: "POST",
        data: {
          weight: ship_weight,
          length: ship_lenght,
          width: ship_width,
          height: ship_height,
          city: city,
          zip: zip,
          street: street1,
          street: street1,
          state: state,
          phone: "+1539888550"
        },
        headers: {
          Authorization:
            "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6ImQ3NmJhYTkzZDBlMjZmY2UxNjA0ODE0MmM1YmJhNGZhOTc3NTAxOGQ2MDNjZDM3OTU1Njg4NjkxZjlkOWQ5YzZlZTlhN2JjZTc3OTMyOTU0In0.eyJhdWQiOiIxIiwianRpIjoiZDc2YmFhOTNkMGUyNmZjZTE2MDQ4MTQyYzViYmE0ZmE5Nzc1MDE4ZDYwM2NkMzc5NTU2ODg2OTFmOWQ5ZDljNmVlOWE3YmNlNzc5MzI5NTQiLCJpYXQiOjE1Njg3MjAwMzQsIm5iZiI6MTU2ODcyMDAzNCwiZXhwIjoxNjAwMzQyNDM0LCJzdWIiOiIxMiIsInNjb3BlcyI6W119.uwyP1k9wfw7ATlF2IByHDtZqeo8wF2EB13SMv1sz-_Z4QUibovIOnZuhUx5PVPVjR2YpfiANrw015bJhnDHkheFcz7iM4ik26ox4J8bPNCk3QmkesF3mJNYKaZ57uK-NgltEYkN0LiH25xm2vAUAv-_lOSmORerdqBvt7JHWjGgGS6SKDzC17WS6-voDA-oTx_LmqCnlv4qo32tonMf3Wkhqzjm-nUsTonDgE-Aa-U4D4GqFuZudq4LewXItyPYC-AlPfYvlnKorCL6bawTffHNTlkwAX1arjj47z8f8M9_OpF4ixQeFpdHJWnUm_qAm0E3vFb6DSJicC1ndSBpZHqNPKFL14VzHMT1vYQZEPdUan0buelBB2wf7ylq9b77qfAVsFkDP8C5xL8u-6XFOoZj8m57zYqScYTr69nWp0N4IpT_Ihcm2kuhy3HGNlgYCqGDkCfTP8kfCGAshlWqo59usL7y9rsr1YFyV2dwTkJJM1RTssUKQSjw1Na_vxgh99bmytJ4ZRQ6RjYWJ9iWHMUPMSHtMbY3olSKSTm01unz__en3WHlUKXqqfowGS05zXoaviqwwfgRYjZ3_ROm8P-8m3LEMICPNeJl4sidlTPccoJFi3IQfWTvAs0u6HMQxMf4WCsY1g-MXwmi3mZUAHCqy_Kz7BPoERH-OI_5OhxM"
        }
      }).then(
        function(data) {
          resolve(data);
        },
        function(err) {
          reject(err);
        }
      );
    })
      .then(result => {
        console.log(result);
        if (result) {
          $("div#loader-container").addClass("hidden");
          this.setState({
            showData: "show",
            getShipmentCharges: result.rates,
            ship_id: result.id
          });
          that.saveBuySell();
          // $(".getShip").removeClass("hide");
          // $(".getLabel").removeClass("hide");
          // $(".getCharge").addClass("hide");
        }
      })
      .catch(err => {
        $("div#loader-container").addClass("hidden");
        //$.LoadingOverlay("hide");
        console.log(err);
      });
    return false;
  }
  getLabel() {
    var buy_id = this.state.buy_id;
    $("div#loader-container").removeClass("hidden");
    var shipment_id = this.state.ship_id;
    var rate_id = this.state.rate_id;

    if (!this.state.ship_id) {
      $("div#loader-container").addClass("hidden");
      toast("Shpping id required !");

      return false;
    }
    if (!this.state.rate_id) {
      $("div#loader-container").addClass("hidden");
      toast("Select Rate  !");

      return false;
    }

    let insertUrl =
      "http://71.78.236.22/laptop-zone-stage/public/api/dummy/register";
    // let insertUrl =
    //   "http://192.168.100.28/sts-laravel/public/api/dummy/register";
    new Promise(function(resolve, reject) {
      $.ajax({
        url: insertUrl,
        dataType: "json",
        type: "POST",
        data: {
          shipment_id: shipment_id,
          rate_id: rate_id,
          request_id: buy_id,
          request_type: "BUY_SELL" //
        },
        headers: {
          Authorization:
            "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6ImQ3NmJhYTkzZDBlMjZmY2UxNjA0ODE0MmM1YmJhNGZhOTc3NTAxOGQ2MDNjZDM3OTU1Njg4NjkxZjlkOWQ5YzZlZTlhN2JjZTc3OTMyOTU0In0.eyJhdWQiOiIxIiwianRpIjoiZDc2YmFhOTNkMGUyNmZjZTE2MDQ4MTQyYzViYmE0ZmE5Nzc1MDE4ZDYwM2NkMzc5NTU2ODg2OTFmOWQ5ZDljNmVlOWE3YmNlNzc5MzI5NTQiLCJpYXQiOjE1Njg3MjAwMzQsIm5iZiI6MTU2ODcyMDAzNCwiZXhwIjoxNjAwMzQyNDM0LCJzdWIiOiIxMiIsInNjb3BlcyI6W119.uwyP1k9wfw7ATlF2IByHDtZqeo8wF2EB13SMv1sz-_Z4QUibovIOnZuhUx5PVPVjR2YpfiANrw015bJhnDHkheFcz7iM4ik26ox4J8bPNCk3QmkesF3mJNYKaZ57uK-NgltEYkN0LiH25xm2vAUAv-_lOSmORerdqBvt7JHWjGgGS6SKDzC17WS6-voDA-oTx_LmqCnlv4qo32tonMf3Wkhqzjm-nUsTonDgE-Aa-U4D4GqFuZudq4LewXItyPYC-AlPfYvlnKorCL6bawTffHNTlkwAX1arjj47z8f8M9_OpF4ixQeFpdHJWnUm_qAm0E3vFb6DSJicC1ndSBpZHqNPKFL14VzHMT1vYQZEPdUan0buelBB2wf7ylq9b77qfAVsFkDP8C5xL8u-6XFOoZj8m57zYqScYTr69nWp0N4IpT_Ihcm2kuhy3HGNlgYCqGDkCfTP8kfCGAshlWqo59usL7y9rsr1YFyV2dwTkJJM1RTssUKQSjw1Na_vxgh99bmytJ4ZRQ6RjYWJ9iWHMUPMSHtMbY3olSKSTm01unz__en3WHlUKXqqfowGS05zXoaviqwwfgRYjZ3_ROm8P-8m3LEMICPNeJl4sidlTPccoJFi3IQfWTvAs0u6HMQxMf4WCsY1g-MXwmi3mZUAHCqy_Kz7BPoERH-OI_5OhxM"
        }
      }).then(
        function(data) {
          resolve(data);
        },
        function(err) {
          reject(err);
        }
      );
    })
      .then(result => {
        console.log(result);
        if (result) {
          $("div#loader-container").addClass("hidden");
          this.setState({
            label_remote_url: result.label_remote_url
          });
          this.setState({
            showOptions: "hide"
          });
          $(".labelPrint").removeClass("hide");
          $(".getLabel").addClass("hide");
        }
      })
      .catch(err => {
        //$.LoadingOverlay("hide");

        $("div#loader-container").addClass("hidden");
        toast(err.responseJSON.message);
        // console.log(err);
      });
    return false;
  }

  render() {
    // console.log(this.state.questions_arr);
    const AutocompleteItem = ({ formattedSuggestion }) => (
      <div className="Demo__suggestion-item">
        <i className="fa fa-map-marker Demo__suggestion-icon" />
        <strong>{formattedSuggestion.mainText}</strong>{" "}
        <small className="text-muted">
          {formattedSuggestion.secondaryText}
        </small>
      </div>
    );

    const inputProps = {
      type: "text",
      value: this.state.address,
      onChange: this.handleChange,
      // onBlur: () => {
      //   console.log("Blur event!");
      // },
      // onFocus: () => {
      //   console.log("Focused!");
      // },
      placeholder: "Search Places",
      name: "Demo__input",
      id: "my-input-id",
      className: "my_custome"
    };
    const inputProps1 = {
      type: "text",
      value: this.state.address1,
      onChange: this.handleChange1,
      onBlur: () => {
        console.log("Blur event!");
      },
      onFocus: () => {
        console.log("Focused!");
      },
      placeholder: "Search Places",
      name: "Demo__input",
      id: "my-input-id",
      className: "my_custome"
    };
    return (
      <React.Fragment>
        <div id="loader-container" className="loader-container hidden">
          <svg
            className="spinner"
            viewBox="0 0 66 66"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              className="path"
              fill="none"
              stroke-linecap="round"
              cx="33"
              cy="33"
              r="30"
            ></circle>
          </svg>

          <div className="icon">
            <img src="assets/image/main_logo.svg" />
          </div>
        </div>

        <section className="BannerSliding hide">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-sm-12 hide">
                <div className="morphText">
                  {/*<h1>Sell Your Product</h1>*/}
                  <span>Select your Product</span>
                </div>
              </div>

              {/*<div className="col-md-12 col-sm-12">
			       	<form action="#" method="#">
			       	 <div className="LandingSearch">
			       	  <input type="text" id="" name="" placeholder="TYPE HERE TO SEARCH YOUR DEVICE"></input>
			       	  <div className="clearfix"></div>
			       	  <button>Search</button>
			       	 </div>
			       	</form>
			       </div>*/}

              <div className="col-md-12 col-sm-12 showmenu">
                {/*<div className="morphText">
				         <span>Select your Product</span>
				        </div>*/}

                <div className="SelectProd">
                  <a
                    href="javascript:void(0)"
                    className="showmenu"
                    onClick={() => {
                      this.showmenu("Iphone", "3");
                    }}
                  >
                    <img
                      src="assets/image/iphones.jpg"
                      width="157"
                      height="167"
                      alt=""
                    />
                  </a>
                  <h2 className="ProdHeading">Iphone</h2>
                </div>

                {/*<div className="SelectProd">
				         <a href="javascript:void(0)" className="showmenu" onClick={() => {
                        	this.showmenu('Samsung','4')
                      		}} >
				          <img src="assets/image/prod-4.png" width="193" height="203" alt="" />
				         </a>
				         <h2 className="ProdHeading">Samsung</h2>
				        </div>*/}

                <div className="SelectProd">
                  <a
                    href="javascript:void(0)"
                    className="showmenu"
                    onClick={() => {
                      this.showmenu("Ipad/Tablet", "5");
                    }}
                  >
                    <img
                      src="assets/image/ipad.jpg"
                      width="193"
                      height="203"
                      alt=""
                    />
                  </a>
                  <h2 className="ProdHeading">Ipad/Tablet</h2>
                </div>

                <div className="SelectProd">
                  <a
                    href="javascript:void(0)"
                    className="showmenu"
                    onClick={() => {
                      this.showmenu("Mackbook", "5");
                    }}
                  >
                    <img
                      src="assets/image/prod-3.png"
                      width="221"
                      height="203"
                      alt=""
                    />
                  </a>
                  <h2 className="ProdHeading">Mackbook</h2>
                </div>

                <div className="SelectProd">
                  <a
                    href="javascript:void(0)"
                    className="showmenu"
                    onClick={() => {
                      this.showmenu("Mac Computer", "1");
                    }}
                  >
                    <img
                      src="assets/image/prod-5.png"
                      width="199"
                      height="175"
                      alt=""
                    />
                  </a>
                  <h2 className="ProdHeading">Mac Computer</h2>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="SectionAllBrands">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-sm-12">
                <div className="Pageto-Back">
                  {/* <a href="/">
                    <i className="icon-icon_arrow"></i> Back
                  </a> */}
                </div>
              </div>
              <input
                type="hidden"
                className="product_name"
                id="product_name"
                onChange={this.handleInput}
                value={this.state.product_name}
              ></input>
              <input
                type="hidden"
                className="brand_name"
                id="brand_name"
                onChange={this.handleInput}
                value={this.state.brand_name}
              ></input>
              <input
                type="hidden"
                id="series_name"
                onChange={this.handleInput}
                value={this.state.series_name}
              ></input>
              <input
                type="hidden"
                id="model_names"
                onChange={this.handleInput}
                value={this.state.model_name}
              ></input>
              <input
                type="hidden"
                id="carrier_name"
                onChange={this.handleInput}
                value={this.state.carrier_name}
              ></input>
              <input
                type="hidden"
                id="storage_name"
                onChange={this.handleInput}
                value={this.state.storage_name}
              ></input>
              <div className="col-md-12 col-sm-12">
                <div className="TabHide">
                  <div className="AllButtons">
                    <a className="showSellSingle showTop" target="top1">
                      <div className="AllSteps hvr-rectangle-out active target_div_1">
                        <h3>Select</h3>
                        <p id="selection"></p>
                      </div>
                    </a>
                    <a className="showSellSingle showTop" target="top2">
                      <div className="AllSteps hvr-rectangle-out target_div_2">
                        <h3>Get Offer</h3>
                        <p id="offers"></p>
                      </div>
                    </a>

                    <a className="showSellSingle showTop" target="top3">
                      <div className="AllSteps hvr-rectangle-out target_div_3">
                        <h3>Payment</h3>
                        <p id="payment"></p>
                      </div>
                    </a>

                    <a className="showSellSingle showTop" target="top4">
                      <div className="AllSteps hvr-rectangle-out target_div_4 AllStepsBorder">
                        <h3>Shipping</h3>
                        <p id="shipping"></p>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              {this.state.getBackButtn == "show" ? (
                    <div className="col-md-12 col-sm-12">
                      <div className="morphText">
                        <span id="showSell"></span>
                        <h2 id="showSellText"></h2>
                        <div className="LandingSearch">
                          <div className="clearfix"></div>
                          <button
                            onClick={() => {
                              this.anotherReq();
                            }}
                          >
                            Make Another Request !
                          </button>
                        </div>
                      </div>
                    </div>
                  ) : null}
              <div id="selectionDiv" className="col-md-12 col-sm-12">
                <div className="TabHide">
                  <div className="AllButtons">
                    <a className="showSellSingle showSellSingle1" target="1">
                      <div className="AllSteps hvr-rectangle-out active target_1">
                        <h3>Product</h3>
                        <p id="product">.....</p>
                        <input
                          type="hidden"
                          id="product_name"
                          name="product_name"
                          value=""
                        ></input>
                      </div>
                    </a>

                    <a className="showSellSingle showSellSingle1" target="2">
                      <div className="AllSteps hvr-rectangle-out target_2 ">
                        <h3>Brand</h3>
                        <p id="brand">.....</p>
                      </div>
                    </a>

                    <a className="showSellSingle  showSellSingle1" target="3">
                      <div className="AllSteps hvr-rectangle-out target_3">
                        <h3>Series</h3>
                        <p id="series">.....</p>
                      </div>
                    </a>

                    <a className="showSellSingle showSellSingle1" target="4">
                      <div className="AllSteps hvr-rectangle-out target_4">
                        <h3>Model</h3>
                        <p id="SelectModelName">.....</p>
                      </div>
                    </a>

                    <a className="showSellSingle showSellSingle1" target="5">
                      <div className="AllSteps hvr-rectangle-out target_5 ">
                        <h3>Carrier</h3>
                        <p id="carrierDiv">.....</p>
                      </div>
                    </a>

                    <a className="showSellSingle showSellSingle1" target="6">
                      <div className="AllSteps hvr-rectangle-out AllStepsBorder target_6">
                        <h3>Storage</h3>
                        <p id="storageDiv">.....</p>
                      </div>
                    </a>
                  </div>

                  <div id="div1" className="targetDiv targetDiv_1 ">
                    <div className="Brandstep-Content">
                      <div className="row">
                        <div className="col-md-12 col-sm-12">
                          <div className="BrandHeading">
                            <h1>Select Product</h1>
                            <p>Step 1/6</p>
                          </div>
                        </div>

                        <div className="col-md-12 col-sm-12">
                          <div className="row">
                            <div className="SelectProd">
                              <a
                                href="javascript:void(0)"
                                onClick={() => {
                                  this.changeproduct("Phones", "6");
                                }}
                              >
                                <img
                                  src="assets/image/iphones.jpg"
                                  width="157"
                                  height="167"
                                  alt=""
                                />
                              </a>
                              <h2 className="ProdHeading">Phones</h2>
                            </div>

                            <div className="SelectProd">
                              <a
                                href="javascript:void(0)"
                                onClick={() => {
                                  this.changeproduct("Ipad/Tablet", "5");
                                }}
                              >
                                <img
                                  src="assets/image/ipad.jpg"
                                  width="193"
                                  height="203"
                                  alt=""
                                />
                              </a>
                              <h2 className="ProdHeading">Ipad/Tablet</h2>
                            </div>

                            <div className="SelectProd">
                              <a
                                href="javascript:void(0)"
                                onClick={() => {
                                  this.changeproduct("Laptop", "2");
                                }}
                              >
                                <img
                                  src="assets/image/prod-3.png"
                                  width="221"
                                  height="203"
                                  alt=""
                                />
                              </a>
                              <h2 className="ProdHeading">Laptop</h2>
                            </div>

                            {/* <div className="SelectProd">
                              <a
                                href="javascript:void(0)"
                                onClick={() => {
                                  this.changeproduct("Mac Computer", "7");
                                }}
                              >
                                <img
                                  src="assets/image/prod-5.png"
                                  width="199"
                                  height="175"
                                  alt=""
                                />
                              </a>
                              <h2 className="ProdHeading">Mac Computer</h2>
                            </div>
                           */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    id="div2"
                    className="targetDiv MainbOx-Close targetDiv_2"
                  >
                    <div className="Brandstep-Content">
                      <div className="row">
                        <div className="col-md-12 col-sm-12">
                          <div className="BrandHeading">
                            <h1>Select Brand</h1>
                            <p>Step 2/6</p>
                          </div>
                        </div>

                        <div className="col-md-12 col-sm-12">
                          <div className="row">
                            {this.state.Brands.map((tit, index) => (
                              <div key={index} className="col-md-2 col-sm-12">
                                <div
                                  class="BrandImage"
                                  onClick={() => {
                                    this.changeBrand(
                                      tit.brand_name,
                                      tit.brand_dt_id
                                    );
                                  }}
                                >
                                  <img
                                    src={
                                      this.state.baseUrl + "/" + tit.brand_url
                                    }
                                  />
                                </div>
                                <div className="SeriesSelected hide">
                                  <label className="checkbox">
                                    <input
                                      id="check1"
                                      type="radio"
                                      onClick={() => {
                                        this.changeBrand(
                                          tit.brand_name,
                                          tit.brand_dt_id
                                        );
                                      }}
                                      name="brand_name"
                                    ></input>
                                    <span className="tickRemove"></span>
                                    <span className="FontSize">
                                      {tit.brand_name}
                                    </span>
                                  </label>
                                </div>
                              </div>
                            ))}
                            {this.state.showbrndInput == "show" ? (
                              <React.Fragment>
                                <div className="col-md-12 col-sm-12">
                                  <div className="ContactSubmit">
                                    <input
                                      type="text"
                                      id="brndInput"
                                      placeholder="Enter Your Brand Name"
                                      name="brndInput"
                                      onChange={this.handleChangeInput}
                                      value={this.state.brndInput}
                                      maxLength="13"
                                    ></input>
                                  </div>
                                </div>

                                <div className="col-md-12 col-sm-12">
                                  <div className="NextBtnRight">
                                    <div className="clearfix"></div>
                                    <button
                                      onClick={() => {
                                        this.otherBrandNext();
                                      }}
                                    >
                                      <i
                                        className="icon-arrow-right"
                                        aria-hidden="true"
                                      ></i>
                                    </button>
                                  </div>
                                </div>
                              </React.Fragment>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    id="div3"
                    className="targetDiv MainbOx-Close targetDiv_3"
                  >
                    <div className="Brandstep-Content">
                      <div className="row">
                        <div className="col-md-12 col-sm-12">
                          <div className="BrandHeading">
                            <h1>Select Series</h1>
                            <p>Step 3/6</p>
                          </div>
                        </div>

                        <div className="col-md-12 col-sm-12">
                          <div className="row">
                            {this.state.SeriesName.map((tit, index) => (
                              <div key={index} className="col-md-4 col-sm-12">
                                <div className="SeriesSelected">
                                  <label className="checkbox">
                                    <input
                                      id="check2"
                                      type="radio"
                                      onClick={() => {
                                        this.changeseries(
                                          tit.series_name,
                                          tit.series_dt_id
                                        );
                                      }}
                                      name="series_name"
                                    ></input>
                                    <span className="tickRemove"></span>
                                    <span className="FontSize">
                                      {tit.series_name}
                                    </span>
                                  </label>
                                </div>
                              </div>
                            ))}
                            <div className="col-md-4 col-sm-12 hide">
                              <div className="SeriesSelected">
                                <label className="checkbox">
                                  <input
                                    id="check2"
                                    type="radio"
                                    name="series_name"
                                    onClick={() => {
                                      this.otherSeries();
                                    }}
                                  ></input>
                                  <span className="tickRemove"></span>
                                  <span className="FontSize">Others</span>
                                </label>
                              </div>
                            </div>

                            {this.state.showSeriesInput == "show" ? (
                              <React.Fragment>
                                <div className="col-md-12 col-sm-12">
                                  <div className="ContactSubmit">
                                    <input
                                      type="text"
                                      id="seriesInput"
                                      placeholder="Enter Your Series Name"
                                      name="seriesInput"
                                      onChange={this.handleChangeInput}
                                      value={this.state.seriesInput}
                                      maxLength="13"
                                    ></input>
                                  </div>
                                </div>

                                <div className="col-md-12 col-sm-12">
                                  <div className="NextBtnRight">
                                    <div className="clearfix"></div>
                                    <button
                                      onClick={() => {
                                        this.otherSeriesNext();
                                      }}
                                    >
                                      <i
                                        className="icon-arrow-right"
                                        aria-hidden="true"
                                      ></i>
                                    </button>
                                  </div>
                                </div>
                              </React.Fragment>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    id="div4"
                    className="targetDiv MainbOx-Close targetDiv_4"
                  >
                    <div className="Brandstep-Content">
                      <div className="row">
                        <div className="col-md-12 col-sm-12">
                          <div className="BrandHeading">
                            <h1>Select Model</h1>
                            <p>Step 4/6</p>
                          </div>
                        </div>

                        <div className="col-md-12 col-sm-12">
                          <div className="row">
                            {this.state.ModelName.map((tit, index) => (
                              <div key={index} className="col-md-3 col-sm-12">
                              <div
                                class="ModelImage"
                                onClick={() => {
                                  this.changeSelectModel(
                                    tit.description,
                                    tit.model_dt_id
                                  );
                                }}
                              >
                                <img
                                  src={
                                    this.state.baseUrl + "/" + tit.model_url
                                  }
                                  alt={tit.description}
                                />
                                <span className="FontSize">
                                      {tit.description}
                                    </span>
                              </div>
                            </div>
                           ))}
                            <div className="col-md-4 col-sm-12 hide">
                              <div className="SeriesSelected">
                                <label className="checkbox">
                                  <input
                                    id="check3"
                                    type="radio"
                                    name="modal"
                                    onClick={() => {
                                      this.otherModals();
                                    }}
                                  ></input>
                                  <span className="tickRemove"></span>
                                  <span className="FontSize">Others</span>
                                </label>
                              </div>
                            </div>

                            {this.state.showModelInput == "show" ? (
                              <React.Fragment>
                                <div className="col-md-12 col-sm-12">
                                  <div className="ContactSubmit">
                                    <input
                                      type="text"
                                      id="modalInput"
                                      placeholder="Enter Your Modal Name"
                                      name="modalInput"
                                      onChange={this.handleChangeInput}
                                      value={this.state.modalInput}
                                      maxLength="13"
                                    ></input>
                                  </div>
                                </div>

                                <div className="col-md-12 col-sm-12">
                                  <div className="NextBtnRight">
                                    <div className="clearfix"></div>
                                    <button
                                      onClick={() => {
                                        this.otherModalNext();
                                      }}
                                    >
                                      <i
                                        className="icon-arrow-right"
                                        aria-hidden="true"
                                      ></i>
                                    </button>
                                  </div>
                                </div>
                              </React.Fragment>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="div5"
                    className="targetDiv MainbOx-Close targetDiv_5"
                  >
                    <div className="Brandstep-Content">
                      <div className="row">
                        <div className="col-md-12 col-sm-12">
                          <div className="BrandHeading">
                            <h1>Select Carrier</h1>
                            <p>Step 5/6</p>
                          </div>
                        </div>
                        {/* new */}
                        <div className="col-md-12 col-sm-12">
                          <div className="row">
                            {this.state.carrierName.map((tit, index) => (
                              <div key={index} className="col-md-2 col-sm-12">
                              <div
                                class="BrandImage"
                                onClick={() => {
                                  this.changeSelectCarrier(
                                    tit.CARRIER_NAME,
                                    tit.CARRIER_ID
                                  );
                                }}
                              >
                                <img
                                  src={
                                    this.state.baseUrl + "/" + tit.IMAGE_URL
                                  }
                                />
                              </div>
                              </div>
                              ))}
                            <div className="col-md-4 col-sm-12 hide">
                              <div className="SeriesSelected">
                                <label className="checkbox">
                                  <input
                                    id="check3"
                                    type="radio"
                                    name="modal"
                                    onClick={() => {
                                      this.otherCarrier();
                                    }}
                                  ></input>
                                  <span className="tickRemove"></span>
                                  <span className="FontSize">Others</span>
                                </label>
                              </div>
                            </div>

                            {this.state.showCarrierInput == "show" ? (
                              <React.Fragment>
                                <div className="col-md-12 col-sm-12">
                                  <div className="ContactSubmit">
                                    <input
                                      type="text"
                                      id="carrierInput"
                                      placeholder="Enter Your Carrier Name"
                                      name="carrierInput"
                                      onChange={this.handleChangeInput}
                                      value={this.state.carrierInput}
                                      maxLength="13"
                                    ></input>
                                  </div>
                                </div>

                                <div className="col-md-12 col-sm-12">
                                  <div className="NextBtnRight">
                                    <div className="clearfix"></div>
                                    <button
                                      onClick={() => {
                                        this.otherCarrierNext();
                                      }}
                                    >
                                      <i
                                        className="icon-arrow-right"
                                        aria-hidden="true"
                                      ></i>
                                    </button>
                                  </div>
                                </div>
                              </React.Fragment>
                            ) : null}
                          </div>
                        </div>

                        {/* end */}
                      </div>
                    </div>
                  </div>
                  

                  <ToastContainer
                    position="top-right"
                    autoClose={2500}
                    hideProgressBar={true}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnVisibilityChange
                    draggable
                    pauseOnHover
                  />

                  <div
                    id="div6"
                    className="targetDiv MainbOx-Close targetDiv_6"
                  >
                    <div className="Brandstep-Content">
                      <div className="row">
                        <div className="col-md-12 col-sm-12">
                          <div className="BrandHeading">
                            <h1>Storage</h1>
                            <p>Step 6/6</p>
                          </div>
                        </div>
                        <div className="col-md-12 col-sm-12">
                          <div className="row">
                            {this.state.storageName.map((tit, index) => (
                              <div key={index} className="col-md-4 col-sm-12">
                                <div className="SeriesSelected">
                                  <label className="checkbox">
                                    <input
                                      id="check3"
                                      type="radio"
                                      onClick={() => {
                                        this.changeSelectStorage(
                                          tit.storage_name,
                                          tit.storage_id,
                                          tit.sale_price
                                        );
                                      }}
                                      name="modal"
                                    ></input>
                                    <span className="tickRemove"></span>
                                    <span className="FontSize">
                                      {tit.storage_name}
                                    </span>
                                  </label>
                                </div>
                              </div>
                            ))}
                            <div className="col-md-4 col-sm-12 hide">
                              <div className="SeriesSelected">
                                <label className="checkbox">
                                  <input
                                    id="check3"
                                    type="radio"
                                    name="modal"
                                    onClick={() => {
                                      this.otherStorage();
                                    }}
                                  ></input>
                                  <span className="tickRemove"></span>
                                  <span className="FontSize">Others</span>
                                </label>
                              </div>
                            </div>

                            {this.state.showStorageInput == "show" ? (
                              <React.Fragment>
                                <div className="col-md-12 col-sm-12">
                                  <div className="ContactSubmit">
                                    <input
                                      type="text"
                                      id="storageInput"
                                      placeholder="Enter Your Storage Name"
                                      name="storageInput"
                                      onChange={this.handleChangeInput}
                                      value={this.state.storageInput}
                                      maxLength="13"
                                    ></input>
                                  </div>
                                </div>
                                <div
                                  id="getOfferHide"
                                  className="col-md-12 col-sm-12"
                                >
                                  <div className="NextBtnRight">
                                    <div className="clearfix"></div>
                                    <button
                                      onClick={() => {
                                        this.otherStorageNext();
                                      }}
                                    >
                                      <i
                                        className="icon-arrow-right"
                                        aria-hidden="true"
                                      ></i>
                                    </button>
                                  </div>
                                </div>
                              </React.Fragment>
                            ) : null}
                            <div
                              id="getOfferShow"
                              className="col-md-12 col-sm-12 hide"
                            >
                              <div className="NextButton">
                                <button
                                  ref="btn"
                                  name="saveButton"
                                  id="saveButton"
                                  type="button"
                                  onClick={() => {
                                    this.getAnOffer();
                                  }}
                                >
                                  Get an offer
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="getOfferDiv" className="SectionHowWorks pt-4 hide">
          <div className="container">
            <div className="row">
            <div className="col-md-12 col-sm-12">
              <div class="HowItRight">
      	        <h3>Product Info: {this.state.product_info_brand +" "+ this.state.product_info_model +" "+ this.state.product_info_storage +" "+ this.state.product_info_carrier }</h3>
              </div>
            </div>
              <div className="col-md-8 col-sm-12 pt-4">
                <div className="TermsContainer">
                  <ul>
                    {this.state.questions.map((tit, index) => (
                      <React.Fragment>
                        <li>
                          <div className="TermsLeft">
                            <h1>{tit.QUESTION_DESCRIPTION}</h1>
                          </div>
                        </li>

                        <li>
                          <div className="TermsRight">
                            {this.state.answers.map((tit1, index1) =>
                              tit1.QUESTIONS_MT_ID == tit.QUESTIONS_MT_ID ? (
                                tit1.DEFAULT_VAL == 1 ? (
                                  <label className="CustomRadio-Button">
                                    {tit1.VALUE_DESC}
                                    <input
                                      type="radio"
                                      className="checkedRadio"
                                      value={tit1.ANSWERS_ID}
                                      name={tit.QUESTIONS_MT_ID}
                                      onChange={e =>
                                        this.updateAnswersCheck(
                                          tit1.EFFECTIVE_VALUE,
                                          tit.QUESTIONS_MT_ID,
                                          tit1.DEFAULT_VAL,
                                          tit.TOT_ANS
                                        )
                                      }
                                      defaultChecked="checked"
                                    />
                                    <span className="Custon-Checkmark"> </span>
                                  </label>
                                ) : (
                                  <label className="CustomRadio-Button">
                                    {tit1.VALUE_DESC}
                                    <input
                                      type="radio"
                                      className="uncheckRadio"
                                      value={tit1.ANSWERS_ID}
                                      name={tit.QUESTIONS_MT_ID}
                                      onChange={e =>
                                        this.updateAnswersCheck(
                                          tit1.EFFECTIVE_VALUE,
                                          tit.QUESTIONS_MT_ID,
                                          tit1.DEFAULT_VAL,
                                          tit.TOT_ANS
                                        )
                                      }
                                    />
                                    <span className="Custon-Checkmark"> </span>
                                  </label>
                                )
                              ) : null
                            )}
                          </div>
                        </li>
                        <div className="clearfix"></div>
                      </React.Fragment>
                    ))}
                  </ul>
                </div>
              </div>

              <div className="col-md-4 col-sm-12 pt-4">
                <div className="GetPaid">
                  <h2>Your LaptopZone Offer</h2>
                  <h3 id="invalidPrice">$ {this.state.sale_price_temp}</h3>
                  <br></br>
                  <p id="invalidOffer"></p>
                  <p>Pending upon device description</p>

                  <div className="GetPaidButton">
                    <button
                      type="button"
                      name="nextPayment"
                      id="nextPayment"
                      onClick={() => {
                        this.nextPayment();
                      }}
                    >
                      Get Paid
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="paymentDiv" className="SectionHowWorks hide pt-4">
          <div className="container">
            <div className="row">
            <div className="col-md-12 col-sm-12">
              <div class="HowItRight">
              <h3>Product Info: {this.state.product_info_brand +" "+ this.state.product_info_model +" "+ this.state.product_info_storage +" "+ this.state.product_info_carrier }</h3>
                
              </div>
            </div>
              <div className="col-md-12 col-sm-12 pt-4">
                <h1>How would you like to be paid?</h1>
                <p>We'll pay you once we've inspected and approved your item</p>
              </div>
              <div className="col-md-6 col-sm-12">
                <div className="GetPaid">
                  <h2
                    className="ClickH2"
                    onClick={e => this.paymentForm("paypal")}
                  >
                    Paypal
                  </h2>
                  <div id="paypalHide" className="hide">
                    <br></br>
                    <div className="col-md-12 col-sm-12">
                      <p>Payment directly to you PayPal account</p>
                      <p>
                        Confirm you PayPal email address so we can ensure prompt
                        payment
                      </p>
                      <div className="form-group">
                        <label htmlFor="">PayPal email address</label>
                        <input
                          type="text"
                          name="paypalEmail"
                          onChange={this.handleInput}
                          className="form-control"
                        />
                        <span
                          id="validEmailSpan"
                          style={{
                            display: "none",
                            color: "#fc3910"
                          }}
                        >
                          Please enter valid email!
                        </span>
                      </div>
                      <div className="form-group">
                        <label htmlFor="">
                          Confirm your PayPal email address
                        </label>
                        <input
                          type="text"
                          name="re_paypalEmail"
                          onChange={this.handleInput}
                          className="form-control"
                        />
                        <span
                          id="confirmEmailSpan"
                          style={{
                            display: "none",
                            color: "#fc3910"
                          }}
                        >
                          Email not match!
                        </span>
                      </div>
                    </div>
                    <div className="GetPaidButton">
                      <button
                        type="button"
                        onClick={() => {
                          this.continuePayment("paypal");
                        }}
                      >
                        Continue
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-sm-12">
                <div className="GetPaid">
                  <h2
                    className="ClickH2"
                    onClick={e => this.paymentForm("check")}
                  >
                    Check delivered by mail within 10 days
                  </h2>
                  <div id="checkHide" className="hide">
                    <br></br>
                    <div className="col-md-12 col-sm-12">
                      <p>
                        Please provide the address you'd like your check sent to
                      </p>
                      <div className="form-group">
                        <label htmlFor="">Payable to</label>
                        <input
                          type="text"
                          name="payable_to"
                          onChange={this.handleInput}
                          className="form-control"
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="">Email</label>
                        <input
                          type="text"
                          name="checkEmail"
                          onChange={this.handleInput}
                          className="form-control"
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="">Address Line 1</label>
                        <input
                          type="text"
                          name="checkaddress1"
                          onChange={this.handleInput}
                          className="form-control"
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="">Address Line 2(optional)</label>
                        <input
                          type="text"
                          name="checkaddress2"
                          onChange={this.handleInput}
                          className="form-control"
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="">City</label>
                        <input
                          type="text"
                          name="cityName"
                          onChange={this.handleInput}
                          className="form-control"
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="">State</label>
                        <input
                          type="text"
                          name="stateName"
                          onChange={this.handleInput}
                          className="form-control"
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="">Zip Code</label>
                        <input
                          type="text"
                          name="zipCode"
                          onChange={this.handleInput}
                          className="form-control"
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="">Country</label>
                        <input
                          type="text"
                          name="countryName"
                          onChange={this.handleInput}
                          value={this.state.countryName}
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="GetPaidButton">
                      <button
                        type="button"
                        onClick={() => {
                          this.continuePayment("check");
                        }}
                      >
                        Continue
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="selectOptions shippingDiv pt-4 hide"
          style={{ margin: "20px" }}
        >
          <div className="container">
            <div className="row">
            <div className="col-md-12 col-sm-12">
              <div class="HowItRight">
              <h3>Product Info: {this.state.product_info_brand +" "+ this.state.product_info_model +" "+ this.state.product_info_storage +" "+ this.state.product_info_carrier }</h3>
                
              </div>
            </div>
              {/*<div className="col-md-12 col-sm-12">
			       	   <div className="BrandHeading">

			       	    <h1>Select Brand</h1>
			       	    <p>Step 2/6</p>
			       	   </div>
			       	  </div>*/}

              <div className="col-md-12 col-sm-12 pt-4">
                <div className="row">
                  <div className="col-md-4 col-sm-12">
                    <div className="SeriesSelected">
                      <label
                        className="checkbox"
                        onClick={() => {
                          this.showSelecOption("drop");
                        }}
                      >
                        <input
                          id="check1"
                          type="radio"
                          name="brand_name"
                          onClick={() => {
                            this.showSelecOption("drop");
                          }}
                        ></input>
                        <span></span>
                        <span className="FontSize">DropOff</span>
                      </label>
                    </div>
                  </div>

                  <div className="col-md-4 col-sm-12">
                    <div className="SeriesSelected">
                      <label className="checkbox">
                        <input
                          id="check1"
                          type="radio"
                          name="brand_name"
                          onClick={() => {
                            this.showSelecOption("pic");
                          }}
                        ></input>
                        <span></span>
                        <span className="FontSize">Pickup</span>
                      </label>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-12">
                    <div className="SeriesSelected">
                      <label className="checkbox">
                        <input
                          id="check1"
                          type="radio"
                          name="brand_name"
                          onClick={() => {
                            this.showSelecOption("ship");
                          }}
                        ></input>
                        <span></span>
                        <span className="FontSize">Shipment</span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="OptionsData shippingDiv hide"
          style={{ margin: "20px" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-sm-12">
                <div className="row">
                  {this.state.shipData == "show" ? (
                    <React.Fragment>
                      <div className="col-md-12 col-sm-12 HeaderSearch">
                        <PlacesAutocomplete
                          onSelect={this.handleSelect1}
                          autocompleteItem={AutocompleteItem}
                          onEnterKeyDown={this.handleSelect1}
                          input={({ ...props }) =>
                            React.createElement("input", props)
                          }
                          inputProps={inputProps1}
                        ></PlacesAutocomplete>
                        <button
                          type="button"
                          onClick={this.handleSelect1}
                          style={{
                            borderRadius: "0 0 0 0",
                            position: "absolute"
                          }}
                        >
                          <i class="fa fa-location-arrow"></i>
                        </button>

                        {this.state.loading ? (
                          <div>
                            <i className="fa fa-spinner fa-pulse fa-3x fa-fw Demo__spinner" />
                          </div>
                        ) : null}
                        {/* {!this.state.loading && this.state.geocodeResults1 ?
								<div className='geocoding-results'>{this.state.geocodeResults1}</div> :
							  null} */}
                      </div>
                      <div className="col-md-12 col-sm-12">
                        {this.state.showShipMap == "show" ? (
                          <div style={{ margin: "0px" }}>
                            <ShipingMap
                              google={this.props.google}
                              repId={this.state.searcInput}
                              radioId={this.state.getRadioVale}
                              center={{
                                lat: this.state.currentLatLng1.lat,
                                lng: this.state.currentLatLng1.lng
                              }}
                              that={that}
                              height="300px"
                              zoom={15}
                            />
                          </div>
                        ) : null}
                      </div>
                      {this.state.showShipMap == "show" ? (
                        <React.Fragment>
                          <div className="col-md-3 col-sm-12">
                            <div className="">
                              <div className="form-group">
                                <label>Lenght</label>
                                <input
                                  type="number"
                                  name="ship_lenght"
                                  onChange={this.handleInput}
                                  className="form-control"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3 col-sm-12">
                            <div className="">
                              <div className="form-group">
                                <label>Width</label>
                                <input
                                  type="number"
                                  name="ship_width"
                                  onChange={this.handleInput}
                                  className="form-control"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3 col-sm-12">
                            <div className="">
                              <div className="form-group">
                                <label>Height</label>
                                <input
                                  type="number"
                                  name="ship_height"
                                  onChange={this.handleInput}
                                  className="form-control"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3 col-sm-12">
                            <div className="">
                              <div className="form-group">
                                <label>Weight (Ounce)</label>
                                <input
                                  type="number"
                                  name="ship_weight"
                                  onChange={this.handleInput}
                                  className="form-control"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-12 hide getShip">
                            <div className="">
                              <div className="form-group">
                                <label>Select Shipment </label>
                                <select
                                  name="rate_id"
                                  onChange={this.handleInput1}
                                  className="form-control"
                                >
                                  <option value="">Select </option>
                                  {this.state.getShipmentCharges.map(
                                    (tit, index) =>
                                      tit.service == "Priority" ||
                                      tit.service == "First" ? (
                                        <option key={index} value={tit.id}>
                                          {tit.carrier +
                                            " " +
                                            tit.rate +
                                            " " +
                                            tit.service}
                                        </option>
                                      ) : null
                                  )}
                                </select>
                              </div>
                            </div>
                          </div>
                        </React.Fragment>
                      ) : null}
                      {this.state.showShipMap == "show" ? (
                        <div className="labelPrint col-md-6 col-sm-12 hide">
                          <div className="">
                            <div className="form-group">
                              <img
                                style={{ width: "25%" }}
                                src={this.state.label_remote_url}
                              />
                            </div>
                            <a
                              target="_blank"
                              className="aproveButton"
                              href={this.state.label_remote_url}
                            >
                              View
                            </a>
                            <button
                              className="aproveButton dropConti"
                              onClick={() => {
                                this.saveBuySell1();
                              }}
                              type="button"
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      ) : null}
                      <div className="col-md-12 col-sm-12">
                        {this.state.showShipMap == "show" ? (
                          <button
                            className="aproveButton getCharge"
                            onClick={() => {
                              this.getShippingCharges();
                            }}
                            type="button"
                          >
                            Get Shipments
                          </button>
                        ) : null}
                        {this.state.showShipMap == "show" ? (
                          <button
                            className="aproveButton getLabel hide"
                            onClick={() => {
                              this.getLabel();
                            }}
                            type="button"
                          >
                            Get Label
                          </button>
                        ) : null}
                      </div>
                    </React.Fragment>
                  ) : null}

                  {this.state.dropData == "show" ? (
                    <React.Fragment>
                      <div className="col-md-12 col-sm-12">
                        <div className="ConDropschedule">
                          <h1>Schedule Drop off</h1>
                          <p>
                            You can drop the package yourself to our store at
                            following office hours{" "}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-12">
                        <div className="DropListing">
                          <ul>
                            <li>Mon-Thus:</li>
                            <li>9AM - 7PM</li>
                            <li>Friday:</li>
                            <li>9AM - 6PM</li>
                            <li>Saturday:</li>
                            <li>9AM - 4PM</li>
                          </ul>
                        </div>
                        <div class="dropoffAddress">
                          <h1>US Office</h1>
                          <p>2720 Royal Lane Ste #180 Dallas, TX 75229</p>
                          <p>United States</p>
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-12">
                        <div style={{ marginTop: "30px" }}>
                          <DropOffMap />
                        </div>
                      </div>
                      <div className="col-md-12 col-sm-12">
                        <button
                          className="aproveButton dropConti"
                          onClick={() => {
                            this.saveBuySell();
                          }}
                          type="button"
                        >
                          Continue
                        </button>
                      </div>
                    </React.Fragment>
                  ) : null}

                  {this.state.picData == "show" ? (
                    <React.Fragment>
                      <div className="col-md-12 col-sm-12 HeaderSearch">
                        <PlacesAutocomplete
                          onSelect={this.handleSelect}
                          autocompleteItem={AutocompleteItem}
                          onEnterKeyDown={this.handleSelect}
                          input={({ ...props }) =>
                            React.createElement("input", props)
                          }
                          inputProps={inputProps}
                        ></PlacesAutocomplete>
                        <button
                          type="button"
                          onClick={this.handleSelect}
                          style={{
                            borderRadius: "0 0 0 0",
                            position: "absolute"
                          }}
                        >
                          <i class="fa fa-location-arrow"></i>
                        </button>

                        {this.state.loading ? (
                          <div>
                            <i className="fa fa-spinner fa-pulse fa-3x fa-fw Demo__spinner" />
                          </div>
                        ) : null}
                        {/* {!this.state.loading && this.state.geocodeResults ?
						            <div className='geocoding-results'>{this.state.geocodeResults}</div> :
						          null} */}
                      </div>

                      <div className="col-md-12 col-sm-12">
                        {this.state.showMap == "show" ? (
                          <div style={{ margin: "0px" }}>
                            <BuySellMap
                              google={this.props.google}
                              repId={this.state.searcInput}
                              radioId={this.state.getRadioVale}
                              center={{
                                lat: this.state.currentLatLng.lat,
                                lng: this.state.currentLatLng.lng
                              }}
                              that={that}
                              height="400px"
                              zoom={15}
                            />
                          </div>
                        ) : null}
                      </div>
                      <div className="col-md-12 col-sm-12" style={{ marginTop: "15px" }}>
                        <button
                          className="aproveButton dropConti"
                          onClick={() => {
                            this.saveBuySell();
                          }}
                          type="button"
                        >
                          Continue
                        </button>
                      </div>
                    </React.Fragment>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
export default Home;
