import React from "react";
import { posturl } from "./configuration.js";
import PageTitles from "./pageTitles";
import $ from "jquery";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//import ReactDOM from 'react-dom';
var that = "";
$(document).ready(function() {
  $(".formdrag input").change(function() {
    that.setState({
      files: []
    });
    $(".formdrag p").text(this.files.length + " files selected");
    $("#changecolor").addClass("changecolor");
    $("#changeicon").addClass("changecolor");

    if (this.files.length == 0) {
      $(".formdrag p").text("Click to add files");
      $("#changecolor").removeClass("changecolor");
      $("#changeicon").removeClass("changecolor");
    }
  });
});
class RecyclingPickup extends React.Component {
  constructor(props) {
    // var getUrl = window.location;
    // var finalurl = getUrl.protocol + "//" + getUrl.hostname;
    var finalurl = posturl;
    super(props);
    that = this;
    this.state = {
      baseUrl: finalurl,
      full_name: "",
      email: "",
      phone: "",
      remarks: "",
      files: []
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleChange1 = this.handleChange1.bind(this);
    this.recycleFormSubmit = this.recycleFormSubmit.bind(this);
    this.fileSelectedHandler = this.fileSelectedHandler.bind(this);
  }
  //   notify = () => toast("Your Request Is Submited !");
  fileSelectedHandler = e => {
    this.setState({ files: [...this.state.files, ...e.target.files] });
    console.log(this.state.files);
  };

  handleChange(e) {
    const name = e.target.name;
    this.setState({ [name]: e.target.value });
  }
  handleChange1(e) {
    const name = e.target.name;
    if ($.isNumeric(e.target.value)) {
      this.setState({ [name]: e.target.value });
      return true;
    } else {
      toast("Please enter number!");
      return false;
    }
  }
  recycleFormSubmit = e => {
    e.preventDefault();
    $("div#loader-container").removeClass("hidden");
    var full_name = $.trim(this.state.full_name);
    var email = $.trim(this.state.email);
    var phone = $.trim(this.state.phone);
    var remarks = $.trim(this.state.remarks);
    var files = this.state.files;
    if (!full_name) {
      toast("Name Required !");
      $("div#loader-container").addClass("hidden");
      return false;
    }
    if (!email) {
      toast("Email Required !");
      $("div#loader-container").addClass("hidden");
      return false;
    }
    if (!phone) {
      toast("Phone Number Required !");
      $("div#loader-container").addClass("hidden");
      return false;
    }
    if (!remarks) {
      toast("Remarks Required !");
      $("div#loader-container").addClass("hidden");
      return false;
    }
    if (!files.length > 0) {
      toast("Select Atleast One Image!");
      $("div#loader-container").addClass("hidden");
      return false;
    }
    if (files.length > 3) {
      toast("Only 3 images allow!");
      $("div#loader-container").addClass("hidden");
      return false;
    }
    let insertUrl =
      this.state.baseUrl +
      "/laptopzone/reactcontroller/Lz_website/c_lz_recycle/lz_recyle_form";
    var formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append("images[]", files[i]);
      console.log(files[i]);
    }

    formData.append("full_name", full_name);
    formData.append("email", email);
    formData.append("phone", phone);
    formData.append("remarks", remarks);
    new Promise(function(resolve, reject) {
      $.ajax({
        url: insertUrl,
        dataType: "json",
        type: "POST",
        cache: false,
        contentType: false,
        processData: false,
        data: formData
      }).then(
        function(data) {
          resolve(data);
        },
        function(err) {
          reject(err);
        }
      );
    })
      .then(result => {
        if (result.insert) {
          $("div#loader-container").addClass("hidden");
          this.setState({
            full_name: "",
            files: [],
            remarks: "",
            email: "",
            phone: ""
          });
          toast("We will get back to you asap.");
          $("#recycleForm")[0].reset();
          $(".formdrag p").text("Click to add files");
        } else {
          toast("Something went wrong");
          return false;
        }
      })
      .catch(err => {
        $("div#loader-container").addClass("hidden");
        //$.LoadingOverlay("hide");
        console.log(err);
      });

    return false;
  };
  render() {
    const title = "Electronics Recycling in Dallas | LaptopZone";
    const pageLink = "http://laptopzone.us/recycling";
    const metaDescription =
      "LaptopZone, the perfect place for repairing electronic gadgets in Dallas, TX. We deal with your e-waste and electronics recycling needs, just to save the surroundings.We recycle your iPhone, MacBook, Dell Laptop etc.";

    return (
      <React.Fragment>
        <PageTitles
          title={title}
          metaDescription={metaDescription}
          pageLink={pageLink}
        />
        <section className="BannerSection">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-sm-12">
                <div className="RecylingBanner">
                  <div className="ContentLeft PickUp-Mrtp">
                    <h1>
                      Sell for <span>Greener</span>
                      <br /> and Better US!
                    </h1>
                    <p>
                      Recycling of old/broken electronic gadgets aren't always
                      <br /> s simple as you think, but we do it with ease!
                    </p>
                    <a href="repairing-form.html">Get a Quote</a>
                    <a
                      href="Javascript:0"
                      className="active"
                      data-toggle="modal"
                      data-target="#PickupModal"
                      role="button"
                    >
                      Request Pickup
                    </a>
                  </div>
                </div>

                {/* <div id="recyling_banner" className="owl-carousel">
         
          <div className="RecylingBanner">
          
           <div className="ContentLeft PickUp-Mrtp">
            <h1>Sell for <span>Greener</span><br> and Better US!</h1>
            <p>Lorem ipsum dolor sit amet, consectere turer adipiser<br />
			   elit sed do eiusmodetn tempor ince didunt ut labore etert<br />
			   ae magna aliqua. Ut enim ad minim aireei veniam we’ll<br />nostrud.</p>
            <a href="repairing-form.html">Get a Quote</a>
            <a href="Javascript:0" className="active" data-toggle="modal" data-target="#PickupModal" role="button">Request Pickup</a>
           </div>
           
          </div>
                  
        </div> */}
              </div>

              <div className="col-md-4 col-sm-12">
                <div className="PickUp-Container">
                  <h3>Pickup Request</h3>

                  <div className="PickUp-Form">
                    <form
                      id="recycleForm"
                      action="#"
                      method="#"
                      onSubmit={this.recycleFormSubmit}
                    >
                      <div className="row">
                        <div className="col-md-12 col-sm-12">
                          <label>Your Full name</label>
                          <input
                            type="name"
                            id=""
                            name="full_name"
                            onChange={this.handleChange}
                          />
                        </div>

                        <div className="col-md-12 col-sm-12">
                          <label>Email Address</label>
                          <input
                            type="email"
                            id="email"
                            name="email"
                            onChange={this.handleChange}
                          />
                        </div>

                        <div className="col-md-12 col-sm-12">
                          <label>Phone</label>

                          <input
                            type="phone"
                            id="phone"
                            name="phone"
                            maxLength="13"
                            onChange={this.handleChange1}
                          />
                        </div>

                        <div className="col-md-12 col-sm-12">
                          <label>Remarks</label>
                          <textarea
                            id="remarks"
                            name="remarks"
                            onChange={this.handleChange}
                          ></textarea>
                        </div>

                        <div className="col-md-12 col-sm-12">
                          <div id="formdrag" className="formdrag">
                            <p id="changecolor">Click to add files</p>
                            <input
                              type="file"
                              id="fileRe"
                              onChange={this.fileSelectedHandler}
                              multiple
                            />

                            <i id="changeicon" className="icon-attach_icon"></i>
                          </div>
                        </div>

                        <div className="col-md-12 col-sm-12">
                          <button type="submit" value="">
                            Send Request
                          </button>
                        </div>
                      </div>
                    </form>

                    <div className="SendRequest-Text">
                      <h4>
                        Fill out the form or call us <span>214-205-0950</span>
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="RecylingEquipment">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-sm-12">
                <div className="EquipmentHead">
                  <h2>
                    <span>Equipment</span> we accept
                  </h2>
                  <p>Join the electronic revolution! </p>
                </div>
              </div>

              <div className="col-md-12 col-sm-12">
                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <div className="EquipmentContent">
                      <img
                        src="/assets/image/recycle-computers-pcs-macs-&-tablets.png"
                        width="262"
                        height="200"
                        alt=""
                      />
                      <h3>Recycle Computers, Pcs, Macs & Tablets</h3>
                      <p>
                        We Recycle Computers, MacBook’s, IPads, IPhones, <br />
                        Desktops, Laptops, Workstations and much more. For
                        electronic recycling | monitor & computer recycling, we
                        will be picked up your useless gadgets and it depends on
                        <br /> area radius. We are also working in MacBook
                        <br /> recycling in Dallas.
                      </p>
                    </div>
                  </div>

                  <div className="col-md-6 col-sm-12">
                    <div className="EquipmentContent">
                      <img
                        src="/assets/image/recycle-servers-&-workstations.png"
                        width="262"
                        height="200"
                        alt=""
                      />
                      <h3>Recycle Servers & Workstations</h3>
                      <p>
                        We can easily Recycle Servers, Workstations,
                        <br /> Cabinets, Rack-Mount Equipment and Other
                        Enterprise
                        <br /> Level Equipment just through or recycle server
                        equipment
                        <br /> technique. You can call use any time without
                        <br /> any hassle.{" "}
                      </p>
                    </div>
                  </div>

                  <div className="col-md-6 col-sm-12">
                    <div className="EquipmentContent">
                      <img
                        src="/assets/image/recycle-networking-equipment.png"
                        width="262"
                        height="200"
                        alt=""
                      />
                      <h3>Recycle Networking Equipment</h3>
                      <p>
                        We are also working in computer recycling for different
                        <br /> networking equipment such as Telecommunications
                        tools,
                        <br /> Routers, Switches, Hubs, Firewalls and All WAN
                        Related
                        <br /> Equipment. Networking equipment is quite
                        expensive,
                        <br /> so don’t try to trash your equipment just come to
                        us.{" "}
                      </p>
                    </div>
                  </div>

                  <div className="col-md-6 col-sm-12">
                    <div className="EquipmentContent">
                      <img
                        src="/assets/image/recycle-computer-peripherals-tools.png"
                        width="262"
                        height="200"
                        alt=""
                      />
                      <h3>Recycle Computer Peripherals Tools</h3>
                      <p>
                        Come to us for recycling purpose because we recycle
                        <br /> LCD monitors, used Printers, Monitors, Credit
                        Card machines,
                        <br /> Microphones, Keyboards, Mouse, and Other
                        Peripheral
                        <br /> Devices. We know that accessories are easy to
                        available
                        <br /> and people throw it to the garbage can without
                        any
                        <br /> hesitation, still it is vital for us and you.
                      </p>
                    </div>
                  </div>

                  <div className="col-md-6 col-sm-12">
                    <div className="EquipmentContent">
                      <img
                        src="/assets/image/recycle-consumer-electronics.png"
                        width="262"
                        height="200"
                        alt=""
                      />
                      <h3>Recycle Consumer Electronics</h3>
                      <p>
                        LaptopZone is working on Samsung Laptops Recycling
                        <br />
                        and we Recycle Cell Phones, Tablets, Radios, Cameras,
                        TVs, Surveillance Systems, LED Lighting, Video Gaming
                        Consoles and House Hold Appliances, AC Units, and
                        Electricity Devices.
                      </p>
                    </div>
                  </div>

                  <div className="col-md-6 col-sm-12">
                    <div className="EquipmentContent">
                      <img
                        src="/assets/image/recycle-media-equipment.png"
                        width="262"
                        height="200"
                        alt=""
                      />
                      <h3>Recycle Media Equipment</h3>
                      <p>
                        You can easily recycle Hard Drives, Floppy’s, Cassette
                        Tapes, <br />
                        USB Thumb Drives, CDs, DVDs, LTOs, and Other Media
                        Storage Devices. If you have a laptop of any brand, then
                        come to us because we do dell laptops recycling in our
                        <br /> warehouse every day.{" "}
                      </p>
                    </div>
                  </div>

                  <div className="col-md-6 col-sm-12">
                    <div className="EquipmentContent">
                      <img
                        src="/assets/image/recycle-testing-equipment.png"
                        width="262"
                        height="200"
                        alt=""
                      />
                      <h3>Recycle Testing Equipment</h3>
                      <p>
                        We Recycle Meters, Gauges, Volt Meters,
                        <br />
                        Frequency Emulators, Chargers, Power Supplies, Scanners,
                        <br /> and Generators. These types of equipment are
                        really costly
                        <br /> and don't try to trash! If you want to get rid of
                        equipment,
                        <br /> then we have a solution to recycling.
                      </p>
                    </div>
                  </div>

                  <div className="col-md-6 col-sm-12">
                    <div className="EquipmentContent">
                      <img
                        src="/assets/image/recycle-industrial-equipment.png"
                        width="262"
                        height="200"
                        alt=""
                      />
                      <h3>Recycle Industrial Equipment</h3>
                      <p>
                        We Recycle Manufacturing Materials, Tools,
                        <br /> Electric Vehicles, Motors, Pumps, Robots,
                        Forklifts, Imaging
                        <br /> Devices, Floor Sweepers, Batteries, Generators,
                        and
                        <br /> Automation Systems. So, we also work on
                        industrial
                        <br /> asset disposition to save the environment.{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="RecylingNews">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-sm-12">
                <div className="NewsHead">
                  <h2>
                    News and <span>Events</span>
                  </h2>
                  <p>Learn more about our latest updates with just a click!</p>
                </div>
              </div>

              <div className="col-md-6 col-sm-12">
                <div className="NewsContent">
                  <h5>
                    The Fastest-growing Waste Stream, 'Approximately 50 Million
                    Tons’
                  </h5>
                  <h6>29 Aug, 2019</h6>
                  <p>
                    The wide-range category of recyclables is known as e-waste
                    and it includes Computers, Laptops, Servers, Printers &
                    Copiers, Storage cabinets, Network cabinets, Scanners,
                    Circuit boards, PC cards, CRT Monitors
                  </p>
                  <a href="#">Read More</a>
                </div>
              </div>

              <div className="col-md-6 col-sm-12">
                <div className="NewsContent">
                  <h5>Hurrah! LaptopZone’s New Site </h5>
                  <h6>29 Aug, 2019</h6>
                  <p>
                    Have you exhausted to see the old LaptopZone’s site due to
                    the old design?  For the convenience of our customers and
                    clients, we have recently deployed a new website. You can
                    now easily request for pickups for your businesses or home.{" "}
                  </p>
                  <a href="#">Read More</a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
export default RecyclingPickup;
