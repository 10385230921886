import React, { Component } from "react";
import $ from "jquery";
//import add from "./configfile.js";
//import 'react-phone-number-input/style.css'
//import PhoneInput , { isValidPhoneNumber ,parsePhoneNumber,formatPhoneNumber } from 'react-phone-number-input'
//import { ToastContainer, toast } from 'react-toastify';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//import 'react-toastify/dist/ReactToastify.css';
import { posturl } from "./configuration.js";
import {
  withGoogleMap,
  GoogleMap,
  withScriptjs,
  InfoWindow,
  Marker
} from "react-google-maps";
import Geocode from "react-geocode";

//import Autocomplete from 'react-google-autocomplete';
const google = window.google;
Geocode.setApiKey("AIzaSyAwOhp1LKDUMBY52Y_WrvwQfHF1x9s1AYI");
Geocode.enableDebug();

class Map extends React.Component {
  constructor(props) {
    super(props);
    // var getUrl = window.location;
    // var finalurl = getUrl.protocol + "//" + getUrl.hostname;
    var finalurl = posturl;
    this.state = {
      baseUrl: finalurl,

      address: "",
      addressShow: "",
      city: "",
      area: "",
      state: "",
      zipcode: "",
      getRepareId: this.props.repId,
      getRadioId: this.props.radioId,
      mapPosition: {
        lat: this.props.center.lat,
        lng: this.props.center.lng
      },
      markerPosition: {
        lat: this.props.center.lat,
        lng: this.props.center.lng
      },
      distance_in_mile: ""
    };

    this.saveOptions = this.saveOptions.bind(this);
    this.callback = this.callback.bind(this);
  }
  /**
   * Get the current address from the default map position and set those values in the state


   */

  componentDidMount() {
    if (!window.google) {
      throw new Error(
        "Google Maps JavaScript API library must be loaded. See: https://github.com/kenny-hibino/react-places-autocomplete#load-google-library"
      );
    }

    if (!window.google.maps.places) {
      throw new Error(
        "Google Maps Places library must be loaded. Please add `libraries=places` to the src URL. See: https://github.com/kenny-hibino/react-places-autocomplete#load-google-library"
      );
    }

    // if (navigator.geolocation) {
    //         navigator.geolocation.getCurrentPosition(
    //             position => {
    //                 console.log('1');
    //                 console.log(position.coords);

    //                 this.setState({
    //                   mapPosition: {
    //                     lat: position.coords.latitude,
    //                     lng: position.coords.longitude
    //                    },
    //                   markerPosition: {
    //                       lat: position.coords.latitude,
    //                     lng: position.coords.longitude
    //                   }
    //                   //mapPosition.lat:position.coords.latitude,
    //                   //mapPosition.lng:position.coords.longitude
    //                 })
    //                 //console.log('2');

    //                 // this.setState ({
    //                 //     mapPosition: {
    //                 //         //...prevState.currentLatLng,
    //                 //         lat: position.coords.latitude,
    //                 //         lng: position.coords.longitude
    //                 //     },
    //                 //     markerPosition: {
    //                 //         //...prevState.currentLatLng,
    //                 //         lat: position.coords.latitude,
    //                 //         lng: position.coords.longitude
    //                 //     }
    //                 // })
    //             }
    //         )
    //     }
    Geocode.fromLatLng(
      this.state.mapPosition.lat,
      this.state.mapPosition.lng
    ).then(
      response => {
        this.setState({
          addressShow: response.results[0].formatted_address
        });
        var address1 = response.results[0].formatted_address.split(",");

        const address = address1[0],
          addressArray = response.results[0].address_components,
          city = this.getCity(addressArray),
          area = this.getArea(addressArray),
          state = this.getState(addressArray),
          zipcode = this.getZipcode(addressArray);

        console.log("city", addressArray);

        console.log("city122", address1);
        this.setState({
          address: address ? address : "",
          area: area ? area : "",
          city: city ? city : "",
          zipcode: zipcode ? zipcode : "",
          state: state ? state : ""
        });
      },
      error => {
        console.error(error);
      }
    );

    // Geocode.fromLatLng( this.state.mapPosition.lat , this.state.mapPosition.lng ).then(
    //  response => {
    //   const address = response.results[0].formatted_address,
    //    addressArray =  response.results[0].address_components,
    //    city = this.getCity( addressArray ),
    //    area = this.getArea( addressArray ),
    //    state = this.getState( addressArray );

    //   console.log( 'city', city, area, state );

    //   this.setState( {
    //    address: ( address ) ? address : '',
    //    area: ( area ) ? area : '',
    //    city: ( city ) ? city : '',
    //    state: ( state ) ? state : '',
    //   } )
    //  },
    //  error => {
    //   console.error(error);
    //  }
    // );
  }

  notifyApprov = () => toast("Your Request Is Approved!");
  notifyFail = () => toast("Error!");
  saveOptions() {
    var origin = this.state.address; //'Kalma Chowk Flyover, Lahore, Punjab, Pakistan';
    var destination = "2720 Royal Ln, Dallas, TX 75229, USA"; //$('#destination').val();

    var service = new google.maps.DistanceMatrixService();
    service.getDistanceMatrix(
      {
        origins: [origin],
        destinations: [destination],
        //origins: [origin1, origin2],
        //destinations: [destinationA, destinationB],

        travelMode: google.maps.TravelMode.DRIVING,
        unitSystem: google.maps.UnitSystem.IMPERIAL,

        avoidHighways: false,
        avoidTolls: false
      },
      this.callback
    );

    // alert('pickup');
    // return false;
  }

  callback(response, status) {
    $("div#loader-container").removeClass("hidden");
    if (status != google.maps.DistanceMatrixStatus.OK) {
      // $("div#loader-container").addClass("hidden");
      //  $('#result').html(err);
    } else {
      var origin = response.originAddresses[0];
      var destination = response.destinationAddresses[0];
      if (response.rows[0].elements[0].status === "ZERO_RESULTS") {
        //  $('#result').html("Better get on a plane. There are no roads between "  + origin + " and " + destination);
        // console.log("Better get on a plane. There are no roads between "  + origin + " and " + destination);
        $("div#loader-container").addClass("hidden");
        $("#result").text("we donot Pick outside 10 mile radius");
        console.log("we donot Pick outside 10 mile radius");
        return false;
      } else {
        var distance = response.rows[0].elements[0].distance;
        var duration = response.rows[0].elements[0].duration;
        //console.log(response.rows[0].elements[0].distance);
        var distance_in_kilo = distance.value / 1000; // the kilom
        var distance_in_mile = distance.value / 1609.34; // the mile
        var duration_text = duration.text;
        var duration_value = duration.value;

        console.log(distance_in_mile.toFixed(2));
        console.log(distance_in_kilo.toFixed(2));
        console.log(duration_text);
        console.log(duration_value);
        console.log(origin);
        console.log(destination);

        if (distance_in_mile.toFixed(2) > 10.0) {
          $("div#loader-container").addClass("hidden");
          $("#result").text("we donot Pick outside 10 mile radius");
          console.log("we donot Pick outside 10 mile radius");
          return false;
        } else {
          // $("div#loader-container").addClass("hidden");

          let insertUrl =
            this.state.baseUrl +
            "/laptopzone/reactcontroller/c_react/saveOptionData";
          var getadd = this.state.address;
          var city = this.state.city;
          var area = this.state.area;
          var state = this.state.state;
          var zipcode = this.state.zipcode;

          var getrepid = this.state.getRepareId;
          var getradio = this.state.getRadioId;
          console.log(getradio);

          new Promise(function(resolve, reject) {
            $.ajax({
              url: insertUrl,
              dataType: "json",
              type: "POST",
              data: {
                address: getadd,
                city: city,
                area: area,
                state: state,
                zipcode: zipcode,
                getRepareId: getrepid,
                getradio: getradio
              }
            }).then(
              function(data) {
                resolve(data);
              },
              function(err) {
                reject(err);
              }
            );
          })
            .then(result => {
              if (result.save) {
                $("div#loader-container").addClass("hidden");
                toast(result.message);
                this.props.that.setState({
                  showOptions: "hide"
                });
                $(".pickConti").addClass("hide");
              } else {
                $("div#loader-container").addClass("hidden");
                toast(result.message);
              }
            })
            .catch(err => {
              // $("div#loader-container").addClass("hidden");
              //$.LoadingOverlay("hide");
              console.log(err);
            });
        }

        // this.setState({
        //   distance_in_mile:distance_in_mile

        // })

        // $('#in_mile').text(distance_in_mile.toFixed(2));
        // $('#in_kilo').text(distance_in_kilo.toFixed(2));
        // $('#duration_text').text(duration_text);
        // $('#duration_value').text(duration_value);
        // $('#from').text(origin);
        // $('#to').text(destination);
      }
    }
  }

  /**
   * Component should only update ( meaning re-render ), when the user selects the address, or drags the pin
   *
   * @param nextProps
   * @param nextState
   * @return {boolean}
   */
  shouldComponentUpdate(nextProps, nextState) {
    if (
      this.state.markerPosition.lat !== this.props.center.lat ||
      this.state.address !== nextState.address ||
      this.state.city !== nextState.city ||
      this.state.area !== nextState.area ||
      this.state.zipcode !== nextState.zipcode ||
      this.state.state !== nextState.state
    ) {
      return true;
    } else if (this.props.center.lat === nextProps.center.lat) {
      return false;
    }
  }
  /**
   * Get the city and set the city input value to the one selected
   *
   * @param addressArray
   * @return {string}
   */
  getCity = addressArray => {
    let city = "";
    for (let i = 0; i < addressArray.length; i++) {
      if (
        addressArray[i].types[0] &&
        "administrative_area_level_2" === addressArray[i].types[0]
      ) {
        city = addressArray[i].long_name;
        return city;
      }
    }
  };
  /**
   * Get the area and set the area input value to the one selected
   *
   * @param addressArray
   * @return {string}
   */
  getArea = addressArray => {
    let area = "";
    for (let i = 0; i < addressArray.length; i++) {
      if (addressArray[i].types[0]) {
        for (let j = 0; j < addressArray[i].types.length; j++) {
          if (
            "sublocality_level_1" === addressArray[i].types[j] ||
            "locality" === addressArray[i].types[j]
          ) {
            area = addressArray[i].long_name;
            return area;
          }
        }
      }
    }
  };
  /**
   * Get the zipcode and set the zip input value to the one selected
   *
   * @param addressArray
   * @return {string}
   */
  getZipcode = addressArray => {
    let zipcode = "";
    for (let i = 0; i < addressArray.length; i++) {
      if (addressArray[i].types[0]) {
        for (let j = 0; j < addressArray[i].types.length; j++) {
          if (
            "administrative_area_level_4" === addressArray[i].types[j] ||
            "postal_code" === addressArray[i].types[j]
          ) {
            zipcode = addressArray[i].long_name;
            return zipcode;
          }
        }
      }
    }
  };
  /**
   * Get the address and set the address input value to the one selected
   *
   * @param addressArray
   * @return {string}
   */
  getState = addressArray => {
    let state = "";
    for (let i = 0; i < addressArray.length; i++) {
      for (let i = 0; i < addressArray.length; i++) {
        if (
          addressArray[i].types[0] &&
          "administrative_area_level_1" === addressArray[i].types[0]
        ) {
          state = addressArray[i].long_name;
          return state;
        }
      }
    }
  };
  /**
   * And function for city,state and address input
   * @param event
   */
  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
  /**
   * When the marker is dragged you get the lat and long using the functions available from event object.
   * Use geocode to get the address, city, area and state from the lat and lng positions.
   * And then set those values in the state.
   *
   * @param event
   */

  onMarkerDragEnd = event => {
    console.log("event", event);
    let newLat = event.latLng.lat(),
      newLng = event.latLng.lng(),
      //alert(event.latLng.lng()),

      addressArray = [];
    //alert(event.latLng.lat());
    Geocode.fromLatLng(newLat, newLng).then(
      response => {
        this.setState({
          addressShow: response.results[0].formatted_address
        });
        var address1 = response.results[0].formatted_address.split(",");
        const address = address1[0],
          addressArray = response.results[0].address_components,
          city = this.getCity(addressArray),
          area = this.getArea(addressArray),
          zipcode = this.getZipcode(addressArray),
          state = this.getState(addressArray);
        this.setState({
          address: address ? address : "",
          area: area ? area : "",
          city: city ? city : "",
          zipcode: zipcode ? zipcode : "",
          state: state ? state : "",
          markerPosition: {
            lat: newLat,
            lng: newLng
          },
          mapPosition: {
            lat: newLat,
            lng: newLng
          }
        });
      },
      error => {
        console.error(error);
      }
    );
  };
  /**
   * This Event triggers when the marker window is closed
   *
   * @param event
   */
  onInfoWindowClose = event => {};

  onPlaceSelected = place => {
    console.log("pc", place);
    console.log(window.google);
    this.setState({
      addressShow: place.formatted_address
    });
    var address1 = place.formatted_address.split(",");

    const address = address1[0],
      addressArray = place.address_components,
      city = this.getCity(addressArray),
      area = this.getArea(addressArray),
      state = this.getState(addressArray),
      zipcode = this.getZipcode(addressArray),
      latValue = place.geometry.location.lat(),
      lngValue = place.geometry.location.lng();
    // Set these values in the state.
    this.setState({
      address: address ? address : "",
      area: area ? area : "",
      city: city ? city : "",
      zipcode: zipcode ? zipcode : "",
      state: state ? state : "",
      markerPosition: {
        lat: latValue,
        lng: lngValue
      },
      mapPosition: {
        lat: latValue,
        lng: lngValue
      }
    });
  };

  render() {
    //alert(this.props.google);
    const AsyncMap = withScriptjs(
      withGoogleMap(props => (
        <GoogleMap
          google={this.props.google}
          defaultZoom={this.props.zoom}
          //defaultCenter={{ lat: -34.397, lng: 150.644 }}
          defaultCenter={{
            lat: this.state.mapPosition.lat,
            lng: this.state.mapPosition.lng
          }}
        >
          {/* InfoWindow on top of marker */}
          <InfoWindow
            onClose={this.onInfoWindowClose}
            position={{
              lat: this.state.markerPosition.lat + 0.0018,
              lng: this.state.markerPosition.lng
            }}
          >
            <div>
              <span style={{ padding: 0, margin: 0 }}>
                {this.state.addressShow}
              </span>
            </div>
          </InfoWindow>

          {/*Marker*/}
          <Marker
            google={this.props.google}
            draggable={true}
            onDragEnd={this.onMarkerDragEnd}
            position={{
              lat: this.state.markerPosition.lat,
              lng: this.state.markerPosition.lng
            }}
          />
        </GoogleMap>
      ))
    );

    let map;

    if (this.props.center.lat !== undefined) {
      map = (
        <React.Fragment>
          <ToastContainer
            position="top-right"
            autoClose={2500}
            hideProgressBar={true}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnVisibilityChange
            draggable
            pauseOnHover
          />

          <div id="loader-container" className="loader-container hidden">
            <svg
              className="spinner"
              viewBox="0 0 66 66"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                className="path"
                fill="none"
                stroke-linecap="round"
                cx="33"
                cy="33"
                r="30"
              ></circle>
            </svg>

            <div className="icon">
              <img src="assets/image/main_logo.svg" />
            </div>
          </div>

          <div className="customInput">
            <div className="form-group">
              <p id="result"></p>
            </div>
            <div className="form-group">
              <label htmlFor="">Street Address</label>
              <input
                type="text"
                name="address"
                className="form-control"
                onChange={this.onChange}
                readOnly="readOnly"
                value={this.state.address}
              />
            </div>
            <div className="form-group">
              <label htmlFor="">City</label>
              <input
                type="text"
                name="city"
                className="form-control"
                onChange={this.onChange}
                readOnly="readOnly"
                value={this.state.city}
              />
            </div>
            <div className="form-group hide">
              <label htmlFor="">Area</label>
              <input
                type="text"
                name="area"
                className="form-control"
                onChange={this.onChange}
                readOnly="readOnly"
                value={this.state.area}
              />
            </div>
            <div className="form-group">
              <label htmlFor="">State</label>
              <input
                type="text"
                name="state"
                className="form-control"
                onChange={this.onChange}
                readOnly="readOnly"
                value={this.state.state}
              />
            </div>
            <div className="form-group">
              <label htmlFor="">Zipcode</label>
              <input
                type="text"
                name="zipcode"
                className="form-control"
                onChange={this.onChange}
                readOnly="readOnly"
                value={this.state.zipcode}
              />
            </div>
          </div>

          <div className="custMapClass">
            <AsyncMap
              googleMapURL=" https://maps.googleapis.com/maps/api/js?libraries=places&language=en&key=AIzaSyAwOhp1LKDUMBY52Y_WrvwQfHF1x9s1AYI&libraries=geometry,drawing,places"
              loadingElement={<div style={{ height: `100%` }} />}
              containerElement={<div style={{ height: this.props.height }} />}
              mapElement={<div style={{ height: `100%` }} />}
            />
          </div>
          <div className="col-md-4 col-sm-12">
            <button
              className="aproveButton pickConti"
              onClick={() => {
                this.saveOptions();
              }}
              type="button"
            >
              Continue
            </button>
          </div>
        </React.Fragment>
      );
    } else {
      map = <div style={{ height: this.props.height }} />;
    }
    return map;
  }
}
export default Map;
