import React from "react"; 
import { posturl } from "./configuration.js";
import PageTitles from "./pageTitles";
import $ from "jquery";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//import ReactDOM from 'react-dom';
var that = "";
$(document).ready(function() {
  $(".formdrag input").change(function() {
    that.setState({
      files: []
    });
    $(".formdrag p").text(this.files.length + " files selected");
    $("#changecolor").addClass("changecolor");
    $("#changeicon").addClass("changecolor");

    if (this.files.length == 0) {
      $(".formdrag p").text("Click to add files");
      $("#changecolor").removeClass("changecolor");
      $("#changeicon").removeClass("changecolor");
    }
  });
});
class SellItEquipment extends React.Component {
  constructor(props) {
    // var getUrl = window.location;
    // var finalurl = getUrl.protocol + "//" + getUrl.hostname;
    var finalurl = posturl;
    super(props);
    that = this;
    this.state = {
      baseUrl: finalurl,
      full_name: "",
      email: "",
      phone: "",
      remarks: "",
      files: []
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleChange1 = this.handleChange1.bind(this);
    this.recycleFormSubmit = this.recycleFormSubmit.bind(this);
    this.fileSelectedHandler = this.fileSelectedHandler.bind(this);
  }
  //   notify = () => toast("Your Request Is Submited !");
  fileSelectedHandler = e => {
    this.setState({ files: [...this.state.files, ...e.target.files] });
    console.log(this.state.files);
  };

  handleChange(e) {
    const name = e.target.name;
    this.setState({ [name]: e.target.value });
  }
  handleChange1(e) {
    const name = e.target.name;
    if ($.isNumeric(e.target.value)) {
      this.setState({ [name]: e.target.value });
      return true;
    } else {
      toast("Please enter number!");
      return false;
    }
  }
  recycleFormSubmit = e => {
    e.preventDefault();
    $("div#loader-container").removeClass("hidden");
    var full_name = $.trim(this.state.full_name);
    var email = $.trim(this.state.email);
    var phone = $.trim(this.state.phone);
    var remarks = $.trim(this.state.remarks);
    var files = this.state.files;
    if (!full_name) {
      toast("Name Required !");
      $("div#loader-container").addClass("hidden");
      return false;
    }
    if (!email) {
      toast("Email Required !");
      $("div#loader-container").addClass("hidden");
      return false;
    }
    if (!phone) {
      toast("Phone Number Required !");
      $("div#loader-container").addClass("hidden");
      return false;
    }
    if (!remarks) {
      toast("Remarks Required !");
      $("div#loader-container").addClass("hidden");
      return false;
    }
    if (!files.length > 0) {
      toast("Select Atleast One Image!");
      $("div#loader-container").addClass("hidden");
      return false;
    }
    if (files.length > 3) {
      toast("Only 3 images allow!");
      $("div#loader-container").addClass("hidden");
      return false;
    }
    let insertUrl =
      this.state.baseUrl +
      "/laptopzone/reactcontroller/Lz_website/c_lz_recycle/lz_recyle_form";
    var formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append("images[]", files[i]);
      console.log(files[i]);
    }

    formData.append("full_name", full_name);
    formData.append("email", email);
    formData.append("phone", phone);
    formData.append("remarks", remarks);
    new Promise(function(resolve, reject) {
      $.ajax({
        url: insertUrl,
        dataType: "json",
        type: "POST",
        cache: false,
        contentType: false,
        processData: false,
        data: formData
      }).then(
        function(data) {
          resolve(data);
        },
        function(err) {
          reject(err);
        }
      );
    })
      .then(result => {
        if (result.insert) {
          $("div#loader-container").addClass("hidden");
          this.setState({
            full_name: "",
            files: [],
            remarks: "",
            email: "",
            phone: ""
          });
          toast("We will get back to you asap.");
          $("#recycleForm")[0].reset();
          $(".formdrag p").text("Click to add files");
        } else {
          toast("Something went wrong");
          return false;
        }
      })
      .catch(err => {
        $("div#loader-container").addClass("hidden");
        //$.LoadingOverlay("hide");
        console.log(err);
      });

    return false;
  };
  render() {
    const title = "Electronics Recycling in Dallas | LaptopZone";
    const pageLink = "http://laptopzone.us/recycling";
    const metaDescription =
      "LaptopZone, the perfect place for repairing electronic gadgets in Dallas, TX. We deal with your e-waste and electronics recycling needs, just to save the surroundings.We recycle your iPhone, MacBook, Dell Laptop etc.";

    return (
      <React.Fragment>
        <PageTitles
          title={title}
          metaDescription={metaDescription}
          pageLink={pageLink}
        />
        <section className="BannerSection">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-sm-12">
                
              <div class="RecylingBanner">
         <div class="ContentLeft PickUp-Mrtp">
          <h1>Sell Your <span>IT Equiment</span><br /> In Dallas, TX</h1>
          <p>Get great prices for your used or unwanted IT equipment!<br /> We make it easy for Dallas people to sell IT equipment.</p>
          <a class="ItBtn" href="/contact-us">Get a Quote</a>
          <div class="clearfix"></div>
          <div class="itPhone-Head">
      	  <i class="icon-header_phone"></i>
      	  <a href="tel:(214)-205-0950">(214)-205-0950</a>
      	 </div>
      	 
         </div>
        </div>
            </div>

              <div className="col-md-4 col-sm-12">
                <div className="PickUp-Container">
                  <h3>Pickup Request</h3>

                  <div className="PickUp-Form">
                    <form
                      id="recycleForm"
                      action="#"
                      method="#"
                      onSubmit={this.recycleFormSubmit}
                    >
                      <div className="row">
                        <div className="col-md-12 col-sm-12">
                          <label>Your Full name</label>
                          <input
                            type="name"
                            id=""
                            name="full_name"
                            onChange={this.handleChange}
                          />
                        </div>

                        <div className="col-md-12 col-sm-12">
                          <label>Email Address</label>
                          <input
                            type="email"
                            id="email"
                            name="email"
                            onChange={this.handleChange}
                          />
                        </div>

                        <div className="col-md-12 col-sm-12">
                          <label>Phone</label>

                          <input
                            type="phone"
                            id="phone"
                            name="phone"
                            maxLength="13"
                            onChange={this.handleChange1}
                          />
                        </div>

                        <div className="col-md-12 col-sm-12">
                          <label>Remarks</label>
                          <textarea
                            id="remarks"
                            name="remarks"
                            onChange={this.handleChange}
                          ></textarea>
                        </div>

                        <div className="col-md-12 col-sm-12">
                          <div id="formdrag" className="formdrag">
                            <p id="changecolor">Click to add files</p>
                            <input
                              type="file"
                              id="fileRe"
                              onChange={this.fileSelectedHandler}
                              multiple
                            />

                            <i id="changeicon" className="icon-attach_icon"></i>
                          </div>
                        </div>

                        <div className="col-md-12 col-sm-12">
                          <button type="submit" value="">
                            Send Request
                          </button>
                        </div>
                      </div>
                    </form>

                    <div className="SendRequest-Text">
                      <h4>
                        Fill out the form or call us <span>214 205 0950</span>
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="RecylingEquipment">
     <div class="container">
      <div class="row">
      	
       <div class="col-md-12 col-sm-12">
       	<div class="EquipmentHead">
      	 <h2><span>IT Equipment</span> we accept</h2>
      	 <p>Join the electronic revolution!</p>
      	</div>
       </div>
       
       <div class="col-md-12 col-sm-12">
       	<div class="row">
       	
       	 <div class="col-md-6 col-sm-12">
       	  <div class="EquipmentContent">
       	   <img src="./assets/image/Sell-Servers,-Server-Blades-&-Workstations.png" width="262" height="200" alt=""/>
		   <h5>Sell Servers, Server <br />Blades & Workstations</h5>
       	   <p>We buy your used or unwanted servers, server blades, workstations, rack-mount equipment, and other enterprises, level equipment. We are the best buyers in Dallas, and offering the fastest-selling and buying services.</p>
       	  </div>
       	 </div>
       	 
       	 <div class="col-md-6 col-sm-12">
       	  <div class="EquipmentContent">
       	   <img src="./assets/image/Sell-Routers,-Network-Switches,-Firewalls-&-Wireless-Controllers.png" width="262" height="200" alt=""/>
		   <h5>Sell Routers, Network Switches, <br />Firewalls & Wireless Controllers</h5>
       	   <p>We buy used routers, network switches, firewalls, wireless controllers, telecommunications tools, hubs, and all WAN related equipment. We offer the fastest, safest, and easiest selling and buying services of IT equipment. </p>
       	  </div>
       	 </div>
       	 
       	 <div class="col-md-6 col-sm-12">
       	  <div class="EquipmentContent">
       	   <img src="./assets/image/Sell-Storage-Devices-&-Batteries-.png" width="262" height="200" alt=""/>
		   <h5>Sell Storage <br />Devices & Batteries</h5>
       	   <p>Sell your batteries and storage devices such as hard drives, floppy’s, cassette tapes, USB thumb drives, CDs, DVDs, LTOs, etc. We’re the most reliable sellers and buyers and help US people with the fastest services. </p>
       	  </div>
       	 </div>
       	 
       	 <div class="col-md-6 col-sm-12">
       	  <div class="EquipmentContent">
       	   <img src="./assets/image/Modem-&-Uninterruptable-Power-Supply-(UPS).png" width="262" height="200" alt=""/>
		   <h5>Sell Modem & Uninterruptable<br /> Power Supply (UPS)</h5>
       	   <p>Sell your modem, uninterruptable power supply (UPS), radios, cameras, surveillance systems, LED lighting, video gaming consoles, and much more. Come to us today and get fast cash at the spot for your used devices. </p>
       	  </div>
       	 </div>
       	 
       	 <div class="col-md-6 col-sm-12">
       	  <div class="EquipmentContent">
       	   <img src="./assets/image/Sell-Security-Appliances,-Conferencing-Equipment,-&-VoIP-Phones.png" width="262" height="200" alt=""/>
		   <h5>Sell Security Appliances,<br /> Conferencing Equipment, & VoIP Phones</h5>
       	   <p>We buy unwanted security appliances, conferencing equipment, VoIP phones, credit card machines, microphones, projectors, and big screen tv. Get our top electronics selling and buying services near you in Dallas.</p>
       	  </div>
       	 </div>
       	 
       	 <div class="col-md-6 col-sm-12">
       	  <div class="EquipmentContent">
       	   <img src="./assets/image/Sell-Multi-Function-Printers-&-Headsets-.png" width="262" height="200" alt=""/>
		   <h5>Sell Multi-Function<br /> Printers & Headsets</h5>
       	   <p>We accept multi-function printers and headsets, AC units, electrical devices, power supplies, scanners, and generators. Bring these devices, sell us, and get paid at the spot. We’re local sellers and buyers near you.</p>
       	  </div>
       	 </div>
       	 
       	 <div class="col-md-6 col-sm-12">
       	  <div class="EquipmentContent">
       	   <img src="./assets/image/Sell-Microphones,-Keyboards-&-Mouse.png" width="262" 
       	   height="200" alt=""/>
		   <h5>Sell Microphones<br /> Keyboards & Mouse</h5>
       	   <p>We accept microphones, keyboards, mouse, and other peripheral devices. Sell these devices for the fast cash. We are located near you; just visit our store and get our most secured and easiest selling and buying services.</p>
       	  </div>
       	 </div>
       	 
       	 <div class="col-md-6 col-sm-12">
       	  <div class="EquipmentContent">
       	   <img src="./assets/image/sell-desktop.png" width="262" height="200" alt=""/>
		   <h5>Sell Desktop <br />Computers & Laptops</h5>
       	   <p>We buy your desktop computers, laptops, MacBooks, iPad, iPhone, and much more. Call us now for an instant quote and sell your electronic devices at the best possible prices.</p>
       	  </div>
       	 </div>
       	 	
       	</div>
       </div>
      	
      </div>
     </div>
    </section>

    <section class="RecylingNews">
     <div class="container-fluid">
      <div class="row">
      	
      	<div class="col-md-12 col-sm-12">
      	 <div class="NewsHead">
      	  <h2>Brand <span>We accept</span></h2>
      	 </div>
      	</div>
      	
      	<div class="col-md-12 col-sm-12">
      	
      	 <div class="BrandingLogo-Con">
      	  <ul>
      	  
      	   <li><img src="./assets/image/01.jpg" width="148" height="51" alt=""/></li>
      	   <li><img src="./assets/image/02.jpg" width="148" height="51" alt=""/></li>
      	   <li><img src="./assets/image/TALENTED-TECHNOLOGIES-HOMEPAGE-GOLDEN-PAGE-COMAPANY-IMGS3_08-min.jpg" width="73" height="49" alt=""/></li>
      	   <li><img src="./assets/image/TALENTED-TECHNOLOGIES-HOMEPAGE-GOLDEN-PAGE-COMAPANY-IMGS3_10-min.jpg" width="161" height="48" alt=""/></li>
      	   <li><img src="./assets/image/TALENTED-TECHNOLOGIES-HOMEPAGE-GOLDEN-PAGE-COMAPANY-IMGS3_13-min.jpg" width="100" height="33" alt=""/></li>
      	   <li><img src="./assets/image/TALENTED-TECHNOLOGIES-HOMEPAGE-GOLDEN-PAGE-COMAPANY-IMGS3_16-min.jpg" width="133" height="32" alt=""/></li>
      	   <li><img src="./assets/image/TALENTED-TECHNOLOGIES-HOMEPAGE-GOLDEN-PAGE-COMAPANY-IMGS3_19-min.jpg" width="127" height="32" alt=""/></li>
      	   <li><img src="./assets/image/TALENTED-TECHNOLOGIES-HOMEPAGE-GOLDEN-PAGE-COMPANY-IMGS1_03-min.jpg" width="104" height="58" alt=""/></li>
      	   <li><img src="./assets/image/TALENTED-TECHNOLOGIES-HOMEPAGE-GOLDEN-PAGE-COMPANY-IMGS1_06-min.jpg" width="55" height="35" alt=""/></li>
      	   
      	  </ul>
      	 </div>
      	 
      	 <div class="clearfix"></div>
      	 
      	 <div class="BrandingLogo-Con2">
      	  <ul>
      	   
      	   <li><img src="./assets/image/TALENTED-TECHNOLOGIES-HOMEPAGE-GOLDEN-PAGE-COMPANY-IMGS1_09-min.jpg" width="123" height="38" alt=""/></li>
      	   <li><img src="./assets/image/TALENTED-TECHNOLOGIES-HOMEPAGE-GOLDEN-PAGE-COMPANY-IMGS1_12-min.jpg" width="94" height="33" alt=""/></li>
      	   <li><img src="./assets/image/TALENTED-TECHNOLOGIES-HOMEPAGE-GOLDEN-PAGE-COMPANY-IMGS1_15-min.jpg" width="82" height="36" alt=""/></li>
      	   <li><img src="./assets/image/TALENTED-TECHNOLOGIES-HOMEPAGE-GOLDEN-PAGE-COMPANY-IMGS1_24-min.jpg" width="115" height="29" alt=""/></li>
      	   <li><img src="./assets/image/TALENTED-TECHNOLOGIES-HOMEPAGE-GOLDEN-PAGE-COMPANY-IMGS1_27-min.jpg" width="114" height="17" alt=""/></li>
		   <li><img src="./assets/image/TALENTED-TECHNOLOGIES-HOMEPAGE-GOLDEN-PAGE-COMPANY-IMGS2_03-min.jpg" width="119" height="68" alt=""/></li>
		   <li><img src="./assets/image/TALENTED-TECHNOLOGIES-HOMEPAGE-GOLDEN-PAGE-COMPANY-IMGS2_06-min.jpg" width="132" height="44" alt=""/></li>
		     
      	  </ul>
      	 </div>
      	 
      	 <div class="clearfix"></div>
      	 
      	 <div class="BrandingLogo-Con3">
      	  <ul>
		   
		   <li><img src="./assets/image/TALENTED-TECHNOLOGIES-HOMEPAGE-GOLDEN-PAGE-COMPANY-IMGS2_09-min-1.jpg" width="149" height="31" alt=""/></li>
		   <li><img src="./assets/image/TALENTED-TECHNOLOGIES-HOMEPAGE-GOLDEN-PAGE-COMPANY-IMGS2_12-min.jpg" width="126" height="32" alt=""/></li>
		   <li><img src="./assets/image/TALENTED-TECHNOLOGIES-HOMEPAGE-GOLDEN-PAGE-COMPANY-IMGS2_15-min.jpg" width="128" height="33" alt=""/></li>
		   <li><img src="./assets/image/TALENTED-TECHNOLOGIES-HOMEPAGE-GOLDEN-PAGE-COMPANY-IMGS2_18-min.jpg" width="168" height="42" alt=""/></li>
		   <li><img src="./assets/image/TALENTED-TECHNOLOGIES-HOMEPAGE-GOLDEN-PAGE-JUNIPERLOGO_03-min.jpg" width="95" height="28" alt=""/></li>  
      	   
      	  </ul>
      	 </div>
      	 
      	</div>
      	
      	
      </div>
     </div>
    </section>
    </React.Fragment>
    );
  }
}
export default SellItEquipment;
