import React, { Component } from "react";
import $ from "jquery";

class AssetsManagement extends React.Component {

  render() {
    return (
    <React.Fragment>
        <section class="BannerSection">
     <div class="container">
      <div class="row">
      
       <div class="col-md-5 col-sm-12 d-xl-none d-block d-sm-none">
       	<div class="AssetManagement-BannerImage">
         <img src="/assets/image/asset-management-image.jpg" width="576" height="625" alt="" />
        </div>
       </div>
      	
       <div class="col-md-7 col-sm-12">
        <div class="AssetManagement-Banner">
         <h1>IT Asset <span>Management</span> and Disposal Dallas</h1>
         
         <p>This is accomplished while guaranteeing compliance to<br /> local and national information security and ecological<br /> guidelines.</p>
         <p class="mt-3">IT Asset Disposition or ITAD is a method for the</p>
         
         <ul>
          <li>Proper disposition</li>
          <li>Removal, reusing and recycling</li>
          <li>Or remarketing of assets</li>
         </ul>
         
        </div>
       </div>
       
       <div class="col-md-5 col-sm-12 d-none d-sm-block">
       	<div class="AssetManagement-BannerImage">
         <img src="/assets/image/asset-management-image.jpg" width="576" height="625" alt=""/>
        </div>
       </div>
      	
      </div>
     </div>
    </section>
    
    
    
    <div class="AssetDispotion-background">
     <div class="container">
      <div class="row">
      	
       <div class="col-lg-6 col-md-4 col-sm-12">
       	<div class="AssetDispotion-Head">
         <div class="DispotionImage">
          <img src="/assets/image/asset-thumable-02.jpg" width="482" height="483" alt="" />
         </div>
       	</div>
       </div>
       
       <div class="col-lg-6 col-md-8 col-sm-12">
       	<div class="AssetDispotion-Head">
       	 <div class="DispotionContent">
       	  <h3>What is the meaning of <br />IT Asset <span>Disposition</span></h3>
       	  <p>IT asset disposition or ITAD, is the safe, administrative compliant handling of outdated electronic business devices such as PCs, servers, storage gadgets, advanced digital printers, video games, handheld gadgets, and so forth.). Center ITAD processes incorporate data deletion and value recovery or reusing/recycling. These procedures are frequently attempted by IT staff, or by different sorts of outsider IT specialist organizations, some of which represent considerable authority in ITAD.<br /><br />Common ITAD clients are organizations, businesses, and societies that are dependent upon regulatory compliance, require secure treatment of private data and want money related recovery of the value of utilized resources. Bigger enterprises regularly require a centralized way to deal with the procedure across wide geographic and organizational divisions, intermittently including extremely transparent compliance reporting and fiscal analytics.</p>
       	 </div>
       	</div>
       </div>
      	
      </div>
     </div>
    </div>
    
    <section class="ITcritical-Seaction">
     <div class="container">
      <div class="row">
      
        <div class="col-lg-6 col-md-4 col-sm-12 d-none d-xl-none d-block d-sm-none">
      	 <div class="ITcriticalImage">
   	      {/* <!--<img src="/assets/image/it-critical-1.png" width="496" height="450" alt=""/>--> */}
   	      
   	      <img src="/assets/image/asset-thumable-02.jpg" width="482" height="483" alt="" />
   	      
   	     </div>
        </div>
      	
      	<div class="col-lg-6 col-md-8 col-sm-12">
      	 <div class="ITcritical-Head">
      	  <h4>Why is ITAD critical?</h4>
      	  <ul>
      	  
      	   <li>It is critical because any association's most<br /> significant resource is its notoriety.</li>
      	   
      	   <li>Improperly outdated, used or unwanted electronic<br /> business apparatuses are effortlessly followed or<br /> misused and can lead to occurrences that may be<br /> embarrassing or compromising to the organization,<br /> just as lawful procedures. </li>
      	   
      	   <li>The unintended divulgence of private client<br /> information or business data can erode public trust in<br /> any organization.</li>
      	   
      	   <li>Even data-free hardware can destroy an<br /> organization's notoriety in the event that it harms<br /> the surroundings.</li>
      	   
      	   <li>Decentralized or ad-libbed assets retirement<br /> strategies won't mitigate these perils.</li>
      	   
      	  </ul>
      	 </div>
      	</div>
      	
      	<div class="col-lg-6 col-md-4 col-sm-12 d-none d-sm-block">
      	 <div class="ITcriticalImage">
   	      {/* <!--<img src="/assets/image/it-critical-1.png" width="496" height="450" alt=""/>--> */}
   	      
   	      <img src="/assets/image/asset-thumable-02.jpg" width="482" height="483" alt=""/>
   	      
   	     </div>
      	</div>
      	
      </div>
     </div>
    </section>
    
    <section class="Disposible-Background">
     <div class="container">
      <div class="row">
       
       <div class="col-lg-6 col-md-4 col-sm-12">
        <div class="Disposible-SCImage">
         <img class="CertifiedBadge" src="/assets/image/certified-image.png" width="251" height="258" alt=""/> 
         <img src="/assets/image/disposable-firm-image.png" width="502" height="549" alt=""/>
        </div>
       </div>
       
       <div class="col-lg-6 col-md-8 col-sm-12">
        <div class="Disposible-SCHead">
         <h1>LaptoZone is certified data destruction and disposal firm</h1>
         <p>Certified data destruction is important to guarantee the redeployment, end-of-rent, resale or removal of IT apparatus is conducted in compliance with security and administrative prerequisites. The N-1 exclusive information eradication procedure can be executed on or off-site and surpasses the Department of Defense standards (1x, 3x, 5x, or 7x overwrite). Each bit of equipment is exclusively processed, examined, and audited and upon fruitful affirmation, a certificate of data disposal, vital for compliance reporting, is issued.<br /><br /> Where exceptionally private or end-of-life hardware is concerned LaptopZone - Asset Management and Disposal in Dallas use amazingly in-depth removal procedures. These procedures may incorporate the degaussing and additionally destroying disks and components. Following the total demolition of materials, a certificate of data destruction is given for compliance reporting purposes. LaptopZone - Asset Management and Disposal discard every waste material in an ecologically mindful way as per EPA rules.</p>
        </div>
       </div>
       
      </div>
     </div>
    </section>
    <section class="SectionRecovery">
     <div class="container">
      <div class="row">
      
       <div class="col-lg-6 col-md-4 col-sm-12 d-none d-xl-none d-block d-sm-none">
      	<div class="Recovery-HeadImage">
      	 <img src="/assets/image/disposable-firm-image.png" width="502" height="549" alt=""/>
      	</div>
       </div>
      	
      	<div class="col-lg-6 col-md-8 col-sm-12">
      	 
      	 <div class="Recovery-Head">
      	  <h1>Why Value Recovery is <br />required?</h1>
      	  <p>IT asset disposal (ITAD) appears as though the end of<br /> an era for organizations dropping out the outdated<br /> gadgets. Be that as it may, the professional team at<br /> LaptopZone is here to say that we offer IT value recovery<br /> that assists organizations with enjoying your experience<br /> money recovery for their obsolete apparatus. We bring<br /> unique value to your asset removal because we can<br /> assist you with making a superior net benefit as a client.<br /> We do this through our removal procedure that<br /> encourages you: </p>
      	 </div>
      	 
      	 <div class="GrossSales">
      	  <h2>Increase gross sales</h2>
      	  <p>Yes, your value recovery process increases gross<br /> sales. LaptopZone is providing the best and<br /> exceptional services of value recovery in<br /> Dallas, TX.</p>
      	 </div>
      	 
      	 <div class="GrossSales">
      	  <h2>Cut expenses</h2>
      	  <p>On the other hand, cut expenses is another good<br /> point that comes into our mind. LaptopZone is the best solution to all of your ITAD problems.</p>
      	 </div>
      	 
      	</div>
      	
      	<div class="col-lg-6 col-md-4 col-sm-12 d-none d-sm-block">
      	 <div class="Recovery-HeadImage">
      	  <img src="/assets/image/disposable-firm-image.png" width="502" height="549" alt=""/>
      	 </div>
      	</div>
      	
      </div>
     </div>
    </section>
    
    <section class="Section-StandardServices">
     <div class="container">
      <div class="row">
      	
      	<div class="col-md-6 col-sm-12">
      	 
      	 <div class="Recovery-HeadImage1">
      	  <img src="/assets/image/we-protect-image.png" width="529" height="279" alt=""/>
      	 </div>
      	 
      	 <div class="GrossSales-two">
      	  <h2>Data cleansing</h2>
      	  <p>We destroy all private or sensitive data at the spot. We guarantee you personal data protection!</p>
      	 </div>
      	 
      	 <div class="GrossSales-two">
      	  <h2>Recycling</h2>
      	  <p>Recycle used electronics with the fastest, safest, easiest and professional services at free of cost.</p>
      	 </div>
      	 
      	</div>
      	
      	<div class="col-md-6 col-sm-12">
      	
      	 <div class="Recovery-Head-one">
      	  <h1>LaptopZone won’t charge <br />for standard services</h1>
      	  <p>Many contending ITAD organizations charge for<br /> fundamental services that are given below but we won’t<br /> charge for standard services.</p>
      	 </div>
      	 
      	 <div class="GrossSales-two Transport-mrtop">
      	  <h2>Transport</h2>
      	  <p>We provide free pick up and drop off services! Yes, our<br /> professionals will visit your place for picking up the<br /> gadgets that you don’t need anymore.</p>
      	 </div>
      	 
      	 <div class="GrossSales-two">
      	  <h2>Auditing</h2>
      	  <p>We ease our valued clients with free audit service. We<br /> have expert team members who can provide you<br /> excellent audit service.</p>
      	 </div>
      	 
      	</div>
      	
      	<div class="col-md-12 col-sm-12">
      	 <div class="Recovery-Head-one">
      	  <p>At the point when you work with LaptopZone for your ITAD, you can have confidence that we will make every effort to not charge for these above-mentioned services in most bulk removal situations. We comprehend that no two clients are the equivalent and that is the reason we are set up to customize a suggestion that meets your reqtuirements and leaves you happy with the services you have gotten toward the day's end.</p>
      	 </div>
      	</div>
      	
      </div>
     </div>
    </section>
    
    <section class="Sec-WeProtect">
     <div class="container">
      <div class="row">
      
        <div class="col-md-6 col-sm-12 d-xl-none d-block d-sm-none">
      	 
      	 <div class="Recovery-HeadImage1">
   	      <img src="/assets/image/we-protect-image.png" width="529" height="279" alt=""/>
   	     </div>
      	 
      	 <div class="GrossSales-two">
      	  <h2>LaptopZone cares about</h2>
      	  <p>New technologies that can help ensure our neighborhoods safe?</p>
      	 </div>
      	 
      	 <div class="GrossSales-two">
      	  <h2>LaptopZone cares about</h2>
      	  <p>What are the approaches to make moderate recycling<br /> for everybody?</p>
      	 </div>
      	 
      	</div>
      
        <div class="col-md-6 col-sm-12">
      	
      	 <div class="Recovery-Head-one">
      	  <h1>We protect the environment<br /> with electronics recycling<br /> and data
          destruction</h1>
      	  <p>A growing worldwide populace places expanded weight on individuals and the environment. Also, as the world develops, humankind places more prominent value on both life and the earth we as a whole offer. We accept that life and our biological system are valuable, and we're attempting to secure them.</p>
      	 </div>
      	 
      	 <div class="GrossSales-two">
      	  <h2>We care about</h2>
      	  <p>How would we be able to help keep people safe and healthy?</p>
      	 </div>
      	 
      	 <div class="GrossSales-two">
      	  <h2>We care about</h2>
      	  <p>How would we make environmentally-friendly materials?</p>
      	 </div>
      	 
      	</div>
      	
      	<div class="col-md-6 col-sm-12 d-none d-sm-block">
      	 
      	 <div class="Recovery-HeadImage1">
   	      <img src="/assets/image/we-protect-image.png" width="529" height="279" alt=""/>
   	     </div>
      	 
      	 <div class="GrossSales-two WeAbout">
      	  <h2>LaptopZone cares about</h2>
      	  <p>New technologies that can help ensure our neighborhoods safe?</p>
      	 </div>
      	 
      	 <div class="GrossSales-two">
      	  <h2>LaptopZone cares about</h2>
      	  <p>What are the approaches to make moderate recycling<br /> for everybody?</p>
      	 </div>
      	 
      	</div>      	
      	
      	<div class="col-md-12 col-sm-12">
      	 <div class="Recovery-Head-one">
      	  <p>LaptopZone is the reputed and primary provider of IT asset disposition Dallas services in Texas to give the full range of services required to safely outdated surplus PC equipment and expand money recovery. We are focused on working hard for our clients to streamline the disposition procedure while guaranteeing as much as a possible return over the whole range of IT assets.<br /> We give clients genuine feelings of serenity by giving the most in-depth tracking and reporting systems available. Each asset is irrefutably tracked from the date it got to final disposition whether it was re-promoted or dependably reused. We are a picked provider of ITAD and liquidation services.</p>
      	 </div>
      	</div>
      	
      </div>
     </div>
    </section>
    
    <section class="Sec-YourMaximize">
     <div class="container">
      <div class="row">
      
        <div class="col-lg-6 col-md-4 col-sm-12">
      	 <div class="Recovery-HeadImage2">
   	      <img src="/assets/image/we-protect-02.png" width="505" height="444" alt=""/>
   	     </div>
      	</div>  
      
        <div class="col-lg-6 col-md-8 col-sm-12">
      	 <div class="Recovery-Head-one">
      	  <h1>Recycle your used<br /> electronics and maximize<br /> your ROI with LaptopZone</h1>
      	  <p>Make the most of your old, used or unwanted electronics devices through removal and recycling services gave by LaptopZone. In case you're prepared for new IT hardware, we are ready to adopt your old technology. Contact us today to find out more about our services. Make the most of your old, used or unwanted electronics devices through removal and recycling services gave by LaptopZone. In case you're prepared for new IT hardware, we are ready to adopt your old technology. Contact us today to find out more about our services.</p>
      	 </div>
      	</div>
      	
      </div>
     </div>
    </section>
    
    <section class="EquipmentSection">
     <div class="container">
      <div class="row">
      
        <div class="col-md-6 col-sm-12 d-xl-none d-block d-sm-none">
      	 
      	 <div class="Recovery-HeadImage2">
   	      <img src="/assets/image/we-protect-image.png" width="529" height="279" alt=""/>
   	     </div>
      	 
      	 <div class="GrossSales-two">
      	  <h2>Brocade</h2>
      	  <p>Brocade is a reputed communications systems software company and originally known for its Fibre Channel storage networks.  If you have unwanted Brocade equipment; visit LaptopZone!</p>
      	 </div>
      	 
      	 <div class="GrossSales-two">
      	  <h2>Extreme Networks</h2>
      	  <p>Extreme Networks is a popular US-based networking equipment company. We accept all equipment of networking.</p>
      	 </div>
      	 
      	 <div class="GrossSales-two">
      	  <h2>Palo Alto Networks</h2>
      	  <p>Palo Alto Networks is an American multinational cybersecurity company. If you have used Palo Alto Networking equipment; bring them to us!</p>
      	 </div>
      	 
      	 <div class="GrossSales-two">
      	  <h2>Dell</h2>
      	  <p>Dell is a leading American multinational computer technology firm. If you have Dell laptops or other used devices of Dell brand; come to LaptopZone!</p>
      	 </div>
      	 
      	 <div class="GrossSales-two">
      	  <h2>Apple</h2>
      	  <p>No need for an introduction! Apple is one of the biggest brands all over the world. We accept all Apple products and pay top dollars!</p>
      	 </div>
      	 
      	 <div class="GrossSales-two">
      	  <h2>Samsung</h2>
      	  <p>Samsung is recognized group multinational conglomerate company. If you have Samsung unwanted or used cell phones or other products; come to LaptopZone</p>
      	 </div>
      	 
      	</div>
      
        <div class="col-md-6 col-sm-12">
      	
      	 <div class="Recovery-Head-one">
      	  <h1>Sell your computer <br />equipment for fast cash </h1>
      	  <p>LaptopZone is a certified electronic recycling and data demolition organization offering money for your pc and other gadgets’ equipment in the Dallas, Coppell, Plano and encompassing DFW cities in Texas. Is it true that you are prepared to sell your pre-owned smartphone gear or PC equipment at the highest price possible? Asset Management and Services makes selling your old gear a simple and high value procedure for your organization. We purchase new, used, old, unwanted or broken Networking Equipment, Server Gear, Apple Products, Test Equipment, Laptops etc.</p>
      	 </div>
      	 
      	 <div class="GrossSales-two">
      	  <h2>Cisco</h2>
      	  <p>Cisco is a renewed systems networking hardware company and they provide cybersecurity solutions. So, if you have Cisco networking equipment, bring them to LaptopZone!</p>
      	 </div>
      	 
      	 <div class="GrossSales-two">
      	  <h2>Juniper</h2>
      	  <p>Juniper is a well-known American multinational network corporation. They develop routers, switches, network management software, and network security products. Sell these products now! </p>
      	 </div>
      	 
      	 <div class="GrossSales-two">
      	  <h2>Foundry</h2>
      	  <p>Foundry equipment includes machines, apparatuses and devices that are used in foundries. You can sell effortlessly. </p>
      	 </div>
      	 
      	 <div class="GrossSales-two">
      	  <h2>Red Hat</h2>
      	  <p>Red Hat is a popular software company in the US. This is an enterprise open source solutions provider.</p>
      	 </div>
      	 
      	 <div class="GrossSales-two">
      	  <h2>EqualLogic</h2>
      	  <p>EqualLogic items are iSCSI-based storage area network systems promoted by Dell. This is a famous brand in the U.S.</p>
      	 </div>
      	 
      	 <div class="GrossSales-two">
      	  <h2>MAC</h2>
      	  <p>MAC is another big name in the U.S market. Sell used MacBook or other products of Mac for fast cash.</p>
      	 </div>
      	 
      	 <div class="GrossSales-two">
      	  <h2>HP</h2>
      	  <p>HP one of the popular brands in the U.S and we can use HP laptops frequently. For used HP laptops; LaptopZone is the best solution.</p>
      	 </div>
      	 
      	</div>
      	
      	<div class="col-md-6 col-sm-12 d-none d-sm-block">
      	 
      	 <div class="Recovery-HeadImage2">
   	      <img src="/assets/image/we-protect-image.png" width="529" height="279" alt=""/>
   	     </div>
      	 
      	 <div class="GrossSales-two Brocade-mrtop">
      	  <h2>Brocade</h2>
      	  <p>Brocade is a reputed communications systems software company and originally known for its Fibre Channel storage networks.  If you have unwanted Brocade equipment; visit LaptopZone!</p>
      	 </div>
      	 
      	 <div class="GrossSales-two">
      	  <h2>Extreme Networks</h2>
      	  <p>Extreme Networks is a popular US-based networking equipment company. We accept all equipment of networking.</p>
      	 </div>
      	 
      	 <div class="GrossSales-two">
      	  <h2>Palo Alto Networks</h2>
      	  <p>Palo Alto Networks is an American multinational cybersecurity company. If you have used Palo Alto Networking equipment; bring them to us!</p>
      	 </div>
      	 
      	 <div class="GrossSales-two">
      	  <h2>Dell</h2>
      	  <p>Dell is a leading American multinational computer technology firm. If you have Dell laptops or other used devices of Dell brand; come to LaptopZone!</p>
      	 </div>
      	 
      	 <div class="GrossSales-two">
      	  <h2>Apple</h2>
      	  <p>No need for an introduction! Apple is one of the biggest brands all over the world. We accept all Apple products and pay top dollars!</p>
      	 </div>
      	 
      	 <div class="GrossSales-two">
      	  <h2>Samsung</h2>
      	  <p>Samsung is recognized group multinational conglomerate company. If you have Samsung unwanted or used cell phones or other products; come to LaptopZone</p>
      	 </div>
      	 
      	</div>      	
      	
      </div>
     </div>
    </section>
    
    <section class="Sec-YourMaximize">
     <div class="container">
      <div class="row">
      
       <div class="col-lg-6 col-md-4 col-sm-12 d-xl-none d-block d-sm-none">
      	 <div class="Recovery-HeadImage2">
   	      <img src="/assets/image/disposable-firm-image.png" width="502" height="549" alt="" />
   	     </div>
      	</div> 
      
        <div class="col-lg-6 col-md-8 col-sm-12">
      	 <div class="Recovery-Head-one">
      	  <h1>Why should you sell your<br /> PCs hardware?</h1>
      	  <p>It is safe to say that you are prepared to sell your pre-owned network equipment for the highest price possible? Similarly, likewise with our telecom removal service, we make the task of liquidating your old network gears a simple and high-end step for your organization. Selling your old network hardware shouldn't be a tedious or labor-intensive task. We make the procedure simple. We pay the highest prices, so you never wait for your installment. The transaction is quick and simple. For your benefit, we offer instant quotes so the procedure doesn't delay. What's more, above all, we address the highest prices possible for the assets you are prepared to sell. We buy all brands of network gear, including, but not limited to Cisco, Foundry, Big 5, Nortel Dell and HP. We are a trustworthy seller/buyer and we treat each client with reliable and polite help. With LaptopZone, the process of liquidating your overflow, abundance or out of network equipment.</p>
      	 </div>
      	</div>
      
        <div class="col-lg-6 col-md-4 col-sm-12 d-none d-sm-block">
      	 <div class="Recovery-HeadImage2">
   	      <img src="/assets/image/disposable-firm-image.png" width="502" height="549" alt="" />
   	     </div>
      	</div>  
      	
      </div>
     </div>
    </section>
    
    <section class="EquipmentSection">
     <div class="container">
      <div class="row">
      
        <div class="col-md-6 col-sm-12">
      	 
      	 <div class="Recovery-HeadImage2">
   	      <img src="/assets/image/we-protect-image.png" width="529" height="279" alt=""/>
   	     </div>
      	 
      	 <div class="GrossSales-two Transport-mrtop">
      	  <h2>SOX</h2>
      	  <p>We destroy all private or sensitive data at the spot. We<br /> guarantee you personal data protection!</p>
      	 </div>
      	 
      	 <div class="GrossSales-two">
      	  <h2>SERPA</h2>
      	  <p>Recycle used electronics with the fastest, safest, easiest<br /> and professional services at free of cost.</p>
      	 </div>
      	 
      	</div>
      
        <div class="col-md-6 col-sm-12">
      	
      	 <div class="Recovery-Head-one">
      	  <h1>Why care about data<br /> security nowadays?</h1>
      	  <p>Depending on your industry, a data breach means that your company could be breaking the laws surrounding various systems. Regardless of what business you are in your equipment holds significant worker and customer data that ought to never find its way into inappropriate hands. Depending upon your industry, an information breach means that your organization could be violating the laws encompassing different systems that include:</p>
      	 </div>
      	 
      	 <div class="GrossSales-two">
      	  <h2>HIPAA</h2>
      	  <p>We provide free pick up and drop off services! Yes,<br /> our professionals will visit your place for picking<br /> up the gadgets that you don’t need anymore.</p>
      	 </div>
      	 
      	 <div class="GrossSales-two">
      	  <h2>FACTA</h2>
      	  <p>We ease our valued clients with free audit service.<br /> We have expert team members who can provide<br /> you excellent audit service.</p>
      	 </div>
      	 
      	</div>
      	
      	<div class="col-md-8 col-sm-12">
      	 <div class="Recovery-Head-one">
      	  <p>Not exclusively can inappropriate data destruction accidentally cause you to overstep the law, however, it can likewise harm your primary concern through identity theft, hackers and loss of current and prospective clients.</p>
      	 </div>
      	</div>
      	
      </div>
     </div>
    </section>
    
    <section class="Sec-ExcessGadgets">
     <div class="container">
      <div class="row">
      	
      	<div class="col-md-6 col-sm-12">
      	 <div class="CashTurn-Head">
      	  <h3>Why data destruction in <br />Dallas?</h3>
      	  <p>Everybody realizes that their tech hardware won't keep going forever. There are even organizations that have confidence in following the "out with the old, in with the new" when it comes to meeting industry gear needs. Notwithstanding how or when you choose asset removal is important, there is consistently the matter of data security and destruction that you should be worried about.</p>
      	 </div>
      	</div>
      	
      	<div class="col-md-6 col-sm-12">
      	 <div class="CashTurn-Head">
      	  <h3>Asset disposal and recycling services in <br />Dallas</h3>
      	  <p>At the point when it's a great opportunity to let your IT gear at your office go, ensure that you practice appropriate removal or reusing strategies. Throughout the years, endless organizations, clinical offices, schools, and more have been improperly discarding old PCs and comparable hardware at local dumps. Not exclusively do these materials take up precious space in landfills, however, they additionally negatively affect the environment.</p>
      	 </div>
      	</div>
      	
      	<div class="col-md-6 col-sm-12">
      	 <div class="CashTurn-Head">
      	  <h3>PC disposal has never<br /> been secure or stress-free</h3>
      	  <p>Technology has gotten one of the most significant domains of any business. Thus, when it comes time to obsolete your old gear, you will need a simple and safe approach to discard your technology. Some hardware might not have resale esteem or basically can't work all around ok to be given. We give removal benefits that completely stick to EPA and government administrative rules concerning electronic waste or e-waste.</p>
      	 </div>
      	</div>
      	
      	<div class="col-md-6 col-sm-12">
      	 <div class="CashTurn-Head">
      	  <h3>Turn your excess gadgets<br /> into cash</h3>
      	  <p>Our PC reusing services can build your productivity and boost your ROI. Try not to store your old hardware in a warehouse or closet where it benefits nobody. By turning in your old gear to LaptopZone, your business could have the chance to make some money on these old items.</p>
      	 </div>
      	</div>
      	
      </div>
     </div>
    </section>
    
    <section class="EquipmentSection">
     <div class="container">
      <div class="row">
      
        <div class="col-lg-6 col-md-4 col-sm-12 d-xl-none d-block d-sm-none">
      	 <div class="Recovery-HeadImage2">
   	      <img src="/assets/image/disposable-firm-image.png" width="502" height="549" alt="" />
   	     </div>
      	</div>      
      
        <div class="col-lg-6 col-md-8 col-sm-12">
      	
      	 <div class="Recovery-Head-one">
      	  <h1>We also recycle used<br /> computers, laptops, phones<br /> and more</h1>
      	  <p>Not exclusively will LaptopZone take your PCs for removal, however, we additionally acknowledge other hardware like:</p>
      	 </div>
      	 
      	 <div class="GrossSales-two">
      	  <h2>Cell Phones</h2>
      	  <p>We accept all brands of cell phones for recycling purposes. So, recycle used iPhones and save the environment.</p>
      	 </div>
      	 
      	 <div class="GrossSales-two">
      	  <h2>Dell/HP Laptops</h2>
      	  <p>You can also recycle used Dell/HP laptops. We give you pick up and drop off services just to ease the people of Dallas.</p>
      	 </div>
      	 
      	 <div class="GrossSales-two">
      	  <h2>Modems and Monitors</h2>
      	  <p>We also accept modems, monitors and other related equipment in Dallas, Coppell, and Plano.</p>
      	 </div>
      	 
      	 <div class="GrossSales-two">
      	  <h2>Servers and Telecommunication Equipment</h2>
      	  <p>On the other hand, if you have unwanted servers, networking or telecommunication equipment, just come to LaptopZone and recycle.</p>
      	 </div>
      	 
      	</div>
      	
      	<div class="col-lg-6 col-md-4 col-sm-12 d-none d-sm-block">
      	 <div class="Recovery-HeadImage2">
   	      <img src="/assets/image/disposable-firm-image.png" width="502" height="549" alt="" />
   	     </div>
      	</div>
      	
      </div>
     </div>
    </section>
   
     </React.Fragment>
    );
  }
}
export default AssetsManagement;
