import React from "react";
import { posturl } from "./configuration.js";
import $ from "jquery";
var that = "";

class PlanoCity extends React.Component {
  constructor(props) {
    var finalurl = posturl;
    super(props);
    that = this;
    this.state = {
      baseUrl: finalurl
    };
  }
  changeTab =(para) =>{
      if(para == 'one'){
          $('#one').addClass('show active');
          $('#two').removeClass('show active');
          $('#three').removeClass('show active');

          $('#one-tab').addClass('active');
          $('#two-tab').removeClass('active');
          $('#three-tab').removeClass('active');
      }else if(para == 'two'){
        $('#one').removeClass('show active');
        $('#two').addClass('show active');
        $('#three').removeClass('show active');

        $('#one-tab').removeClass('active');
          $('#two-tab').addClass('active');
          $('#three-tab').removeClass('active');
      }else{
        $('#one').removeClass('show active');
          $('#two').removeClass('show active');
          $('#three').addClass('show active');

          $('#one-tab').removeClass('active');
          $('#two-tab').removeClass('active');
          $('#three-tab').addClass('active');
      }

  }
  render() {
    return <React.Fragment>
           <section className="SectionMacBook">
    <img src="/assets/image/mac-banner.png" alt="" />
    
    <div className="Macbook-InnerContent">
     <div className="container">
      <div className="row">
      	
       <div className="col-md-6 col-sm-12">
       
       	<div className="UsedMacbook">
       	 <h1>Sell Your Used Electronics</h1>
       	 <br />
       	 <p>The best place to sell your used or unwanted electronic devices at the best possible prices!</p>
       	</div>
       	
       	<div className="MackbookForms">
       	 <form action="#" method="#">
       	  <input type="text" name="" id="" value="" placeholder="eg. Macbook Pro 450" />
       	  <button type="submit" value="">Get A Quote</button>
       	 </form>
       	</div>
       	
       </div>
       
       <div className="col-md-6 col-sm-12">
       	<div className="MacbookImage">
       	 <img src="/assets/image/mackbook-image.png" alt=""/>
       	</div>
       </div>
      	
      </div>
     </div>
    </div> 
       
   </section>
       
   <section className="SectionSell-Gadget">
   	<div className="container">
   	 <div className="row">
   	 	
   	  <div className="col-md-12 col-sm-12">
   	   <h2>Welcome to Our Top Services </h2>
   	  </div>
   	  
   	  <div className="col-md-4 col-sm-12">
   	   <div className="GetQuote-Container">
   	   	
   	   	<div className="GetIcon-Box">
   	   	 <i className="icon-send-quote-icon"></i>
   	   	</div>
   	   	
   	   	<div className="GetContent-Btm">
   	   	 <h3>Repairing</h3>
   	   	 <p>Repairing saves money and time. Bring broken gadgets and enjoy the fastest repairing services.</p>
               <a href="/repairing">Get a Quote</a>
   	   	</div>
   	   	
   	   </div>
   	  </div>
   	  
   	  <div className="col-md-4 col-sm-12">
   	   <div className="GetQuote-Container">
   	   	
   	   	<div className="GetIcon-Box">
   	   	 <i className="icon-send-quote-icon"></i>
   	   	</div>
   	   	
   	   	<div className="GetContent-Btm">
   	   	 <h3>Recycling</h3>
   	   	 <p>Bring your unwanted electronics, we recycle them, and you save the environment.</p>
               <a href="/recycling">Get a Quote</a>
   	   	</div>
   	   	
   	   </div>
   	  </div>
   	  
   	  <div className="col-md-4 col-sm-12">
   	   <div className="GetQuote-Container">
   	   	
   	   	<div className="GetIcon-Box">
   	   	 <i className="icon-send-quote-icon"></i>
   	   	</div>
   	   	
   	   	<div className="GetContent-Btm">
   	   	 <h3>Buy / Sell</h3>
   	   	 <p>Best store to sell or trade your used electronics! Get Instant Cash at the Spot.</p>
               <a href="/sell">Get a Quote</a>
   	   	</div>
   	   	
   	   </div>
   	  </div>
   	  
   	  {/* <div className="col-md-12 col-sm-12">
   	   <div className="GetQuote-Button">
   	   	<a href="#">Get a Quote</a>
   	   </div>
   	  </div> */}
   	 	
   	 </div>
   	</div>
   </section>
   
   <section className="Section-AboutLaptop">
   	<div className="container">
   	 <div className="row">
   	 
   	  <div className="col-md-12 col-sm-12">
   	   <div className="SellHeading">
   	   	<h4>We are Your One-Stop Solution</h4>
   	   </div>
   	  </div>
   	 	
   	  <div className="col-md-12 col-sm-12">
   	   <div className="tab" role="tabpanel">
        
        <div className="tab-card">
        
         <div className="card-header tab-card-header">
          <ul className="nav nav-tabs card-header-tabs" id="myTab" role="tablist">
           
            <li className="nav-item">
             <a className="nav-link active" id="one-tab" data-toggle="tab"  onClick={() => this.changeTab('one')} role="tab" aria-controls="One" aria-selected="true">How to sell my electronics?</a>
            </li>
            
            <li className="nav-item">
             <a className="nav-link" id="two-tab" data-toggle="tab"  role="tab" onClick={ () => this.changeTab('two')} aria-controls="Two" aria-selected="false">Why repairing is the best option?</a>
            </li>
            
            <li className="nav-item">
             <a className="nav-link" id="three-tab" data-toggle="tab"  role="tab" onClick={ () => this.changeTab('three')} aria-controls="Three" aria-selected="false">Can I recycle my unwanted electronics?</a>
            </li>
            
          </ul>
         </div>

         <div className="tab-content" id="myTabContent">
         
          <div className="tab-pane fade show active" id="one" role="tabpanel" aria-labelledby="one-tab">
           <div className="row">
           	
           	<div className="col-md-8 col-sm-12">
           	 <div className="TabAll-Content">
           	  
           	  <p>We buy all Apple, Samsung, tablet pc’s, cell phones, watches and other high-end electronics in Plano. Our selling process is quite simple, secure and fast. Bring your used or unwanted electronics to us and get fast cash at the spot. You can sell your iPhones, MacBook, laptops, iPads, iPods, tablets, Apple watches, networking accessories etc.</p>
           	  
           	  <p>The best part of this, we pay you top dollars for your used devices. So, make a call, get a quote, visit our store, sell your unwanted electronics and get fast cash.</p>
           	  
           	 </div>
           	</div>
           	
           	<div className="col-md-4 col-sm-12">
           	 <div className="TabAbout-Image">
           	  <img src="/assets/image/mackbook-image.png" alt="" />
           	 </div>
           	</div>
           	
           </div>
          </div>
          
          <div className="tab-pane fade" id="two" role="tabpanel" aria-labelledby="two-tab">
           <div className="row">
           	
           	<div className="col-md-8 col-sm-12">
           	 <div className="TabAll-Content">
           	  
           	  <p>No doubt, repair saves you money as well as saves the environment. The best thing about repairing, it connects us to our valuable things. So, ditch the throwaway economy! If you don’t lose your old or broken gadgets or can’t afford to buy a new device, bring your broken gadgets, our experts will repair your electronics at affordable rates.</p>
           	  
           	  <p>Yes, our electronics repairing services are the fastest, safest, cheapest, so make a call, get a quote, visit our store, enjoy the quickest repairing service and make your device new again.</p>
           	  
           	 </div>
           	</div>
           	
           	<div className="col-md-4 col-sm-12">
           	 <div className="TabAbout-Image">
           	  <img src="/assets/image/mackbook-image.png" alt="" />
           	 </div>
           	</div>
           	
           </div>
          </div>
          
          <div className="tab-pane fade" id="three" role="tabpanel" 
           aria-labelledby="three-tab">
           <div className="row">
           	
           	<div className="col-md-8 col-sm-12">
           	 <div className="TabAll-Content">
           	  
           	  <p>Yes, you have the right to recycle your own gadgets if they are useless to you. Without any uncertainty, recycling saves the environment and it’s our prime responsibility to save the planet and make it greener. Try electronic recycling in Plano and bring your unwanted or useless gadgets or get our pick-up service. Yes, we can pick up your electronic stuff from your doorstep.</p>
           	  
           	  <p>So, don’t throw your old or broken electronics into the trash, just recycle and get the best and fastest electronics recycling services from us at any time.</p>
           	  
           	 </div>
           	</div>
           	
           	<div className="col-md-4 col-sm-12">
           	 <div className="TabAbout-Image">
           	  <img src="/assets/image/mackbook-image.png" alt="" />
           	 </div>
           	</div>
           	
           </div>
          </div>

         </div>
        
        </div>
        
       </div>
   	  </div>	
   	 	
   	 </div>
   	</div>
   </section>
       
     
     <section className="RecylingAbout" id="AboutUs">
    <div className="container">
     <div className="row">
      	
      <div className="col-md-12 col-sm-12">
       <div className="RecylingHeading">
      	<h2>Who We <span>Are</span></h2>
        <p>Professional, Reliable and Fastest Services<br /> for Electronics Selling/Buying, Repairing or Recycling</p>
       </div>
      </div>
      	
      <div className="col-md-6 col-sm-6">
      	 
       <div className="Recylingbt-Content">
      	<h3>About Us</h3>
      	<p>We are running a small business that offers high-end services for your used, broken or old electronic devices. We are your one-stop solution to buy, sell, repair and recycle home or business electronic devices. We have bought, sold, repaired and recycled hundreds of electronics and have become recognized as Plano’s place to buy, sell and trade electronics ever since.<br /><br />We provide the fastest, safest, easiest, and fair way for valued consumers to buy, sell, and trade electronics. Our company pays profound attention to local communities and help to make a difference by recycling useless or unwanted electronics properly.</p>
       </div>
      	 
       <h4 className="UseAllHeading">What we do</h4>
      	 
       <div className="RecyleContainer">
      	<div className="row">
      	  
      	 <div className="col-md-3 col-sm-12">
      	  <div className="RecylePC_Left">
      	   <i className="icon-tablet_icon"></i>
      	  </div>
      	 </div>
      	  	
      	 <div className="col-md-9 col-sm-12">
      	  <div className="RecylePC_Right">
      	   <h4>Recycling</h4>
      	   <p>We help you with your e-waste and electronics recycling needs as well as we assure your personal data protection! Join hands for a better and greener environment.</p>
      	  </div>
      	 </div>
      	  	
        </div>
       </div>
      	 
       <div className="RecyleContainer">
      	<div className="row">
      	  
      	 <div className="col-md-3 col-sm-12">
      	  <div className="RecylePC_Left">
      	   <i className="icon-tablet_icon"></i>
      	  </div>
      	 </div>
      	  	
      	 <div className="col-md-9 col-sm-12">
      	  <div className="RecylePC_Right">
      	   <h4>Repairing</h4>
      	   <p>You have the right to repair your own electronics because the repair is freedom and saves money! We repair your broken dell/hp laptops and repair cracked MacBook's screen in Plano.</p>
      	  </div>
      	 </div>
      	  	
        </div>
       </div>
      	 
       <div className="RecyleContainer">
      	<div className="row">
      	  
      	 <div className="col-md-3 col-sm-12">
      	  <div className="RecylePC_Left">
      	   <i className="icon-tablet_icon"></i>
      	  </div>
      	 </div>
      	  	
      	 <div className="col-md-9 col-sm-12">
      	  <div className="RecylePC_Right">
      	   <h4>Buy/Sell</h4>
      	   <p>We will buy all electronics like sell MacBook in Plano, you have stocked up, useless for you or you're unsure about them. Sell your unwanted electronics for an instant at the spot.</p>
      	  </div>
      	 </div>
      	  	
        </div>
       </div>
      	       	 
      </div>
      	
      <div className="col-md-6 col-sm-6">
      	 
       <h4 className="UseAllHeading">Why choose us</h4>
      	 
       <div className="RecyleContainer">
      	<div className="row">
      	  
      	 <div className="col-md-3 col-sm-12">
      	  <div className="RecylePC_Left">
      	   <i className="icon-tablet_icon"></i>
      	  </div>
      	 </div>
      	  	
      	 <div className="col-md-9 col-sm-12">
      	  <div className="RecylePC_Right">
      	   <h4>Prime Location</h4>
      	   <p>If you are looking for a reliable and economical electronic selling/buying store, you are in the right place. We got a prime location for our business, you can easily approach us.</p>
      	  </div>
      	 </div>
      	  	
        </div>
       </div>
      	 
       <div className="RecyleContainer">
      	<div className="row">
      	  
      	 <div className="col-md-3 col-sm-12">
      	  <div className="RecylePC_Left">
      	   <i className="icon-tablet_icon"></i>
      	  </div>
      	 </div>
      	  	
      	 <div className="col-md-9 col-sm-12">
      	  <div className="RecylePC_Right">
      	   <h4>Doorstep, Pick-up Service</h4>
      	   <p>Are you planning to trash your unwanted electronics? Wait, don’t throw your used electronics into the trash, contact us, we will pick-up your useless electronics from your doorstep.</p>
      	  </div>
      	 </div>
      	  	
        </div>
       </div>
      	 
       <div className="RecyleContainer">
      	<div className="row">
      	  
      	 <div className="col-md-3 col-sm-12">
      	  <div className="RecylePC_Left">
      	   <i className="icon-tablet_icon"></i>
      	  </div>
      	 </div>
      	  	
      	 <div className="col-md-9 col-sm-12">
      	  <div className="RecylePC_Right">
      	   <h4>Fast and Safe Services</h4>
      	   <p>We provide the fastest and safest services to sell your broken laptop for cash in Plano and computer repairing. We give you outstanding selling and buying services in a friendly environment.</p>
      	  </div>
      	 </div>
      	  	
        </div>
       </div>
      	 
       <div className="RecyleContainer">
      	<div className="row">
      	  
      	 <div className="col-md-3 col-sm-12">
      	  <div className="RecylePC_Left">
      	   <i className="icon-tablet_icon"></i>
      	  </div>
      	 </div>
      	  	
      	 <div className="col-md-9 col-sm-12">
      	  <div className="RecylePC_Right">
      	   <h4>We Pay You Top $$$</h4>
      	   <p>Sell your used for top dollars! Yes, we are paying $$$ at the spot for your unwanted  gadgets. We have hundreds of satisfied customers who consider us while selling their old electronics.</p>
      	  </div>
      	 </div>
      	  	
        </div>
       </div>
      	 
      </div>
      	
     </div>
    </div>
   </section>
      
   <div className="ShopMr-Btm"></div>


    </React.Fragment>;
  }
}
export default PlanoCity;
