import React, { Component } from 'react'; 
import $ from 'jquery';

import Map from  "./map.js";
import { posturl } from   "./configuration.js";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from './getSrc.js'

class MapLoad extends React.Component{

	constructor (props) {

    var finalurl = posturl;

    super(props);
    
     // /that = this;
    this.state = {
      baseUrl: finalurl,
      currentLatLng: {
        
        lat: 32.8946109,
    	lng: -96.88679239999999
      },
      address: '',
      showMap:'hide',
      geocodeResults: null,
      loading: false
    }

    this.handleSelect = this.handleSelect.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.renderGeocodeFailure = this.renderGeocodeFailure.bind(this)
    this.renderGeocodeSuccess = this.renderGeocodeSuccess.bind(this)
    
    
	}

	 handleSelect(address) {
    this.setState({
      address,
      loading: true,
      showMap:'hide',
    })

    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        console.log('Success Yay', { lat, lng })
        this.setState({
          geocodeResults: this.renderGeocodeSuccess(lat, lng),
          currentLatLng: {
		        lat: lat,
		        lng: lng
		      },
		      	showMap:'show',
		        loading: false
        })
      })
      .catch((error) => {
        console.log('Oh no!', error)
        this.setState({
          geocodeResults: this.renderGeocodeFailure(error),
          loading: false
        })
      })

    /* NOTE: Using callback (Deprecated version) */
    // geocodeByAddress(address,  (err, { lat, lng }) => {
    //   if (err) {
    //     console.log('Oh no!', err)
    //     this.setState({
    //       geocodeResults: this.renderGeocodeFailure(err),
    //       loading: false
    //     })
    //   }
    //   console.log(`Yay! got latitude and longitude for ${address}`, { lat, lng })
    //   this.setState({
    //     geocodeResults: this.renderGeocodeSuccess(lat, lng),
    //     loading: false
    //   })
    // })
  }
  handleChange(address) {
  	//alert(address);
    this.setState({
      address,
      geocodeResults: null
    })
  }

  renderGeocodeFailure(err) {
    return (
      <div className="alert alert-danger" role="alert">
        <strong>Error!</strong> {err}
      </div>
    )
  }

  renderGeocodeSuccess(lat, lng) {
    return (
      <div className="alert alert-success" role="alert">
        <strong>Success!</strong> Geocoder found latitude and longitude: <strong>{lat}, {lng}</strong>
      </div>
    )
  }

	render(){
	
	const cssClasses = {
      root: 'form-group',
      input: 'Demo__search-input',
      autocompleteContainer: 'Demo__autocomplete-container',
    }

    const AutocompleteItem = ({ formattedSuggestion }) => (
      <div className="Demo__suggestion-item">
        <i className='fa fa-map-marker Demo__suggestion-icon'/>
        <strong>{formattedSuggestion.mainText}</strong>{' '}
        <small className="text-muted">{formattedSuggestion.secondaryText}</small>
      </div>)

    const inputProps = {
      type: "text",
      value: this.state.address,
      onChange: this.handleChange,
      onBlur: () => { console.log('Blur event!'); },
      onFocus: () => { console.log('Focused!'); },
      placeholder: "Search Places",
      name: 'Demo__input',
      id: "my-input-id",
    }
                   
	return(

		<React.Fragment>


			<div className='page-wrapper'>
		        
		        <div className='container'>
		          <PlacesAutocomplete
		            onSelect={this.handleSelect}
		            autocompleteItem={AutocompleteItem}
		            onEnterKeyDown={this.handleSelect}
		            

		            input={
		              ({...props}) => React.createElement('input', props)
		            }
		            inputProps={inputProps}
		          />
		          {this.state.loading ? <div><i className="fa fa-spinner fa-pulse fa-3x fa-fw Demo__spinner" /></div> : null}
		          {!this.state.loading && this.state.geocodeResults ?
		            <div className='geocoding-results'>{this.state.geocodeResults}</div> :
		          null}
		        </div>
		      </div>
{this.state.showMap == 'show' ? (
			<div style ={{ margin: '50px'}}>
			<Map google = {this.props.google}
				 center={{lat:this.state.currentLatLng.lat,lng:this.state.currentLatLng.lng}}
				 
				 height = '300px'
				 zoom = {15}/>
			</div>
			) : null}

		</React.Fragment>
		);
	}
}
export default MapLoad;