import React, { Component } from "react";
import $ from "jquery";
import PageTitles from "./pageTitles";
//import add from "./configfile.js";
import "react-phone-number-input/style.css";
import PhoneInput, {
  isValidPhoneNumber,
  parsePhoneNumber,
  formatPhoneNumber
} from "react-phone-number-input";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { posturl } from "./configuration.js";
import FileUploadWithPreview from "file-upload-with-preview";
// import "file-upload-with-preview/dist/file-upload-with-preview.min.css";
//import ReactDOM from 'react-dom';
//jQuery(function(){
var that = "";
var upload = "";
// upload = new FileUploadWithPreview("myUniqueUploadId");
$(document).ready(function() {
  // var upload = new FileUploadWithPreview("myUniqueUploadId");

  console.log(upload);
  $(".targetDiv").hide();
  var finalurl = posturl;

  //$(".targetDiv").show();
  // $(".AllButtons .AllSteps").removeClass("active");
  // $(".AllButtons .target_1").addClass("active");
  // $(".targetDiv_1").show();
  $(".showSingle").click(function() {
    $(".targetDiv").hide();
    var clickDiv = $(this).attr("target");
    if (clickDiv == 1) {
      $(".targetDiv").hide();
      $(".AllButtons .AllSteps").removeClass("active");
      $(".AllButtons .target_1").addClass("active");
      $(".targetDiv_1").show();
    } else if (clickDiv == 2) {
      var get_product_name = $("#product_name").val();

      if (
        get_product_name == null ||
        get_product_name == "" ||
        get_product_name == "undefined"
      ) {
        $(".targetDiv").hide();
        $(".AllButtons .AllSteps").removeClass("active");
        $(".AllButtons .target_1").addClass("active");
        $(".targetDiv_1").show();
      } else {
        $(".targetDiv").hide();
        $(".AllButtons .AllSteps").removeClass("active");
        $(".AllButtons .target_1").addClass("active");
        $(".AllButtons .target_2").addClass("active");

        let insertUrl =
          finalurl + "/laptopzone/reactcontroller/c_react/ljw_Brands";
        var ljw_getobject = []; //'adi;l asada  yahoo';

        new Promise(function(resolve, reject) {
          $.ajax({
            url: insertUrl,
            dataType: "json",
            type: "POST",
            data: { get_product_name: get_product_name }
          }).then(
            function(data) {
              resolve(data);
            },
            function(err) {
              reject(err);
            }
          );
        })
          .then(result => {
            if (result.exist) {
              var length = result.ljw_getobject.length;

              for (var i = 0; i < length; i++) {
                //console.log(i);
                ljw_getobject.push({
                  brand_name: result.ljw_getobject[i].BRAND_NAME,
                  brand_url: result.ljw_getobject[i].BRAND_URL,
                  brand_dt_id: result.ljw_getobject[i].BRAND_DT_ID
                });
              }

              $(".targetDiv_2").show();
              //$('#div'+$(this).attr('target')).show();
              that.setState({
                Brands: ljw_getobject
              });
            } else {
            }
          })
          .catch(err => {
            //$.LoadingOverlay("hide");
            console.log(err);
          });
      }
    } else if (clickDiv == 3) {
      let insertUrl =
        finalurl + "/laptopzone/reactcontroller/c_react/ljw_Series";
      var get_brand_name = $("#brand_name").val();
      var get_product_name = $("#product_name").val();
      if (
        get_product_name == null ||
        get_product_name == "" ||
        get_product_name == "undefined"
      ) {
        $(".targetDiv").hide();
        $(".AllButtons .AllSteps").removeClass("active");
        $(".AllButtons .target_1").addClass("active");
        $(".targetDiv_1").show();
        // return false;
      } else if (
        get_brand_name == null ||
        get_brand_name == "" ||
        get_brand_name == "undefined"
      ) {
        $(".targetDiv").hide();
        $(".AllButtons .AllSteps").removeClass("active");
        $(".AllButtons .target_1").addClass("active");
        $(".AllButtons .target_2").addClass("active");
        $(".targetDiv_2").show();
      } else {
        $(".targetDiv").hide();
        $(".AllButtons .AllSteps").removeClass("active");
        $(".AllButtons .target_1").addClass("active");
        $(".AllButtons .target_2").addClass("active");
        $(".AllButtons .target_3").addClass("active");
        var ljw_Series = []; //'adi;l asada  yahoo';
        new Promise(function(resolve, reject) {
          $.ajax({
            url: insertUrl,
            dataType: "json",
            type: "POST",
            data: { get_brand_name: get_brand_name }
          }).then(
            function(data) {
              resolve(data);
            },
            function(err) {
              reject(err);
            }
          );
        })
          .then(result => {
            if (result.exist) {
              var length = result.ljw_Series.length;

              for (var i = 0; i < length; i++) {
                //console.log(i);
                ljw_Series.push({
                  series_name: result.ljw_Series[i].SERIES_NAME,
                  series_dt_id: result.ljw_Series[i].SERIES_DT_ID
                  // brand_url: result.ljw_Series[i].BRAND_URL,
                  // brand_dt_id: result.ljw_Series[i].BRAND_DT_ID,
                });
              }

              $(".targetDiv_3").show();
              //$('#div'+$(this).attr('target')).show();
              that.setState({
                SeriesName: ljw_Series
              });
            } else {
            }
          })
          .catch(err => {
            //$.LoadingOverlay("hide");
            console.log(err);
          });
      }
    } else if (clickDiv == 4) {
      let insertUrl =
        finalurl + "/laptopzone/reactcontroller/c_react/ljw_Model";
      var get_product_name = $("#product_name").val();
      var get_brand_name = $("#brand_name").val();
      var get_series_name = $("#series_name").val();

      //console.log(get_brand_name);
      if (
        get_product_name == null ||
        get_product_name == "" ||
        get_product_name == "undefined"
      ) {
        $(".targetDiv").hide();
        $(".AllButtons .AllSteps").removeClass("active");
        $(".AllButtons .target_1").addClass("active");
        $(".targetDiv_1").show();
      } else if (
        get_brand_name == null ||
        get_brand_name == "" ||
        get_brand_name == "undefined"
      ) {
        $(".targetDiv").hide();
        $(".AllButtons .AllSteps").removeClass("active");
        $(".AllButtons .target_1").addClass("active");
        $(".AllButtons .target_2").addClass("active");
        $(".targetDiv_2").show();
        // alert('select Brand First');
        // return false;
      } else if (
        get_series_name == null ||
        get_series_name == "" ||
        get_series_name == "undefined"
      ) {
        $(".targetDiv").hide();
        $(".AllButtons .AllSteps").removeClass("active");
        $(".AllButtons .target_1").addClass("active");
        $(".AllButtons .target_2").addClass("active");
        $(".AllButtons .target_3").addClass("active");
        $(".targetDiv_3").show();
        // alert('select Series First');
        // return false;
      } else {
        $(".targetDiv").hide();
        $(".AllButtons .AllSteps").removeClass("active");
        $(".AllButtons .target_1").addClass("active");
        $(".AllButtons .target_2").addClass("active");
        $(".AllButtons .target_3").addClass("active");
        $(".AllButtons .target_4").addClass("active");

        var ljw_Model = []; //'adi;l asada  yahoo';

        new Promise(function(resolve, reject) {
          $.ajax({
            url: insertUrl,
            dataType: "json",
            type: "POST",
            data: { get_series_name: get_series_name }
          }).then(
            function(data) {
              resolve(data);
            },
            function(err) {
              reject(err);
            }
          );
        })
          .then(result => {
            if (result.exist) {
              var length = result.ljw_Model.length;

              for (var i = 0; i < length; i++) {
                //console.log(i);
                ljw_Model.push({
                  description: result.ljw_Model[i].DESCRIPTION,
                  model_dt_id: result.ljw_Model[i].MODEL_DT_ID,
                  model_url: result.ljw_Model[i].IMAGE_URL
                  // brand_url: result.ljw_Model[i].BRAND_URL,
                  // brand_dt_id: result.ljw_Model[i].BRAND_DT_ID,
                });
              }

              $(".targetDiv_4").show();
              //$('#div'+$(this).attr('target')).show();
              that.setState({
                ModelName: ljw_Model
              });
            } else {
            }
          })
          .catch(err => {
            //$.LoadingOverlay("hide");
            console.log(err);
          });
      }
    } else if (clickDiv == 5) {
      let insertUrl =
        finalurl + "/laptopzone/reactcontroller/c_react/ljw_Issues";
      var get_product_name = $("#product_name").val();
      var get_brand_name = $("#brand_name").val();
      var get_series_name = $("#series_name").val();
      var get_model_names = $("#model_names").val();

      //console.log(get_brand_name);
      if (
        get_product_name == null ||
        get_product_name == "" ||
        get_product_name == "undefined"
      ) {
        $(".targetDiv").hide();
        $(".AllButtons .AllSteps").removeClass("active");
        $(".AllButtons .target_1").addClass("active");
        $(".targetDiv_1").show();
        // alert('select Product First');
        // return false;
      } else if (
        get_brand_name == null ||
        get_brand_name == "" ||
        get_brand_name == "undefined"
      ) {
        $(".targetDiv").hide();
        $(".AllButtons .AllSteps").removeClass("active");
        $(".AllButtons .target_1").addClass("active");
        $(".AllButtons .target_2").addClass("active");
        $(".targetDiv_2").show();
        // alert('select Brand First');
        // return false;
      } else if (
        get_series_name == null ||
        get_series_name == "" ||
        get_series_name == "undefined"
      ) {
        $(".targetDiv").hide();
        $(".AllButtons .AllSteps").removeClass("active");
        $(".AllButtons .target_1").addClass("active");
        $(".AllButtons .target_2").addClass("active");
        $(".AllButtons .target_3").addClass("active");
        $(".targetDiv_3").show();
        // alert('select Series First');
        // return false;
      } else if (
        get_model_names == null ||
        get_model_names == "" ||
        get_model_names == "undefined"
      ) {
        $(".targetDiv").hide();
        $(".AllButtons .AllSteps").removeClass("active");
        $(".AllButtons .target_1").addClass("active");
        $(".AllButtons .target_2").addClass("active");
        $(".AllButtons .target_3").addClass("active");
        $(".AllButtons .target_4").addClass("active");
        $(".targetDiv_4").show();
        // alert('select Model First');
        // return false;
      } else {
        $(".targetDiv").hide();
        $(".AllButtons .AllSteps").removeClass("active");
        $(".AllButtons .target_1").addClass("active");
        $(".AllButtons .target_2").addClass("active");
        $(".AllButtons .target_3").addClass("active");
        $(".AllButtons .target_4").addClass("active");
        $(".AllButtons .target_5").addClass("active");

        var ljw_Issues = []; //'adi;l asada  yahoo';

        new Promise(function(resolve, reject) {
          $.ajax({
            url: insertUrl,
            dataType: "json",
            type: "POST",
            data: { get_product_name: get_product_name }
          }).then(
            function(data) {
              resolve(data);
            },
            function(err) {
              reject(err);
            }
          );
        })
          .then(result => {
            if (result.exist) {
              var length = result.ljw_Issues.length;

              for (var i = 0; i < length; i++) {
                //console.log(i);
                ljw_Issues.push({
                  issu_name: result.ljw_Issues[i].ISSU_NAME,
                  issue_dt_id: result.ljw_Issues[i].ISSUE_DT_ID
                  // brand_url: result.ljw_Issues[i].BRAND_URL,
                  // brand_dt_id: result.ljw_Issues[i].BRAND_DT_ID,
                });
              }

              $(".targetDiv_5").show();
              //$('#div'+$(this).attr('target')).show();
              that.setState({
                IssuesName: ljw_Issues
              });
            } else {
            }
          })
          .catch(err => {
            //$.LoadingOverlay("hide");
            console.log(err);
          });
      }
    } else if (clickDiv == 6) {
      // $(".targetDiv").hide();
      // $(".AllButtons .target_6").addClass("active");
      // $(".targetDiv_6").show();
      // return false;
      var get_product_name = $("#product_name").val();
      var get_brand_name = $("#brand_name").val();
      var get_series_name = $("#series_name").val();
      var get_model_names = $("#model_names").val();
      var get_issues_names = $("#issues_name").val();
      if (
        get_product_name == null ||
        get_product_name == "" ||
        get_product_name == "undefined"
      ) {
        $(".targetDiv").hide();
        $(".AllButtons .AllSteps").removeClass("active");
        $(".AllButtons .target_1").addClass("active");
        $(".targetDiv_1").show();
        // alert('select Product First');
        // return false;
      } else if (
        get_brand_name == null ||
        get_brand_name == "" ||
        get_brand_name == "undefined"
      ) {
        $(".targetDiv").hide();
        $(".AllButtons .AllSteps").removeClass("active");
        $(".AllButtons .target_1").addClass("active");
        $(".AllButtons .target_2").addClass("active");
        $(".targetDiv_2").show();
        // alert('select Brand First');
        // return false;
      } else if (
        get_series_name == null ||
        get_series_name == "" ||
        get_series_name == "undefined"
      ) {
        $(".targetDiv").hide();
        $(".AllButtons .AllSteps").removeClass("active");
        $(".AllButtons .target_1").addClass("active");
        $(".AllButtons .target_2").addClass("active");
        $(".AllButtons .target_3").addClass("active");
        $(".targetDiv_3").show();
        // alert('select Series First');
        // return false;
      } else if (
        get_model_names == null ||
        get_model_names == "" ||
        get_model_names == "undefined"
      ) {
        $(".targetDiv").hide();
        $(".AllButtons .AllSteps").removeClass("active");
        $(".AllButtons .target_1").addClass("active");
        $(".AllButtons .target_2").addClass("active");
        $(".AllButtons .target_3").addClass("active");
        $(".AllButtons .target_4").addClass("active");
        $(".targetDiv_4").show();
        // alert('select Model First');
        // return false;
      } else if (
        get_issues_names == null ||
        get_issues_names == "" ||
        get_issues_names == "undefined"
      ) {
        $(".targetDiv").hide();
        $(".AllButtons .AllSteps").removeClass("active");
        $(".AllButtons .target_1").addClass("active");
        $(".AllButtons .target_2").addClass("active");
        $(".AllButtons .target_3").addClass("active");
        $(".AllButtons .target_4").addClass("active");
        $(".AllButtons .target_5").addClass("active");
        $(".targetDiv_5").show();
      } else {
        $(".targetDiv").hide();
        $(".AllButtons .target_6").addClass("active");
        $(".targetDiv_6").show();
      }
    }
  });
});

// $(document).ready(function(){
//      $('.AllButtons .AllSteps').click(function(){
//      $('.AllButtons .AllSteps').removeClass("active");
//      $(this).addClass("active");
//     });
//    });

// $(document).ready(function(){
//      $('.BrandImage img').click(function(){
//      $('.BrandImage img').removeClass("active");
//      $(this).addClass("active");
//     });
//    });

// $(document).ready(function(){
//      $('.SeriesSelected label').click(function(){
//      $('.SeriesSelected label').removeClass("active");
//      $(this).addClass("active");
//     });
//    });

// $(document).ready(function(){
//      $('.SelectModal').click(function(){
//      $('.SelectModal').removeClass("active");
//      $(this).addClass("active");
//     });
//    });

$(document).ready(function() {
  $(".DesktopPc").click(function() {
    $(".DesktopPc").removeClass("active");
    $(this).addClass("active");
  });
});

// function changeproduct(productName){
//   $("#product").text(productName);
//    }

function changeouterproduct(outerproductName) {
  $("#outerproduct").text(outerproductName);
}

// $(document).ready(function() {
//      $('.showmenu').click(function() {
//         $('.TabHide').slideToggle("slow");
//         $('.showmenu').css("display", "none");
//      });
//    });

// $(document).ready(function(){
//      // jQuery('#showall').click(function(){
//      //   jQuery('.targetDiv').show();
//      // });

// 		console.log('1');
//      $('.showSingle').click(function(){
//      	console.log('2');
//       $('.targetDiv').hide();
//       $('#div'+$(this).attr('target')).show();
//      });

//     });

// 	$(document).ready(function(){
//       $('.AllButtons .AllSteps').click(function(){
//       $('.AllButtons .AllSteps').removeClass("active");
//       $(this).addClass("active");
//      });
//     });

$(document).ready(function() {
  $(".formdrag1 input").change(function() {
    that.setState({
      files: []
    });
    $(".formdrag1 p").text(this.files.length + " files selected");
    $("#changecolor1").addClass("changecolor");
    $("#changeicon1").addClass("changecolor");

    if (this.files.length == 0) {
      $(".formdrag1 p").text("Click to add files");
      $("#changecolor1").removeClass("changecolor");
      $("#changeicon1").removeClass("changecolor");
    }
  });
});
class Home extends React.Component {
  constructor(props) {
    //var getUrl = window.location

    var finalurl = posturl;

    super(props);
    that = this;
    this.state = {
      baseUrl: finalurl,
      Brands: [],
      SeriesName: [],
      ModelName: [],
      IssuesName: [],
      options: [],
      files: [],
      product_name: "",
      brand_name: "",
      series_name: "",
      model_name: "",
      phone: "",
      issues_name: [],
      emailNumb: "",
      phoneNumb: "",
      enterComents: "",
      LastName: "",
      yourName: "",
      brndInput: "",
      seriesInput: "",
      modalInput: "",
      issuesInput: "",
      showbrndInput: "hide",
      showSeriesInput: "hide",
      showModalInput: "hide",
      selectProduction: "show",
      selectValues: "show",
      getBackButtn: "hide",
      showIssueInput: "hide"
    };

    //this.changeBrand = this.changeBrand.bind(this);
    //this.changeseries = this.changeseries.bind(this);
    //this.changeproduct = this.changeproduct.bind(this);
    //this.changeSelectModel = this.changeSelectModel.bind(this);
    //this.changeIssue = this.changeIssue.bind(this);
    this.handleInput = this.handleInput.bind(this);
    this.saveRequest = this.saveRequest.bind(this);
    this.showmenu = this.showmenu.bind(this);
    this.otherBrand = this.otherBrand.bind(this);
    this.otherIssue = this.otherIssue.bind(this);
    this.otherBrandNext = this.otherBrandNext.bind(this);
    this.otherSeriesNext = this.otherSeriesNext.bind(this);
    this.otherModalNext = this.otherModalNext.bind(this);
    this.otherIssuesNext = this.otherIssuesNext.bind(this);
    this.anotherReq = this.anotherReq.bind(this);
    this.fileSelectedHandler = this.fileSelectedHandler.bind(this);
  }

  fileSelectedHandler = e => {
    console.log(upload);
    this.setState({ files: [...this.state.files, ...e.target.files] });
  };

  // componentDidMount () {
  //   }

  notify = () => toast("Your Request Is Submited !");

  saveRequest() {
    var product_name = this.state.product_name;
    var brand_name = this.state.brand_name;
    var series_name = this.state.series_name;
    var model_name = this.state.model_name;
    var issues_name = this.state.options; // sleected values
    var issuesInput = this.state.issuesInput; // issue inpout val
    var emailNumb = $.trim(this.state.emailNumb);
    var phoneNumb = $.trim(this.state.phone);
    var LastName = $.trim(this.state.LastName);
    var yourName = $.trim(this.state.yourName);
    var enterComents = $.trim(this.state.enterComents);
    var files = upload.cachedFileArray;

    if (!yourName) {
      $("#yourNameSpan")
        .css("display", "inline")
        .fadeOut(2000);
      return false;
    }

    if (phoneNumb) {
      if (!isValidPhoneNumber(this.state.phone)) {
        $("#validNumberSpan")
          .css("display", "inline")
          .fadeOut(2000);
        return false;
      }
    } else {
      $("#numberSpan")
        .css("display", "inline")
        .fadeOut(2000);
      return false;
    }

    if (emailNumb) {
      if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(emailNumb)) {
        $("#validEmailSpan")
          .css("display", "inline")
          .fadeOut(2000);
        return false;
      }
    } else {
      $("#emailSpan")
        .css("display", "inline")
        .fadeOut(2000);
      return false;
    }

    //this.refs.btn.setAttribute("disabled", "disabled");
    if (files.length > 3) {
      toast("Only 3 images allow!");
      $("div#loader-container").addClass("hidden");
      return false;
    }
    $("div#loader-container").removeClass("hidden");
    var formData = new FormData();
    var validImageTypes = [
      "image/gif",
      "image/jpeg",
      "image/png",
      "image/jpg",
      "image/psd"
    ];

    if (files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        // console.log(files[i].type);
        if ($.inArray(files[i].type, validImageTypes) < 0) {
          $("div#loader-container").addClass("hidden");
          toast("Select only images!");
          return false;
        }
        formData.append("images[]", files[i]);
      }
    }
    // return false;

    //console.log(issues_name);

    for (let i = 0; i < issues_name.length; i++) {
      formData.append("issues_name[]", issues_name[i]);
    }

    formData.append("product_name", product_name);
    formData.append("brand_name", brand_name);
    formData.append("series_name", series_name);
    formData.append("model_name", model_name);
    //formData.append("issues_name[]", issues_name;
    formData.append("issuesInput", issuesInput);
    formData.append("emailNumb", emailNumb);
    formData.append("phoneNumb", phoneNumb);
    formData.append("LastName", LastName);
    formData.append("yourName", yourName);
    formData.append("enterComents", enterComents);
    // var getUrl = window.location;
    // var finalurl = getUrl.protocol + "//" + getUrl.hostname;
    let insertUrl =
      this.state.baseUrl +
      "/laptopzone/reactcontroller/c_react/ljw_SaveRequest";
    // let insertUrl =
    //   finalurl + "/laptopzone/reactcontroller/c_react/ljw_SaveRequest";

    new Promise(function(resolve, reject) {
      $.ajax({
        url: insertUrl,
        dataType: "json",
        type: "POST",
        cache: false,
        contentType: false,
        processData: false,
        data: formData
      }).then(
        function(data) {
          resolve(data);
        },
        function(err) {
          reject(err);
        }
      );
    })
      .then(result => {
        if (result) {
          //alert('record saved');
          //setTimeout(function(){ $("div#loader-container").removeClass("hidden"), 3000);

          $("div#loader-container").addClass("hidden");

          this.setState({
            product_name: "",
            brand_name: "",
            series_name: "",
            model_name: "",
            issues_name: "",

            getBackButtn: "show",
            showIssueInput: "hide"
          });

          $("#product").text(".....");
          $("#brand").text(".....");
          $("#series").text(".....");
          $("#SelectModelName").text(".....");
          $("#Issue").text(".....");
          //$("#submited").text("Submited");

          $("#submited").html("");
          $("#submited").append(
            "Submited" +
              '<i class="fa fa-check-circle" aria-hidden="true" "></i>'
          );

          $("#showRepar").text("Your Repair No is " + result.data);
          $("#showReparText").text("We will get back to you ASAP.");
          $("#inputFiles").val("");
          $("#emptyFiles").empty();
          upload = "";
          upload = new FileUploadWithPreview("myUniqueUploadId");
          this.notify();
          //return false;

          $(".targetDiv").hide();
          return false;
        } else {
          $("div#loader-container").removeClass("hidden");
          alert("Error");
          return false;
        }
      })
      .catch(err => {
        //$.LoadingOverlay("hide");
        console.log(err);
      });

    return false;
  }
  anotherReq() {
    this.setState({
      product_name: "",
      brand_name: "",
      series_name: "",
      model_name: "",
      issues_name: "",
      getBackButtn: "hide"
    });

    $("#product").text(".....");
    $("#brand").text(".....");
    $("#series").text(".....");
    $("#SelectModelName").text(".....");
    $("#Issue").text(".....");
    $("#submited").text(".....");

    $(".targetDiv").hide();
    $(".AllButtons .AllSteps").removeClass("active");
    $(".AllButtons .target_1").addClass("active");
    $(".targetDiv_1").show();

    //this.refs.btn.removeAttr("disabled");

    //alert('assad');
    return false;
  }

  handleInput(e) {
    const name = e.target.name;

    this.setState({ [name]: e.target.value });
  }

  handleChangeInput = event => {
    // console.log(event.target);
    // console.log(event.target.maxLength);
    // console.log(event.target.value);
    // console.log(event.target.name);

    const name = event.target.name;

    const { value, maxLength } = event.target;
    const brndInput = value.slice(0, maxLength);
    // const seriesInput = value.slice(0, maxLength);

    this.setState({
      [name]: event.target.value
      //	seriesInput
    });
  };
  changeproduct(productName, product_id) {
    //alert('asd');
    //$("#brand_name").html();
    //console.log(product_id);
    $("#div2 .tickRemove").addClass("without-after-element");
    $("#div3 .tickRemove").addClass("without-after-element");
    $("#div4 .tickRemove").addClass("without-after-element");
    $("#div5 .tickRemove").addClass("without-after-element");
    $("#product").html("");
    $("#brand").html("....");
    $("#series").text(".....");
    $("#SelectModelName").text(".....");
    $("#Issue").text(".....");
    $("#product").append(
      productName + '<i class="fa fa-check-circle" aria-hidden="true" "></i>'
    );

    this.setState({
      product_name: product_id,
      brand_name: "",
      showbrndInput: "hide",
      showSeriesInput: "hide"
    });

    var get_product_name = product_id; //$('#product_name').val();

    if (
      get_product_name == null ||
      get_product_name == "" ||
      get_product_name == "undefined"
    ) {
      $(".targetDiv").hide();
      $(".AllButtons .AllSteps").removeClass("active");
      $(".AllButtons .target_1").addClass("active");
      // alert('select Product First');
      // return false;
    } else {
      $(".targetDiv").hide();
      $(".AllButtons .AllSteps").removeClass("active");
      $(".AllButtons .target_1").addClass("active");
      $(".AllButtons .target_2").addClass("active");

      let insertUrl =
        this.state.baseUrl + "/laptopzone/reactcontroller/c_react/ljw_Brands";
      var ljw_getobject = []; //'adi;l asada  yahoo';

      new Promise(function(resolve, reject) {
        $.ajax({
          url: insertUrl,
          dataType: "json",
          type: "POST",
          data: { get_product_name: get_product_name }
        }).then(
          function(data) {
            resolve(data);
          },
          function(err) {
            reject(err);
          }
        );
      })
        .then(result => {
          if (result.exist) {
            var length = result.ljw_getobject.length;

            for (var i = 0; i < length; i++) {
              //console.log(i);
              ljw_getobject.push({
                brand_name: result.ljw_getobject[i].BRAND_NAME,
                brand_url: result.ljw_getobject[i].BRAND_URL,
                brand_dt_id: result.ljw_getobject[i].BRAND_DT_ID
              });
            }

            $(".targetDiv_2").show();
            //$('#div'+$(this).attr('target')).show();
            that.setState({
              Brands: ljw_getobject
            });
          } else {
          }
        })
        .catch(err => {
          //$.LoadingOverlay("hide");
          console.log(err);
        });
    }
    // console.log(brandName);
    // console.log(brand_dt_id);

    return false;
  }

  otherIssuesNext() {
    if (this.state.showIssueInput == "show") {
      var issueInput = $.trim(this.state.issuesInput);
      if (issueInput == null || issueInput == "" || issueInput == "undefined") {
        alert("Enter Issue Name");
        return false;
      }
    }
    $(".targetDiv").hide();
    $(".AllButtons .AllSteps").removeClass("active");
    $(".AllButtons .target_1").addClass("active");
    $(".AllButtons .target_2").addClass("active");
    $(".AllButtons .target_3").addClass("active");
    $(".AllButtons .target_4").addClass("active");
    $(".AllButtons .target_5").addClass("active");
    $(".AllButtons .target_6").addClass("active");
    $(".targetDiv_6").show();
  }

  otherModals() {
    this.setState({
      showModalInput: "show"
    });
    $("#div4 .tickRemove").removeClass("without-after-element");
  }

  otherModalNext() {
    var modalInput = $.trim(this.state.modalInput);

    if (modalInput == null || modalInput == "" || modalInput == "undefined") {
      alert("Enter Modal Name");
      return false;
    }
    $("#SelectModelName").html("");
    $("#SelectModelName").append(
      modalInput + '<i class="fa fa-check-circle" aria-hidden="true" "></i>'
    );
    var model_dt_id = modalInput;
    this.setState({
      model_name: model_dt_id
    });
    //console.log('3');
    let insertUrl =
      this.state.baseUrl + "/laptopzone/reactcontroller/c_react/ljw_Issues";
    var get_product_name = $("#product_name").val();
    var get_brand_name = $("#brand_name").val();
    var get_series_name = $("#series_name").val();
    var get_model_names = model_dt_id; //$('#model_names').val();
    this.setState({
      // series_name:'',
      // model_names:'',
      // seriesInput:'',
      issuesInput: "",
      issues_name: "",
      showIssueInput: "hide"
      //
      // showbrndInput:'hide',
      // showSeriesInput:'hide',
      // showModalInput:'hide'
    });

    //console.log(get_brand_name);
    if (
      get_product_name == null ||
      get_product_name == "" ||
      get_product_name == "undefined"
    ) {
      $(".targetDiv").hide();
      $(".AllButtons .AllSteps").removeClass("active");
      $(".AllButtons .target_1").addClass("active");
      // alert('select Product First');
      // return false;
    } else if (
      get_brand_name == null ||
      get_brand_name == "" ||
      get_brand_name == "undefined"
    ) {
      $(".targetDiv").hide();
      $(".AllButtons .AllSteps").removeClass("active");
      $(".AllButtons .target_2").addClass("active");
      // alert('select Brand First');
      // return false;
    } else if (
      get_series_name == null ||
      get_series_name == "" ||
      get_series_name == "undefined"
    ) {
      $(".targetDiv").hide();
      $(".AllButtons .AllSteps").removeClass("active");
      $(".AllButtons .target_3").addClass("active");
      alert("select Series First");
      return false;
    } else if (
      get_model_names == null ||
      get_model_names == "" ||
      get_model_names == "undefined"
    ) {
      $(".targetDiv").hide();
      $(".AllButtons .AllSteps").removeClass("active");
      $(".AllButtons .target_4").addClass("active");
      alert("select Model First");
      return false;
    } else {
      $(".targetDiv").hide();
      $(".AllButtons .AllSteps").removeClass("active");
      $(".AllButtons .target_1").addClass("active");
      $(".AllButtons .target_2").addClass("active");
      $(".AllButtons .target_3").addClass("active");
      $(".AllButtons .target_4").addClass("active");
      $(".AllButtons .target_5").addClass("active");

      //$("#series").text('.....');
      //$("#SelectModelName").text('.....');
      $("#Issue").text(".....");

      var ljw_Issues = []; //'adi;l asada  yahoo';

      new Promise(function(resolve, reject) {
        $.ajax({
          url: insertUrl,
          dataType: "json",
          type: "POST",
          data: { get_product_name: get_product_name }
        }).then(
          function(data) {
            resolve(data);
          },
          function(err) {
            reject(err);
          }
        );
      })
        .then(result => {
          if (result.exist) {
            var length = result.ljw_Issues.length;

            for (var i = 0; i < length; i++) {
              //console.log(i);
              ljw_Issues.push({
                issu_name: result.ljw_Issues[i].ISSU_NAME,
                issue_dt_id: result.ljw_Issues[i].ISSUE_DT_ID
                // brand_url: result.ljw_Issues[i].BRAND_URL,
                // brand_dt_id: result.ljw_Issues[i].BRAND_DT_ID,
              });
            }

            $(".targetDiv_5").show();
            //$('#div'+$(this).attr('target')).show();
            that.setState({
              IssuesName: ljw_Issues
            });
          } else {
          }
        })
        .catch(err => {
          //$.LoadingOverlay("hide");
          console.log(err);
        });
    }
    return false;
  }

  otherSeries() {
    this.setState({
      showSeriesInput: "show"
    });
    $("#div3 .tickRemove").removeClass("without-after-element");
  }

  otherSeriesNext() {
    var seriesInput = $.trim(this.state.seriesInput);
    $("#div4 .tickRemove").addClass("without-after-element");
    if (
      seriesInput == null ||
      seriesInput == "" ||
      seriesInput == "undefined"
    ) {
      alert("Enter Series Name");
      return false;
    }

    $("#series").html("");
    $("#series").append(
      seriesInput + '<i class="fa fa-check-circle" aria-hidden="true" "></i>'
    );
    //	$("#brand").text(brandName);

    //extra variable initialization in case on input value

    var series_dt_id = seriesInput;

    this.setState({
      series_name: series_dt_id
    });

    let insertUrl =
      this.state.baseUrl + "/laptopzone/reactcontroller/c_react/ljw_Model";
    var get_product_name = $("#product_name").val();
    var get_brand_name = $("#brand_name").val();
    var get_series_name = series_dt_id; //$('#series_name').val();

    //console.log(get_brand_name);
    if (
      get_product_name == null ||
      get_product_name == "" ||
      get_product_name == "undefined"
    ) {
      $(".targetDiv").hide();
      $(".AllButtons .AllSteps").removeClass("active");
      $(".AllButtons .target_1").addClass("active");
      // alert('select Product First');
      // return false;
    } else if (
      get_brand_name == null ||
      get_brand_name == "" ||
      get_brand_name == "undefined"
    ) {
      $(".targetDiv").hide();
      $(".AllButtons .AllSteps").removeClass("active");
      $(".AllButtons .target_2").addClass("active");
      // alert('select Brand First');
      // return false;
    } else if (
      get_series_name == null ||
      get_series_name == "" ||
      get_series_name == "undefined"
    ) {
      $(".targetDiv").hide();
      $(".AllButtons .AllSteps").removeClass("active");
      $(".AllButtons .target_3").addClass("active");
      // alert('select Series First');
      // return false;
    } else {
      $(".targetDiv").hide();
      $(".AllButtons .AllSteps").removeClass("active");
      $(".AllButtons .target_1").addClass("active");
      $(".AllButtons .target_2").addClass("active");
      $(".AllButtons .target_3").addClass("active");
      $(".AllButtons .target_4").addClass("active");

      this.setState({
        //series_name:'',
        model_name: "",
        //seriesInput:'',
        modalInput: "",
        issues_name: "",
        //showbrndInput:'hide',
        //showSeriesInput:'hide',
        showModalInput: "hide"
      });

      //$("#series").text('.....');
      $("#SelectModelName").text(".....");
      $("#Issue").text(".....");

      //  			this.setState({

      //   	model_names:'',
      //   	modalInput:''
      // })

      //  			$("#model_names").text('.....');

      var ljw_Model = []; //'adi;l asada  yahoo';

      new Promise(function(resolve, reject) {
        $.ajax({
          url: insertUrl,
          dataType: "json",
          type: "POST",
          data: { get_series_name: get_series_name }
        }).then(
          function(data) {
            resolve(data);
          },
          function(err) {
            reject(err);
          }
        );
      })
        .then(result => {
          if (result.exist) {
            var length = result.ljw_Model.length;

            for (var i = 0; i < length; i++) {
              //console.log(i);
              ljw_Model.push({
                description: result.ljw_Model[i].DESCRIPTION,
                model_dt_id: result.ljw_Model[i].MODEL_DT_ID,
                model_url: result.ljw_Model[i].IMAGE_URL
                // brand_url: result.ljw_Model[i].BRAND_URL,
                // brand_dt_id: result.ljw_Model[i].BRAND_DT_ID,
              });
            }

            $(".targetDiv_4").show();
            //$('#div'+$(this).attr('target')).show();
            that.setState({
              ModelName: ljw_Model
            });
          } else {
          }
        })
        .catch(err => {
          //$.LoadingOverlay("hide");
          console.log(err);
        });
    }

    //console.log('2');
    return false;

    //$('#series_name').attr('value', series_dt_id);
    //$("#series_name").val(series_dt_id);
  }

  otherBrand() {
    this.setState({
      showbrndInput: "show"
    });
    $("#div2 .tickRemove").removeClass("without-after-element");
  }

  otherBrandNext() {
    var brndInput = $.trim(this.state.brndInput);
    $("#div3 .tickRemove").addClass("without-after-element");
    if (brndInput == null || brndInput == "" || brndInput == "undefined") {
      alert("Enter Brand Name");
      return false;
    }

    // $("#brand").text(brndInput);
    $("#brand").html("");
    $("#brand").append(
      brndInput + '<i class="fa fa-check-circle" aria-hidden="true" "></i>'
    );
    //	$("#brand").text(brandName);

    //extra variable initialization in case on input value

    var brand_dt_id = brndInput;
    this.setState({
      brand_name: brand_dt_id
    });
    let insertUrl =
      this.state.baseUrl + "/laptopzone/reactcontroller/c_react/ljw_Series";
    // alert(brand_dt_id);
    // alert($('#brand_name').val());
    // alert(this.state.brand_name);
    var get_brand_name = brand_dt_id; // this.state.brand_name;//$('#brand_name').val();
    var get_product_name = $("#product_name").val();

    if (
      get_product_name == null ||
      get_product_name == "" ||
      get_product_name == "undefined"
    ) {
      $(".targetDiv").hide();
      $(".AllButtons .AllSteps").removeClass("active");
      $(".AllButtons .target_1").addClass("active");
      // alert('select Product First');
      // return false;
    } else if (
      brand_dt_id == null ||
      brand_dt_id == "" ||
      brand_dt_id == "undefined"
    ) {
      $(".targetDiv").hide();
      $(".AllButtons .AllSteps").removeClass("active");
      $(".AllButtons .target_2").addClass("active");
      // alert('select Brands First');
      // return false;
    } else {
      $(".targetDiv").hide();
      $(".AllButtons .AllSteps").removeClass("active");
      $(".AllButtons .target_1").addClass("active");
      $(".AllButtons .target_2").addClass("active");
      $(".AllButtons .target_3").addClass("active");

      this.setState({
        series_name: "",
        model_name: "",
        seriesInput: "",
        modalInput: "",
        issues_name: "",
        showSeriesInput: "hide",
        showModalInput: "hide"
      });

      $("#series").text(".....");
      $("#SelectModelName").text(".....");
      $("#Issue").text(".....");
      $(".SeriesSelected label").removeClass("active");

      var ljw_Series = []; //'adi;l asada  yahoo';

      new Promise(function(resolve, reject) {
        $.ajax({
          url: insertUrl,
          dataType: "json",
          type: "POST",
          data: { get_brand_name: get_brand_name }
        }).then(
          function(data) {
            resolve(data);
          },
          function(err) {
            reject(err);
          }
        );
      })
        .then(result => {
          if (result.exist) {
            var length = result.ljw_Series.length;

            for (var i = 0; i < length; i++) {
              //console.log(i);
              ljw_Series.push({
                series_name: result.ljw_Series[i].SERIES_NAME,
                series_dt_id: result.ljw_Series[i].SERIES_DT_ID
                // brand_url: result.ljw_Series[i].BRAND_URL,
                // brand_dt_id: result.ljw_Series[i].BRAND_DT_ID,
              });
            }

            $(".targetDiv_3").show();
            //$('#div'+$(this).attr('target')).show();
            that.setState({
              SeriesName: ljw_Series
            });
          } else {
          }
        })
        .catch(err => {
          //$.LoadingOverlay("hide");
          console.log(err);
        });
    }

    return false;
  }

  changeBrand(brandName, brand_dt_id) {
    //alert(brandName);
    // console.log('dasd');
    // return false;

    $("#brand").html("");
    $("#brand").append(
      brandName + '<i class="fa fa-check-circle" aria-hidden="true" "></i>'
    );
    $("#div2 .tickRemove").addClass("without-after-element");
    $("#div3 .tickRemove").addClass("without-after-element");
    $("#div4 .tickRemove").addClass("without-after-element");
    $("#div5 .tickRemove").addClass("without-after-element");
    this.setState({
      brand_name: brand_dt_id
    });
    let insertUrl =
      this.state.baseUrl + "/laptopzone/reactcontroller/c_react/ljw_Series";
    // alert(brand_dt_id);
    // alert($('#brand_name').val());
    // alert(this.state.brand_name);
    var get_brand_name = brand_dt_id; // this.state.brand_name;//$('#brand_name').val();
    var get_product_name = $("#product_name").val();

    if (
      get_product_name == null ||
      get_product_name == "" ||
      get_product_name == "undefined"
    ) {
      $(".targetDiv").hide();
      $(".AllButtons .AllSteps").removeClass("active");
      $(".AllButtons .target_1").addClass("active");
      // alert('select Product First');
      // return false;
    } else if (
      brand_dt_id == null ||
      brand_dt_id == "" ||
      brand_dt_id == "undefined"
    ) {
      $(".targetDiv").hide();
      $(".AllButtons .AllSteps").removeClass("active");
      $(".AllButtons .target_2").addClass("active");
      // alert('select Brands First');
      // return false;
    } else {
      $(".targetDiv").hide();
      $(".AllButtons .AllSteps").removeClass("active");
      $(".AllButtons .target_1").addClass("active");
      $(".AllButtons .target_2").addClass("active");
      $(".AllButtons .target_3").addClass("active");

      this.setState({
        series_name: "",
        model_name: "",
        seriesInput: "",
        modalInput: "",
        issuesInput: "",
        issues_name: "",
        showbrndInput: "hide",
        showSeriesInput: "hide",
        showModalInput: "hide",
        showIssueInput: "hide"
      });

      $("#series").text(".....");
      $("#SelectModelName").text(".....");
      $("#Issue").text(".....");
      $(".SeriesSelected label").removeClass("active");

      var ljw_Series = []; //'adi;l asada  yahoo';

      new Promise(function(resolve, reject) {
        $.ajax({
          url: insertUrl,
          dataType: "json",
          type: "POST",
          data: { get_brand_name: get_brand_name }
        }).then(
          function(data) {
            resolve(data);
          },
          function(err) {
            reject(err);
          }
        );
      })
        .then(result => {
          if (result.exist) {
            var length = result.ljw_Series.length;

            for (var i = 0; i < length; i++) {
              //console.log(i);
              ljw_Series.push({
                series_name: result.ljw_Series[i].SERIES_NAME,
                series_dt_id: result.ljw_Series[i].SERIES_DT_ID
                // brand_url: result.ljw_Series[i].BRAND_URL,
                // brand_dt_id: result.ljw_Series[i].BRAND_DT_ID,
              });
            }

            $(".targetDiv_3").show();
            //$('#div'+$(this).attr('target')).show();
            that.setState({
              SeriesName: ljw_Series
            });
          } else {
          }
        })
        .catch(err => {
          //$.LoadingOverlay("hide");
          console.log(err);
        });
    }

    // console.log(brandName);
    // console.log(brand_dt_id);

    return false;

    //$('#brand_name').html();
    //$('#brand_name').attr('value', brand_dt_id);
    //$(".brand_name").val(brand_dt_id);
  }

  changeseries(seriesName, series_dt_id) {
    //$("#series").text(seriesName);

    $("#series").html("");
    $("#series").append(
      seriesName + '<i class="fa fa-check-circle" aria-hidden="true" "></i>'
    );

    this.setState({
      series_name: series_dt_id,
      showSeriesInput: "hide"
    });
    $("#div3 .tickRemove").removeClass("without-after-element");
    $("#div4 .tickRemove").addClass("without-after-element");
    $("#div5 .tickRemove").addClass("without-after-element");

    let insertUrl =
      this.state.baseUrl + "/laptopzone/reactcontroller/c_react/ljw_Model";
    var get_product_name = $("#product_name").val();
    var get_brand_name = $("#brand_name").val();
    var get_series_name = series_dt_id; //$('#series_name').val();

    //console.log(get_brand_name);
    if (
      get_product_name == null ||
      get_product_name == "" ||
      get_product_name == "undefined"
    ) {
      $(".targetDiv").hide();
      $(".AllButtons .AllSteps").removeClass("active");
      $(".AllButtons .target_1").addClass("active");
      // alert('select Product First');
      // return false;
    } else if (
      get_brand_name == null ||
      get_brand_name == "" ||
      get_brand_name == "undefined"
    ) {
      $(".targetDiv").hide();
      $(".AllButtons .AllSteps").removeClass("active");
      $(".AllButtons .target_2").addClass("active");
      // alert('select Brand First');
      // return false;
    } else if (
      get_series_name == null ||
      get_series_name == "" ||
      get_series_name == "undefined"
    ) {
      $(".targetDiv").hide();
      $(".AllButtons .AllSteps").removeClass("active");
      $(".AllButtons .target_3").addClass("active");
      // alert('select Series First');
      // return false;
    } else {
      $(".targetDiv").hide();
      $(".AllButtons .AllSteps").removeClass("active");
      $(".AllButtons .target_1").addClass("active");
      $(".AllButtons .target_2").addClass("active");
      $(".AllButtons .target_3").addClass("active");
      $(".AllButtons .target_4").addClass("active");

      this.setState({
        //series_name:'',
        model_names: "",
        //seriesInput:'',
        modalInput: "",
        issues_name: "",
        //showbrndInput:'hide',
        showSeriesInput: "hide",
        showModalInput: "hide"
      });

      //$("#series").text('.....');
      $("#SelectModelName").text(".....");
      $("#Issue").text(".....");

      var ljw_Model = []; //'adi;l asada  yahoo';

      new Promise(function(resolve, reject) {
        $.ajax({
          url: insertUrl,
          dataType: "json",
          type: "POST",
          data: { get_series_name: get_series_name }
        }).then(
          function(data) {
            resolve(data);
          },
          function(err) {
            reject(err);
          }
        );
      })
        .then(result => {
          if (result.exist) {
            var length = result.ljw_Model.length;

            for (var i = 0; i < length; i++) {
              //console.log(i);
              ljw_Model.push({
                description: result.ljw_Model[i].DESCRIPTION,
                model_dt_id: result.ljw_Model[i].MODEL_DT_ID,
                model_url: result.ljw_Model[i].IMAGE_URL
                // brand_url: result.ljw_Model[i].BRAND_URL,
                // brand_dt_id: result.ljw_Model[i].BRAND_DT_ID,
              });
            }

            $(".targetDiv_4").show();
            //$('#div'+$(this).attr('target')).show();
            that.setState({
              ModelName: ljw_Model
            });
          } else {
          }
        })
        .catch(err => {
          //$.LoadingOverlay("hide");
          console.log(err);
        });
    }

    //console.log('2');
    return false;

    //$('#series_name').attr('value', series_dt_id);
    //$("#series_name").val(series_dt_id);
  }
  changeSelectModel(SelectModelName, model_dt_id) {
    //console.log(SelectModelName);
    //$("#SelectModelName").text(SelectModelName);
    $("#div4 .tickRemove").removeClass("without-after-element");
    $("#div5 .tickRemove").addClass("without-after-element");
    $("#div5 #check4").prop("checked", false);
    $("#SelectModelName").html("");
    $("#SelectModelName").append(
      SelectModelName +
        '<i class="fa fa-check-circle" aria-hidden="true" "></i>'
    );

    this.setState({
      model_name: model_dt_id,
      options: []
    });
    //console.log('3');
    let insertUrl =
      this.state.baseUrl + "/laptopzone/reactcontroller/c_react/ljw_Issues";
    var get_product_name = $("#product_name").val();
    var get_brand_name = $("#brand_name").val();
    var get_series_name = $("#series_name").val();
    var get_model_names = model_dt_id; //$('#model_names').val();

    //console.log(get_brand_name);
    if (
      get_product_name == null ||
      get_product_name == "" ||
      get_product_name == "undefined"
    ) {
      $(".targetDiv").hide();
      $(".AllButtons .AllSteps").removeClass("active");
      $(".AllButtons .target_1").addClass("active");
      // alert('select Product First');
      // return false;
    } else if (
      get_brand_name == null ||
      get_brand_name == "" ||
      get_brand_name == "undefined"
    ) {
      $(".targetDiv").hide();
      $(".AllButtons .AllSteps").removeClass("active");
      $(".AllButtons .target_2").addClass("active");
      // alert('select Brand First');
      // return false;
    } else if (
      get_series_name == null ||
      get_series_name == "" ||
      get_series_name == "undefined"
    ) {
      $(".targetDiv").hide();
      $(".AllButtons .AllSteps").removeClass("active");
      $(".AllButtons .target_3").addClass("active");
      alert("select Series First");
      return false;
    } else if (
      get_model_names == null ||
      get_model_names == "" ||
      get_model_names == "undefined"
    ) {
      $(".targetDiv").hide();
      $(".AllButtons .AllSteps").removeClass("active");
      $(".AllButtons .target_4").addClass("active");
      alert("select Model First");
      return false;
    } else {
      $(".targetDiv").hide();
      $(".AllButtons .AllSteps").removeClass("active");
      $(".AllButtons .target_1").addClass("active");
      $(".AllButtons .target_2").addClass("active");
      $(".AllButtons .target_3").addClass("active");
      $(".AllButtons .target_4").addClass("active");
      $(".AllButtons .target_5").addClass("active");

      this.setState({
        // series_name:'',
        // model_names:'',
        // seriesInput:'',
        // modalInput:'',
        issues_name: "",
        //
        // showbrndInput:'hide',
        // showSeriesInput:'hide',
        showModalInput: "hide"
      });

      //$("#series").text('.....');
      //$("#SelectModelName").text('.....');
      $("#Issue").text(".....");

      var ljw_Issues = []; //'adi;l asada  yahoo';

      new Promise(function(resolve, reject) {
        $.ajax({
          url: insertUrl,
          dataType: "json",
          type: "POST",
          data: { get_product_name: get_product_name }
        }).then(
          function(data) {
            resolve(data);
          },
          function(err) {
            reject(err);
          }
        );
      })
        .then(result => {
          if (result.exist) {
            var length = result.ljw_Issues.length;

            for (var i = 0; i < length; i++) {
              //console.log(i);
              ljw_Issues.push({
                issu_name: result.ljw_Issues[i].ISSU_NAME,
                issue_dt_id: result.ljw_Issues[i].ISSUE_DT_ID
                // brand_url: result.ljw_Issues[i].BRAND_URL,
                // brand_dt_id: result.ljw_Issues[i].BRAND_DT_ID,
              });
            }

            $(".targetDiv_5").show();
            //$('#div'+$(this).attr('target')).show();
            that.setState({
              IssuesName: ljw_Issues
            });
          } else {
          }
        })
        .catch(err => {
          //$.LoadingOverlay("hide");
          console.log(err);
        });
    }
    return false;

    // $('#model_name').attr('value', model_dt_id);
    //$("#model_name").val(model_dt_id);
  }

  otherIssue() {
    this.setState({
      showIssueInput: "show"
    });
  }

  onSelectCheck(e) {
    $("#div5 .tickRemove").removeClass("without-after-element");

    if (e.target.value == 0) {
      if (this.state.showIssueInput == "show") {
        this.setState({
          showIssueInput: "hide"
        });
      } else {
        this.setState({
          showIssueInput: "show"
        });
      }
    }
    const options = this.state.options;
    let index;

    // check if the check box is checked or unchecked
    if (e.target.checked) {
      // add the numerical value of the checkbox to options array
      options.push(+e.target.value);
      $("#Issue").html("");
      $("#Issue").append(
        options.length +
          '<i class="fa fa-check-circle" aria-hidden="true" "></i>'
      );
    } else {
      // or remove the value from the unchecked checkbox from the array
      index = options.indexOf(+e.target.value);
      options.splice(index, 1);
      // this.setState({
      //   showIssueInput: "hide"
      // });

      if (options.length >= 1) {
        $("#Issue").html("");
        $("#Issue").append(
          options.length +
            '<i class="fa fa-check-circle" aria-hidden="true" "></i>'
        );
      } else {
        $("#Issue").html("");
        $("#Issue").append(options.length + "");
      }
    }

    // sort the array
    options.sort();

    // update the state with the new array of options
    this.setState({ options: options });
    //$("#Issue").text(options.length);
    //$("#Issue").html('');
    //$("#Issue").append(options.length+'<i class="fa fa-check-circle" aria-hidden="true" "></i>');

    //options.length
    //console.log(options.length)
    //console.log(this.state.options)
  }

  // changeIssue(IssueName, issue_dt_id) {
  //   //console.log(IssueName + " " + issue_dt_id);

  //   $("#Issue").html('');
  //   $("#Issue").append(IssueName+'<i class="fa fa-check-circle" aria-hidden="true" "></i>');

  //   //$("#Issue").text(IssueName);

  //   //console.log(issue_dt_id);
  //   //return false;
  //   this.setState({
  //     issues_name: issue_dt_id
  //   });
  //   console.log(issue_dt_id);
  //   console.log(this.state.issues_name);
  //   return false;
  //   $(".targetDiv").hide();
  //   $(".AllButtons .AllSteps").removeClass("active");
  //   $(".AllButtons .target_1").addClass("active");
  //   $(".AllButtons .target_2").addClass("active");
  //   $(".AllButtons .target_3").addClass("active");
  //   $(".AllButtons .target_4").addClass("active");
  //   $(".AllButtons .target_5").addClass("active");
  //   $(".AllButtons .target_6").addClass("active");
  //   $(".targetDiv_6").show();

  //   //console.log('3');
  //   return false;
  // }

  selectValues = e => {
    this.setState({
      selectValues: "show"
    });
  };

  showmenu(productName, product_id) {
    //alert('123');
    $(".TabHide").slideToggle("slow");
    $(".showmenu").css("display", "none");
    $("#product").text(productName);
    this.setState({
      product_name: product_id
    });

    var get_product_name = product_id; //$('#product_name').val();

    if (
      get_product_name == null ||
      get_product_name == "" ||
      get_product_name == "undefined"
    ) {
      $(".targetDiv").hide();
      $(".AllButtons .AllSteps").removeClass("active");
      $(".AllButtons .target_1").addClass("active");
      // alert('select Product First');
      // return false;
    } else {
      $(".targetDiv").hide();
      $(".AllButtons .AllSteps").removeClass("active");
      $(".AllButtons .target_1").addClass("active");
      //$(".AllButtons .target_2").addClass("active");

      let insertUrl =
        this.state.baseUrl + "/laptopzone/reactcontroller/c_react/ljw_Brands";
      var ljw_getobject = []; //'adi;l asada  yahoo';

      new Promise(function(resolve, reject) {
        $.ajax({
          url: insertUrl,
          dataType: "json",
          type: "POST",
          data: { get_product_name: get_product_name }
        }).then(
          function(data) {
            resolve(data);
          },
          function(err) {
            reject(err);
          }
        );
      })
        .then(result => {
          if (result.exist) {
            var length = result.ljw_getobject.length;

            for (var i = 0; i < length; i++) {
              //console.log(i);
              ljw_getobject.push({
                brand_name: result.ljw_getobject[i].BRAND_NAME,
                brand_url: result.ljw_getobject[i].BRAND_URL,
                brand_dt_id: result.ljw_getobject[i].BRAND_DT_ID
              });
            }

            $(".targetDiv_2").show();
            //$('#div'+$(this).attr('target')).show();
            that.setState({
              Brands: ljw_getobject
            });
          } else {
          }
        })
        .catch(err => {
          //$.LoadingOverlay("hide");
          console.log(err);
        });
    }

    // console.log(brandName);
    // console.log(brand_dt_id);

    return false;
  }
  componentDidMount() {
    upload = new FileUploadWithPreview("myUniqueUploadId");
  }
  render() {
    const title = "Computer & Laptop Repairing | LaptopZone";
    const pageLink = "http://laptopzone.us/repairing";
    const metaDescription =
      "Come today to LaptopZone, the best repairing center in Dallas, TX. We do Electronics Repairing in Dallas, TX such as iPhone, Cell Phone and Computer Repairing, iPhone Screen Repair, Samsung Mobile Repairing etc.";

    // console.log('123');
    // console.log(this.state.Brands);
    return (
      <React.Fragment>
        <PageTitles
          title={title}
          metaDescription={metaDescription}
          pageLink={pageLink}
        />
        <div id="loader-container" className="loader-container hidden">
          <svg
            className="spinner"
            viewBox="0 0 66 66"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              className="path"
              fill="none"
              stroke-linecap="round"
              cx="33"
              cy="33"
              r="30"
            ></circle>
          </svg>

          <div className="icon">
            <img src="assets/image/main_logo.svg" />
          </div>
        </div>

        <section className="BannerSliding hide">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-sm-12 hide">
                <div className="morphText">
                  <h1>Desktop PC and Laptops</h1>
                  <span>Repairing</span>
                </div>
              </div>

              {/*<div className="col-md-12 col-sm-12">
			       	<form action="#" method="#">
			       	 <div className="LandingSearch">
			       	  <input type="text" id="" name="" placeholder="TYPE HERE TO SEARCH YOUR DEVICE"></input>
			       	  <div className="clearfix"></div>
			       	  <button>Search</button>
			       	 </div>
			       	</form>
			       </div>*/}
            </div>
          </div>
        </section>

        <section className="SectionAllBrands">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-sm-12 showmenu hide">
                <div className="SelectHeading">
                  <h1>Select your Product</h1>
                </div>
              </div>
              <div className="col-md-12 col-sm-12">
                <div className="Pageto-Back">
                  <a href="/">
                    <i className="icon-icon_arrow"></i> Back
                  </a>
                </div>
              </div>
              <input
                type="hidden"
                className="product_name"
                id="product_name"
                onChange={this.handleInput}
                value={this.state.product_name}
              ></input>
              <input
                type="hidden"
                className="brand_name"
                id="brand_name"
                onChange={this.handleInput}
                value={this.state.brand_name}
              ></input>
              <input
                type="hidden"
                id="series_name"
                onChange={this.handleInput}
                value={this.state.series_name}
              ></input>
              <input
                type="hidden"
                id="model_names"
                onChange={this.handleInput}
                value={this.state.model_name}
              ></input>
              <input
                type="hidden"
                id="issues_name"
                name="issues_name"
                onChange={this.handleInput}
                value={this.state.issues_name}
              ></input>

              {/*<div className="col-md-12 col-sm-12">
                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <a
                      className="showmenu"
                      onClick={() => {
                        this.showmenu("Desktop", "1");
                      }}
                    >
                      <div className="DesktopPc">
                        <div className="PcLeft">
                          <h1>Desktop PC</h1>
                          <p>Select this for mac Windows Linux etc.</p>
                        </div>

                        <div className="PcRight">
                          <img
                            src="assets/image/Desktop.png"
                            width="157"
                            height="167"
                            alt=""
                          />
                        </div>
                      </div>
                    </a>
                  </div>

                 

                  <div className="col-md-6 col-sm-12">
                    <a className="showmenu">
                      <div
                        className="DesktopPc"
                        onClick={() => {
                          this.showmenu("Laptop", "2");
                        }}
                      >
                        <div className="PcLeft">
                          <h1>Laptop</h1>
                          <p>Select this for mac Windows Linux etc.</p>
                        </div>

                        <div className="PcRight">
                          <img
                            src="assets/image/Laptop.png"
                            width="199"
                            height="175"
                            alt=""
                          />
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>*/}

              <div className="col-md-12 col-sm-12">
                {/*} <div className="TabHide">*/}
                <div className="">
                  <div className="AllButtons">
                    <a className="showSingle " target="1">
                      {/*<div className="AllSteps hvr-rectangle-out active target_1">*/}
                      <div className="AllSteps hvr-rectangle-out  target_1">
                        <h3>Product </h3>
                        <p id="product">..... </p>
                        <input
                          type="hidden"
                          id="product_name"
                          name="product_name"
                          value=""
                        ></input>
                      </div>
                    </a>

                    <a className="showSingle " target="2">
                      <div className="AllSteps  hvr-rectangle-out target_2 ">
                        <h3>Brand</h3>
                        <p id="brand">.....</p>
                      </div>
                    </a>

                    <a className="showSingle  " target="3">
                      <div className="AllSteps hvr-rectangle-out target_3">
                        <h3>Series</h3>
                        <p id="series">.....</p>
                      </div>
                    </a>

                    <a className="showSingle " target="4">
                      <div className="AllSteps hvr-rectangle-out target_4">
                        <h3>Model</h3>
                        <p id="SelectModelName">.....</p>
                      </div>
                    </a>

                    <a className="showSingle" target="5">
                      <div className="AllSteps hvr-rectangle-out target_5 ">
                        <h3>Problem</h3>
                        <p id="Issue">.....</p>
                      </div>
                    </a>

                    <a className="showSingle" target="6">
                      <div className="AllSteps AllStepsBorder  hvr-rectangle-out target_6">
                        <h3>Contact</h3>
                        <p id="submited">...</p>
                      </div>
                    </a>
                  </div>

                  <div id="div1" className="targetDiv targetDiv_1">
                    <div className="Brandstep-Content">
                      <div className="row">
                        <div className="col-md-12 col-sm-12">
                          <div className="BrandHeading">
                            <h1>Select Product</h1>
                            <p>Step 1/6</p>
                          </div>
                        </div>

                        <div className="col-md-12 col-sm-12">
                          <div className="row">
                            <div className="col-md-6 col-sm-12">
                              <a href="javascript:void(0)">
                                <div
                                  className="DesktopPc  desktopActiv"
                                  onClick={() => {
                                    this.changeproduct("Desktop", "1");
                                  }}
                                >
                                  <div className="PcLeft">
                                    <h1 id="outerproduct">Desktop PC</h1>
                                    <p>
                                      Select this for mac Windows Linux etc.
                                    </p>
                                  </div>

                                  <div className="PcRight">
                                    <img
                                      src="assets/image/Desktop.png"
                                      width="157"
                                      height="167"
                                      alt=""
                                    />
                                  </div>
                                </div>
                              </a>
                            </div>

                            {/* <div className="col">
			       	     <div className="TextDesktop">
			       	      <h2>Or</h2>
			       	     </div>
			       	    </div> */}

                            <div className="col-md-6 col-sm-12">
                              <a href="javascript:void(0)">
                                <div
                                  className="DesktopPc laptopActive"
                                  onClick={() => {
                                    this.changeproduct("Laptop", "2");
                                  }}
                                >
                                  <div className="PcLeft">
                                    <h1>Laptop</h1>
                                    <p>
                                      Select this for mac Windows Linux etc.
                                    </p>
                                  </div>

                                  <div className="PcRight">
                                    <img
                                      src="assets/image/Laptop.png"
                                      width="199"
                                      height="175"
                                      alt=""
                                    />
                                  </div>
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    id="div2"
                    className="targetDiv  MainbOx-Close targetDiv_2"
                  >
                    <div className="Brandstep-Content">
                      <div className="row">
                        <div className="col-md-12 col-sm-12">
                          <div className="BrandHeading">
                            <h1>Select Brand</h1>
                            <p>Step 2/6</p>
                          </div>
                        </div>

                        <div className="col-md-12 col-sm-12">
                          <div className="row">
                            {this.state.Brands.map((tit, index) => (
                              <div key={index} class="col-md-2 col-sm-6 col-6">
                                <div
                                  class="BrandImage"
                                  onClick={() => {
                                    this.changeBrand(
                                      tit.brand_name,
                                      tit.brand_dt_id
                                    );
                                  }}
                                >
                                  <img
                                    src={
                                      this.state.baseUrl + "/" + tit.brand_url
                                    }
                                  />
                                </div>
                              </div>

                              // <div key={index} className="col-md-4 col-sm-12">

                              //   <div className="SeriesSelected">
                              //     <label className="checkbox">

                              //       <input
                              //         id="check1"
                              //         type="radio"
                              //         name="brand_name"
                              //         onClick={() => {
                              //           this.changeBrand(
                              //             tit.brand_name,
                              //             tit.brand_dt_id
                              //           );
                              //         }}
                              //       ></input>
                              //       <span className="tickRemove"></span>
                              //       <span className="FontSize">
                              //         {tit.brand_name}
                              //       </span>
                              //     </label>
                              //   </div>
                              // </div>
                            ))}
                            <div className="col-md-12 col-sm-12">
                              <div className="SeriesSelected">
                                <label className="checkbox">
                                  <input
                                    id="check1"
                                    type="radio"
                                    name="brand_name"
                                    onClick={() => {
                                      this.otherBrand();
                                    }}
                                  ></input>
                                  <span className="tickRemove"></span>
                                  <span className="FontSize">Others</span>
                                </label>
                              </div>
                            </div>

                            {this.state.showbrndInput == "show" ? (
                              <React.Fragment>
                                <div className="col-md-12 col-sm-12">
                                  <div className="ContactSubmit">
                                    <input
                                      type="text"
                                      id="brndInput"
                                      placeholder="Enter Your Brand Name"
                                      name="brndInput"
                                      onChange={this.handleChangeInput}
                                      value={this.state.brndInput}
                                      maxLength="13"
                                    ></input>
                                  </div>
                                </div>

                                <div className="col-md-12 col-sm-12">
                                  <div className="NextBtnRight">
                                    <div className="clearfix"></div>
                                    <button
                                      onClick={() => {
                                        this.otherBrandNext();
                                      }}
                                    >
                                      Next
                                      <i
                                        className="icon-icon_arrow"
                                        aria-hidden="true"
                                      ></i>
                                    </button>
                                  </div>
                                </div>
                              </React.Fragment>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    id="div3"
                    className="targetDiv MainbOx-Close targetDiv_3"
                  >
                    <div className="Brandstep-Content">
                      <div className="row">
                        <div className="col-md-12 col-sm-12">
                          <div className="BrandHeading">
                            <h1>Select Series</h1>
                            <p>Step 3/6</p>
                          </div>
                        </div>

                        <div className="col-md-12 col-sm-12">
                          <div className="row">
                            {this.state.SeriesName.map((tit, index) => (
                              <div key={index} className="col-md-4 col-sm-12">
                                <div className="SeriesSelected">
                                  <label className="checkbox">
                                    <input
                                      id="check2"
                                      type="radio"
                                      name="series_name"
                                      onClick={() => {
                                        this.changeseries(
                                          tit.series_name,
                                          tit.series_dt_id
                                        );
                                      }}
                                    ></input>
                                    <span className="tickRemove"></span>
                                    <span className="FontSize">
                                      {tit.series_name}
                                    </span>
                                  </label>
                                </div>
                              </div>
                            ))}

                            <div className="col-md-4 col-sm-12">
                              <div className="SeriesSelected">
                                <label className="checkbox">
                                  <input
                                    id="check2"
                                    type="radio"
                                    name="series_name"
                                    onClick={() => {
                                      this.otherSeries();
                                    }}
                                  ></input>
                                  <span className="tickRemove"></span>
                                  <span className="FontSize">Others</span>
                                </label>
                              </div>
                            </div>

                            {this.state.showSeriesInput == "show" ? (
                              <React.Fragment>
                                <div className="col-md-12 col-sm-12">
                                  <div className="ContactSubmit">
                                    <input
                                      type="text"
                                      id="seriesInput"
                                      placeholder="Enter Your Series Name"
                                      name="seriesInput"
                                      onChange={this.handleChangeInput}
                                      value={this.state.seriesInput}
                                      maxLength="13"
                                    ></input>
                                  </div>
                                </div>

                                <div className="col-md-12 col-sm-12">
                                  <div className="NextBtnRight">
                                    <div className="clearfix"></div>
                                    <button
                                      onClick={() => {
                                        this.otherSeriesNext();
                                      }}
                                    >
                                      Next
                                      <i
                                        className="icon-icon_arrow"
                                        aria-hidden="true"
                                      ></i>
                                    </button>
                                  </div>
                                </div>
                              </React.Fragment>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    id="div4"
                    className="targetDiv MainbOx-Close targetDiv_4"
                  >
                    <div className="Brandstep-Content">
                      <div className="row">
                        <div className="col-md-12 col-sm-12">
                          <div className="BrandHeading">
                            <h1>Select Model</h1>
                            <p>Step 4/6</p>
                          </div>
                        </div>

                        <div className="col-md-12 col-sm-12">
                          <div className="row">
                            {this.state.ModelName.map((tit, index) => (
                              <div key={index} className="col-md-3 col-sm-12">
                                <div
                                  class="ModelImage"
                                  onClick={() => {
                                    this.changeSelectModel(
                                      tit.description,
                                      tit.model_dt_id
                                    );
                                  }}
                                >
                                  <img
                                    src={
                                      this.state.baseUrl + "/" + tit.model_url
                                    }
                                    alt={tit.description}
                                  />
                                  <span className="FontSize">
                                    {tit.description}
                                  </span>
                                </div>
                              </div>
                            ))}

                            <div className="col-md-3 col-sm-12">
                              <div className="SeriesSelected">
                                <label className="checkbox">
                                  <input
                                    id="check3"
                                    type="radio"
                                    name="modal"
                                    onClick={() => {
                                      this.otherModals();
                                    }}
                                  ></input>
                                  <span className="tickRemove"></span>
                                  <span className="FontSize">Others</span>
                                </label>
                              </div>
                            </div>

                            {this.state.showModalInput == "show" ? (
                              <React.Fragment>
                                <div className="col-md-12 col-sm-12">
                                  <div className="ContactSubmit">
                                    <input
                                      type="text"
                                      id="modalInput"
                                      placeholder="Enter Your Modal Name"
                                      name="modalInput"
                                      onChange={this.handleChangeInput}
                                      value={this.state.modalInput}
                                      maxLength="13"
                                    ></input>
                                  </div>
                                </div>

                                <div className="col-md-12 col-sm-12">
                                  <div className="NextBtnRight">
                                    <div className="clearfix"></div>
                                    <button
                                      onClick={() => {
                                        this.otherModalNext();
                                      }}
                                    >
                                      Next
                                      <i
                                        className="icon-icon_arrow"
                                        aria-hidden="true"
                                      ></i>
                                    </button>
                                  </div>
                                </div>
                              </React.Fragment>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    id="div5"
                    className="targetDiv MainbOx-Close targetDiv_5"
                  >
                    <div className="Brandstep-Content">
                      <div className="row">
                        <div className="col-md-12 col-sm-12">
                          <div className="BrandHeading">
                            <h1>Select Problem</h1>
                            <p>Step 5/6</p>
                          </div>
                        </div>

                        <div className="col-md-12 col-sm-12">
                          <div className="row">
                            {this.state.IssuesName.map((tit, index) => (
                              <div key={index} className="col-md-4 col-sm-12">
                                <div className="SeriesSelected">
                                  <label className="checkbox">
                                    <input
                                      id="check4"
                                      type="Checkbox"
                                      name="issue_name"
                                      value={tit.issue_dt_id}
                                      onChange={this.onSelectCheck.bind(this)}
                                    ></input>
                                    <span className="tickRemove"></span>
                                    <span className="FontSize">
                                      {tit.issu_name}
                                    </span>
                                  </label>
                                </div>
                              </div>
                            ))}
                            <div className="col-md-4 col-sm-12">
                              <div className="SeriesSelected">
                                <label className="checkbox">
                                  <input
                                    id="check4"
                                    type="Checkbox"
                                    name="issue_name"
                                    value={0}
                                    onChange={this.onSelectCheck.bind(this)}
                                  ></input>
                                  <span className="tickRemove"></span>
                                  <span className="FontSize">Others</span>
                                </label>
                              </div>
                            </div>

                            {this.state.showIssueInput == "show" ? (
                              <React.Fragment>
                                <div className="col-md-12 col-sm-12">
                                  <div className="ContactSubmit">
                                    <input
                                      type="text"
                                      id="issuesInput"
                                      placeholder="Enter Your Issues"
                                      name="issuesInput"
                                      onChange={this.handleChangeInput}
                                      value={this.state.issuesInput}
                                      maxLength="20"
                                    ></input>
                                  </div>
                                </div>
                              </React.Fragment>
                            ) : null}
                            <div className="col-md-12 col-sm-12">
                              <div className="NextBtnRight">
                                <div className="clearfix"></div>
                                <button
                                  onClick={() => {
                                    this.otherIssuesNext();
                                  }}
                                >
                                  Next
                                  <i
                                    className="icon-icon_arrow"
                                    aria-hidden="true"
                                  ></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {this.state.getBackButtn == "show" ? (
                    <div className="col-md-12 col-sm-12">
                      <div className="morphText">
                        <span id="showRepar"></span>
                        <h2 id="showReparText"></h2>
                        <div className="LandingSearch">
                          <div className="clearfix"></div>
                          <button
                            onClick={() => {
                              this.anotherReq();
                            }}
                          >
                            Make Another Request !
                          </button>
                        </div>
                      </div>
                    </div>
                  ) : null}

                  <ToastContainer
                    position="top-right"
                    autoClose={2500}
                    hideProgressBar={true}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnVisibilityChange
                    draggable
                    pauseOnHover
                  />

                  <div
                    id="div6"
                    className="targetDiv MainbOx-Close targetDiv_6"
                  >
                    <div className="Brandstep-Content">
                      <div className="row">
                        <div className="col-md-12 col-sm-12">
                          <div className="BrandHeading">
                            <h1>Your Contact</h1>
                            <p>Step 6/6</p>
                          </div>
                        </div>

                        <div className="col-md-12 col-sm-12">
                          <form action="repForm" method="#">
                            <div className="row">
                              <div className="col-md-6 col-sm-12">
                                <div className="ContactSubmit">
                                  {/* <label>First name</label> */}

                                  <input
                                    type="text"
                                    id="yourName"
                                    placeholder="First Name"
                                    name="yourName"
                                    onChange={this.handleInput}
                                    value={this.state.yourName}
                                  ></input>
                                  <span
                                    id="yourNameSpan"
                                    style={{
                                      display: "none",
                                      color: "#fc3910"
                                    }}
                                  >
                                    Please enter your name
                                  </span>
                                </div>
                              </div>

                              <div className="col-md-6 col-sm-12">
                                <div className="ContactSubmit">
                                  {/* <label>Last name</label> */}
                                  <input
                                    type="text"
                                    id="LastName"
                                    placeholder="Last Name"
                                    name="LastName"
                                    onChange={this.handleInput}
                                    value={this.state.LastName}
                                  ></input>
                                </div>
                              </div>

                              <div className="col-md-6 col-sm-12">
                                <div className="ContactSubmit">
                                  {/* <label>Phone</label> */}
                                  <PhoneInput
                                    displayInitialValueAsLocalNumber="true"
                                    country="US"
                                    name="ContactNumber_update"
                                    placeholder="Enter phone number"
                                    required
                                    limitMaxLength="10"
                                    value={this.state.phone}
                                    onChange={phone => this.setState({ phone })}
                                  />
                                  <span
                                    id="numberSpan"
                                    style={{
                                      display: "none",
                                      color: "#fc3910"
                                    }}
                                  >
                                    Please enter phone number.
                                  </span>
                                  <span
                                    id="validNumberSpan"
                                    style={{
                                      display: "none",
                                      color: "#fc3910"
                                    }}
                                  >
                                    Please enter valid number!
                                  </span>

                                  {/*<input type="text" id="phoneNumb" name="phoneNumb" onChange={this.handleInput} value={this.state.phoneNumb}  ></input>*/}
                                </div>
                              </div>

                              <div className="col-md-6 col-sm-12">
                                <div className="ContactSubmit">
                                  {/* <label>Email</label> */}
                                  <input
                                    type="text"
                                    id="emailNumb"
                                    placeholder="Email"
                                    name="emailNumb"
                                    onChange={this.handleInput}
                                    value={this.state.emailNumb}
                                  ></input>
                                  <span
                                    id="emailSpan"
                                    style={{
                                      display: "none",
                                      color: "#fc3910"
                                    }}
                                  >
                                    Please enter email!
                                  </span>
                                  <span
                                    id="validEmailSpan"
                                    style={{
                                      display: "none",
                                      color: "#fc3910"
                                    }}
                                  >
                                    Please enter valid email!
                                  </span>
                                </div>
                              </div>

                              <div className="col-md-6 col-sm-12">
                                <div className="ProblemText ContactSubmit">
                                  {/* <label>Remarks</label> */}
                                  <textarea
                                    id="enterComents"
                                    name="enterComents"
                                    placeholder="Enter your comments here...."
                                    onChange={this.handleInput}
                                    value={this.state.enterComents}
                                  ></textarea>
                                </div>
                              </div>

                              <div className="col-md-6 col-sm-12">
                                {/* <div className="ContactSubmit">
                                  <label>Select Images</label>
                                  <div
                                    id="formdrag-Secound"
                                    className="formdrag1"
                                  >
                                    <p id="changecolor1">Click to add files</p>
                                    <input
                                      type="file"
                                      onChange={this.fileSelectedHandler}
                                      multiple
                                    />

                                    <i
                                      id="changeicon1"
                                      className="icon-attach_icon"
                                    ></i>
                                  </div>
                                </div> */}
                                <div
                                  class="custom-file-container"
                                  data-upload-id="myUniqueUploadId"
                                >
                                  <label className="hide">
                                    Upload File
                                    <a
                                      href="javascript:void(0)"
                                      class="custom-file-container__image-clear "
                                      title="Clear Image"
                                    >
                                      &times;
                                    </a>
                                  </label>
                                  <div
                                    id="emptyFiles"
                                    class="custom-file-container__image-preview"
                                  ></div>
                                  <label class="custom-file-container__custom-file">
                                    <input
                                      id="inputFiles"
                                      type="file"
                                      class="custom-file-container__custom-file__custom-file-input"
                                      accept="image/*"
                                      multiple
                                      aria-label="Choose File"
                                      // onChange={this.fileSelectedHandler}
                                    />
                                    <input
                                      type="hidden"
                                      name="MAX_FILE_SIZE"
                                      value="10485760"
                                    />
                                    <span
                                      id="multi-files"
                                      class="custom-file-container__custom-file__custom-file-control"
                                    ></span>
                                  </label>
                                </div>
                              </div>
                              <div className="col-md-12 col-sm-12">
                                <div className="NextButton">
                                  <button
                                    ref="btn"
                                    name="saveButton"
                                    id="saveButton"
                                    type="button"
                                    onClick={() => {
                                      this.saveRequest();
                                    }}
                                  >
                                    Send
                                  </button>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
export default Home;
