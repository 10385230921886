import React from "react";
import { compose, withProps } from "recompose";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  InfoWindow
} from "react-google-maps";

const MyMapComponent = compose(
  withProps({
    googleMapURL:
      "https://maps.googleapis.com/maps/AIzaSyAwOhp1LKDUMBY52Y_WrvwQfHF1x9s1AYI/js?v=3.exp&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: "100%" }} />,
    containerElement: <div style={{ height: "250px" }} />,
    mapElement: <div style={{ height: "100%" }} />
  }),
  withScriptjs,
  withGoogleMap
)(props => (
  <GoogleMap
    defaultZoom={12}
    defaultCenter={{ lat: 32.894661, lng: -96.886848 }}
  >
    <InfoWindow
      position={{
        lat: 32.894661 + 0.0018,
        lng: -96.886848
      }}
    >
      <div>
        <span style={{ padding: 0, margin: 0 }}>
          2720 Royal Ln #180 Dallas, TX 75229 United States
        </span>
      </div>
    </InfoWindow>
    <Marker
      position={{ lat: 32.894661, lng: -96.886848 }}
      onClick={props.onMarkerClick}
    />
  </GoogleMap>
));

class DropOffMap extends React.PureComponent {
  state = {
    isMarkerShown: false
  };

  //   componentDidMount() {
  //     // this.delayedShowMarker();
  //   }

  delayedShowMarker = () => {
    setTimeout(() => {
      this.setState({ isMarkerShown: true });
    }, 3000);
  };

  handleMarkerClick = () => {
    // this.setState({ isMarkerShown: false });
    // this.delayedShowMarker();
  };

  render() {
    return (
      <MyMapComponent
        isMarkerShown={this.state.isMarkerShown}
        onMarkerClick={this.handleMarkerClick}
      />
    );
  }
}
export default DropOffMap;
